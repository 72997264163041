import NextHead from 'next/head';

interface HeadProps {
  title?: string;
  link?: JSX.IntrinsicElements['link'][];
  meta?: JSX.IntrinsicElements['meta'][];
  noindex?: boolean;
  children?: React.ReactNode;
}

/**
 * Wrapper for the Head component, allowing for better SEO defaults
 */
const Head = ({
  title = 'Find Wedding Vendors',
  link = [],
  meta = [],
  noindex = false,
  children,
}: HeadProps) => {
  if (typeof window !== 'undefined') {
    link.unshift({ rel: 'canonical', href: window.location.href.split('?')[0], key: 'canonical' });
  }
  if (noindex) {
    meta.push({ name: 'robots', content: 'noindex', key: 'meta-robots' });
  }
  return (
    <NextHead>
      <title key="title">{title}</title>
      {link.map((attributes, index) => (
        <link {...attributes} key={attributes.key || index} />
      ))}
      {meta.map((attributes, index) => (
        <meta {...attributes} key={attributes.key || index} />
      ))}
      {children}
    </NextHead>
  );
};

export default Head;
