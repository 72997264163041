import { useState, useEffect } from 'react';

import { CoupleSearchCardView, CoupleSearchListView } from '@zola/svc-marketplace-ts-types';

import { receivedInterestedCouples } from '~/actions/interestedCoupleActions';
import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import { useToast } from '~/hooks/useToast';
import { useAppDispatch, useAppSelector } from '~/reducers';
import { getInterestedCouplesLoaded } from '~/selectors/vendors/interestedCouplesSelectors';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';
import { VENDOR_SUPPORT_EMAIL } from '~/util/vendorSupportEmail';

/**
 * Data fetcher for interested couples.
 */
export const useInterestedCouples = ({
  storefrontUuid,
  offset,
  limit,
  ready,
}: { storefrontUuid: string } & (
  | {
      offset: number;
      limit: number;
      ready: true;
    }
  | {
      ready: false;
      limit: number | null;
      offset: number | null;
    }
)) => {
  const dispatch = useAppDispatch();
  const [interestedCouples, setInterestedCouples] = useState<CoupleSearchCardView[]>([]);
  const [hasMore, setHasMore] = useState<boolean>();
  const [loaded, setLoaded] = useState(false);

  const { negativeToast } = useToast();

  useEffect(() => {
    if (ready) {
      setLoaded(false);

      ApiService.post<CoupleSearchListView>(
        `/web-marketplace-api/v1/manage/find-couples/for-storefront/${storefrontUuid}/search`,
        {
          offset,
          limit,
        }
      )
        .then((response) => {
          setInterestedCouples(response.entities);
          setHasMore(response.hasMoreResults);
        })
        .catch((error) => {
          StandardLogFn(error);
          if (error.response?.error?.category?.reason !== 'unauthorized') {
            dispatch(
              negativeToast({
                headline:
                  'An error occurred fetching your couples. Please try reloading the page. If the problem persists, please contact vendor support at ' +
                  VENDOR_SUPPORT_EMAIL,
                autoDismissInSeconds: 10,
              })
            );
          }
        })
        .finally(() => setLoaded(true));
    }
  }, [dispatch, limit, negativeToast, offset, ready, storefrontUuid]);

  return {
    interestedCouples,
    hasMore,
    loaded,
  };
};

export const useInterestedCouplesForVendorDashboard = () => {
  const dispatch = useAppDispatch();
  const interestedCouplesLoaded = useAppSelector(getInterestedCouplesLoaded);

  const { uuid } = useCurrentStorefront() || {};

  useEffect(() => {
    if (uuid && !interestedCouplesLoaded) {
      ApiService.post<CoupleSearchListView>(
        `/web-marketplace-api/v1/manage/find-couples/for-storefront/${uuid}/search`,
        {
          offset: 0,
          limit: 20,
        }
      )
        .then((response) => {
          dispatch(receivedInterestedCouples(response));
        })
        .catch((error) => {
          StandardLogFn(error);
        });
    }
  }, [dispatch, interestedCouplesLoaded, uuid]);
};
