import COLORS, { COLORS_VENDOR } from '@zola/zola-ui/src/styles/emotion/colors3';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import utils from '@zola/zola-ui/src/styles/emotion/utils';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GladlyButton = styled.button`
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 56px;
  height: 56px;
  background-color: ${COLORS_VENDOR.WINTER_100};
  color: ${COLORS.WHITE_100};
  box-shadow: none;
  ${utils.circle};
  border: none;
  align-items: center;
  justify-content: center;
  z-index: 10;

  :hover,
  :active,
  :focus {
    color: ${COLORS.CLOUD_100};
    > svg {
      opacity: 0.75;
    }
  }

  display: none;
  &.gladly-show,
  &.gladly-unread,
  .gladlyChat_open & {
    ${MEDIA_QUERY.EXCLUDE_MOBILE} {
      display: flex;
    }
  }
`;

const unreadIndicatorSize = '8px';
export const UnreadDot = styled.div`
  ${utils.circle};
  width: ${unreadIndicatorSize};
  height: ${unreadIndicatorSize};
  background-color: ${COLORS.ROSE_100};

  position: absolute;
  top: 4px;
  right: 4px;
  display: none;
  ${GladlyButton}.gladly-unread & {
    display: inline-block;
  }
  border: 1px solid ${COLORS.WHITE_100};
`;

export const GladlyStyles = css`
  #gladlyChat_container {
    a {
      color: ${COLORS.BLACK_100} !important;

      &:hover,
      &:active,
      &:focus {
        color: ${COLORS.BLACK_075} !important;
      }
    }
  }
`;
