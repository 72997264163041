import { useCallback } from 'react';

import { getBusinessCategory } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { NavigationClicked } from '@zola/tracking-contracts/src/events';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import cx from 'classnames';

import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import CircleImage from '~/components/common/images/CircleImage';
import { useAppDispatch } from '~/reducers';
import { MappedAccountStorefront } from '~/types/mappedResponseTypes';
import featureFlags from '~/util/featureFlags';

import useStorefrontCreditText from '../useStorefrontCreditText';
import { onChangeStorefront } from '../util';

import styles from './accountDropdown.module.less';

export const StorefrontLink = ({
  storefront,
  trackingProps,
}: {
  storefront: MappedAccountStorefront;
  trackingProps: Omit<NavigationClicked, 'navigation_type' | 'business_unit' | 'business_category'>;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentStorefront = useCurrentStorefront();
  const creditText = useStorefrontCreditText(storefront);
  const itemImageUrl = storefront.coverImageUrl ? `${storefront.coverImageUrl}?w=100` : '';

  const handleStorefrontClick = useCallback(
    (event: React.MouseEvent) => {
      if (storefront.uuid !== currentStorefront?.uuid) {
        trackNavigationClicked({
          navigation_type: 'GLOBAL',
          business_unit: 'VENDOR_EXPERIENCE',
          business_category: currentStorefront
            ? getBusinessCategory(currentStorefront.taxonomyKey)
            : 'UNATTRIBUTED',
          ...trackingProps,
        });
        if (featureFlags.get('debugVendorNavigationClicked')) {
          event.stopPropagation();
          event.preventDefault();
        } else {
          onChangeStorefront(storefront, dispatch)(event);
        }
      }
    },
    [storefront, currentStorefront, trackingProps, dispatch]
  );

  return (
    <div className={styles.dropdownItem} role="listitem">
      <LinkV2
        className={cx(styles.link, styles.storefront, {
          [styles.active]: storefront.uuid === currentStorefront?.uuid,
        })}
        onClick={handleStorefrontClick}
        role="button"
      >
        <CircleImage className={styles.linkImage} src={itemImageUrl} lazyLoad={false} />
        <div className={styles.info}>
          <div className={styles.linkName}>{storefront.name}</div>
          {creditText && <div className={styles.creditBalance}>{creditText}</div>}
        </div>
      </LinkV2>
    </div>
  );
};
