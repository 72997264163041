import { Fragment } from 'react';

import cx from 'classnames';

type LabelProps = {
  /** pass in custom CSS class names */
  className?: string;
  /** render the label */
  useLabel?: boolean;
  /** named element for which this label is presented */
  labelFor?: string;
  /** text to display as the label */
  labelText?: string | React.ReactNode;
  /** content wrapped by the label */
  children?: React.ReactElement;
};

const Label = ({
  className,
  useLabel = false,
  labelFor = '',
  labelText = '',
  children,
}: LabelProps) => {
  const classes = cx('form-component__label', className);

  return useLabel ? (
    <label className={classes} htmlFor={labelFor}>
      <span className="form-component__label-text">{labelText}</span>
      {children}
    </label>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export default Label;
