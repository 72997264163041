import { useCallback, useState } from 'react';

import { getSessionStorage, setSessionStorage } from '@zola-helpers/client/dist/es/util/storage';
import { CreditBalanceView } from '@zola/svc-marketplace-ts-types';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

import { receivedStorefrontCreditBalance } from '~/actions/vendors/types/vendorCreditActionTypes';
import { useToast } from '~/hooks/useToast';
import { useAppDispatch } from '~/reducers';
import ApiService from '~/util/apiService';
import { formatCreditCount } from '~/util/formatters';
import { StandardLogFn } from '~/util/logger';
import { ACCEPT_FREE_CREDITS_OFFER, DISMISS_FREE_CREDITS_OFFER } from '~/util/trackingConstants';
import { CtaType, trackCtaClicked, TrackingProductLocation } from '~/util/trackingHelper';
import { VENDOR_SUPPORT_EMAIL } from '~/util/vendorSupportEmail';

import styles from './ClaimCreditsModal.module.less';

export type ClaimCreditsModalProps = {
  hideModalFn: () => void;
  storefrontUuid: string;
  storefrontName: string;
  freeCreditOfferAmount: number;
};

const getClaimFreeCreditsSessionKey = ({ storefrontUuid }: { storefrontUuid: string }) => {
  return `CLAIM_FREE_CREDITS_DISMISSED-${storefrontUuid}`;
};

export const hasDismissedFreeCreditModal = ({
  storefrontUuid,
}: {
  storefrontUuid: string | undefined;
}) => {
  if (storefrontUuid) {
    return Boolean(getSessionStorage(getClaimFreeCreditsSessionKey({ storefrontUuid })));
  }
  return false;
};

const dismissFreeCreditsModal = ({ storefrontUuid }: { storefrontUuid: string }) => {
  setSessionStorage(getClaimFreeCreditsSessionKey({ storefrontUuid }), 'true');
};

export const ClaimCreditsModalContent = ({
  hideModalFn,
  storefrontUuid,
  freeCreditOfferAmount,
  setRedeemed,
}: ClaimCreditsModalProps & {
  setRedeemed: (newBalance: CreditBalanceView) => void;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const { negativeToast } = useToast();

  const dismiss = useCallback(() => {
    trackCtaClicked({
      location: TrackingProductLocation.CLAIM_CREDITS_MODAL,
      ctaType: CtaType.BUTTON,
      ctaId: DISMISS_FREE_CREDITS_OFFER,
      storefrontUuid,
    });

    dismissFreeCreditsModal({ storefrontUuid });
    hideModalFn();
  }, [hideModalFn, storefrontUuid]);

  const claimCredits = useCallback(() => {
    trackCtaClicked({
      location: TrackingProductLocation.CLAIM_CREDITS_MODAL,
      ctaType: CtaType.BUTTON,
      ctaId: ACCEPT_FREE_CREDITS_OFFER,
      storefrontUuid,
    });

    ApiService.post<CreditBalanceView>(
      `/web-marketplace-api/v1/manage/vendor-credits/accounts/storefronts/${storefrontUuid}/free-credit-redemption`
    )
      .then((creditBalanceView) => {
        dispatch(receivedStorefrontCreditBalance(creditBalanceView));
        setRedeemed(creditBalanceView);
      })
      .catch((error) => {
        StandardLogFn(error);
        negativeToast({
          headline: `An error occurred redeeming your free credit offer. Please try again or contact ${VENDOR_SUPPORT_EMAIL}.`,
        });
      });
  }, [dispatch, negativeToast, setRedeemed, storefrontUuid]);

  return (
    <div className={styles.claimCreditsModal} data-testid="ClaimCreditsModal">
      <div className={styles.body}>
        <h2 className={styles.hed}>
          Welcome back! Here&apos;s a gift from&nbsp;us!&nbsp;
          <span role="img" aria-label="heart-shaped gift box">
            💝
          </span>
        </h2>
        <p className={styles.dek}>
          Your free period has come to an end, but since we haven&apos;t seen you in a while we want
          to give you a little something extra.
        </p>
        <p className={styles.dek}>
          As a thank you for being a Zola vendor, here&apos;s{' '}
          <span className={styles.bold}>{freeCreditOfferAmount} more free credits</span> so your
          next inquiry is on us.
        </p>
      </div>
      <div className={styles.controls}>
        <ButtonV3 variant="secondary" onClick={dismiss}>
          Not Right Now
        </ButtonV3>
        <ButtonV3 variant="primary" onClick={claimCredits}>
          Accept Credits
        </ButtonV3>
      </div>
    </div>
  );
};

export const ClaimCreditsRedeemedContent = ({
  hideModalFn,
  storefrontName,
  creditBalance,
}: ClaimCreditsModalProps & { creditBalance: CreditBalanceView }): JSX.Element => {
  return (
    <div className={styles.creditsRedeemedModal} data-testid="CreditsRedeemedModal">
      <div className={styles.body}>
        <h2 className={styles.hed}>
          Here&apos;s to great connections!&nbsp;
          <span role="img" aria-label="party popper">
            🎉
          </span>
        </h2>
        <p className={styles.dek}>
          {storefrontName} now has a total of{' '}
          <span className={styles.bold}>{formatCreditCount(creditBalance.balance)}</span> to connect
          with inquiring couples. These are available to your whole team to use.
        </p>
      </div>
      <div className={styles.controls}>
        <ButtonV3
          component="a"
          role="link"
          variant="secondary"
          href="/inspire/vendors/purchase-history/balance-history"
        >
          Credit History
        </ButtonV3>
        <ButtonV3 variant="primary" onClick={hideModalFn}>
          Done
        </ButtonV3>
      </div>
    </div>
  );
};

export const ClaimCreditsModal = (props: ClaimCreditsModalProps) => {
  const [creditBalance, setRedeemed] = useState<CreditBalanceView>();

  if (creditBalance) {
    return <ClaimCreditsRedeemedContent {...props} creditBalance={creditBalance} />;
  }

  return <ClaimCreditsModalContent {...props} setRedeemed={setRedeemed} />;
};

export default ClaimCreditsModal;
