import { useState, useEffect } from 'react';

import { CreditBalanceView } from '@zola/svc-marketplace-ts-types';

import { useAppSelector } from '~/reducers';
import { getCreditsByStorefront } from '~/selectors/vendors/creditsSelectors';
import { MappedAccountStorefront } from '~/types/mappedResponseTypes';
import { getCreditText } from '~/util/creditUtils';

export const useStorefrontCreditText = (storefront: MappedAccountStorefront): string | null => {
  const creditsByStorefront = useAppSelector(getCreditsByStorefront);

  const [creditText, setCreditText] = useState<string | null>(null);
  useEffect(() => {
    const creditBalanceView: CreditBalanceView | undefined = creditsByStorefront[storefront.uuid];

    if (!creditBalanceView) {
      return;
    }

    setCreditText(getCreditText(storefront.published, creditBalanceView));
  }, [storefront, creditsByStorefront]);

  return creditText;
};

export default useStorefrontCreditText;
