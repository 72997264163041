/** If this is set in session data to a truthy value, an authentication request will end up showing a login/signup dialog instead of launching an onboarding flow */
export const MARKETPLACE_SKIP_ONBOARDING = 'marketplaceSkipOnboarding';

// the placeholder names we use for the user's first name and last name
export const USER_FIRST_NAME_PLACEHOLDER = 'Your';
export const USER_LAST_NAME_PLACEHOLDER = 'Name';

// the placeholder names we use for the user's partner first name and last name
export const PARTNER_FIRST_NAME_PLACEHOLDER = 'Partner';
export const PARTNER_LAST_NAME_PLACEHOLDER = 'Name';

// user settings stored on the backend for showing promotions
export const CROSS_SELL_VIEWED = 'marketplace:post-third-inquiry-cross-sell-modal:time-last-seen';

export const VENDOR_CURRENT_STOREFRONT_COOKIE = 'inspireVendorCurrentStorefront';
