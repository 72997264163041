import { setCurrentStorefront } from '~/actions/vendorAccountActions';
import { SelectPlanUrl } from '~/pages/vendors/Credits/utils/urlHelpers';
import { AppDispatch } from '~/reducers';
import { MappedAccountStorefront } from '~/types/mappedResponseTypes';
import { getVendorStartUrl } from '~/util/vendorUtils';

/** Generates a click handler to set a cookie for the current storefront and navigate */
export const onChangeStorefront = (storefront: MappedAccountStorefront, dispatch: AppDispatch) => {
  return (event: React.MouseEvent) => {
    const { uuid } = storefront;

    event.preventDefault();
    event.stopPropagation();

    dispatch(setCurrentStorefront(uuid))
      .then(() => {
        const { pathname } = window.location || {};
        // do not redirect to start URL if vendor is on the credit packages page
        if (pathname.includes(SelectPlanUrl)) {
          // force reload to ensure redux is properly set to new storefront
          window.location.reload();
        } else {
          window.location.href = getVendorStartUrl(storefront);
        }
      })
      .catch(() => null);
  };
};
