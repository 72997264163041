import { useEffect, useState } from 'react';

import type { WUserSettingView } from '@zola/svc-web-api-ts-client';

import { getVendorUser } from '~/actions/vendorAccountActions';
import { useAnyModalShown } from '~/hooks/useAnyModalShown';
import { useAppDispatch, useAppSelector } from '~/reducers';
import { getIsAuthenticated } from '~/selectors/vendorAccountSelectors';
import { MappedVendorUserView } from '~/types/responseTypes';
import { VendorStorefrontDetails } from '~/types/storefrontDetails';
import ApiService from '~/util/apiService';
import { isAutomatedTest } from '~/util/automatedTestUtils';
import { StandardLogFn } from '~/util/logger';

import useCurrentStorefront from '../../hooks/useCurrentStorefront';
import { useNotificationModal } from './TextNotificationsModal';

const KEY = 'marketplace:vendor:sms-notification-modal-shown';

/**
 * Gets if Notifications modal has been seen:
 */

const getHasSeenNotificationsModal = async () => {
  return ApiService.get<WUserSettingView>(
    `/web-marketplace-api/v1/vendor-user/settings/${KEY}`
  ).then((value) => Boolean(value.flag));
};

/**
 * Marks Notifications modal as seen: it will never be
 * shown again.
 */

const markNotificationsModalSeen = async () => {
  return ApiService.post('/web-marketplace-api/v1/vendor-user/settings', {
    key: KEY,
  });
};

/**
 * Shows SMS notifications modal to users
 * Handles all conditionals required for the modal to be shown
 * And Marks the modal as seen, to never be shown again
 */

export const useNotificationsModal = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const [vendorUserInfo, setVendorUserInfo] = useState<MappedVendorUserView>();
  const [ready, setReady] = useState(false);
  const [hasSeenModal, setHasSeenModal] = useState(false);

  const anyModalShown = useAnyModalShown();

  const storefrontDetails = useCurrentStorefront() as VendorStorefrontDetails;

  const { publishedAt } = storefrontDetails || {};

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      getVendorUser()
        .then((vendorUser) => {
          if (vendorUser) {
            setVendorUserInfo({ ...vendorUser });
          }
        })
        .catch(StandardLogFn);
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      getHasSeenNotificationsModal()
        .then((seenModal) => {
          setHasSeenModal(seenModal);
        })
        .catch(StandardLogFn)
        .finally(() => setReady(true));
    }
  }, [isAuthenticated]);

  const published = Boolean(publishedAt);
  const userOptedIn = Boolean(vendorUserInfo?.smsOptedInAt);
  const unsubscribed = vendorUserInfo !== undefined && vendorUserInfo?.phoneUnsubscribed;
  const { showNotificationModal } = useNotificationModal();

  useEffect(() => {
    if (
      !isAutomatedTest() &&
      !anyModalShown &&
      vendorUserInfo !== undefined &&
      published &&
      !unsubscribed &&
      !userOptedIn &&
      ready &&
      !hasSeenModal
    ) {
      showNotificationModal();
      markNotificationsModalSeen().catch(StandardLogFn);
    }
  }, [
    anyModalShown,
    dispatch,
    hasSeenModal,
    published,
    ready,
    showNotificationModal,
    unsubscribed,
    userOptedIn,
    vendorUserInfo,
  ]);
};
