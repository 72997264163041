export const YourPlansUrl = '/inspire/vendors/your-plans';

export const SelectPlanUrl = '/inspire/vendors/select-plan';

export const HowPlansWorkUrl = '/inspire/vendors/how-plans-work';

export const AboutPlansUrl = '/inspire/vendors/about-plans';

export const ConnectionPolicyUrl = '/inspire/vendors/connection-protection-policy';

export const TermsPageUrl = '/inspire/vendors/plans-terms';

export const ResourcesPageUrl = '/inspire/vendors/resources';
