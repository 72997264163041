import { useEffect } from 'react';

import _once from 'lodash/once';

import { receivedFavorites } from '~/actions/favoriteActions';
import { useUserContext } from '~/contexts/UserContext';
import { AppDispatch, useAppDispatch, useAppSelector } from '~/reducers';
import {
  getFavoritePhotoBoardUuid,
  getFavoritePhotosLoaded,
  getFavoriteStorefrontsLoaded,
  getFavoriteStorefrontEntity,
} from '~/selectors/favoritesSelectors';
import { MappedBoardView } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import { isGuest } from '~/util/userContextUtils';

const getFavoritesOnce = _once((dispatch: AppDispatch) => {
  return ApiService.get<MappedBoardView>(`/web-marketplace-api/v1/favorites`)
    .then((response: MappedBoardView) => {
      // Ifffff this is moved outside the once,  we'll update redux
      // each time the effect hook runs (even though we've only fetched from the
      // backend once)
      dispatch(receivedFavorites(response));
      return null;
    })
    ?.catch(() => null);
});

/**
 * Fetcher to get favorites once and store them in Redux
 */
const useFavorites = () => {
  const userContext = useUserContext();
  const dispatch = useAppDispatch();
  const favoritePhotoBoardUuid = useAppSelector(getFavoritePhotoBoardUuid);
  const photosLoaded = useAppSelector(getFavoritePhotosLoaded);
  const storefrontsLoaded = useAppSelector(getFavoriteStorefrontsLoaded);
  const storefrontFavoritesCount = useAppSelector(getFavoriteStorefrontEntity)?.allIds?.length || 0;

  useEffect(() => {
    if (!isGuest(userContext)) {
      getFavoritesOnce(dispatch).catch(() => null);
    }
  }, [dispatch, userContext]);

  return {
    favoritePhotoBoardUuid,
    photosLoaded,
    storefrontsLoaded,
    storefrontFavoritesCount,
    isGuest,
  };
};

export default useFavorites;
