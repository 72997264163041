import { Fragment } from 'react';

import ToastV2 from '@zola/zola-ui/src/components/ToastsV2/ToastV2';
import { useForceUpdateOnWindowResize } from '@zola/zola-ui/src/hooks/useForceUpdateOnWindowResize';

import cx from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { useAnyModalShown } from '~/hooks/useAnyModalShown';
import { useAnyToastShown } from '~/hooks/useAnyToastShown';
import { trackCtaClicked } from '~/util/trackingHelper';

import styles from './UnrespondedMessagesNotification.module.less';

const onToastClick = () => {
  trackCtaClicked({
    ctaId: 'TOAST_NOTIFICATION',
  });
};

interface UnrespondedMessagesNotificationProps {
  unrespondedTotal: number;
  /** If there is a banner on the page, push the top of the toast down a smidge */
  hasBanner?: boolean;
}

/**
 * If the couple has any unresponded message in their inquiries, show them a toast
 * to boost response rates.
 */
const UnrespondedMessagesNotification = (props: UnrespondedMessagesNotificationProps) => {
  const { unrespondedTotal, hasBanner = false } = props;
  const anyToastShown = useAnyToastShown({ ignoreClassName: 'unrespondedMessage' });
  const anyModalShown = useAnyModalShown();

  useForceUpdateOnWindowResize();

  const message = unrespondedTotal === 1 ? `message needs reply` : `messages need replies`;
  const showUnrespondedMessages = !anyToastShown && !anyModalShown && unrespondedTotal > 0;

  return (
    <Fragment>
      {showUnrespondedMessages && (
        <div className={styles.unrespondedMessageWrapper}>
          <TransitionGroup>
            <CSSTransition classNames="fadeOut" timeout={{ exit: 1000 }}>
              <ToastV2
                onRemove={() => null}
                toast={{
                  id: 4200,
                  type: 'POSITIVE',
                  className: cx(styles.unrespondedMessage, { [styles.withBanner]: hasBanner }),
                  headline: `${unrespondedTotal} ${message}`,
                  autoDismissInSeconds: 0,
                  linkText: 'View',
                  linkDestination: '/wedding-vendors/inquiries/active',
                  onLinkClick: onToastClick,
                }}
              />
            </CSSTransition>
          </TransitionGroup>
        </div>
      )}
    </Fragment>
  );
};

export default UnrespondedMessagesNotification;
