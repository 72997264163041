import { FavButtonElementProps, RenderFavoriteButton, FavoriteButtonVariant } from '../types';
import CircleFavButton from './CircleFavButton';
import CircleZuiFavButton from './CircleZuiFavButton';
import CircleZuiV3FavButton from './CircleZuiV3FavButton';
import FullWidthFavButton from './FullWidthFavButton';
import HeartFavButton from './HeartFavButton';
import V3FavButton from './v3FavButton';

/**
 * Render function to show one of 6 (7?) different favorite button styles
 *
 * Danger: hot mess ahead.
 */
export const renderDefaultFavoriteButton = (
  props: FavButtonElementProps & { variant?: FavoriteButtonVariant }
): RenderFavoriteButton => {
  const { variant = 'compact', ...rest } = props;
  if (variant === 'full') {
    return (renderProps) => FullWidthFavButton({ ...rest, ...renderProps });
  }

  if (variant === 'circle') {
    return (renderProps) => CircleFavButton({ ...rest, ...renderProps });
  }

  if (variant === 'circleZui') {
    return (renderProps) => CircleZuiFavButton({ ...rest, ...renderProps });
  }

  if (variant === 'circleZuiV3') {
    return (renderProps) => CircleZuiV3FavButton({ ...rest, ...renderProps });
  }

  if (variant === 'v3') {
    return (renderProps) => V3FavButton({ ...rest, ...renderProps });
  }

  return (renderProps) =>
    HeartFavButton({ ...rest, ...renderProps, isCircleOutline: variant === 'circleOutline' });
};
