import cx from 'classnames';
import LazyLoad from 'react-lazyload';
import './CircleImage.less';

interface CircleImageProps {
  src: string;
  className?: string;
  lazyLoad?: boolean;
}

const CircleImage = ({
  className = '',
  src = '',
  lazyLoad = true,
}: CircleImageProps): JSX.Element => {
  const classes = cx('marketplace__circle-image', className);
  const placeholder = <div />;
  const style = { backgroundImage: `url(${src})` };

  return (
    <div className={classes}>
      {lazyLoad ? (
        <LazyLoad placeholder={placeholder} offset={100} once>
          <div className="circle-image__image" style={style} />
        </LazyLoad>
      ) : (
        <div className="circle-image__image" style={style} />
      )}
    </div>
  );
};

export default CircleImage;
