import { useCallback } from 'react';

import {
  createFavoriteStorefront,
  deleteFavoriteStorefront,
  goToVendorFavoriteOnboarding,
} from '~/actions/favoriteActions';
import useFavorites from '~/hooks/useFavorites';
import { useAppSelector, useAppDispatch } from '~/reducers';
import { getFavoriteStorefront, getFavoriteStorefrontEntity } from '~/selectors/favoritesSelectors';
import { BoardSourceEnum } from '~/types/responseTypes';

import { FavoriteButtonWrapper } from './internal/FavoriteButtonWrapper';
import { showFavoritedVendorToast } from './showFavoritedVendorToast';
import { FavoriteButtonProps } from './types';

export type StorefrontFavoriteButtonProps = FavoriteButtonProps & {
  afterFavoriteCallback?: () => void;
  showCustomInitialToasts?: boolean;
} & (
    | {
        suppressToast: true;
      }
    | {
        suppressToast?: false;
        vendorPhotoUuid?: string;
      }
  );

export const StorefrontFavoriteButton = (props: StorefrontFavoriteButtonProps) => {
  const {
    afterFavoriteCallback,
    location,
    shelfCustomized,
    showCustomInitialToasts,
    suppressToast,
    tilePosition,
    uuid,
    children,
  } = props;

  const { vendorPhotoUuid } = props.suppressToast ? { vendorPhotoUuid: undefined } : props;

  const dispatch = useAppDispatch();

  const vendorFavoriteCount = useAppSelector(getFavoriteStorefrontEntity)?.allIds?.length || 0;

  const favoritesInfo = useFavorites();

  const createFavorite = useCallback(() => {
    const createFavoriteParams = {
      boardUuid: favoritesInfo.favoritePhotoBoardUuid || 'ERROR',
      source: BoardSourceEnum.WEB,
      location,
      tilePosition,
      shelfCustomized,
    };

    dispatch(
      createFavoriteStorefront({
        storefrontUuid: uuid,
        ...createFavoriteParams,
      })
    )
      .then(() => {
        if (!suppressToast) {
          dispatch(
            showFavoritedVendorToast({
              vendorFavoriteCount,
              uuid,
              vendorPhotoUuid,
              showCustomInitialToasts,
              location,
            })
          );
        }

        // special favorite treatment for vendor blender cards
        if (afterFavoriteCallback) {
          afterFavoriteCallback();
        }
      })
      .catch(() => null);
  }, [
    afterFavoriteCallback,
    dispatch,
    favoritesInfo.favoritePhotoBoardUuid,
    location,
    shelfCustomized,
    showCustomInitialToasts,
    suppressToast,
    tilePosition,
    uuid,
    vendorFavoriteCount,
    vendorPhotoUuid,
  ]);

  const deleteFavorite = useCallback(
    (favoriteUuid: string) => {
      dispatch(
        deleteFavoriteStorefront({
          uuid: favoriteUuid,
          storefrontUuid: uuid,
          tilePosition,
          location,
          shelfCustomized,
        })
      ).catch(() => null);
    },
    [dispatch, location, shelfCustomized, tilePosition, uuid]
  );

  const handlePreAuthFavorite = useCallback(() => {
    goToVendorFavoriteOnboarding(uuid);
  }, [uuid]);

  const favoriteItem = useAppSelector(getFavoriteStorefront(uuid));

  return (
    <FavoriteButtonWrapper
      createFavorite={createFavorite}
      deleteFavorite={deleteFavorite}
      handlePreAuthFavorite={handlePreAuthFavorite}
      favoriteItem={favoriteItem}
    >
      {children}
    </FavoriteButtonWrapper>
  );
};
