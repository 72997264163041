import ZolaLogo from '@zola/zola-ui/src/components/ZolaLogo';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';

import cx, { Argument as ClassName } from 'classnames';

import styles from './vendorLogo.module.less';

type VendorLogoProps = {
  className?: ClassName;
  color?: string;
};

const VendorLogo = (props: VendorLogoProps) => {
  const { className, color = COLORS3.BLACK_100 } = props;
  const classes = cx(styles.vendorLogo, className);
  return (
    <div className={classes}>
      <ZolaLogo className={styles.zolaLogo} type="vendor" color={color} />
    </div>
  );
};

export default VendorLogo;
