import { Fragment } from 'react';

import { NavigationClicked } from '@zola/tracking-contracts/src/events';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { CaretV2Icon } from '@zola/zola-ui/src/components/SvgIcons/CaretV2';

import cx from 'classnames';

import CircleImage from '~/components/common/images/CircleImage';
import { useAppSelector } from '~/reducers';
import { getCurrentStorefront } from '~/selectors/vendorAccountSelectors';

import { addNextLevelTrackingProps, MenuDropdown, MenuType } from '../useNavigationLinks';
import { LogoutButton } from './LogoutButton';
import { MobileNavPanel } from './MobileNavMenu';
import { useStorefrontMenu } from './useStorefrontMenu';

import styles from './mobileNav.module.less';

interface StorefrontMenuProps {
  /** Is the menu expanded */
  expanded: boolean;

  /** Callback to toggle (open or close) the menu */
  toggleExpanded: () => void;

  trackingProps: Omit<NavigationClicked, 'navigation_type' | 'business_unit' | 'business_category'>;
}

const MENU: MenuDropdown = {
  type: MenuType.DROPDOWN,
  items: [],
  text: '',
  to: '#',
  trackingIdentifier: 'ACCOUNT_MENU',
};

/**
 * Right hand menu with the storefront image and controls for changing storefronts
 */
export const StorefrontMenu = ({
  expanded,
  toggleExpanded,
  trackingProps,
}: StorefrontMenuProps): JSX.Element => {
  const currentStorefront = useAppSelector(getCurrentStorefront);

  const items = useStorefrontMenu();

  const { coverImageUrl = null } = currentStorefront || {};
  const itemImageUrl = coverImageUrl ? `${coverImageUrl}?w=100` : '';

  return (
    <Fragment>
      <LinkV2
        className={styles.storefrontMenu}
        onClick={toggleExpanded}
        role="button"
        data-testid="storefront-menu-toggle"
      >
        <CircleImage src={itemImageUrl} lazyLoad={false} />
        <CaretV2Icon title="Caret" width={12} />
      </LinkV2>
      <div
        className={cx(styles.dropdownMenu, styles.storefrontDropdownMenu, {
          [styles.activeDropdown]: expanded,
        })}
        // This sets the max height of the panel to 100% of the window height - 70px for the nav
        // 100vh does not work on mobile because the viewport height can be more than the window height
        // when the browser is adding buttons or nav space at the top and bottom of the screen
        style={{ maxHeight: expanded ? `${window.innerHeight - 70}px` : '' }}
        onClick={toggleExpanded}
        role="presentation"
      >
        <MobileNavPanel
          menu={{ ...MENU, items: items }}
          trackingProps={{ ...trackingProps, navigation_type: 'GLOBAL' }}
          isOpen
        />
        <LogoutButton
          trackingProps={{
            ...addNextLevelTrackingProps(trackingProps, 'LOGOUT', items.length + 1),
          }}
        />
      </div>
    </Fragment>
  );
};
