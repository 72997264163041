import { CoupleSearchListView } from '@zola/svc-marketplace-ts-types';

import type { RootState } from '~/reducers';

export const getInterestedCouples = (state: RootState): CoupleSearchListView | null => {
  return state.vendors.interestedCouplesData?.interestedCouples;
};

export const getInterestedCouplesLoaded = (state: RootState): boolean => {
  return state.vendors.interestedCouplesData?.loaded;
};
