import { useEffect, useState, useCallback } from 'react';

import { useUserContext } from '~/contexts/UserContext';

import { RenderFavoriteButton } from '../types';

type FavoriteButtonWrapperProps = {
  createFavorite: () => void;
  deleteFavorite: (favoriteUuid: string) => void;
  handlePreAuthFavorite: () => void;

  /** Is the storefront or photo a favorite.  The uuid is for the favorite (not the storefront uuid or photo uuid) */
  favoriteItem: { uuid: string } | undefined;

  children: RenderFavoriteButton;
};

/**
 * Helper to bundle up the logic for favoriting / unfavoriting an item
 * regardless of what _type_ of thing is being favorited (storefront or photo).
 *
 * This builds the props passed to the RenderFavoriteButton children function.
 */
export const FavoriteButtonWrapper = ({
  createFavorite,
  deleteFavorite,
  handlePreAuthFavorite,
  favoriteItem,
  children,
}: FavoriteButtonWrapperProps): JSX.Element => {
  const [isFavorited, setIsFavorited] = useState(false);
  const userContext = useUserContext();

  useEffect(() => {
    setIsFavorited(!!favoriteItem);
  }, [favoriteItem]);

  const favorite = useCallback(() => {
    if (userContext?.is_guest) {
      handlePreAuthFavorite();
    } else {
      createFavorite();
    }
  }, [createFavorite, handlePreAuthFavorite, userContext?.is_guest]);

  const unfavorite = useCallback(() => {
    if (favoriteItem) {
      deleteFavorite(favoriteItem.uuid);
    }
  }, [deleteFavorite, favoriteItem]);

  const clickHandler = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (isFavorited) {
        unfavorite();
      } else {
        favorite();
      }

      if (userContext?.is_guest) {
        setIsFavorited(false);
      } else {
        setIsFavorited(!isFavorited);
      }
    },
    [favorite, isFavorited, unfavorite, userContext?.is_guest]
  );

  return children({ onClick: clickHandler, isFavorited });
};
