import {
  isExploreVendorsPage,
  isVendorSearchResultsPage,
  isVendorStateLandingPage,
  isVendorStorefrontPage,
} from '@zola-helpers/client/dist/es/marketplace/vendorSearchUtils';
import { isClient } from '@zola-helpers/client/dist/es/util/nextUtils';
import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';

const PRE_AUTH_NAV_HEIGHTS = {
  mobile: {
    announcement: 0,
    utility: 56,
    utilityNoSecondary: 56,
    secondary: 52,
    tertiary: 0,
  },
  desktop: {
    announcement: 56,
    utility: 77,
    utilityNoSecondary: 77,
    secondary: 56,
    tertiary: 0,
  },
};

const POST_AUTH_NAV_HEIGHTS = {
  mobile: {
    announcement: 0,
    utility: 56,
    utilityNoSecondary: 56,
    secondary: 40,
    tertiary: 52,
  },
  desktop: {
    announcement: 56,
    utility: 60,
    utilityNoSecondary: 76,
    secondary: 56,
    tertiary: 0,
  },
};

/**
 * Determines whether the current path is a Real Weddings page
 *
 * @example
 * `/inspiration`
 * `/inspiration/wedding/natural-boho-barn-wedding`
 * `/inspiration/photo/natural-boho-barn-wedding-30`
 */
const isRealWeddingsPage = () => {
  if (!isClient()) return false;
  const pathRegExp = new RegExp('^/inspiration(?:/wedding|/photo)?');
  return pathRegExp.test(window.location.pathname);
};

/**
 * Determines whether the current path is the favorites home page
 */
const isFavoritesHomePage = () => {
  if (!isClient()) return false;
  return window.location.pathname.startsWith('/favorites/home');
};

/**
 * Determines whether to show the secondary nav
 */
export const showSecondaryNav = (isGuest = false) => {
  if (isGuest && isDesktop() && isRealWeddingsPage()) return true;
  return !isRealWeddingsPage() && !isVendorStorefrontPage() && !isFavoritesHomePage();
};

/**
 * Determines whether to show the tertiary nav
 */
export const showTertiaryNav = (isGuest: boolean) => {
  const showForPostAuthMobile =
    !isGuest &&
    !isDesktop() &&
    showSecondaryNav() &&
    (isExploreVendorsPage() || isVendorStateLandingPage());
  const showForPreAuthMobile =
    isGuest &&
    !isDesktop() &&
    showSecondaryNav() &&
    (isExploreVendorsPage() || isVendorSearchResultsPage() || isVendorStateLandingPage());
  return showForPostAuthMobile || showForPreAuthMobile;
};

/**
 * Gets the mobile & desktop nav heights based on which components of the nav are shown
 */
export const getNavHeights = (
  isGuest: boolean,
  showAnnouncement: boolean,
  showSecondary: boolean,
  showTertiary: boolean
) => {
  const heights = isGuest ? PRE_AUTH_NAV_HEIGHTS : POST_AUTH_NAV_HEIGHTS;
  let mobile = heights.mobile.utility;
  let desktop = heights.desktop.utility;

  if (!isGuest && !showSecondary) {
    desktop = heights.desktop.utilityNoSecondary;
  }

  if (showAnnouncement) {
    mobile += heights.mobile.announcement;
    desktop += heights.desktop.announcement;
  }

  if (showSecondary) {
    mobile += heights.mobile.secondary;
    desktop += heights.desktop.secondary;
  }

  if (showTertiary) {
    mobile += heights.mobile.tertiary;
    desktop += heights.desktop.tertiary;
  }

  return {
    mobile,
    desktop,
  };
};

/**
 * Parse the HTML from the /v1/nav/get endpoint for the nav script & style tags
 *
 * @param navHtml The HTML from the endpoint
 */
export const getNavSource = (navHtml: string | null): { navScript?: string; navStyle?: string } => {
  let navScript;
  let navStyle;

  if (isClient() && navHtml) {
    const regexScript = /src="(.*?)"/;
    const scriptTagMatch = regexScript.exec(navHtml);

    if (scriptTagMatch) {
      navScript = scriptTagMatch[1];
    }

    const regexStyle = /href="(.*?)"/;
    const styleTagMatch = regexStyle.exec(navHtml);

    if (styleTagMatch) {
      navStyle = styleTagMatch[1];
    }
  }

  return {
    navScript,
    navStyle,
  };
};
