import { useState } from 'react';

import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

interface UseAnyToastShownProps {
  ignoreClassName?: string;
}

/**
 * Check whether any toast has been shown on this page
 */
export const useAnyToastShown = (props?: UseAnyToastShownProps) => {
  const { ignoreClassName } = props || {};
  const [anyToastShown, setAnyToastShown] = useState(false);

  useEffectOnce(() => {
    const body = document.querySelector('body') as HTMLBodyElement;
    const observer = new MutationObserver(() => {
      const toast = document.querySelector('[class*="zui-toast--"]');
      if (toast && (!ignoreClassName || !toast.className.includes(ignoreClassName))) {
        setAnyToastShown(true);
      }
    });

    observer.observe(body, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  });

  return anyToastShown;
};
