/* eslint-disable react/no-danger */
import { RelatedLinkView } from '@zola/svc-marketplace-ts-types';

import type { WithContext, FAQPage } from 'schema-dts';

import { FaqContent } from '~/types/types';

export const getAbsoluteUrl = (url: string): string => {
  return new URL(url, `https://www.zola.com`).toString();
};

export const getFaqSchema = (content: FaqContent[]): WithContext<FAQPage> => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: content.map(({ question, answer }) => ({
    '@type': 'Question',
    name: question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: answer,
    },
  })),
});

export const mapGraphiteRelatedLinks = (links: RelatedLinkView[]) => {
  return links.map((link) => ({
    title: link.title,
    url_path: link.urlPath,
    url: `https://www.zola.com${link.urlPath}`,
    image_url: '',
    type: '',
  }));
};

export const CANONICAL_FACET_TEXT_OVERRIDES_SENTENCE_CASE = () => ({
  'target-couple-venue-preference-ballrooms-banquet-halls': 'Ballroom & banquet hall',
  'target-couple-venue-preference-farms-barns-ranches': 'Barn & farm',
  'target-couple-venue-preference-parks-gardens': 'Park & garden',
  'target-couple-venue-preference-historic-estates-mansions': 'Historic estate & mansion',
  'target-couple-venue-preference-urban-spaces': 'Urban',
  'target-couple-venue-preference-hotels-inns-resorts': 'Hotel & resort',
  'target-couple-venue-preference-industrial-buildings': 'Industrial',
  'target-couple-venue-preference-restaurants-breweries': 'Restaurant & brewery',
  'target-couple-venue-preference-lodges-retreats': 'Retreat',
  'target-couple-venue-preference-beaches-waterfronts': 'Beach & waterfront',
  'target-couple-venue-preference-golf-country-clubs': 'Country club & golf club',
  'target-couple-venue-preference-museums-galleries': 'Museum & gallery',
  'target-couple-venue-preference-vineyards-wineries': 'Vineyard & winery',
  'target-couple-venue-preference-zoos': 'Aquarium & zoo',
});

export const CANONICAL_FACET_TEXT_OVERRIDES_TITLE_CASE = () => {
  return {
    ...CANONICAL_FACET_TEXT_OVERRIDES_SENTENCE_CASE(),
    'target-couple-venue-preference-ballrooms-banquet-halls': 'Ballroom & Banquet Hall',
    'target-couple-venue-preference-farms-barns-ranches': 'Barn & Farm',
    'target-couple-venue-preference-parks-gardens': 'Park & Garden',
    'target-couple-venue-preference-historic-estates-mansions': 'Historic Estate & Mansion',
    'target-couple-venue-preference-hotels-inns-resorts': 'Hotel & Resort',
    'target-couple-venue-preference-restaurants-breweries': 'Restaurant & Brewery',
    'target-couple-venue-preference-beaches-waterfronts': 'Beach & Waterfront',
    'target-couple-venue-preference-golf-country-clubs': 'Country Club & Golf Club',
    'target-couple-venue-preference-museums-galleries': 'Museum & Gallery',
    'target-couple-venue-preference-vineyards-wineries': 'Vineyard & Winery',
    'target-couple-venue-preference-zoos': 'Aquarium & Zoo',
    'venue-setting-covered-outdoor-space': 'Covered Outdoor',
    'venue-service-level-limited-services': 'Select Services',
    'venue-service-level-raw-space': 'Raw Space',
    'venue-service-level-all-inclusive': 'All-Inclusive',
    'photography-style-fine-art': 'Fine Art',
    'florist-service-level-a-la-carte': 'A La Carte',
    'florist-service-level-full-service-floral-design': 'Full-Service Floral Design',
    'caterers-cuisine-types-bbq': 'BBQ',
    'caterers-cuisine-types-eastern-european': 'Eastern European',
    'caterers-cuisine-types-latin-american': 'Latin American',
    'caterers-cuisine-types-middle-eastern': 'Middle Eastern',
    'music-genre-arabic-persia': 'Arabic or Persian',
    'music-genre-bollywood-bhangra': 'Bollywood or Bhangra',
    'music-genre-rap-hip-hop': 'Rap/Hip Hop',
    'music-genre-scottish-irish': 'Scottish or Irish',
    'music-genre-motown-soul': 'Motown/Soul',
    'music-genre-edm-house': 'EDM/House',
    'music-genre-gospel-christian': 'Gospel/Christian',
    'music-genre-funk': 'Discos/Funk',
    'music-genre-country-folk': 'Country/Folk',
    'music-genre-pop-top-40s': 'Pop/Top 40s',
  };
};

const seoUtils = { getAbsoluteUrl };

export default seoUtils;
