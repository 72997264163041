import { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import getEnvironment, { EnvironmentTypes } from '@zola-helpers/client/dist/es/util/environment';
import GavelIcon from '@zola/zola-ui/src/assets/images/icons/decisive.svg';
import SunglassesIcon from '@zola/zola-ui/src/assets/images/icons/easygoing_2.svg';
import BirdIcon from '@zola/zola-ui/src/assets/images/icons/lively_2.svg';
import LuxuriousAndChic from '@zola/zola-ui/src/assets/images/icons/luxurious_and_chic.svg';
import ReadyToParty from '@zola/zola-ui/src/assets/images/icons/ready_to_party.svg';
import RelaxedCasual from '@zola/zola-ui/src/assets/images/icons/relaxed_casual.svg';
import PlantIcon from '@zola/zola-ui/src/assets/images/icons/serene.svg';
import WarmCozy2 from '@zola/zola-ui/src/assets/images/icons/warm_cozy_2.svg';

import _capitalize from 'lodash/capitalize';
import _includes from 'lodash/includes';

import { WeddingEventType } from '~/pages/couples/inquiry-flow/types';
import {
  FacetParentKey,
  FacetChildKey,
  VenueServiceLevelChildKey,
  VenueSettingChildKey,
  VENUE_SERVICE_LEVEL_KEYS,
  VENUE_SETTING_KEYS,
} from '~/types/facets';
import {
  EntityFacetView,
  MappedOptionFacetView,
  TaxonomyWithFacetsView,
} from '~/types/responseTypes';
import { capitalizeFirstLetters } from '~/util/formatters';

import {
  compressEventTypes,
  EVENTS_TEXTS,
} from '../pages/couples/inquiry-flow/questions/Events/helpers';
import { alphabetical } from './sortUtils';
import { formatArrayWithOxfordComma } from './textUtils';

type ChildKeyLookup = {
  [index in FacetChildKey]?: {
    description?: string | string[];
    title?: string;
    icon?: string;
    excludeFromOnboarding?: boolean;
    thirdPersonDescription?: string;
  };
};

const PERSONALITY: ChildKeyLookup = {
  'personality-authoritative': {
    description: 'When you speak, everyone listens',
    thirdPersonDescription: 'When they speak, everyone listens',
    title: 'Decisive',
    icon: GavelIcon,
  },
  'personality-reserved': {
    description: 'You’re the ultimate source of calm',
    thirdPersonDescription: "They're the ultimate source of calm",
    title: 'Serene',
    icon: PlantIcon,
  },
  'personality-easygoing': {
    description: 'You roll with the punches',
    thirdPersonDescription: 'They roll with the punches',
    title: 'Easygoing',
    icon: SunglassesIcon,
  },

  'personality-lively': {
    description: 'You can put a smile on anyone’s face',
    thirdPersonDescription: "They can put a smile on anyone's face",
    title: 'Lively',
    icon: BirdIcon,
  },
};

const FLORIST_STYLE: ChildKeyLookup = {
  'florist-style-classic': {
    description: 'Think polished arrangements, timeless flowers, minimal greenery.',
  },
  'florist-style-romantic': {
    description: 'Think lush arrangements, bold flowers, simple greenery',
  },
  'florist-style-bohemian': {
    description: 'Think rustic arrangements, vibrant flowers, abundant greenery',
  },
  'florist-style-garden-inspired': {
    description: 'Think loosely structured arrangements, seasonal flowers and greenery',
  },
  'florist-style-alternative-non-traditional': {
    description: 'Think playful arrangements, dried or artificial flowers, non-floral elements',
  },
  'florist-style-modern': {
    description: 'Think minimalist and chic arrangements, unique flowers',
  },
};

const FLORIST_ETHICAL_AREAS: ChildKeyLookup = {
  'florist-ethical-areas-fair-trade-sourcing': {
    title: 'Fairtrade sourcing',
  },
  'florist-ethical-areas-support-local-farms': {
    title: 'Local sourcing',
  },
  'florist-ethical-areas-seasonal-sourcing': {
    title: 'Seasonal sourcing',
  },
  'florist-ethical-areas-chemical-free-sourcing': {
    title: 'Chemical-free sourcing',
  },
  'florist-ethical-areas-green-breakdown': {
    title: 'Biodegradable design',
  },
  'florist-ethical-areas-reusable-recyclable-materials': {
    title: 'Recyclable & reusable design',
  },
  'florist-ethical-areas-foam-free': {
    title: 'Foam-free design',
  },
};

const VIDEOGRAPHY_STYLE: ChildKeyLookup = {
  'videography-style-storytelling': {
    description: 'Weaves together special moments to tell the story of the couple’s wedding.',
  },
  'videography-style-documentary': {
    description: 'Captures intimate, organic moments and behind-the-scenes snippets.',
  },
  'videography-style-cinematic': {
    description: 'Blends emotion with striking shots to create a dramatic look and feel',
  },
  'videography-style-vintage': {
    description: 'Mimics the nostalgic look and feel of films from decades past',
  },
  'videography-style-classic': {
    description: 'Follows the couple through all the major moments of their ceremony and reception',
  },
};

const VIDEOGRAPHY_DELIVERABLES: ChildKeyLookup = {
  'videographer-deliverables-social-media-sharing': {
    excludeFromOnboarding: true,
  },
  'videographer-deliverables-usb': {
    excludeFromOnboarding: true,
  },
  'videographer-deliverables-dvd-or-blue-ray': {
    excludeFromOnboarding: true,
  },
  'videographer-deliverables-trailer': {
    excludeFromOnboarding: true,
  },
  'videographer-deliverables-highlight-reel': {
    description: '3-5 minutes',
  },
  'videographer-deliverables-short-feature': {
    description: 'Less than 30 minutes',
  },
  'videographer-deliverables-long-feature': {
    description: '30 minutes to 1 hour',
  },
  'videographer-deliverables-raw-footage': {
    description: 'Length varies',
  },
};

const VIDEOGRAPHY_PACKAGE_COMPONENTS: ChildKeyLookup = {
  'videographer-package-components-extra-hours': {
    excludeFromOnboarding: true,
  },
};

const CATERER_GENERAL_SERVICES: ChildKeyLookup = {
  'caterer-general-services-delivery-and-setup': {
    excludeFromOnboarding: true,
  },
  'caterer-general-services-cleanup-and-breakdown': {
    excludeFromOnboarding: true,
  },
  'caterer-general-services-serving-staff': {
    excludeFromOnboarding: true,
  },
  'caterer-general-services-bartenders': {
    excludeFromOnboarding: true,
  },
  'caterer-general-services-event-planner': {
    excludeFromOnboarding: true,
  },
  'caterer-general-services-consultations-tastings-for-a-fee': {
    excludeFromOnboarding: true,
  },
  'caterer-general-services-consultations-tastings-complimentary': {
    excludeFromOnboarding: true,
  },
  'caterer-general-services-rental-coordination-couple-invoice': {
    description: ['We manage rentals and ', 'directly', ' invoice couples for them.'],
  },
  'caterer-general-services-rental-coordination-no-couple-invoice': {
    description: ['We manage rentals and ', 'the rental company', ' invoices couples.'],
  },
};

const CATERER_PRICING_STRUCTURE: ChildKeyLookup = {
  'caterer-pricing-structure-minimum': {
    description: 'Yes',
  },
  'caterer-pricing-structure-per-person-food-only': {
    excludeFromOnboarding: true,
  },
  'caterer-pricing-structure-per-person-all-inclusive': {
    excludeFromOnboarding: true,
  },
};

const MENU_TYPES: ChildKeyLookup = {
  'menu-types-packages': {
    description: 'Packages with specific options',
  },
  'menu-types-custom-menus': {
    description: 'Custom menus that couples can personalize',
  },
};

const VENUE_GUEST_EMOTION: ChildKeyLookup = {
  'venue-guest-emotion-relaxed-and-casual': {
    title: 'Relaxed and casual',
    icon: RelaxedCasual,
  },
  'venue-guest-emotion-luxurious-and-chic': {
    title: 'Luxurious and chic',
    icon: LuxuriousAndChic,
  },
  'venue-guest-emotion-warm-and-cozy': {
    title: 'Warm and cozy',
    icon: WarmCozy2,
  },
  'venue-guest-emotion-fun-and-festive': {
    title: 'Fun and festive',
    icon: ReadyToParty,
  },
};

const LOOKUP: {
  [index in FacetParentKey]?: ChildKeyLookup;
} = {
  personality: PERSONALITY,
  'videography-style': VIDEOGRAPHY_STYLE,
  'videographer-package-components': VIDEOGRAPHY_PACKAGE_COMPONENTS,
  'videographer-deliverables': VIDEOGRAPHY_DELIVERABLES,
  'florist-style': FLORIST_STYLE,
  'caterer-general-services': CATERER_GENERAL_SERVICES,
  'caterer-pricing-structure': CATERER_PRICING_STRUCTURE,
  'menu-types': MENU_TYPES,
  'venue-guest-emotion': VENUE_GUEST_EMOTION,
  'florist-ethical-areas': FLORIST_ETHICAL_AREAS,
};

// https://docs.google.com/document/d/1Qf-v-gwtHGNf3JTwwPGccS11vwK6WAMZxUhJU0FDtzo/edit#
export const getParentFacetCustomText = (key: FacetParentKey): string => {
  switch (key) {
    case 'venue-service-level':
      return 'Venue types';
    case 'florist-services-pre-wedding':
      return 'Pre-wedding services';
    case 'florist-services-during-wedding':
      return 'Day-of wedding services';
    case 'florist-services-post-wedding':
      return 'Post-wedding services';
    case 'florist-floral-arrangement':
      return 'Arrangements';
    case 'florist-service-level':
      return 'Service types';
    case 'food-beverage-offered':
      return 'Food and beverage offerings';
    case 'menu-formats-offered':
      return 'Menu formats';
    case 'caterer-drink-types':
      return 'Beverage types';
    case 'caterer-dietary-restrictions':
      return 'Dietary accommodations';
    case 'caterer-menus-food-services':
      return 'Food and menu services';
    case 'event-types':
      return 'Events';
    case 'hair-beauty-service-location':
      return 'Service locations';
    case 'hair-beauty-service-category':
      return 'Beauty services';
    case 'music-genre':
      return 'Music genres';
    case 'musician-performers':
      return 'Performer counts';
    case 'musician-type':
      return 'Performer type';
    case 'bakers-product-types':
      return 'Desserts';
    case 'bakers-services':
      return 'Services';
    case 'bakers-dietary-accommodations':
      return 'Dietary accommodations';
    case 'planner-service-level':
      return 'Planning levels';
    default:
      return '';
  }
};

// TypeScript function overloads for lookupField
/* eslint-disable prettier/prettier */
function lookupField(parentKey: FacetParentKey, childKey: FacetChildKey, field: 'description'): string | string[] | undefined; // prettier-ignore
function lookupField(parentKey: FacetParentKey, childKey: FacetChildKey, field: 'title'): string | undefined; // prettier-ignore
function lookupField(parentKey: FacetParentKey, childKey: FacetChildKey, field: 'icon'): string | undefined; // prettier-ignore
function lookupField(parentKey: FacetParentKey, childKey: FacetChildKey, field: 'excludeFromOnboarding'): boolean | undefined; // prettier-ignore
function lookupField(parentKey: FacetParentKey, childKey: FacetChildKey, field: 'thirdPersonDescription'): string | undefined; // prettier-ignore
/* eslint-enable prettier/prettier */

function lookupField(
  parentKey: FacetParentKey,
  childKey: FacetChildKey,
  field: 'description' | 'title' | 'icon' | 'excludeFromOnboarding' | 'thirdPersonDescription'
): string | string[] | boolean | undefined {
  const table = LOOKUP[parentKey];
  if (table) {
    const value = table[childKey] ? table[childKey]?.[field] : undefined;
    if (value) {
      return value;
    }
    if (getEnvironment() === EnvironmentTypes.DEVELOPMENT) {
      throw new Error(`Failed to find ${field} for facet: ${childKey}`);
    }
  }
  if (getEnvironment() === EnvironmentTypes.DEVELOPMENT) {
    throw new Error(`Failed to find lookup for root facet: ${parentKey}`);
  }
  return undefined;
}

/**
 * Some child facets for a parent facet get excluded from onboarding.
 *
 * This is generally a deliverable or something that appears in the services
 * offered section rather than something that appears on the lead preferences
 * @param {*} parentKey
 *
 * @example
 *   const filter = getOnboardingFacetFilter('videographer-deliverables');
 *   const onboardingFacets = facets.filter((facet) => filter(facet.key));
 * @return (childKey:string) => boolean
 */
export const getOnboardingFacetFilter = (parentKey: FacetParentKey) => {
  return (childKey: FacetChildKey): boolean => {
    const table = LOOKUP[parentKey];
    if (table && table[childKey] && table[childKey]?.excludeFromOnboarding) {
      return false;
    }

    return true;
  };
};

export const getFacetIcon = (
  parentKey: FacetParentKey,
  childKey: FacetChildKey
): string | undefined => {
  const icon = lookupField(parentKey, childKey, 'icon');
  return icon;
};

export const getFacetDescription = (
  parentKey: FacetParentKey,
  childKey: FacetChildKey
): string | string[] | undefined => {
  const description = lookupField(parentKey, childKey, 'description');
  return description;
};

export const getFacetList = (
  facetArray: EntityFacetView[],
  parentFacetKey: FacetParentKey
): string[] => {
  let facetsAsStrings: string[] = [];

  if (parentFacetKey === 'event-types') {
    // Special case for event types which we present grouped in both the app
    // and the web.  We combine event like "baraat" and "ceremony" into "Day Of"
    // for example.
    const keys = facetArray.map(({ key }) => key) as WeddingEventType[];
    const compressedEventTypes = compressEventTypes(keys);
    facetsAsStrings = compressedEventTypes
      .map((weddingEventTypeGroup) => EVENTS_TEXTS[weddingEventTypeGroup])
      .sort(alphabetical);
  } else {
    facetsAsStrings = facetArray.map(({ name }) => capitalizeFirstLetters(name || ''));
  }

  // Always put "Other" at the end of the list
  const otherIndex = facetsAsStrings.indexOf('Other');
  if (otherIndex !== -1) {
    facetsAsStrings.splice(otherIndex, 1);
    facetsAsStrings.push('Other');
  }

  return facetsAsStrings;
};

export const getListofFacetNames = (
  facetArray: EntityFacetView[],
  parentFacetKey: FacetParentKey,
  conjunction: 'and' | 'or' | null = 'and'
): string => {
  const facetList = getFacetList(facetArray, parentFacetKey);

  if (conjunction === null) {
    return facetList.join(', ');
  }
  return formatArrayWithOxfordComma(facetList, conjunction);
};

export const getFacetsForParentKey = (
  facetList: EntityFacetView[],
  parentKey: FacetParentKey
): EntityFacetView[] => facetList.filter((item) => item.parentKey === parentKey);

export const lookupVendorTypeChildFacetByParent = (key: string): string => {
  // find child facets based on parent facet that was passed
  const parentKeyBeauty = 'hair-beauty-service-category-';
  const childKeyBeauty = 'beauty-services-';

  const parentKeyMusician = 'musician-type-';
  switch (key) {
    // beauty
    case `${parentKeyBeauty}hair`:
      return `${childKeyBeauty}hair`;
    case `${parentKeyBeauty}makeup`:
      return `${childKeyBeauty}makeup`;
    case `${parentKeyBeauty}henna-art`:
      return `${childKeyBeauty}henna`;
    case `${parentKeyBeauty}spa-treatments`:
      return `${childKeyBeauty}spa`;
    case `${parentKeyBeauty}styling`:
      return `${childKeyBeauty}styling`;
    case `${parentKeyBeauty}fitness-wellness`:
      return `${childKeyBeauty}fitness-wellness`;
    // musicians
    case `${parentKeyMusician}ceremony-only-musician`:
      return `musician-category`;
    case `${parentKeyMusician}dj`:
      return `music-djs`;
    case `${parentKeyMusician}live-band-or-ensemble`:
      return `music-band-acts`;
    case `${parentKeyMusician}live-solo-performer`:
      return `music-solo-acts`;
    default:
      return key;
  }
};

/**
 * Looks through the options array and returns any facet that is from one of the parents provided
 *
 * @param {Array} options
 * @param {Array} parentKeys
 */
export const getOptionsForParentKeys = (
  options: MappedOptionFacetView[],
  parentKeys: string[]
): MappedOptionFacetView[] => {
  // This could be simpler, but INSPO-1339 exists (at the time) and we sometimes don't have the parentKey
  // Simpler looks like just mapping the parentKeys in the options
  // options.map(o => o.parentKey).filter(key => includes(parentKeys, key));
  return options.filter((option) => {
    if (_includes(parentKeys, option.parentKey)) {
      return true;
    }

    return parentKeys.find((parentKey) => option.key.indexOf(parentKey) === 0) !== undefined;
  });
};

export const isVenueServiceLevelKey = (
  key: string | undefined
): key is VenueServiceLevelChildKey => {
  return Boolean(key && ([...VENUE_SERVICE_LEVEL_KEYS] as string[]).includes(key));
};

export const isVenueSettingKey = (key: string | undefined): key is VenueSettingChildKey => {
  return Boolean(key && ([...VENUE_SETTING_KEYS] as string[]).includes(key));
};

/**
  The keys are the names of the facets on the backend
  This lookup is used to hook the filters in search to the facets on the backend.

  For example, the Service Level and Design Style are the two facets we search for with
  florists, this mapper says that Design Style is the 'Florist style' facet from this
  definition of the search filters:
    [FLORISTS_TYPE_KEY]: {
      desktop: ['Location', 'Price', 'Availability', 'Service level', 'Design style'],
      extra: [],
  },
*/
const categoriesNameMapper: { [key in FacetParentKey]?: string } = {
  // Venues
  'target-couple-venue-preference': 'Venue type',
  'venue-service-level': 'Included',
  'services-offered': 'Amenities',
  'venue-setting': 'Indoor/outdoor',

  // Photographers
  'package-components-services-offered': 'Services',
  'package-deliverables-offered': "What you'll get",

  // Videographers
  'videographer-package-components': 'Services',
  'videographer-deliverables': "What you'll get",

  // Florists
  'florist-ethical-areas': 'Ethical considerations',
  'florist-style': 'Design style',
  'florist-service-level': 'Services',
  'florist-floral-arrangement': 'Arrangements',
  'florist-arrangement-types': 'Composition',

  // Caterers
  'menu-formats-offered': 'Meal services',
  'caterers-cuisine-types': 'Cuisine type',
  'caterer-dietary-restrictions': 'Dietary accommodations',
  'beverages-related-services': 'Beverage services',
  'caterer-drink-types': 'Drink types',
  'menu-types': 'Menus and packages',

  // Bands & DJs
  'musician-type': 'Type',
  'music-genre': 'Music genre',
  'musician-services': 'Services',
  'music-band-acts': 'Band or ensemble type',
  'music-solo-acts': 'Solo performer type',
  'music-djs': 'DJ type',
  'musician-category': 'Musician type',

  // Beauty Facets
  'hair-beauty-service-category': 'Services',
  'hair-beauty-style': 'Signature style',
  'beauty-services-general': 'Other services',
  'beauty-services-hair': 'Hair services',
  'beauty-services-makeup': 'Makeup services',
  'beauty-services-henna': 'Henna services',
  'beauty-services-spa': 'Spa services',
  'beauty-services-styling': 'Styling services',
  'beauty-services-fitness-wellness': 'Fitness/Wellness services',

  // Bakers
  'bakers-dietary-accommodations': 'Dietary accommodation',
  'bakers-product-types': 'Dessert type',

  // Planners
  'planner-service-level': 'Services',
};

export const mapCategoryName = (parentKey: FacetParentKey, name: string): string => {
  return categoriesNameMapper[parentKey]
    ? (categoriesNameMapper[parentKey] as string)
    : _capitalize(name);
};

const optionsNamesMapper: { [key in FacetChildKey]?: string } = {
  'musician-type-dj': 'DJ',
  'music-djs-solo-dj': 'Solo DJ',
  'music-djs-group-of-djs': 'Group of DJs',
  'music-genre-r-and-b': 'R&B',
  'musician-staff-available-djs': 'DJs',
  'videographer-deliverables-dvd-or-blue-ray': 'DVD or Blu-ray',
  'videographer-deliverables-usb': 'USB',
  'rental-services-sound-av': 'Sound / AV',
};

export const mapOptionName = (childKey: FacetChildKey, name: string): string => {
  return optionsNamesMapper[childKey]
    ? (optionsNamesMapper[childKey] as string)
    : _capitalize(name);
};

export interface SearchableFacet {
  displayName: string;
  key: string;
  name: string;
  parentKey: FacetParentKey;
  slug: string;
  description?: string | null;
}

export type FacetsByVendorType = {
  [key in VendorTaxonomyKey]?: Record<string, SearchableFacet>;
};

export const getFacetsByVendorType = (
  taxonomiesWithFacets: TaxonomyWithFacetsView[]
): FacetsByVendorType => {
  const facetsByVendorType: FacetsByVendorType = {};
  taxonomiesWithFacets.forEach((taxonomy) => {
    const facets: Record<string, SearchableFacet> = {};
    taxonomy.facets.forEach((parentFacet) => {
      parentFacet.facets.forEach((facet) => {
        facets[facet.slug] = {
          parentKey: parentFacet.key,
          name: facet.name,
          displayName: mapOptionName(facet.key, facet.name),
          key: facet.key,
          slug: facet.slug,
          description: facet.description,
        };
      });
    });
    facetsByVendorType[taxonomy.key] = facets;
  });
  return facetsByVendorType;
};
