import CircleImage from '~/components/common/images/CircleImage';
import { useAppSelector, useAppDispatch } from '~/reducers';
import { getCurrentStorefrontUuid } from '~/selectors/vendorAccountSelectors';
import { MappedAccountStorefront } from '~/types/mappedResponseTypes';

import { MenuLink, MenuType } from '../useNavigationLinks';
import useStorefrontCreditText from '../useStorefrontCreditText';
import { onChangeStorefront } from '../util';

import styles from './mobileNav.module.less';

const StorefrontLink = (storefront: MappedAccountStorefront): MenuLink => {
  const currentStorefrontUuid = useAppSelector(getCurrentStorefrontUuid);

  const dispatch = useAppDispatch();

  const { name, coverImageUrl, uuid } = storefront;
  const storefrontImageUrl = coverImageUrl ? `${coverImageUrl}?w=100` : '';

  const creditText = useStorefrontCreditText(storefront);

  const link: MenuLink = {
    type: MenuType.LINK,
    trackingIdentifier: 'STOREFRONT',
    to: '#',
    onClick: (event) => {
      event.preventDefault();
      if (storefront.uuid !== currentStorefrontUuid) {
        onChangeStorefront(storefront, dispatch)(event);
      }
    },
    isActive: () => currentStorefrontUuid === uuid,
    text: name,
    renderText: (
      <div className={styles.storefrontLink}>
        <CircleImage className={styles.storefrontImage} src={storefrontImageUrl} lazyLoad={false} />
        <div className={styles.storefrontInfo}>
          <div className={styles.storefrontName}>{name}</div>
          {creditText && <div className={styles.storefrontCreditBalance}>{creditText}</div>}
        </div>
      </div>
    ),
  };

  return link;
};

export default StorefrontLink;
