const TARGET_COUPLE_VENUE_PREFERENCE = { id: 1, key: 'target-couple-venue-preference' } as const;
const EVENT_TYPES = { id: 16, key: 'event-types' } as const;
const VENUE_SERVICE_LEVEL = { id: 25, key: 'venue-service-level' } as const;
const SERVICES_OFFERED = { id: 30, key: 'services-offered' } as const;
const MENU_OPTIONS = { id: 50, key: 'menu-options' } as const;
const TRANSPORTATION_SERVICES = { id: 66, key: 'transportation-services' } as const;
const RENTAL_SERVICES = { id: 74, key: 'rental-services' } as const;
const PRICING_STRUCTURE = { id: 125, key: 'pricing-structure' } as const;
const PHOTOGRAPHY_STYLE = { id: 129, key: 'photography-style' } as const;
const PERSONALITY = { id: 149, key: 'personality' } as const;
const WORKING_STYLE = { id: 154, key: 'working-style' } as const;
const PACKAGE_COMPONENTS_SERVICES_OFFERED = {
  id: 157,
  key: 'package-components-services-offered',
} as const;
const PACKAGE_DELIVERABLES_OFFERED = { id: 167, key: 'package-deliverables-offered' } as const;
const PHOTO_FORMAT = { id: 177, key: 'photo-format' } as const;
const HOURS_OFFERED = { id: 181, key: 'hours-offered' } as const;
const VENUE_SETTING = { id: 187, key: 'venue-setting' } as const;
const ACCOMMODATIONS_SERVICES = { id: 191, key: 'accommodations-services' } as const;
const PLANNER_REQUIRED = { id: 200, key: 'planner-required' } as const;
const VIDEOGRAPHY_STYLE = { id: 206, key: 'videography-style' } as const;
const VIDEOGRAPHER_WORKING_STYLE = { id: 212, key: 'videographer-working-style' } as const;
const VIDEOGRAPHER_PACKAGE_COMPONENTS = {
  id: 216,
  key: 'videographer-package-components',
} as const;
const VIDEOGRAPHER_DELIVERABLES = { id: 222, key: 'videographer-deliverables' } as const;
const VIDEOGRAPHER_A_LA_CARTE_OPTIONS = {
  id: 231,
  key: 'videographer-a-la-carte-options',
} as const;
const FLORIST_STYLE = { id: 247, key: 'florist-style' } as const;
const FLORIST_SERVICES_PRE_WEDDING = { id: 254, key: 'florist-services-pre-wedding' } as const;
const FLORIST_SERVICES_DURING_WEDDING = {
  id: 368,
  key: 'florist-services-during-wedding',
} as const;
const FLORIST_SERVICES_POST_WEDDING = { id: 369, key: 'florist-services-post-wedding' } as const;
const FLORIST_FLORAL_ARRANGEMENT = { id: 267, key: 'florist-floral-arrangement' } as const;
const FLORIST_SERVICE_LEVEL = { id: 551, key: 'florist-service-level' } as const;
const FLORIST_WORKING_STYLE = { id: 278, key: 'florist-working-style' } as const;
const FLORIST_ARRANGEMENT_TYPE = { id: 285, key: 'florist-arrangement-types' } as const;
const FLORIST_BUSINESS_ETHICS = { id: 370, key: 'business-ethics' } as const;
// CATERERS
const FOOD_BEVERAGE_OFFERED = { id: 289, key: 'food-beverage-offered' } as const;
const MENU_FORMATS_OFFERED = { id: 294, key: 'menu-formats-offered' } as const;
const MENU_TYPES = { id: 303, key: 'menu-types' } as const;
const BEVERAGES_RELATED_SERVICES = { id: 306, key: 'beverages-related-services' } as const;
const CATERER_GENERAL_SERVICES = { id: 311, key: 'caterer-general-services' } as const;
const CATERER_PRICING_STRUCTURE = { id: 321, key: 'caterer-pricing-structure' } as const;
const CATERER_EXTRA_COSTS = { id: 325, key: 'caterer-extra-costs' } as const;
const CATERER_PRICING_VARIABLES = { id: 341, key: 'caterer-pricing-variables' } as const;
const CATERER_INCLUDED_IN_PRICE = { id: 346, key: 'caterer-included-in-price' } as const;
const CATERER_DIETARY_RESTRICTIONS = { id: 355, key: 'caterer-dietary-restrictions' } as const;
const CATERER_MENUS_FOOD_SERVICES = { id: 556, key: 'caterer-menus-food-services' } as const;
const CATERERS_CUISINE_TYPES = { id: 363, key: 'caterers-cuisine-types' } as const;
const FOOD_BEVERAGE_SERVICES = { id: 654, key: 'food-beverage-services' } as const;
// BAR SERVICES
const CATERER_DRINK_TYPES = { id: 678, key: 'caterer-drink-types' } as const;
// MUSICIANS
const MUSIC_GENRE = { id: 379, key: 'music-genre' } as const;
const MUSIC_SOLO_ACTS = { id: 391, key: 'music-solo-acts' } as const;
const MUSIC_BAND_ACTS = { id: 394, key: 'music-band-acts' } as const;
const MUSIC_DJS = { id: 398, key: 'music-djs' } as const;
const MUSICIAN_TYPE = { id: 401, key: 'musician-type' } as const;
const MUSICIAN_SERVICES = { id: 405, key: 'musician-services' } as const;
const MUSICIAN_STAFF_AVAILABLE = { id: 420, key: 'musician-staff-available' } as const;
const MUSICIAN_VIBE = { id: 424, key: 'musician-vibe' } as const;
const MUSICIAN_PERFORMERS = { id: 578, key: 'musician-performers' } as const;
const MUSICIAN_CATEGORY = { id: 646, key: 'musician-category' } as const;
// HAIR AND BEAUTY
const HAIR_BEAUTY_TYPE = { id: 428, key: 'hair-beauty-type' } as const;
const HAIR_BEAUTY_STYLE = { id: 431, key: 'hair-beauty-style' } as const;
const HAIR_BEAUTY_SERVICE_TYPE = { id: 438, key: 'hair-beauty-service-type' } as const;
const HAIR_BEAUTY_SERVICES = { id: 445, key: 'hair-beauty-services' } as const;
const HAIR_BEAUTY_SERVICE_CATEGORY = { id: 566, key: 'hair-beauty-service-category' } as const;
const HAIR_BEAUTY_SERVICE_LOCATION = { id: 573, key: 'hair-beauty-service-location' } as const;
// UPDATED BEAUTY FACETS
const BEAUTY_SERVICE_GENERAL = { id: 592, key: 'beauty-services-general' } as const;
const BEAUTY_SERVICE_HAIR = { id: 598, key: 'beauty-services-hair' } as const;
const BEAUTY_SERVICE_HENNA = { id: 617, key: 'beauty-services-henna' } as const;
const BEAUTY_SERVICE_SPA = { id: 629, key: 'beauty-services-spa' } as const;
const BEAUTY_SERVICE_MAKEUP = { id: 609, key: 'beauty-services-makeup' } as const;
const BEAUTY_SERVICE_STYLING = { id: 636, key: 'beauty-services-styling' } as const;
const BEAUTY_SERVICE_FITNESS = { id: 640, key: 'beauty-services-fitness-wellness' } as const;
// RENTALS
const RENTALS_PRODUCT_FOOD_BEV = { id: 455, key: 'rentals-product-food-bev' } as const;
const RENTALS_PRODUCTS_DECOR = { id: 472, key: 'rentals-products-decor' } as const;
const RENTALS_PRODUCT_SERVICE = { id: 485, key: 'rentals-product-service' } as const;
// BAKERS
const BAKERS_DIETARY_ACCOMMODATIONS = { id: 492, key: 'bakers-dietary-accommodations' } as const;
const BAKERS_PRODUCT_TYPES = { id: 497, key: 'bakers-product-types' } as const;
const BAKERS_CAKE_CUSTOMIZATIONS = { id: 506, key: 'bakers-cake-customizations' } as const;
const BAKERS_DECORATIONS = { id: 510, key: 'bakers-decorations' } as const;
const BAKERS_SERVICES = { id: 517, key: 'bakers-services' } as const;
// WEDDING PLANNERS
const PLANNER_PERSONALITY = { id: 724, key: 'planner-personality' } as const;
const PLANNER_SERVICE_LEVEL = { id: 690, key: 'planner-service-level' } as const;

/**
 * facets that are used during the inquiry flow
 *
 */
export const InquiryFlowFacetKey = {
  // common
  EVENT_TYPES: 'EVENT_TYPES',

  // venues
  VENUE_SERVICE_LEVEL: 'VENUE_SERVICE_LEVEL',

  // caterers
  CATERER_DIETARY_RESTRICTIONS: 'CATERER_DIETARY_RESTRICTIONS',
  MENU_FORMATS_OFFERED: 'MENU_FORMATS_OFFERED',
  FOOD_BEVERAGE_OFFERED: 'FOOD_BEVERAGE_OFFERED',
  // caterers - bar services
  CATERER_DRINK_TYPES: 'CATERER_DRINK_TYPES',

  // florists
  FLORIST_SERVICE_LEVEL: 'FLORIST_SERVICE_LEVEL',
  FLORIST_FLORAL_ARRANGEMENT: 'FLORIST_FLORAL_ARRANGEMENT',

  // beauticians
  HAIR_BEAUTY_SERVICE_CATEGORY: 'HAIR_BEAUTY_SERVICE_CATEGORY',

  // musicians
  MUSICIAN_TYPE: 'MUSICIAN_TYPE',
  MUSICIAN_PERFORMERS: 'MUSICIAN_PERFORMERS',
  MUSIC_GENRE: 'MUSIC_GENRE',

  // bakers
  BAKERS_PRODUCT_TYPES: 'BAKERS_PRODUCT_TYPES',
  BAKERS_SERVICES: 'BAKERS_SERVICES',
  BAKERS_DIETARY_ACCOMMODATIONS: 'BAKERS_DIETARY_ACCOMMODATIONS',

  // planners
  PLANNER_SERVICE_LEVEL: 'PLANNER_SERVICE_LEVEL',
};

export const FACETS = {
  TARGET_COUPLE_VENUE_PREFERENCE,
  EVENT_TYPES,
  VENUE_SERVICE_LEVEL,
  SERVICES_OFFERED,
  MENU_OPTIONS,
  TRANSPORTATION_SERVICES,
  RENTAL_SERVICES,
  PRICING_STRUCTURE,
  PHOTOGRAPHY_STYLE,
  PERSONALITY,
  WORKING_STYLE,
  PACKAGE_COMPONENTS_SERVICES_OFFERED,
  PACKAGE_DELIVERABLES_OFFERED,
  PHOTO_FORMAT,
  HOURS_OFFERED,
  VENUE_SETTING,
  ACCOMMODATIONS_SERVICES,
  PLANNER_REQUIRED,
  VIDEOGRAPHY_STYLE,
  VIDEOGRAPHER_WORKING_STYLE,
  VIDEOGRAPHER_PACKAGE_COMPONENTS,
  VIDEOGRAPHER_DELIVERABLES,
  VIDEOGRAPHER_A_LA_CARTE_OPTIONS,
  // Florists
  FLORIST_ARRANGEMENT_TYPE,
  FLORIST_FLORAL_ARRANGEMENT,
  FLORIST_SERVICE_LEVEL,
  FLORIST_STYLE,
  FLORIST_SERVICES_PRE_WEDDING,
  FLORIST_SERVICES_DURING_WEDDING,
  FLORIST_SERVICES_POST_WEDDING,
  FLORIST_WORKING_STYLE,
  FLORIST_BUSINESS_ETHICS,
  // Caterers
  FOOD_BEVERAGE_OFFERED,
  MENU_FORMATS_OFFERED,
  MENU_TYPES,
  BEVERAGES_RELATED_SERVICES,
  CATERER_GENERAL_SERVICES,
  CATERER_PRICING_STRUCTURE,
  CATERER_EXTRA_COSTS,
  CATERER_PRICING_VARIABLES,
  CATERER_INCLUDED_IN_PRICE,
  CATERER_DIETARY_RESTRICTIONS,
  CATERERS_CUISINE_TYPES,
  CATERER_MENUS_FOOD_SERVICES,
  FOOD_BEVERAGE_SERVICES,
  CATERER_DRINK_TYPES,
  // Musicians
  MUSIC_GENRE,
  MUSIC_SOLO_ACTS,
  MUSIC_BAND_ACTS,
  MUSIC_DJS,
  MUSICIAN_TYPE,
  MUSICIAN_SERVICES,
  MUSICIAN_STAFF_AVAILABLE,
  MUSICIAN_VIBE,
  MUSICIAN_PERFORMERS,
  MUSICIAN_CATEGORY,
  // Beauticians
  HAIR_BEAUTY_TYPE,
  HAIR_BEAUTY_STYLE,
  HAIR_BEAUTY_SERVICE_TYPE,
  HAIR_BEAUTY_SERVICES,
  HAIR_BEAUTY_SERVICE_CATEGORY,
  HAIR_BEAUTY_SERVICE_LOCATION,
  // Updated Beauty Facets
  BEAUTY_SERVICE_GENERAL,
  BEAUTY_SERVICE_HAIR,
  BEAUTY_SERVICE_HENNA,
  BEAUTY_SERVICE_SPA,
  BEAUTY_SERVICE_MAKEUP,
  BEAUTY_SERVICE_STYLING,
  BEAUTY_SERVICE_FITNESS,
  // Rentals
  RENTALS_PRODUCT_FOOD_BEV,
  RENTALS_PRODUCTS_DECOR,
  RENTALS_PRODUCT_SERVICE,
  // Bakers
  BAKERS_DIETARY_ACCOMMODATIONS,
  BAKERS_PRODUCT_TYPES,
  BAKERS_CAKE_CUSTOMIZATIONS,
  BAKERS_DECORATIONS,
  BAKERS_SERVICES,
  // Planners
  PLANNER_PERSONALITY,
  PLANNER_SERVICE_LEVEL,
} as const;

export default FACETS;
