import { Fragment } from 'react';

import {
  deleteSessionStorage,
  getSessionStorage,
  setSessionStorage,
} from '@zola-helpers/client/dist/es/util/storage';
import { show } from '@zola/zola-ui/src/actions/toastsV2Actions';
import { ToastV2ActionType } from '@zola/zola-ui/src/components/ToastsV2/types/ToastV2Options';
import { useEffectOnce } from '@zola/zola-ui/src/hooks';

import { useAppDispatch } from '~/reducers';

const MARKETPLACE_TOASTS_KEY = 'zola-vendors-toasts';

type SessionToast = {
  type: ToastV2ActionType;
  headline: string;
  dek?: string | null;
  autoDismissInSeconds?: number;
  linkDestination?: string;
  linkText?: string;
  linkArrow?: boolean;
  noTextTransform?: boolean;
};

const parseToasts = () => {
  const val = getSessionStorage(MARKETPLACE_TOASTS_KEY);

  let toasts: SessionToast[] = [];
  if (val) {
    try {
      toasts = JSON.parse(val) as SessionToast[];
    } catch {
      toasts = [];
    }
  }
  return toasts;
};

/**
 * Adds a toast to session storage to show on the next page load.
 *
 * Note that you can't put react nodes or click handlers in local storage, so the
 * options for these toasts are more restrictive than our general toasts.
 *
 * As an aside, putting click handlers and react nodes in redux isn't great either.
 */
export const addToastToSessionStorage = (toast: SessionToast) => {
  setSessionStorage(MARKETPLACE_TOASTS_KEY, JSON.stringify([...parseToasts(), toast]));
};

/**
 * Displays a toast message written to session storage before a page navigation event.
 *
 * Typically, you will:
 * - call addToastToSessionStorage({headline: 'Amazing thing happened});
 * - assign the browser to a new URL: window.location.assign(...)
 *
 * Redux state is gone with window.location.assign (and we're trying to
 * get away from redux).  So we can't put a toast message in redux then navigate,
 * so we put it in session storage then navigate.  This hook handles showing
 * "persisted" toast messages on the next page load.
 */
export const useToastsFromSessionStorage = () => {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    const toasts = parseToasts();

    deleteSessionStorage(MARKETPLACE_TOASTS_KEY);

    toasts.forEach(({ type, ...opts }) => {
      dispatch(show(opts, type));
    });
  });
};

export const SessionStorageToasts = () => {
  useToastsFromSessionStorage();
  return <Fragment />;
};
