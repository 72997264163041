import { createContext, useContext, useEffect, useState } from 'react';

import { identifyUser } from '@zola-helpers/client/dist/es/redux/user/userActions';
import { getLocalStorage, setLocalStorage } from '@zola-helpers/client/dist/es/util/storage';

import { getUserContext } from '~/libs/client/api/user';
import { IUserContext } from '~/types/userContext';

const CreatedUserContext = createContext<{
  userContext: IUserContext | null;
  setUserContext: (userContext: IUserContext | null) => void;
}>({
  userContext: null,
  setUserContext: () => undefined,
});

export const UserContextProvider = ({
  userContext: providedUserContext = null,
  children,
}: {
  userContext?: IUserContext | null;
  children: React.ReactNode;
}) => {
  const [userContext, setUserContext] = useState<IUserContext | null>(providedUserContext);

  useEffect(() => {
    if (!providedUserContext) {
      getUserContext()
        .then((data) => setUserContext(data))
        .catch(() => null);
    }
  }, [providedUserContext]);

  useEffect(() => {
    if (userContext) {
      const { object_id: objectId } = userContext;
      const identity = objectId?.slice(-8);
      const zolaIdentity = getLocalStorage('zolaIdentity');
      const hasIdentified = zolaIdentity ? zolaIdentity === identity : false;
      // @ts-expect-error The IUserContext and UserContext types are interchangeable for identify
      identifyUser({ userContext, hasIdentified, forceIdentify: false });
      if (identity) {
        setLocalStorage('zolaIdentity', identity);
      }
    }
  }, [userContext]);

  return (
    <CreatedUserContext.Provider value={{ userContext, setUserContext }}>
      {children}
    </CreatedUserContext.Provider>
  );
};

export const useUserContext = () => {
  const { userContext } = useContext(CreatedUserContext);
  return userContext;
};

export const useUpdatableUserContext = () => useContext(CreatedUserContext);
