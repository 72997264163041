import { useCallback } from 'react';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// eslint-disable-next-line react-hooks/exhaustive-deps
const useDebounce = (func, ms) => useCallback(debounce(func, ms), []);

export default useDebounce;
