import React, { Fragment, FC, useEffect } from 'react';

import { Message2Icon } from '@zola/zola-ui/src/components/SvgIconsV3/Message2';
import { useGladlyChat } from '@zola/zola-ui/src/hooks/useGladlyChat';

import { Global } from '@emotion/react';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '~/reducers';
import { getIsAuthenticated } from '~/selectors/vendorAccountSelectors';
import featureFlags from '~/util/featureFlags';

import { GladlyStyles, GladlyButton, UnreadDot } from './VendorGladApp.styles';

const ZolaGladApp: FC = () => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  // For SPA, Gladly needs to know the url changed
  useEffect(() => {
    if (window.Gladly && window.Gladly.navigate) {
      window.Gladly.navigate();
    }
  }, [location]);

  useGladlyChat({
    isChatEnabled: featureFlags.get('enableVendorGladlyChat') && isAuthenticated,
    appId: 'zola.com-vendor-chat',
  });

  return (
    <Fragment>
      <Global styles={GladlyStyles} />
      <GladlyButton type="button" id="custom-gladly-chat-button">
        <Message2Icon width={24} height={24} />
        <UnreadDot id="with-unread-message" />
      </GladlyButton>
    </Fragment>
  );
};

export default ZolaGladApp;
