import { setLocalStorage } from '@zola-helpers/client/dist/es/util/storage';

import { error as errorNotification } from '~/actions/notificationActions';
import { AppThunk } from '~/reducers';
import { BoardStorefrontView, BoardWeddingPhotoView, MappedBoardView } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';
import { trackObjectFavorited, trackObjectUnfavorited, FavoriteType } from '~/util/trackingHelper';

import {
  ReceivedFavoritesAction,
  RECEIVED_FAVORITES,
  CREATING_FAVORITE_PHOTO,
  CREATED_FAVORITE_PHOTO,
  DELETING_FAVORITE_PHOTO,
  DELETED_FAVORITE_PHOTO,
  CREATING_FAVORITE_STOREFRONT,
  CREATED_FAVORITE_STOREFRONT,
  DELETING_FAVORITE_STOREFRONT,
  DELETED_FAVORITE_STOREFRONT,
  CreatedFavoritePhotoAction,
  CreatingFavoritePhotoAction,
  DeletedFavoritePhotoAction,
  DeletingFavoritePhotoAction,
  FavoritePhotoRequest,
  FavoritePhotoPayload,
  CreatedFavoriteStorefrontAction,
  CreatingFavoriteStorefrontAction,
  DeletedFavoriteStorefrontAction,
  DeletingFavoriteStorefrontAction,
  FavoriteStorefrontPayload,
  FavoriteStorefrontRequest,
  UnfavoritePhotoRequest,
  UnfavoriteStorefrontRequest,
} from './types/favoriteActionTypes';

export const goToVendorFavoriteOnboarding = (storefrontUuid: string): void => {
  setLocalStorage('vendorFavoriteStorefrontUuid', storefrontUuid);
  setLocalStorage('vendorFavoritePreviousLocation', window.location.href);
  window.location.assign('/wedding/onboard/wedding-vendor-favorite');
};

export const receivedFavorites = (response: MappedBoardView): ReceivedFavoritesAction => ({
  type: RECEIVED_FAVORITES,
  payload: response,
});

const creatingFavoritePhoto = (request: FavoritePhotoRequest): CreatingFavoritePhotoAction => ({
  type: CREATING_FAVORITE_PHOTO,
  payload: request,
});

const createdFavoritePhoto = (response: FavoritePhotoPayload): CreatedFavoritePhotoAction => ({
  type: CREATED_FAVORITE_PHOTO,
  payload: response,
});

const deletingFavoritePhoto = (uuid: string): DeletingFavoritePhotoAction => ({
  type: DELETING_FAVORITE_PHOTO,
  payload: uuid,
});

const deletedFavoritePhoto = (response: FavoritePhotoPayload): DeletedFavoritePhotoAction => ({
  type: DELETED_FAVORITE_PHOTO,
  payload: response,
});

// Set photo as favorite before waiting for an API response, in order to reflect
// change in the view without delay (in case of API failure, change to original state)
export const createFavoritePhoto = (
  request: FavoritePhotoRequest
): AppThunk<Promise<BoardWeddingPhotoView | void>> => {
  return (dispatch) => {
    dispatch(creatingFavoritePhoto(request));
    dispatch(createdFavoritePhoto(request));
    return ApiService.post(`/web-marketplace-api/v1/favorites/wedding-photo`, request)
      .then((response: BoardWeddingPhotoView) => {
        const { imageId, location, tilePosition, shelfCustomized } = request;
        dispatch(createdFavoritePhoto(response));
        trackObjectFavorited({
          uuid: imageId,
          type: FavoriteType.PHOTO,
          slug: response.weddingPhotoSlug,
          location,
          tilePosition,
          shelfCustomized,
        });
        return response;
      })
      .catch((error) => {
        Logger.error(error);
        dispatch(errorNotification({ message: 'Error creating favorite.' }));
        dispatch(deletedFavoritePhoto({ imageId: request.imageId }));
      });
  };
};

/**
 * Unfavorite photo before waiting for an API response, in order to reflect
 * change in the view without delay (in case of API failure, change to original state)
 * @param {String} uuid  - uuid of the favorite
 * @param {String} imageId - id of the image, used to toggle visual favorite state
 */
export const deleteFavoritePhoto = (request: UnfavoritePhotoRequest): AppThunk<Promise<void>> => {
  return (dispatch) => {
    const { uuid, imageId, tilePosition, location, shelfCustomized } = request;
    dispatch(deletingFavoritePhoto(uuid));
    dispatch(deletedFavoritePhoto({ imageId }));
    return ApiService.delete(`/web-marketplace-api/v1/favorites/wedding-photo/${uuid}`)
      .then((response: BoardWeddingPhotoView) => {
        dispatch(deletedFavoritePhoto(response));
        trackObjectUnfavorited({
          uuid: imageId,
          type: FavoriteType.PHOTO,
          slug: response.weddingPhotoSlug,
          location,
          tilePosition,
          shelfCustomized,
        });
      })
      .catch((error) => {
        Logger.error(error);
        dispatch(createdFavoritePhoto({ imageId }));
      });
  };
};

const creatingFavoriteStorefront = (
  request: FavoriteStorefrontPayload
): CreatingFavoriteStorefrontAction => ({
  type: CREATING_FAVORITE_STOREFRONT,
  payload: request,
});

const createdFavoriteStorefront = (
  response: FavoriteStorefrontPayload
): CreatedFavoriteStorefrontAction => ({
  type: CREATED_FAVORITE_STOREFRONT,
  payload: response,
});

const deletingFavoriteStorefront = (uuid: string): DeletingFavoriteStorefrontAction => ({
  type: DELETING_FAVORITE_STOREFRONT,
  payload: uuid,
});

const deletedFavoriteStorefront = (
  response: FavoriteStorefrontPayload
): DeletedFavoriteStorefrontAction => ({
  type: DELETED_FAVORITE_STOREFRONT,
  payload: response,
});

export const createFavoriteStorefront = (
  request: FavoriteStorefrontRequest
): AppThunk<Promise<BoardStorefrontView | void>> => {
  const { storefrontUuid, location, tilePosition, shelfCustomized } = request;
  return (dispatch) => {
    dispatch(creatingFavoriteStorefront(request));
    dispatch(createdFavoriteStorefront({ storefrontUuid }));
    return ApiService.post(`/web-marketplace-api/v1/favorites/storefront`, request)
      .then((response: BoardStorefrontView) => {
        dispatch(createdFavoriteStorefront(response));
        trackObjectFavorited({
          uuid: storefrontUuid,
          type: FavoriteType.STOREFRONT,
          // casting to 'as string' because you can only favorite a published storefront, and all storefronts have slugs
          slug: response.vendorCard.storefrontSlug as string,
          location,
          tilePosition,
          shelfCustomized,
        });
        return response;
      })
      .catch((error) => {
        Logger.error(error);
        dispatch(errorNotification({ message: 'Error creating favorite.' }));
        // since we preemptively mark storefront as favorite,
        // we need to unfavorite it in case of failure
        dispatch(deletedFavoriteStorefront({ storefrontUuid }));
      });
  };
};

/**
 *
 * @param {String} uuid
 * @param {String} storefrontUuid - uuid of a storefront used to toggle visual favorite state
 */
export const deleteFavoriteStorefront = (
  request: UnfavoriteStorefrontRequest
): AppThunk<Promise<void>> => {
  return (dispatch) => {
    const { uuid, storefrontUuid, tilePosition, location, shelfCustomized } = request;
    dispatch(deletingFavoriteStorefront(uuid));
    dispatch(deletedFavoriteStorefront({ storefrontUuid }));
    return ApiService.delete(`/web-marketplace-api/v1/favorites/storefront/${uuid}`)
      .then((response: BoardStorefrontView) => {
        dispatch(deletedFavoriteStorefront(response));
        trackObjectUnfavorited({
          uuid: storefrontUuid,
          type: FavoriteType.STOREFRONT,
          // casting to 'as string' because you can only favorite a published storefront, and all storefronts have slugs
          slug: response.vendorCard.storefrontSlug as string,
          tilePosition,
          location,
          shelfCustomized,
        });
      })
      .catch((error) => {
        Logger.error(error);
        // since we preemptively unfavorited the storefront,
        // we need to favorite it back in case of failure
        dispatch(createdFavoriteStorefront({ storefrontUuid }));
      });
  };
};
