import { NavigationClicked } from '@zola/tracking-contracts/src/events';

import {
  addNextLevelTrackingProps,
  MenuHeading,
  MenuType,
  StorefrontMenuLink,
} from '../useNavigationLinks';
import { StorefrontLink } from './StorefrontLink';

import styles from './accountDropdown.module.less';

interface StorefrontListProps {
  items: (StorefrontMenuLink | MenuHeading)[];
  trackingProps: Omit<NavigationClicked, 'navigation_type' | 'business_unit' | 'business_category'>;
}

export const StorefrontList = (props: StorefrontListProps): JSX.Element => {
  const { items, trackingProps } = props;

  let trackingIndex = 0;
  return (
    <div className={styles.storefrontList}>
      <div className={styles.storefrontListOverlay} />
      {items.map((item, index) => {
        const key = `storefront-item-${index}`;

        if (item.type === MenuType.STOREFRONT) {
          trackingIndex = trackingIndex + 1;
          return (
            <StorefrontLink
              storefront={item.storefront}
              key={key}
              trackingProps={{
                ...addNextLevelTrackingProps(trackingProps, item.trackingIdentifier, trackingIndex),
              }}
            />
          );
        }

        return (
          <div key={key} className={styles.dropdownHeader} role="listitem">
            {item.text}
          </div>
        );
      })}
    </div>
  );
};
