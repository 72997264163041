import { Fragment } from 'react';

import { ImageFormat, getImageUrl as getBaseImageUrl } from '~/util/imageUtils';

/**
 * Imgix fit param values
 */
export type Fit = 'max' | 'crop';

interface ImageSizesProps {
  width?: number;
  height?: number;
  fit?: Fit;
  quality?: number;
  format?: ImageFormat;
  imgixParams?: Record<string, unknown>;
  srcSizes?: {
    width?: number;
    height?: number;
  }[];
}

interface ImageSize {
  width?: number;
  height?: number;
  descriptor: string;
}

const LOCAL_ASSETS_BASE_URL = '/web-marketplace-api/resize-image-asset';
const ASSETS_BASE_URL = 'https://zola-web-assets.imgix.net/web-home';
const DEFAULT_WIDTH = 300;
const DEVICE_PIXEL_RATIOS = [1, 2, 3];

const isImgixUrl = (url: string) => {
  return url.includes('imgix.net');
};

export const isUploadCareUrl = (url?: string) => {
  return Boolean(url?.includes('ucarecdn.com'));
};

const getCachedUrl = (src?: string | null): string | null => {
  if (!src) return null;
  if (src.includes('cloudfront')) {
    return src.replace(/^.+\.cloudfront\.net\/(v[a-z]assets)/, `${ASSETS_BASE_URL}/$1`);
  }
  if (src.includes('/_next/static/images/')) {
    return src.replace(/\/web-.+\/_next\/static\/images/, LOCAL_ASSETS_BASE_URL);
  }
  return src.replace(/\/static-.+\/media/, LOCAL_ASSETS_BASE_URL);
};

const getImageQueryString = (baseUrl: string, imageParams: ImageSizesProps) => {
  if (!baseUrl) return null;
  const { width, height, fit, quality, format, imgixParams } = imageParams;
  let params = '';
  if (width && width > 0) params += `&w=${width}`;
  if (height && height > 0) params += `&h=${height}`;
  if (isImgixUrl(baseUrl)) {
    if ((width || height) && fit) params += `&fit=${fit}`;
    if (imgixParams) {
      Object.keys(imgixParams).forEach((key) => {
        params += `&${key}=${imgixParams[key]}`;
      });
    }
  } else if (width || height) {
    // Convert imgix fit params into image service fit params
    if (fit === 'crop') {
      params += '&fit=crop';
    } else {
      params += '&fit=clip';
    }
  }
  if (quality && quality > 0) params += `&q=${quality}`;
  if (format) params += `&fm=${format}`;
  return params.length > 0 ? params.substring(1) : null;
};

const getImageUrl = (baseUrl: string, queryString?: string | null): string | undefined => {
  if (!baseUrl) return undefined;
  return baseUrl + (queryString ? `?${queryString}` : '');
};

const getImageSizes = (baseUrl: string, props: ImageSizesProps): ImageSize[] => {
  if (!baseUrl) return [];
  const { width, height, srcSizes } = props;
  const imageSizes: ImageSize[] = [];
  if (srcSizes) {
    srcSizes.forEach((srcSize) => {
      imageSizes.push({
        width: srcSize.width,
        height: srcSize.height,
        descriptor: `${srcSize.width}w`,
      });
    });
  } else {
    const baseWidth = width || (height ? 0 : DEFAULT_WIDTH);
    const baseHeight = height || 0;
    DEVICE_PIXEL_RATIOS.forEach((dpr) => {
      imageSizes.push({
        width: baseWidth > 0 ? baseWidth * dpr : undefined,
        height: baseHeight > 0 ? baseHeight * dpr : undefined,
        descriptor: `${dpr}x`,
      });
    });
  }
  return imageSizes;
};

interface ImageCandidate {
  url: string | undefined;
  descriptor: string;
}

const getImageCandidates = (baseUrl: string, props: ImageSizesProps): ImageCandidate[] => {
  if (!baseUrl) return [];
  const { fit, quality, format, imgixParams } = props;
  const imageSizes = getImageSizes(baseUrl, props);
  const imageCandidates: ImageCandidate[] = [];
  imageSizes.forEach((imageSize) => {
    const { width, height, descriptor } = imageSize;
    const queryString = getImageQueryString(baseUrl, {
      width,
      height,
      fit,
      quality,
      format,
      imgixParams,
    });
    const url = getImageUrl(baseUrl, queryString);
    imageCandidates.push({ url, descriptor });
  });
  return imageCandidates;
};

const getSrc = (imageCandidates: ImageCandidate[]): string | undefined => {
  if (imageCandidates.length === 0) return undefined;
  let imageCandidate = imageCandidates[Math.floor(imageCandidates.length / 2)];
  imageCandidate = imageCandidate || imageCandidates[0];
  return imageCandidate.url;
};

const getSrcSet = (imageCandidates: ImageCandidate[]): string | undefined => {
  if (imageCandidates.length === 0) return undefined;
  return imageCandidates.map(({ url, descriptor }) => `${url} ${descriptor}`).join(', ');
};

export interface CachedImageProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    ImageSizesProps {
  uuid?: string;
  width?: number;
  height?: number;
  explicitSize?: boolean; // temporary attribute to set explicit height and with until the feature flag is flipped
}

const CachedImage = (props: CachedImageProps): JSX.Element => {
  const {
    uuid,
    src: imageSrc,
    sizes,
    fit = 'max',
    format = ImageFormat.WEBP,
    alt = '',
    imgixParams = { auto: 'format' },
    width = undefined,
    height = undefined,
    explicitSize = false,
    // These props are just removed from rest which gets passed to img
    quality: _q,
    srcSizes: _s,
    ...rest
  } = props;

  // Since we're not currently using imgix for images on S3, we don't need the useCachedUrl hook
  // to look up the URL, which removes a lot of image service API calls
  let baseUrl = getBaseImageUrl(uuid);

  if (!baseUrl && !uuid) {
    baseUrl = getCachedUrl(imageSrc);
  }

  if (!baseUrl) return <Fragment />;

  // The fit, format and imgixParams props have default values, so they get passed separately
  const imageCandidates = getImageCandidates(baseUrl, {
    ...props,
    fit,
    format,
    imgixParams,
  });

  const src = isUploadCareUrl(imageSrc) ? imageSrc : getSrc(imageCandidates);
  const srcSet = isUploadCareUrl(imageSrc) ? undefined : getSrcSet(imageCandidates);

  if (explicitSize) {
    return (
      <img
        {...rest}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
        width={width}
        height={height}
        alt={alt}
      />
    );
  }

  return <img {...rest} src={src} srcSet={srcSet} sizes={sizes} alt={alt} />;
};
export default CachedImage;
