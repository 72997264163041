import type { AppThunk } from '~/reducers';
import { LoadedVendorAccountSettingsState } from '~/reducers/vendorAccountSettingsReducer';
import { MappedVendorUserView, UpdateUserRequest } from '~/types/responseTypes';
import { createAction } from '~/util/actionUtils';
import ApiService from '~/util/apiService';
import Logger from '~/util/logger';

import { getVendorUser } from '../vendorAccountActions';
import {
  REQUESTING_ACCOUNT_SETTINGS,
  RECEIVED_ACCOUNT_SETTINGS,
  UPDATING_ACCOUNT_SETTINGS,
  UPDATED_ACCOUNT_SETTINGS,
  RequestingAccountSettingsAction,
  UpdatedAccountSettingsAction,
  UpdatingAccountSettingsAction,
} from './types/vendorAccountSettingsActionTypes';

const updateVendorUser = (request: UpdateUserRequest): Promise<MappedVendorUserView> => {
  return ApiService.put('/web-marketplace-api/v1/vendor-user/current/update', request);
};

const createUserUpdateRequest = (
  accountSettings: LoadedVendorAccountSettingsState
): UpdateUserRequest => {
  const { email, firstName, lastName, profileOnboardedAt, phoneNumber } = accountSettings;
  return { email, firstName, lastName, profileOnboardedAt, phoneNumber };
};

export const requestAccountSettings = (): AppThunk<Promise<void>> => {
  return (dispatch) => {
    dispatch(createAction<RequestingAccountSettingsAction>(REQUESTING_ACCOUNT_SETTINGS));
    return getVendorUser()
      .then((vendorUser) => {
        const accountSettings = { ...vendorUser };
        dispatch(createAction(RECEIVED_ACCOUNT_SETTINGS, { payload: accountSettings }));
      })
      .catch((error) => Logger.error(error.message, error));
  };
};

export const updateAccountInformationSettings = (
  accountSettings: LoadedVendorAccountSettingsState
): AppThunk<Promise<void>> => {
  return (dispatch) => {
    dispatch(createAction<UpdatingAccountSettingsAction>(UPDATING_ACCOUNT_SETTINGS));
    const userUpdateRequest = createUserUpdateRequest(accountSettings);
    return updateVendorUser(userUpdateRequest)
      .then((vendorUser) => {
        const newAccountSettings = { ...vendorUser };
        dispatch(
          createAction<UpdatedAccountSettingsAction>(UPDATED_ACCOUNT_SETTINGS, {
            payload: newAccountSettings,
          })
        );
      })
      .catch((error) => {
        Logger.error(error.message, error);
      });
  };
};
