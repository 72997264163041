import { CreditAccountDetailsView } from '@zola/svc-marketplace-ts-types';

import { AppDispatch } from '~/reducers';
import ApiService from '~/util/apiService';

import {
  RECEIVED_VENDOR_CREDIT_ACCOUNT_DETAILS,
  ReceivingVendorCreditAccountDetailsAction,
  REQUESTING_VENDOR_CREDIT_ACCOUNT_DETAILS,
  RequestingVendorCreditAccountDetailsAction,
  VendorCreditAccountState,
} from './vendorCreditAccountDetailsActionTypes';

const requestingVendorCreditAccountDetails = (): RequestingVendorCreditAccountDetailsAction => {
  return {
    type: REQUESTING_VENDOR_CREDIT_ACCOUNT_DETAILS,
  };
};

const receivedVendorCreditAccountDetails = (
  payload: VendorCreditAccountState
): ReceivingVendorCreditAccountDetailsAction => {
  return {
    type: RECEIVED_VENDOR_CREDIT_ACCOUNT_DETAILS,
    payload,
  };
};

export const fetchVendorCreditAccountDetails = (storefrontUuid: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(requestingVendorCreditAccountDetails());
    return ApiService.get<CreditAccountDetailsView>(
      `web-marketplace-api/v2/manage/vendor-credit/account/storefronts/${storefrontUuid}/details`
    )
      .then((response) => {
        dispatch(
          receivedVendorCreditAccountDetails({ storefrontUuid, creditAccountDetails: response })
        );
      })
      .catch(() => null);
  };
};
