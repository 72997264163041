import queryString from 'query-string';

import { InquiryUrlStatus } from './constants';
import { InboxSort, INBOX_SORTS, SortDirection, SORT_DIRECTIONS } from './vendorInboxTypes';

export const isInboxSort = (unverifiedSort: string): unverifiedSort is InboxSort => {
  return (INBOX_SORTS as unknown as string[]).includes(unverifiedSort);
};

export const isSortDirection = (
  unverifiedDirection: string
): unverifiedDirection is SortDirection => {
  return (SORT_DIRECTIONS as unknown as string[]).includes(unverifiedDirection);
};

export type VendorInboxNavigationParams = {
  page?: number;
  status?: InquiryUrlStatus;
  uuid?: string;
  sort?: InboxSort;
  direction?: SortDirection;
};

/**
 * These are transient query string parameters (and not an exhaustive list)
 * that are used to trigger modals after actions on other pages.
 *
 */
export type VendorInboxTransientParams = {
  storefrontUuid?: string;
  purchased?: 'true';
  quickCheckout?: string;
  success?: 'true';
  survey?: 'rate-my-lead';
};
export type VendorInboxParams = VendorInboxNavigationParams & VendorInboxTransientParams;

export const getVendorInboxUrl = ({ status, uuid, ...query }: VendorInboxParams = {}): string => {
  // Expired inquiries are shown in the closed tab
  const statusForUrl = status === 'expired' ? 'closed' : status || 'ready';

  // All inquiry details are now shown at /inspire/vendors/leads/:status/:uuid
  let baseUrl = `/inspire/vendors/leads/${statusForUrl}`;
  if (uuid) {
    baseUrl += `/${uuid}`;
  }

  return queryString.stringifyUrl({ url: baseUrl, query });
};
