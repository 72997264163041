import {
  VendorTaxonomyKey,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomyKeyFromVendorType } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import {
  StorefrontCardView,
  VendorCardView as BackendVendorCardView,
  NumericRangeInteger,
  VendorDetailCardView,
} from '@zola/svc-marketplace-ts-types';

import { MappedStorefrontDetailsPreferredVendor } from '~/types/mappedResponseTypes';
import { VendorCardView } from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { centsToDollars } from '~/util/priceConversion';
import { formatAsCurrency } from '~/util/textUtils';

import { VendorCardVendor } from './types';

export const isStorefrontCardView = (
  storefront: VendorCardView | StorefrontCardView
): storefront is StorefrontCardView => {
  return 'details' in storefront && 'metroType' in storefront;
};

/**
 * Convert a couples view of the storefront to a vendor card.  Since this doesn't
 * include the details, it will be of limited use in showing a vendor card, but for
 * something like generating the schema.org for a vendor, it should be fine.
 */
export const storefrontDetailsToVendorCardVendor = (
  storefront: CouplesStorefrontDetails
): VendorCardVendor => {
  return {
    storefrontUuid: storefront.uuid,
    storefrontSlug: storefront.slug,
    badges: [],
    awards: storefront.badges,
    vendorName: storefront.name,
    taxonomyNodeKey: storefront.taxonomyKey,
    address1: storefront.address.address1,
    city: storefront.address.city,
    stateProvince: storefront.address.stateProvince,
    postalCode: storefront.address.postalCode,
    priceRangeVisible: storefront.priceRangeVisible,
    priceTier: null,
    storefrontCoverId: storefront.coverGallery?.[0]?.uuid ?? null,
    reviewsCount: storefront.recommendations.length, //
    averageReviewsRate: storefront.averageReviewsRate,
    metroType: null,
    price: {
      min: (storefront.startingPrice || 0) * 100,
      max: null,
    },
    imageIds: [
      ...(storefront.coverGallery || []).map(({ uuid }) => uuid),
      ...storefront.photoGallery.map(({ uuid }) => uuid),
    ],

    // This is where converting storefront details to a vendor card falls apart:
    // the details has a lot of information we rely on in other places.
    details: null,
    socialProof: storefront.socialProof,
    searchScore: null,
    displayAvailability: false,
    availableDates: null,
    isVendorCardVendorType: true,
    featuredPlacement: false,
    featuredReview: null,
    description: storefront.description,
    listingMessageType: null,
    claimed: Boolean(storefront.claimedAt),
  };
};

export const vendorCardViewToVendorCardVendor = (
  vendor: VendorCardView | BackendVendorCardView | MappedStorefrontDetailsPreferredVendor
): VendorCardVendor => {
  const {
    storefrontUuid,
    storefrontSlug,
    vendorName,
    taxonomyNode,
    city,
    stateProvince,
    priceRangeVisible,
    priceTier,
    storefrontCoverId,
    storefrontResponsiveStatsView,
    reviewsCount,
    averageReviewsRate,
    startingPriceCents,
    claimedAt,
  } = vendor;
  return {
    storefrontUuid,
    storefrontSlug,
    badges: storefrontResponsiveStatsView?.quickResponder
      ? [{ type: 'QUICK_RESPONDER', message: 'Quick responder' }]
      : [],
    vendorName,
    taxonomyNodeKey: (taxonomyNode?.key as VendorTaxonomyKey) || null,
    address1: null,
    city,
    stateProvince,
    postalCode: null,
    priceRangeVisible: Boolean(priceRangeVisible),
    priceTier,
    storefrontCoverId,
    reviewsCount,
    averageReviewsRate,
    metroType: null,
    price: {
      min: startingPriceCents || null,
      max: null,
    },
    imageIds: null,
    details: null,
    socialProof: null,
    searchScore: null,
    displayAvailability: false,
    availableDates: null,
    isVendorCardVendorType: true,
    featuredPlacement: false,
    featuredReview: null,
    description: null,
    listingMessageType: null,
    claimed: Boolean(claimedAt),
  };
};

export const storefrontCardViewToVendorCardVendor = (
  vendor: StorefrontCardView
): VendorCardVendor => {
  const {
    awards,
    storefrontUuid,
    slug,
    badges,
    name,
    city,
    stateProvince,
    postalCode,
    priceRangeVisible,
    priceTier,
    reviewsCount,
    averageReviewsRate,
    vendorType,
    metroType,
    startingPriceCents,
    price,
    imageIds,
    details,
    socialProof,
    searchScore,
    displayAvailability,
    availableDates,
    featuredPlacement,
    featuredReview,
    description,
    listingMessageType,
    claimed,
    pros,
    cons,
  } = vendor;
  return {
    awards,
    storefrontUuid,
    storefrontSlug: slug,
    badges,
    vendorName: name,
    taxonomyNodeKey: getVendorTaxonomyKeyFromVendorType(vendorType) || null,
    address1: null,
    city,
    stateProvince,
    postalCode,
    priceRangeVisible: Boolean(priceRangeVisible),
    priceTier,
    storefrontCoverId: imageIds[0] || null,
    reviewsCount,
    averageReviewsRate,
    metroType,
    price: {
      min: price?.min || startingPriceCents || null,
      max: price?.max,
    },
    imageIds,
    details,
    socialProof,
    searchScore,
    displayAvailability: Boolean(displayAvailability),
    availableDates,
    isVendorCardVendorType: true,
    featuredPlacement,
    featuredReview,
    description,
    listingMessageType,
    claimed,
    pros,
    cons,
  };
};

export const cardViewToVendorCardVendor = (vendor: VendorCardView | StorefrontCardView) => {
  if (isStorefrontCardView(vendor)) {
    return storefrontCardViewToVendorCardVendor(vendor);
  }
  return vendorCardViewToVendorCardVendor(vendor);
};

export const getDisplayPrice = (startingPriceCents?: number | null) => {
  return (startingPriceCents || 0) > 0
    ? formatAsCurrency(centsToDollars(startingPriceCents))
    : null;
};

export const vendorsWithPerEntityPricingSet = new Set([
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
]);

const getFloristsLowCenterPiecePrice = (details?: VendorDetailCardView | null) => {
  // @ts-expect-error this type is empty in svc-marketplace-ts-types because members are variable based on category
  return getDisplayPrice(details?.lowCenterpieceStartPriceCents);
};

const getStartingPricePerPerson = (details?: VendorDetailCardView | null) => {
  // @ts-expect-error this type is empty in svc-marketplace-ts-types because members are variable based on category
  return getDisplayPrice(details?.startPriceCentsPerPerson);
};

export const getVendorsPerPrice = (
  vendorTypeKey?: VendorTaxonomyKey | null,
  details?: VendorDetailCardView | null
) => {
  if (vendorTypeKey === FLORISTS_TAXONOMY_KEY) return getFloristsLowCenterPiecePrice(details);

  return getStartingPricePerPerson(details);
};

export const showPerEntityPricing = (
  taxonomyNodeKey?: VendorTaxonomyKey | null,
  price?: NumericRangeInteger | null
) => {
  const hasNoMinumumPrice = price?.min === null || price?.min === undefined;
  return (
    hasNoMinumumPrice && taxonomyNodeKey && vendorsWithPerEntityPricingSet.has(taxonomyNodeKey)
  );
};

export type PricePerTextProps = 'per arrangement' | 'per serving' | 'per person' | '';

export const getPerPriceText = (vendorTypeKey?: VendorTaxonomyKey): PricePerTextProps => {
  switch (vendorTypeKey) {
    case FLORISTS_TAXONOMY_KEY: {
      return 'per arrangement';
    }
    case CAKES_DESSERTS_TAXONOMY_KEY: {
      return 'per serving';
    }
    default: {
      return 'per person';
    }
  }
};

/**
 * (null) '' - Couldn't determine price tier
 * (0) '$' - On request
 * (1) '$' - Inexpensive
 * (2) '$$' - Affordable
 * (3) '$$$' - Moderate
 * (4) '$$$$' - Luxury
 */
export const getPriceTierText = (priceTier?: VendorCardView['priceTier']) => {
  if (priceTier && priceTier >= 0 && priceTier <= 4) {
    return '$'.repeat(priceTier);
  }
  return '';
};
