import { CheckboxFieldAdapter } from '@zola/zola-ui/src/components/Form/CheckboxField';
import { DropdownV2Adapter } from '@zola/zola-ui/src/components/Form/Dropdown';
import { FormInput } from '@zola/zola-ui/src/components/Form/InputFieldV2';
import { FormInputProps } from '@zola/zola-ui/src/components/Form/InputFieldV2/FormInput/FormInput';
import { RadioFieldAdapter } from '@zola/zola-ui/src/components/Form/RadioField';
import { TextareaFieldAdapter } from '@zola/zola-ui/src/components/Form/TextareaField';
import { composeValidators } from '@zola/zola-ui/src/components/Form/util/composeValidators';

import { FieldValidator } from 'final-form';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { stateOptionList } from '~/meta/stateOptionList';

import AutoSuggestLocationField from '../AutoSuggestLocationField/AutoSuggestLocationField';
import AutoSuggestVendorField from '../AutoSuggestVendorField/AutoSuggestVendorField';

// TODO: move 👇

const normalizeErrorMessage = (message: string, name: string) => {
  if (typeof message === 'string') {
    return message;
  }
  if (typeof message === 'object') {
    return message[name];
  }
  return '';
};

interface FieldWithValidation<T = string | undefined>
  extends Omit<FieldProps<T, FieldRenderProps<T>>, 'validate'> {
  validate?: FieldValidator<T> | FieldValidator<T>[];
}

interface AutoSuggestVendorFieldAdapterProps extends FieldWithValidation {
  useVendorObject?: boolean;
}

type InputChanges = {
  onSuggestionSelected?:
    | (() => void)
    | ((e: Event, { suggestion }: { suggestion: string }) => void);
  onInputChange?: (() => void) | ((name: string) => void);
};

/**
 * Renders a auto suggest vendor field in react final form.
 *
 * @deprecated Use VendorAutosuggestField from Zola-UI instead
 */
export const AutoSuggestVendorFieldAdapter = ({
  input,
  useVendorObject = false,
  onSuggestionSelected,
  onInputChange,
  meta,
  onInputBlur,
  ...rest
}: AutoSuggestVendorFieldAdapterProps) => {
  const errorMessage = normalizeErrorMessage(meta.error, input.name);
  let inputChanges: InputChanges = {};

  if (onSuggestionSelected || onInputChange) {
    // If one of these was provided, use those
    inputChanges = {
      onSuggestionSelected,
      onInputChange,
    };
  } else if (useVendorObject) {
    // if not provided, set the object in the field
    inputChanges.onSuggestionSelected = (e: Event, { suggestion }: { suggestion: string }) => {
      e.preventDefault();
      input.onChange(suggestion);
    };
  } else {
    // If not provided, Set the field value
    inputChanges.onInputChange = (name: string) => input.onChange(name);
  }
  return (
    <AutoSuggestVendorField
      {...input}
      {...rest}
      {...inputChanges}
      onInputBlur={(event) => {
        input.onBlur(event);
        if (onInputBlur) {
          onInputBlur(event);
        }
      }}
      errorMessage={meta.touched ? errorMessage : ''}
    />
  );
};

type AutoSuggestLocationFieldAdapterProps = FieldWithValidation<Event | string> & InputChanges;

/**
 * Renders a auto suggest location (market) field in react final form.
 *
 * @deprecated Use VendorAutosuggestField or VendorAutosuggest from Zola-UI instead
 */
export const AutoSuggestLocationFieldAdapter = ({
  input,
  onSuggestionSelected,
  onInputChange,
  meta,
  ...rest
}: AutoSuggestLocationFieldAdapterProps) => {
  const errorMessage = normalizeErrorMessage(meta.error, input.name);
  let inputChanges: InputChanges = {};

  if (onSuggestionSelected || onInputChange) {
    // If one of these was provided, use those
    inputChanges = {
      onSuggestionSelected,
      onInputChange,
    };
  } else {
    // If not provided, Set the field value
    inputChanges.onInputChange = (name: string) => input.onChange(name);
  }
  return (
    <AutoSuggestLocationField
      {...input}
      {...rest}
      {...inputChanges}
      meta={meta}
      onInputBlur={(event) => input.onBlur && input.onBlur(event)}
      errorMessage={meta.touched ? errorMessage : ''}
    />
  );
};

interface FormTextareaProps<T> extends FieldWithValidation<T> {
  className?: string;
  name: string;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
}

/**
 * Renders a text area in React Final Form using the Design System
 *
 * @param {*} props
 * @param {FieldValidator} props.validate - an array of validators or single validator to check if the input is OK
 *
 * All other props are passed to the field component
 */
export const FormTextarea = <T,>({ validate, ...rest }: FormTextareaProps<T>) => {
  return <Field component={TextareaFieldAdapter} validate={getValidator(validate)} {...rest} />;
};

FormTextarea.defaultProps = {
  disabled: false,
  validate: null,
};

/**
 * Renders a checkbox in react final form using the design system
 *
 * @param {Object} props
 * @param {FieldValidator} props.validate - an array of validators or single validator to check if the input is OK
 * @param {string} props.name
 * @param {boolean} props.useSelectorStyle
 */
export const FormCheckbox = ({ validate, name, ...rest }: FieldWithValidation) => {
  return (
    <Field
      name={name}
      validate={getValidator(validate)}
      // @ts-expect-error incompatible validator types
      component={CheckboxFieldAdapter}
      id={`checkbox-${name}`}
      type="checkbox"
      {...rest}
    />
  );
};

/**
 * Renders a radio in react final form using the design system
 *
 * @param {Object} props
 * @param {FieldValidator} props.validate - an array of validators or single validator to check if the input is OK
 */
export const FormRadio = ({ validate, ...rest }: FieldProps<string, FieldRenderProps<string>>) => (
  <Field component={RadioFieldAdapter} validate={getValidator(validate)} type="radio" {...rest} />
);

/**
 * Renders a dropdown in react final form using the design system
 *
 * @param {Object} props
 * @param {FieldValidator} props.validate - an array of validators or single validator to check if the input is OK
 * @param {Array<{label, value>}} props.options
 */
export const FormDropdown = ({ name, validate, ...rest }: FieldWithValidation) => (
  <Field name={name} validate={getValidator(validate)}>
    {(props) => <DropdownV2Adapter {...rest} {...props} />}
  </Field>
);

/**
 * Renders a auto suggest vendor field in react final form.
 *
 * @param {Object} props
 * @param {FieldValidator} props.validate - an array of validators or single validator to check if the input is OK
 * @param {bool} props.inputV2 - render using design system
 *
 * @deprecated Use VendorAutosuggestField or VendorAutosuggest from Zola-UI instead
 */
export const FormVendorAutosuggest = ({
  validate,
  ...rest
}: FieldProps<string, FieldRenderProps<string>>) => {
  return (
    <Field component={AutoSuggestVendorFieldAdapter} validate={getValidator(validate)} {...rest} />
  );
};

/**
 * Renders a state dropdown list in react final form using the design system
 *
 * @param {object} props
 * @param {FieldValidator} props.validate - an array of validators or single validator to check if the input is OK
 */
export const StateDropdown = (props: FieldWithValidation) => {
  const options = stateOptionList.map((option) => ({ ...option, label: option.title }));
  return <FormDropdown options={options} {...props} />;
};

function getValidator<T>(
  validate: FieldValidator<T> | FieldValidator<T>[] | undefined
): FieldValidator<T> | undefined {
  if (!validate) return undefined;
  if (!Array.isArray(validate)) return validate;
  return composeValidators(...validate);
}

export const FormNumberInput = (
  props: FormInputProps<FieldRenderProps<number, HTMLInputElement>, HTMLInputElement>
) => {
  return <FormInput type="number" parse={(value: string) => parseInt(value, 10)} {...props} />;
};
