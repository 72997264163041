import {
  EMAIL,
  DOES_NOT_CONTAIN_EMOJI,
  GREATER_THAN_OR_EQUAL_TO_ZERO,
  GREATER_THAN_ZERO,
  GREATER_THAN_ZERO_OR_BLANK,
  INSTAGRAM_HANDLE,
  NUMBER,
  PASSWORD,
  PHONE,
  ZIP,
  maxLength,
  MAX_LENGTH_100,
  MAX_LENGTH_1000,
  MAX_LENGTH_150,
  MAX_LENGTH_1500,
  MAX_LENGTH_25,
  MAX_LENGTH_255,
  MAX_LENGTH_3500,
  MAX_LENGTH_40,
  MAX_LENGTH_400,
  MAX_LENGTH_50,
  MAX_LENGTH_500,
  MAX_LENGTH_5000,
  MAX_LENGTH_650,
  MAX_LENGTH_80,
  MIN_LENGTH_50,
  MIN_LENGTH_200,
} from '@zola/zola-ui/src/components/Form/util/validations';

import _isInteger from 'lodash/isInteger';

import {
  addYears,
  DateValue,
  formatDate,
  getDateUtc,
  isAfterDate,
  isBeforeDate,
  parseDateUtc,
} from '~/util/dateUtils';
import { formatWithCommas } from '~/util/textUtils';

import { getStateName } from './addresses';

export {
  EMAIL,
  DOES_NOT_CONTAIN_EMOJI,
  GREATER_THAN_OR_EQUAL_TO_ZERO,
  GREATER_THAN_ZERO_OR_BLANK,
  GREATER_THAN_ZERO,
  INSTAGRAM_HANDLE,
  NUMBER,
  PASSWORD,
  PHONE,
  ZIP,
  maxLength,
  MAX_LENGTH_100,
  MAX_LENGTH_1000,
  MAX_LENGTH_150,
  MAX_LENGTH_1500,
  MAX_LENGTH_25,
  MAX_LENGTH_255,
  MAX_LENGTH_3500,
  MAX_LENGTH_40,
  MAX_LENGTH_400,
  MAX_LENGTH_50,
  MAX_LENGTH_500,
  MAX_LENGTH_5000,
  MAX_LENGTH_650,
  MAX_LENGTH_80,
  MIN_LENGTH_200,
  MIN_LENGTH_50,
};

// Missed this one
export const MAX_LENGTH_64 = maxLength(64);
export const MAX_LENGTH_450 = maxLength(450);

const DATE_REGEX =
  /^([0]?[1-9]|1[0-2]|\d{4})[-|/](0[1-9]|1[0-9]|2[0-9]|3[0-1])[-|/](\d{4}|[0]?[1-9]|1[1-2])/;
const MONEY_REGEX = /^[0-9]+(\.[0-9]{1,2})?$/;
const MONEY_WITH_COMMA_REGEX = /^[0-9,]+(\.[0-9,]{1,2})?$/;
const URL_REGEX =
  /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const URL_OPTIONAL_PROTOCOL_REGEX =
  /^(?:(?:https?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const URL_PROTOCOL_REGEX = /^https?:\/\//i;
const STRONG_PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^0-9a-zA-Z ])(.{8,})$/;
const EMAIL_ANYWHERE =
  /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+/;
const URL_ANYWHERE =
  /((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)/;
const PHONE_ANYWHERE = /((?:\([2-9]\d{2}\) ?|[2-9]\d{2}(?:-?| ?|\.?))[2-9]\d{2}[- \\.]?\d{4})/;
const STRANGE_PHONE_NUMBER =
  /((?:[(]?[2-9][-\s]?[0-9][-\s]?[0-9])(?:[-\s]?[)]?)(?:[-\s]?)(?:[2-9][-\s]?[0-9][-\s]?[0-9][-\s]?)(?:[0-9][-\s]?[0-9][-\s]?[0-9][-\s]?[0-9][-\s]?))/;
const AT_SYMBOL_ANYWHERE = /[@](\w+)|[@]/;

export const REQUIRED = (value: any) => {
  if (typeof value === 'string') {
    // eslint-disable-next-line react/destructuring-assignment
    return value && value.trim() ? undefined : 'Required';
  }
  if (typeof value === 'number') {
    return value !== undefined && value !== null ? undefined : 'Required';
  }
  // eslint-disable-next-line react/destructuring-assignment
  if (typeof value === 'object' && (value === null || value.length === 0)) {
    // This is maybe not the the right way to be validating checkboxes
    return 'At least one option must be selected';
  }
  return value ? undefined : 'Required';
};

export const REQUIRED_TEXT = (value: any) => {
  if (typeof value === 'string') {
    return value && value.trim() ? undefined : 'Required';
  }
  return value ? undefined : 'Required';
};

export const GUEST_COUNT_REQUIRED = (value: string | number | undefined) => {
  return value ? undefined : 'Give your guest count to continue. Guesses welcome!';
};

export const MONEY = (value: string | undefined) =>
  value && (!MONEY_REGEX.test(value) ? 'Invalid dollar amount.' : undefined);

export const MONEY_WITH_COMMA = (value: string | undefined) =>
  value && (!MONEY_WITH_COMMA_REGEX.test(value) ? 'Invalid dollar amount.' : undefined);

export const DATE = (value: Date | string) => {
  if (typeof value === 'object') {
    return undefined;
  }
  return value && !DATE_REGEX.test(value) ? 'Invalid date' : undefined;
};

export const US_STATE = (value: string | undefined) => {
  if (getStateName(value)) {
    return undefined;
  }
  return 'Invalid state/territory code';
};

export const betweenOrBlank =
  (min: number, max: number) => (value: string | number | undefined) => {
    if (value === null || value === undefined || value === '') {
      return undefined;
    }
    const number = Number(value);
    const isNumber = _isInteger(number);
    if (isNumber && (number < min || number > max)) {
      return `Please enter a number between ${min} and ${max}`;
    }
    return undefined;
  };

export const maxIntegerValue = (max: number) => (value: string | number | undefined) => {
  return value && Number(value) >= max
    ? `Please enter a number less than ${formatWithCommas(max)}`
    : undefined;
};

export const WITHIN_STANDARD_RANGE_OR_BLANK = (value: string | null | undefined | number) => {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }
  const number = Number(value);
  const isNumber = _isInteger(number);

  const min = -10000000;
  const max = 10000000;

  if (isNumber && (number < min || number > max)) {
    return `Please enter a number between ${min} and ${max}`;
  }
  return undefined;
};

export const fakeValid = () => undefined;

export const STRONG_PASSWORD = (value: string | undefined) =>
  value && !STRONG_PASSWORD_REGEX.test(value)
    ? 'Password must be at least 8 characters long, and include at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 symbol.'
    : undefined;

const dateAfterNow = (value: DateValue | undefined, format = 'MM/DD/YYYY') => {
  if (!value) {
    return undefined;
  }
  const date = parseDateUtc(value, format);
  const now = getDateUtc();
  const tenYearsFromNow = addYears(now, 10);
  if (isBeforeDate(date, now)) {
    return 'Hmm… this looks like a past date.';
  }
  if (isAfterDate(date, tenYearsFromNow)) {
    return `Date cannot be after ${formatDate(tenYearsFromNow, 'YYYY')}`;
  }
  return undefined;
};

export const DATE_AFTER_NOW = (value: DateValue | undefined) => {
  return dateAfterNow(value);
};
export const DATE_AFTER_NOW_ALREADY_FORMATTED = (value: DateValue | undefined) => {
  return dateAfterNow(value);
};
export const isValidUrlWithProtocol = (value: string | undefined) =>
  Boolean(value && URL_REGEX.test(value));

export const isValidUrlWithoutProtocol = (value: string | undefined) =>
  Boolean(value && URL_OPTIONAL_PROTOCOL_REGEX.test(value));

export const URL = (value: string | undefined) => {
  if (value && !URL_PROTOCOL_REGEX.test(value)) {
    return "Please include 'http://' or 'https://' with your address.";
  }
  return value && !URL_REGEX.test(value) ? 'Please enter a valid web address.' : undefined;
};

export const URL_PROTOCOL_OPTIONAL = (value: string | undefined) =>
  value && !URL_OPTIONAL_PROTOCOL_REGEX.test(value)
    ? 'Please enter a valid web address.'
    : undefined;

const COMPETITOR_REGEXS: [RegExp, string][] = [
  [/wed(d?)ing(\W{0,})wire/gim, 'Wedding Wire'],
  [/(the\W{0,}knot)/gim, 'The Knot'],
  [/(k|c)ar(r{0,1})[a|o]t(s{0,1})\s{0,}(and|&)\s{0,1}cake|caratscake/gim, 'Carats and Cake'],
  [/style\W{0,}me\W{0,}pretty/gim, 'Style Me Pretty'],
];

const COMPETITOR_URL_REGEXS: [RegExp, string][] = [
  [/\bweddingwire\.com/gim, 'Wedding Wire'],
  [/\btheknot\.com/gim, 'The Knot'],
  [/\bcaratsandcake\.com/gim, 'Carats and Cake'],
  [/\bstylemepretty\.com/gim, 'Style Me Pretty'],
];

const doesNotContainReferences = (value: string, regexTuples: [RegExp, string][]) => {
  const references = regexTuples
    .filter((regexAndReference) => {
      const [regex, reference] = regexAndReference;
      const text = value || '';
      const matches = [...text.matchAll(regex)];
      let hasReference = matches.length > 0;
      // Don't count "tie the knot" as a reference to The Knot
      if (reference === 'The Knot' && text.includes('tie the knot') && matches.length === 1) {
        hasReference = false;
      }
      return hasReference;
    })
    .map((regexAndReference) => regexAndReference[1]);

  switch (references.length) {
    case 0:
      return undefined;
    case 1:
      return `Please remove references to ${references[0]}.`;
    case 2:
      return `Please remove references to ${references.join(' and ')}.`;
    default:
      return `Please remove references to ${references
        .slice(0, -1)
        .join(', ')}, and ${references.slice(-1)}.`;
  }
};

export const DOES_NOT_CONTAIN_COMPETITOR = (value: any) => {
  return doesNotContainReferences(value, COMPETITOR_REGEXS);
};

export const DOES_NOT_CONTAIN_COMPETITOR_URL = (value: string) => {
  return doesNotContainReferences(value, COMPETITOR_URL_REGEXS);
};

export const NOT_EMPTY_LOCATION = (value: any) => {
  if (value && typeof value === 'object' && Object.values(value).length < 1) {
    return 'Please enter a location';
  }
  return value ? undefined : 'Please enter a location';
};

export const validateAtLeastOne = (value: undefined | string[], message?: string) => {
  if (value === undefined || value.length === 0) {
    return message || 'Please choose at least one.';
  }
  return undefined;
};

const EMAIL_ANYWHERE_REGEX: [RegExp, string] = [
  EMAIL_ANYWHERE,
  'Email addresses aren’t allowed. Remove',
];
const URL_ANYWHERE_REGEX: [RegExp, string] = [
  URL_ANYWHERE,
  'Links to other websites or services aren’t allowed. Remove',
];
const PHONE_ANYWHERE_REGEX: [RegExp, string] = [
  PHONE_ANYWHERE,
  'Phone and other personal numbers aren’t allowed. Remove',
];
const STRANGE_PHONE_NUMBER_REGEX: [RegExp, string] = [
  STRANGE_PHONE_NUMBER,
  'Phone and other personal numbers aren’t allowed. Remove',
];
const AT_SYMBOL_ANYWHERE_REGEX: [RegExp, string] = [
  AT_SYMBOL_ANYWHERE,
  'Links to other websites or services aren’t allowed. Remove',
];

const IDENTIFYING_INFORMATION_REGEXES: [RegExp, string][] = [
  EMAIL_ANYWHERE_REGEX,
  URL_ANYWHERE_REGEX,
  PHONE_ANYWHERE_REGEX,
  STRANGE_PHONE_NUMBER_REGEX,
  AT_SYMBOL_ANYWHERE_REGEX,
];

const formattedErrorMsg = (value: string | undefined, regularExp: RegExp, msg = '') => {
  if (!value) {
    return msg;
  }
  const results = value?.match(regularExp);
  const msgEnd = 'to continue.';
  let dynamicMessage = msg;
  if (results && results.length > 0) {
    dynamicMessage = ''.concat(dynamicMessage, ' ', results[0], ' ', msgEnd);
  }
  return dynamicMessage;
};

const doesNotContainPrivateInfo = (value: string, regexTuples: [RegExp, string][]) => {
  const filteredReferences = regexTuples.filter((regAndref) => {
    return regAndref[0].test(value);
  });
  const references = filteredReferences.map((regAndref) => regAndref[1]);

  const regexes = filteredReferences.map((regAndRef) => regAndRef[0]);

  if (references.length) {
    return formattedErrorMsg(value, regexes[0], references[0]);
  }
  return undefined;
};

export const DOES_NOT_CONTAIN_PERSONAL_INFO = (value: any) => {
  return doesNotContainPrivateInfo(value, IDENTIFYING_INFORMATION_REGEXES);
};
