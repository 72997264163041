import { createAction } from '@reduxjs/toolkit';

import {
  RECEIVED_INQUIRY_PAPER_CROSS_SELL_ELIGIBLE,
  RECEIVED_UNREAD_INQUIRIES,
  REQUESTING_UNREAD_INQUIRIES,
  RECEIVED_UNRESPONDED_INQUIRIES,
  ReceivedUnreadInquiriesAction,
  RequestUnreadInquiriesAction,
  ReceivedUnrespondedInquiriesAction,
} from '~/actions/types/inquiryJewelActionTypes';
import type { AppThunk } from '~/reducers';
import type { ActionableInquiryView } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';

const requestingUnreadInquiries = (): RequestUnreadInquiriesAction => ({
  type: REQUESTING_UNREAD_INQUIRIES,
});

const receivedUnreadInquiries = (
  unreadInquiries: ActionableInquiryView[]
): ReceivedUnreadInquiriesAction => ({
  type: RECEIVED_UNREAD_INQUIRIES,
  payload: unreadInquiries,
});

const receivedUnrespondedInquiries = (
  unrespondedInquiries: ActionableInquiryView[]
): ReceivedUnrespondedInquiriesAction => ({
  type: RECEIVED_UNRESPONDED_INQUIRIES,
  payload: unrespondedInquiries,
});

export interface UnreadInquiryResponse {
  inquiryPaperCrossSellEligible: boolean | null;
  unreadInquiries: ActionableInquiryView[];
  unrespondedInquiries: ActionableInquiryView[];
}

const receivedInquiryPaperCrossSellEligible = createAction<{
  inquiryPaperCrossSellEligible: boolean | null;
}>(RECEIVED_INQUIRY_PAPER_CROSS_SELL_ELIGIBLE);

/**
 * Requests unread inquiries for the logged in user from the backend,
 * dispatching the results into redux.
 *
 * @example
 * dispatch(fetchUnreadInquiries()).then(() => null).catch(() => null);
 *
 * @returns Promise<ActionableInquiryView[]> when dispatched
 */
export const fetchUnreadInquiries = (): AppThunk<Promise<ActionableInquiryView[]>> => {
  return (dispatch) => {
    dispatch(requestingUnreadInquiries());
    return ApiService.get(`/web-marketplace-api/v1/inquiry/unread`)
      .then((response: UnreadInquiryResponse) => {
        dispatch(receivedUnreadInquiries(response.unreadInquiries));
        dispatch(receivedUnrespondedInquiries(response.unrespondedInquiries));
        dispatch(
          receivedInquiryPaperCrossSellEligible({
            inquiryPaperCrossSellEligible: response.inquiryPaperCrossSellEligible,
          })
        );
        return response.unreadInquiries;
      })
      .catch((error: Error) => {
        StandardLogFn(error);
        throw error;
      });
  };
};

/**
 * Requests unread inquiries for the given storefront from the backend
 * dispatching the results into redux.
 *
 * @param storefrontUuid - storefront identifier
 *
 * @example
 * dispatch(fetchUnreadStorefrontInquiries(storefrontUuid)).then(() => null).catch(() => null);
 *
 * @returns Promise<ActionableInquiryView[]> when dispatched
 */
export const fetchUnreadStorefrontInquiries = (
  storefrontUuid: string
): AppThunk<Promise<ActionableInquiryView[]>> => {
  return (dispatch) => {
    dispatch(requestingUnreadInquiries());
    return ApiService.get(`/web-marketplace-api/v1/vendor-inquiry/${storefrontUuid}/unread`)
      .then((response: UnreadInquiryResponse) => {
        dispatch(receivedUnreadInquiries(response.unreadInquiries));
        dispatch(receivedUnrespondedInquiries(response.unrespondedInquiries));
        return response.unreadInquiries;
      })
      .catch((error: Error) => {
        StandardLogFn(error);
        throw error;
      });
  };
};
