import { StorefrontDetailView, V4StorefrontSearchRequest } from '@zola/svc-marketplace-ts-types';

/**
 * Array of arrays for all BE AwardGroupsEnums that can be used
 * when making storefront search requests for all award groups
 */
const ALL_AWARD_GROUPS: V4StorefrontSearchRequest.AwardGroupsEnum[][] = [
  ['BEST_OF_2023', 'BEST_OF_2024'],
];

/**
 * BE AwardGroupsEnum plus 'BEST_OF_ALL', which is used on the FE
 * to parse URL query param when searching for all award groups
 */
export type ClientAwardGroupsEnum = V4StorefrontSearchRequest.AwardGroupsEnum | 'BEST_OF_ALL';

export type SupportedBadge = {
  dek: string;
  imgUrl: string;
  secondaryCta?: string;
  secondaryLink?: string;
  title: string;
};

export type SupportedAward = SupportedBadge & {
  badge: StorefrontDetailView.BadgesEnum;
  order: number;
  toolTip: string;
};

/**
 * To update or upload a new asset
 *   - Log in to AWS management console
 *   - Navigate to s3 / zola-web-assets / static-assets/ images/ badges/
 *   - Upload a 320x320px png with transparent background using underscores
 * d1tntvpcrzvon2 is a cloudfront distribution that points to the zola-web-assets bucket
 * For completeness, also consider checking in the png / svg in this directory
 */
const BEST_OF_2023_AWARD_URL =
  'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/badges/best_of_zola_2023.png';
const BEST_OF_2024_AWARD_URL =
  'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/badges/best_of_zola_2024.png';
const FEATURED_ON_ZOLA_URL =
  'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/badges/featured_on_zola_v3.png';
export const UPCOMING_AWARD_URL =
  'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/badges/upcoming_badge.png';

const nextYear = new Date().getFullYear() + 1;

export const UPCOMING: SupportedBadge = {
  dek: "This coveted best-in-class award is for our couples' favorite vendors who have exemplary reviews and response rates. You still have time to qualify!",
  imgUrl: UPCOMING_AWARD_URL,
  secondaryCta: 'See details',
  secondaryLink:
    '/faq/category/360000310271-zola-for-vendors-and-wedding-professionals/10670261830285-best-of-zola',
  title: `Upcoming: Best of Zola ${nextYear}`,
};

export const BADGES: SupportedBadge[] = [
  {
    dek: "Let everyone know you stand for quality and excellence as a proud Zola vendor.  We're happy you're here.",
    imgUrl: FEATURED_ON_ZOLA_URL,
    title: 'Featured on Zola',
  },
];

const TOOLTIP_COPY =
  "Earned from consistently high ratings and a fast response time. They're the best of the best!";

/**
 * Lookup with all available Best of Zola awards, currently being used on
 *   1. Storefront LDPs (/wedding-photographers/federico-photography)
 *   2. Vendor awards and badges page (/inspire/vendors/listing/badges)
 */
export const AWARD_LOOKUP: Record<StorefrontDetailView.BadgesEnum, SupportedAward> = {
  BEST_OF_2024: {
    badge: 'BEST_OF_2024',
    dek: "Congrats on winning Best of Zola 2024! Showcase your accomplishment and let couples know you're a top vendor with this nifty badge.",
    imgUrl: BEST_OF_2024_AWARD_URL,
    order: 1,
    title: 'Best of Zola 2024',
    toolTip: TOOLTIP_COPY,
    secondaryLink:
      'https://www.dropbox.com/scl/fo/phwk6y5pba1g8slz8os7f/h?rlkey=8k9oe5lljwbwp1rz4mkv30hyp&dl=0',
    secondaryCta: 'Download social kit',
  },
  BEST_OF_2023: {
    badge: 'BEST_OF_2023',
    dek: 'You won best of Zola in 2023. Show off your experience and stellar reputation with the 2023 badge.',
    imgUrl: BEST_OF_2023_AWARD_URL,
    order: 2,
    title: 'Best of Zola 2023',
    toolTip: TOOLTIP_COPY,
  },
};

export const AWARDS: SupportedAward[] = (
  Object.keys(AWARD_LOOKUP) as StorefrontDetailView.BadgesEnum[]
).map((badge) => AWARD_LOOKUP[badge]);

const isSupportedAward = (
  award: StorefrontDetailView.BadgesEnum
): award is StorefrontDetailView.BadgesEnum => {
  return Object.keys(AWARD_LOOKUP).includes(award);
};

export const filterSupportedAwards = (
  awards?: StorefrontDetailView.BadgesEnum[] | null
): StorefrontDetailView.BadgesEnum[] => {
  return awards?.filter((award) => isSupportedAward(award)) || [];
};

export const filterAndSortSupportedAwards = (
  awards: StorefrontDetailView.BadgesEnum[]
): StorefrontDetailView.BadgesEnum[] =>
  filterSupportedAwards(awards).sort((a, b) => AWARD_LOOKUP[a].order - AWARD_LOOKUP[b].order);

/**
 * Maps BE AwardGroupsEnum to FE ClientAwardGroupsEnum
 * [['BEST_OF_2024', 'BEST_OF_2023']] -> [['BEST_OF_ALL']]
 * [['BEST_OF_2024']] -> [['BEST_OF_2024']]
 */
export const awardGroupsEnumToClientAwardGroupsEnum = (
  awardGroups: V4StorefrontSearchRequest.AwardGroupsEnum[][]
): ClientAwardGroupsEnum[][] =>
  (ALL_AWARD_GROUPS[0].every((award) => awardGroups[0].includes(award))
    ? [['BEST_OF_ALL']]
    : [awardGroups]) as ClientAwardGroupsEnum[][];

/**
 * Maps FE ClientAwardGroupsEnum to BE AwardGroupsEnum
 * [['BEST_OF_ALL']] -> [['BEST_OF_2024', 'BEST_OF_2023']]
 * [['BEST_OF_2024']] -> [['BEST_OF_2024']]
 */
export const clientAwardGroupsEnumToAwardGroupsEnum = (
  awardGroups: ClientAwardGroupsEnum[][]
): V4StorefrontSearchRequest.AwardGroupsEnum[][] => {
  return awardGroups[0].includes('BEST_OF_ALL')
    ? ALL_AWARD_GROUPS
    : (awardGroups as V4StorefrontSearchRequest.AwardGroupsEnum[][]);
};
