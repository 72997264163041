import { CreditBalanceView } from '@zola/svc-marketplace-ts-types';

import { createSelector } from 'reselect';

import type { RootState } from '~/reducers';
import { getCurrentStorefrontUuid } from '~/selectors/vendorAccountSelectors';

export const getCreditsByStorefront = (state: RootState): Record<string, CreditBalanceView> =>
  state.vendors.credits.byStorefrontUuid;

export const getStorefrontCredits = createSelector(
  getCurrentStorefrontUuid,
  (state: RootState) => state.vendors.credits.byStorefrontUuid,
  (storefrontUuid, lookupByUuid): CreditBalanceView | undefined =>
    storefrontUuid ? lookupByUuid[storefrontUuid] : undefined
);

export const getUnlimitedCreditsActive = (state: RootState): boolean =>
  Boolean(getStorefrontCredits(state)?.discountActiveUntil);

export const getStorefrontPromoCodes = createSelector(
  getCurrentStorefrontUuid,
  (state: RootState) => state.vendors.promos.byStorefrontUuid,
  (storefrontUuid, lookupByUuid) => {
    // this is returning undefined or an array if the data is loaded
    return storefrontUuid ? lookupByUuid[storefrontUuid] : undefined;
  }
);
