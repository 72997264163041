import { createSelector } from 'reselect';

import {
  FavoritePhotoPayload,
  FavoriteStorefrontPayload,
} from '~/actions/types/favoriteActionTypes';
import type { RootState } from '~/reducers';
import type { FavoritePhotosState, FavoriteStorefrontsState } from '~/reducers/entities/favorites';

/** return the raw state slice for use with createSelector for performance and preventing re-rendering  */
const getFavoritePhotosEntity = (state: RootState): FavoritePhotosState =>
  state.entities.favorites['wedding-photos'];

export const hasFavoritePhoto = createSelector(getFavoritePhotosEntity, (stateSlice) =>
  stateSlice.loaded ? stateSlice.allIds.length > 0 : null
);

const getFavoritePhotoLookup = createSelector(
  getFavoritePhotosEntity,
  (stateSlice) => stateSlice.byId
);

// The type is needed because the result can be undefined and TS is not inferring that.
export const getFavoritePhoto = (imageId: string) =>
  createSelector(
    getFavoritePhotoLookup,
    (lookup): (FavoritePhotoPayload & { uuid: string }) | undefined => lookup[imageId]
  );

export const getFavoritePhotoBoardUuid = createSelector(
  getFavoritePhotosEntity,
  (stateSlice) => (stateSlice.boards ? stateSlice.boards[0] : undefined) // this is better than boards[0] because ts infers that the result can be undefined
);

export const getFavoritePhotosLoaded = createSelector(
  getFavoritePhotosEntity,
  (stateSlice) => stateSlice.loaded
);

/** return the raw state slice for use with createSelector for performance and preventing re-rendering  */
export const getFavoriteStorefrontEntity = (state: RootState): FavoriteStorefrontsState =>
  state.entities.favorites['storefront-listings'];

const getFavoriteStorefrontLookup = createSelector(
  getFavoriteStorefrontEntity,
  (stateSlice) => stateSlice.byId
);

export const getFavoriteStorefront = (storefrontUuid: string) =>
  createSelector(
    getFavoriteStorefrontLookup,
    (lookup): (FavoriteStorefrontPayload & { uuid: string }) | undefined => lookup[storefrontUuid]
  );

export const getFavoriteStorefrontsLoaded = createSelector(
  getFavoriteStorefrontEntity,
  (stateSlice) => stateSlice.loaded
);
