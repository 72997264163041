import React, { useCallback } from 'react';

import { getBusinessCategory } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { NavigationClicked } from '@zola/tracking-contracts/src/events';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';

import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import VendorLogo from '~/components/common/vendors/VendorLogo';
import featureFlags from '~/util/featureFlags';

export type NavLogoProps = {
  isAuthenticated: boolean;
  className?: string;
  trackingProps: Omit<NavigationClicked, 'navigation_type' | 'business_unit' | 'business_category'>;
};

export const NavLogo = ({
  className = '',
  isAuthenticated,
  trackingProps,
}: NavLogoProps): JSX.Element => {
  const storefront = useCurrentStorefront();

  const href = isAuthenticated ? `/inspire/vendors/dashboard` : `/inspire/vendors`;

  const trackClicked = useCallback(
    (event: React.SyntheticEvent) => {
      const eventData: NavigationClicked = {
        navigation_type: 'CATEGORICAL',
        business_unit: 'VENDOR_EXPERIENCE',
        business_category: storefront
          ? getBusinessCategory(storefront.taxonomyKey)
          : 'UNATTRIBUTED',
        ...trackingProps,
      };
      if (featureFlags.get('debugVendorNavigationClicked')) {
        event.preventDefault();
        event.stopPropagation();
      }

      trackNavigationClicked(eventData);
    },
    [storefront, trackingProps]
  );

  return (
    <a className={className} href={href} onClick={trackClicked}>
      <VendorLogo color={COLORS3.WHITE_100} />
    </a>
  );
};
