import { useMemo } from 'react';

import { getBusinessCategory } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { NavigationClicked } from '@zola/tracking-contracts/src/events';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { TagV2, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';

import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import InquiryJewel from '~/components/common/ui/InquiryJewel';
import featureFlags from '~/util/featureFlags';

import { MenuDropdown, MenuLink } from '../useNavigationLinks';

import styles from './mobileNav.module.less';

interface MobileNavLinkProps {
  item: MenuLink;
  menu?: MenuDropdown;
  /** Optional class name to apply to the link (not the item) */
  className?: string;

  trackingProps: Omit<
    NavigationClicked,
    'navigation_type' | 'business_unit' | 'business_category'
  > & {
    navigation_type?: NavigationClicked['navigation_type'];
  };
}

const InternalOrExternalLink = (
  props: Pick<
    MenuLink,
    'text' | 'to' | 'isActive' | 'renderText' | 'external' | 'jewel' | 'dataTestId'
  > & {
    className?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  }
) => {
  const { className, to, isActive, onClick, dataTestId, renderText, text, jewel, external } = props;

  if (external) {
    return (
      <a
        role="link"
        className={cx(styles.navLink, className)}
        onClick={(event) => {
          onClick?.(event);

          // Without stop propagation, the event may bubble up to a nav panel that collapses and prevents default
          event.stopPropagation();
        }}
        href={to}
        data-testid={dataTestId}
      >
        {renderText || text}
      </a>
    );
  }

  return (
    <NavLink
      role="link"
      className={cx(styles.navLink, className)}
      activeClassName={styles.activeNavLink}
      to={to}
      isActive={isActive}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {renderText || text}
      {jewel && <InquiryJewel style={{ marginBottom: '6px' }} />}
    </NavLink>
  );
};

/** A link the mobile nav menu, either in a sub menu or at  the root */
export const MobileNavLink = (props: MobileNavLinkProps): JSX.Element => {
  const { item, className, trackingProps } = props;
  const { onClick, tag, ...rest } = item;

  const storefront = useCurrentStorefront();

  const eventData: NavigationClicked = useMemo(
    () => ({
      navigation_type: 'CATEGORICAL',
      business_unit: 'VENDOR_EXPERIENCE',
      business_category: storefront ? getBusinessCategory(storefront.taxonomyKey) : 'UNATTRIBUTED',
      ...trackingProps,
    }),
    [storefront, trackingProps]
  );

  return (
    <li className={styles.navItem} role="presentation">
      <InternalOrExternalLink
        className={className}
        {...rest}
        onClick={(event) => {
          trackNavigationClicked(eventData);

          if (featureFlags.get('debugVendorNavigationClicked')) {
            event.stopPropagation();
            event.preventDefault();
          } else {
            if (onClick) {
              onClick(event);
            }
          }
        }}
      />
      {tag && (
        <TagV2 className="tag" variant={TagV2Variant.GREEN}>
          {tag}
        </TagV2>
      )}
    </li>
  );
};
