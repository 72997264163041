import { VendorCardBadgeView } from '@zola/svc-marketplace-ts-types';

import _capitalize from 'lodash/capitalize';
import _sortBy from 'lodash/sortBy';

interface RankedBadge extends VendorCardBadgeView {
  rank: number;
}

/**
 * Assigns sort rankings to badges so we can show the highest ranked badge (and use it in tracking)
 */
const tagRank: Record<string, number> = {
  QUICK_RESPONDER: 1,
  TRENDING: 2,
  HIDDEN_GEM: 3,
  NEW: 4,
  // these are other enum values TS was complaining about not having
  // these are relevant in trackingHelper.ts
  MATCHING_WEDDING_DATE: 5,
  MATCHING_LOCATION: 5,
  MATCHING_BUDGET: 5,
  MATCHING_FLORIST_ETHICS: 5,
  MATCHING_PHOTOG_PERSONALITY: 5,
  MATCHING_PHOTOG_STYLE: 5,
  MATCHING_VENUE_ATMOSPHERE: 5,
  MATCHING_VENUE_CAPACITY: 5,
  BOOKED: 5,
};

/**
 * Returns the highest ranked badge when given an array of badges;
 * clients can get the user-readable message or machine-readable type
 * from the badge after calling the function if additional
 * display output is required.
 * @param badges
 * @returns Highest ranked badge
 */
export const getHighestRankedBadge = (
  badges: VendorCardBadgeView[] | null
): RankedBadge | undefined => {
  const assignRank = (badge: VendorCardBadgeView): RankedBadge => {
    // John wanted the default rank to be higher in case we add more
    // tags and forget to update this. I think 100,000 is good enough
    const rank = badge?.type ? tagRank[badge?.type] : 100000;
    const message = badge?.message ? _capitalize(badge.message.toLowerCase()) : null;
    return { message, type: badge?.type, rank };
  };
  const sortedBadges = () => {
    const rankedBadges = badges?.map((badge) => assignRank(badge));
    return _sortBy(rankedBadges, 'rank');
  };

  return sortedBadges()[0];
};
