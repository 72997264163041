import { ButtonV2 } from '@zola/zola-ui/src/components/Button';

import cx from 'classnames';

import { FavButtonShapeProps } from '../types';

import styles from './buttonShapes.module.less';

interface HeartFavButtonProps extends FavButtonShapeProps {
  isCircleOutline?: boolean;
  imageClassOverrides?: string;
}

export const HeartFavButton = (props: HeartFavButtonProps) => {
  const {
    onMouseEnter,
    onMouseLeave,
    isFavorited,
    onClick,
    buttonClassOverrides,
    isCircleOutline,
    className,
    type,
    imageClassOverrides,
  } = props;

  const getHeartClass = () => {
    if (isFavorited) {
      return type === 'PHOTO' ? styles.photoHeartFavedLg : styles.vendorHeartFaved;
    }

    return type === 'PHOTO' ? styles.photoHeartUnfaved : styles.vendorHeartUnfaved;
  };

  return (
    <div
      className={cx(styles.container, className)}
      data-testid="FavoriteButton"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ButtonV2
        aria-label={isFavorited ? 'Unfavorite this' : 'Favorite this'}
        className={cx(
          styles.button,
          styles.buttonCompact,
          isFavorited && styles.selected,
          isCircleOutline && styles.buttonCircleOutline,
          buttonClassOverrides
        )}
        variant="transparent"
        bordered={false}
        onClick={onClick}
      >
        <div className={cx(styles.heart, imageClassOverrides, getHeartClass())} />
      </ButtonV2>
    </div>
  );
};

export default HeartFavButton;
