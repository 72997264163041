/* eslint-disable camelcase */
/**
 * To debug tracking events, you can view events and event payloads in the Segment Debugger:
 * https://app.segment.com/zola/sources/web/debugger
 */
import {
  SearchableVendorTaxonomyKey,
  VendorTaxonomyKey,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import {
  getVendorType,
  VendorTypeEnum,
} from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { CtaType } from '@zola-helpers/client/dist/es/tracking/ctaEvents/ctaEvents';
import {
  trackFlowStarted as clientTrackFlowStarted,
  trackFlowStepCompleted as clientTrackFlowStepCompleted,
  trackFlowStepViewed as clientTrackFlowStepViewed,
  trackFlowCompleted as clientTrackFlowCompleted,
  FlowEventType,
  MarketplaceInquiryFlowEntryPoint,
  FlowStepEventType,
} from '@zola-helpers/client/dist/es/tracking/flow/flowEvents';
import {
  BUSINESS_UNIT,
  COMPONENT,
} from '@zola-helpers/client/dist/es/tracking/onboard/onboardTrackingConstants';
import { trackEvent, BadgeValue } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import getEnvironment from '@zola-helpers/client/dist/es/util/environment';
import {
  StorefrontCardView,
  VendorCardView as BackendVendorCardView,
  CreateInquiryRequest,
  InquiryView,
  VendorCardBadgeView,
} from '@zola/svc-marketplace-ts-types';

import { CamelCasedProperties, SetRequired, ValueOf } from 'type-fest/index.d';

import { ToastTypes } from '~/actions/types/toastsActionTypes';
import {
  storefrontCardViewToVendorCardVendor,
  vendorCardViewToVendorCardVendor,
} from '~/components/common/cards/util/helper';
import { VendorCardVendor } from '~/components/common/cards/util/types';
import { VendorCardBadgeViewEnum, VendorCardView } from '~/types/responseTypes';
import type { NormalizedAddressType } from '~/types/types';
import { getCanonicalUrl } from '~/util/storefrontUrl';

import { getHighestRankedBadge } from './badgeRanking';
import { toSnakeCase } from './keyConversion';
import Logger from './logger';
import {
  BOOK_INQUIRY_CTA_ID,
  CANCEL_CLOSE_INQUIRY_CTA_ID,
  CLOSE_INQUIRY_CTA_ID,
  SNOOZE_BOOKING_REMINDER,
  UNBOOK_INQUIRY_CTA_ID,
  VIEW_INQUIRY_CTA_ID,
} from './trackingConstants';

export { CtaType };

export const trackFlowStarted = (payload: FlowEventType): void => {
  if (getEnvironment() === 'development') {
    Logger.debug(`Track Flow Started: ${JSON.stringify(payload)}`);
  }
  return clientTrackFlowStarted(payload);
};

export const trackFlowStepCompleted = (payload: FlowStepEventType): void => {
  if (getEnvironment() === 'development') {
    Logger.debug(`Track Flow Step Completed: ${JSON.stringify(payload)}`);
  }
  return clientTrackFlowStepCompleted(payload);
};

/**
 * @deprecated - Viewed events are frowned upon.
 */
export const trackFlowStepViewed = (payload: FlowStepEventType): void => {
  if (getEnvironment() === 'development') {
    Logger.debug(`Track Flow Step Viewed: ${JSON.stringify(payload)}`);
  }
  return clientTrackFlowStepViewed(payload);
};

export const trackFlowCompleted = (payload: FlowEventType): void => {
  if (getEnvironment() === 'development') {
    Logger.debug(`Track Flow Completed: ${JSON.stringify(payload)}`);
  }
  return clientTrackFlowCompleted(payload);
};

export enum TrackingProductLocation {
  DISPLAY_PAGE = 'PDP', // real-weddings
  EXPLORE_VENDORS_PLP = 'EXPLORE_VENDORS_PLP',
  LISTING_PAGE = 'PLP', // real-weddings
  CATEGORY_LANDING_PAGE = 'CLP',
  SEARCH_RESULTS = 'SRP', // vendor srp
  SEARCH_RESULTS_TOP_PICKS = 'SRP_TOP_PICKS',
  SEARCH_RESULTS_REAL_WEDDINGS = 'SEARCH_RESULTS_REAL_WEDDINGS', // RW carousel on vendor SRP
  SEARCH_RESULTS_ADJACENT_VENDORS_CAROUSEL = 'SEARCH_RESULTS_ADJACENT_VENDORS_CAROUSEL',
  EXPLORE_VENDORS_PLP_EXPERT_ADVICE = 'VLP_EXPERT_ADVICE',
  EXPLORE_VENDORS_PLP_PHOTOGRAPHER_SHELF = 'VLP_PHOTOGRAPHER_SHELF',
  EXPLORE_VENDORS_PLP_VENUE_SHELF = 'VLP_VENUE_SHELF',
  VENDOR_CARD_CAROUSEL = 'VENDOR_CARD_CAROUSEL',
  STOREFRONT_DETAIL_PAGE = 'STOREFRONT_DETAIL_PAGE',
  VENDOR_CREDIT_PACKAGES = 'VENDOR_CREDIT_PACKAGES',
  VENDOR_CHECKOUT = 'VENDOR_CHECKOUT',
  VENDOR_STOREFRONT_LISTING_BADGES = 'VENDOR_STOREFRONT_LISTING_BADGES',
  COUPLES_INQUIRIES = 'COUPLES_INQUIRIES',
  COUPLES_INQUIRIES_DETAIL_PAGE = 'COUPLES_INQUIRIES_DETAIL_PAGE',
  COUPLES_INQUIRIES_BOOKING_CONFIRMATION = 'COUPLES_INQUIRIES_BOOKING_CONFIRMATION',
  COUPLES_SUGGESTED_VENDORS_MODAL = 'COUPLES_SUGGESTED_VENDORS_MODAL',
  VENDOR_INBOX = 'VENDOR_INBOX',
  VENDOR_INQUIRIES_DECLINE = 'VENDOR_INQUIRIES_DECLINE',
  VENDOR_INQUIRY_EMAIL_DECLINE = 'VENDOR_INQUIRY_EMAIL_DECLINE',
  VENDOR_INQUIRY_EMAIL_RATE_LEAD = 'VENDOR_INQUIRY_EMAIL_RATE_LEAD',
  VENDOR_INQUIRY_DETAILS = 'VENDOR_INQUIRY_DETAILS', // pre-message (READY status) and expired inquiries
  VENDOR_MESSAGING_PAGE = 'VENDOR_MESSAGING_PAGE',
  TOP_PICKS_DRAWER = 'TOP_PICKS_DRAWER',
  VENDOR_LANDING_PAGE = 'VENDOR_LANDING_PAGE',
  HOW_CREDITS_WORK_PAGE = 'HOW_CREDITS_WORK_PAGE',
  HOW_WE_PRICE_PAGE = 'HOW_WE_PRICE_PAGE',
  PURCHASE_CREDITS_PAGE = 'PURCHASE_CREDITS_PAGE',
  VENDOR_PURCHASE_MODAL = 'VENDOR_PURCHASE_MODAL',
  VENDOR_CONNECT_PAGE = 'VENDOR_CONNECT_PAGE',
  RESOLUTIONS_VENDOR_INQUIRIES_DASHBOARD = 'RESOLUTIONS_VENDOR_INQUIRIES_DASHBOARD',
  RESOLUTIONS_VENDOR_MESSAGING_PAGE = 'RESOLUTIONS_VENDOR_MESSAGING_PAGE',
  COUPLES_COLLECTIONS_PAGE = 'COUPLES_COLLECTIONS_PAGE',
  COUPLES_COLLECTIONS_LANDING_PAGE = 'COUPLES_COLLECTIONS_LANDING_PAGE',
  UNSUPPLIED_MARKET_MODAL = 'UNSUPPLIED_MARKET_MODAL',
  RW_SUBMISSIONS_LP = 'RW_SUBMISSIONS_LP',
  BOOKED_VENDORS_PAGE = 'BOOKED_VENDORS_PAGE',
  BUDGET_QUIZ = 'BUDGET_QUIZ',

  // new vendor dashboard
  VENDOR_DASHBOARD = 'VENDOR_DASHBOARD',

  // The budget fit screen in inquiries
  COUPLES_INQUIRY_BUDGET_FIT = 'COUPLES_INQUIRY_BUDGET_FIT',
  COUPLES_INQUIRY_BUDGET = 'COUPLES_INQUIRY_BUDGET',

  // Inquiry question tracking
  COUPLES_INQUIRY_FOOD_SERVICES = 'COUPLES_INQUIRY_FOOD_SERVICES',
  COUPLES_INQUIRY_FLORIST_SERVICES = 'COUPLES_INQUIRY_FLORIST_SERVICES',
  COUPLES_INQUIRY_BEAUTY_SERVICES = 'COUPLES_INQUIRY_BEAUTY_SERVICES',
  COUPLES_INQUIRY_BAKER_SERVICES = 'COUPLES_INQUIRY_BAKER_SERVICES',
  COUPLES_INQUIRY_DRINK_SERVICES = 'COUPLES_INQUIRY_DRINK_SERVICES',
  COUPLES_INQUIRY_MUSICIAN_TYPE = 'COUPLES_INQUIRY_MUSICIAN_TYPE',
  COUPLES_INQUIRY_GUEST_COUNT = 'COUPLES_INQUIRY_GUEST_COUNT',
  COUPLES_INQUIRY_PLANNER_SERVICES = 'COUPLES_INQUIRY_PLANNER_SERVICES',

  // vendor dashboard
  LEAD_PREFERENCES = 'LEAD_PREFERENCES',
  EDIT_LISTING = 'EDIT_LISTING',

  // Vendor Credits
  CLAIM_CREDITS_MODAL = 'CLAIM_CREDITS_MODAL',
  CREDITS_FAQ = 'CREDITS_FAQ',

  // RW V3 Tracking Location
  RW_PDP = 'PDP', // yes, this is a duplicate of PDP above
  RW_ALBUM = 'ALBUM',
  RW_LP = 'LP',
  RW_COLLECTION = 'COLLECTION',
  RW_PHOTO = 'PHOTO',
  RW_PHOTO_RECOMMENDATION = 'PHOTO_RECOMMENDATION',
  RW_SRP = 'SRP', // yes, this is a duplicate of SRP above
  RW_VENDOR_RECOMMENDATION = 'VENDOR_RECOMMENDATION',
  RW_RELATED_VENDOR_TOAST = 'RELATED_VENDOR_TOAST',

  VENDOR_STARTER_PACKS = 'VENDOR_STARTER_PACKS',
  QUICK_VIEW = 'QUICK_VIEW',
  SAVED_STOREFRONTS = 'SAVED_STOREFRONTS',

  FIND_COUPLES_SEARCH_RESULTS = 'FIND_COUPLES_SEARCH_RESULTS',
  CATEGORY_RESOLUTION_NOT_BOOKED = 'CATEGORY_RESOLUTION_NOT_BOOKED',
  CATEGORY_RESOLUTION_BOOKED = 'CATEGORY_RESOLUTION_BOOKED',

  // V3 post-auth lp
  VENDOR_FAVORITES = 'VENDOR_FAVORITES',
  INQUIRY_DRAFTS = 'INQUIRY_DRAFTS',
  SUGGESTED_CROSS_CATEGORY_VENDORS = 'SUGGESTED_CROSS_CATEGORY_VENDORS',
  SUGGESTED_SAME_CATEGORY_VENDORS = 'SUGGESTED_SAME_CATEGORY_VENDORS',
  TOP_PICKS = 'TOP_PICKS',
  MOST_INQUIRED = 'MOST_INQUIRED',
  RECENTLY_VIEWED_VENDORS = 'RECENTLY_VIEWED_VENDORS',
}

export enum TrackingProductType {
  EXPERT_ADVICE = 'EXPERT ADVICE',
  FREQUENTLY_ASKED_QUESTION = 'FREQUENTLY ASKED QUESTION',
  STOREFRONT_CARD = 'STOREFRONT CARD',
  VENDOR_SEARCH_CARD = 'VENDOR SEARCH CARD',
  WEDDING_ALBUM = 'WEDDING ALBUM',
  WEDDING_ALBUM_CARD = 'WEDDING ALBUM CARD',
  WEDDING_SEARCH_CARD = 'WEDDING SEARCH CARD',
  LISTING_PAGE = 'LISTING PAGE', // This is also a storefront details page
  LANDING = 'LANDING',
  QUIZLET = 'QUIZLET',
  RECOMMENDED_VENDORS = 'RECOMMENDED VENDORS',
  TAG = 'TAG',
  PHOTO = 'PHOTO',
  SIMILAR_PHOTO = 'SIMILAR PHOTO',
  TOAST = 'TOAST',
  TOAST_LOCATION_MODAL = 'TOAST_LOCATION_MODAL',
  COUPLES_DASHBOARD = 'COUPLES DASHBOARD',
}

export enum FavoriteType {
  PHOTO = 'PHOTO',
  STOREFRONT = 'STOREFRONT',
  WEDDING_ALBUM = 'WEDDING ALBUM',
}

export function getEntryPointByProductLocation(
  productLocation: TrackingProductLocation
): CreateInquiryRequest.CtaPlacementEnum & MarketplaceInquiryFlowEntryPoint {
  switch (productLocation) {
    case TrackingProductLocation.RW_PDP:
      return 'PHOTO';
    case TrackingProductLocation.RW_ALBUM:
      return 'ALBUM';
    case TrackingProductLocation.RW_COLLECTION:
      return 'COLLECTION';
    case TrackingProductLocation.RW_VENDOR_RECOMMENDATION:
      return 'VENDOR_RECOMMENDATION';
    case TrackingProductLocation.RW_RELATED_VENDOR_TOAST:
      return 'RELATED_VENDOR_TOAST';
    case TrackingProductLocation.QUICK_VIEW:
      return 'QUICK_VIEW';
    case TrackingProductLocation.VENDOR_FAVORITES:
      // @ts-expect-error not in zola-helpers yet
      return 'VENDOR_FAVORITES';
    case TrackingProductLocation.INQUIRY_DRAFTS:
      // @ts-expect-error not in zola-helpers yet
      return 'INQUIRY_DRAFTS';
    case TrackingProductLocation.SUGGESTED_CROSS_CATEGORY_VENDORS:
      // @ts-expect-error not in zola-helpers yet
      return 'SUGGESTED_CROSS_CATEGORY_VENDORS';
    case TrackingProductLocation.SUGGESTED_SAME_CATEGORY_VENDORS:
      // @ts-expect-error not in zola-helpers yet
      return 'SUGGESTED_SAME_CATEGORY_VENDORS';
    case TrackingProductLocation.TOP_PICKS:
      // @ts-expect-error not in zola-helpers yet
      return 'TOP_PICKS';
    case TrackingProductLocation.MOST_INQUIRED:
      // @ts-expect-error not in zola-helpers yet
      return 'MOST_INQUIRED';
    case TrackingProductLocation.RECENTLY_VIEWED_VENDORS:
      // @ts-expect-error not in zola-helpers yet
      return 'RECENTLY_VIEWED_VENDORS';
    case TrackingProductLocation.CATEGORY_LANDING_PAGE:
      // @ts-expect-error not defined yet
      return 'CATEGORY_LANDING_PAGE';
    case TrackingProductLocation.SEARCH_RESULTS:
      return 'SEARCH_RESULTS_PAGE';
    default:
      return 'MARKETPLACE';
  }
}

// The `traits` argument is defined as UserTraits in the @segment/analytics-next package, but
// including the package as a dependency breaks the build in production
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function identifyVendorUser(uuid: string, traits: any): void {
  if (window.analytics && window.analytics.identify) {
    window.analytics.identify(uuid, traits);
  }
}

export function trackGoogleTagManagerEvent(eventName: string, payload: unknown): void {
  const options = {
    integrations: {
      All: false,
      'Google Tag Manager': true,
    },
  };
  trackEvent(eventName, payload, options);
}

export function trackVendorUserCreated(vpkey: string | null): void {
  const payload = toSnakeCase({ businessUnit: BUSINESS_UNIT.MARKETPLACE_VENDORS, vpkey });
  trackEvent('Vendor User Created', payload);
}

export function trackRecommendation(action: string, recommendationData: unknown): void {
  trackEvent(`CTA Recommendation ${action}`, recommendationData);
}

export interface TrackProductClicked {
  location: TrackingProductLocation;
  productId: string;
  name: string;
  position: number | null;
  productType: TrackingProductType;
  businessUnit?: ValueOf<typeof BUSINESS_UNIT>;
  transactionId?: string; // only include for search related clicks
  valueSubmitted?: string;
  recommendationValue?: number;
  slug?: string;
  coupon?: ProductCoupon[];
}
/**
When a user clicks on a wedding page, card or vendor page 

@deprecated - use tracking contracts
*/
export function trackProductClicked({
  location,
  productId,
  name,
  position,
  productType,
  businessUnit = BUSINESS_UNIT.MARKETPLACE,
  transactionId, // only include for search related clicks
  valueSubmitted,
  recommendationValue = 0,
  slug,
  coupon,
}: TrackProductClicked): void {
  const payload = toSnakeCase({
    businessUnit,
    location,
    name,
    position,
    productId,
    productType,
    transactionId: transactionId || undefined,
    valueSubmitted,
    recommendationValue,
    slug,
    coupon,
  });
  trackEvent('Product Clicked', payload);
}

interface TrackProductViewed {
  productId: string;
  name: string;
  productType: TrackingProductType;
  storefrontUuid?: string;
  url?: string;
  businessUnit?: ValueOf<typeof BUSINESS_UNIT>;
  location?: TrackingProductLocation;
  coupon?: ProductCoupon[];
  rest?: unknown;
  /** @deprecated - use storefrontUuid */
  storefrontId?: number;
}

/**
 * Track a product (storefront/real wedding/couple, etc) being viewed.
 *
 * @deprecated use tracking contracts
 */
export function trackProductViewed({
  productId,
  name,
  productType,
  storefrontUuid,
  url,
  businessUnit = BUSINESS_UNIT.MARKETPLACE,
  location,
  coupon,
  ...rest
}: TrackProductViewed): void {
  const payload = toSnakeCase({
    productId,
    name,
    productType,
    storefrontUuid,
    url,
    businessUnit,
    location,
    coupon,
    ...rest,
  });
  trackEvent('Product Viewed', payload);
}

interface TrackProductCard {
  location: TrackingProductLocation;
  productId: string;
  name: string;
  position: number;
  productType?: TrackingProductType;
  businessUnit?: ValueOf<typeof BUSINESS_UNIT>;
}
/* When a user clicks a search filter, a card or chip that filters vendors */
export function trackVendorSearchCardClicked({
  location,
  productId,
  name,
  position,
  productType = TrackingProductType.VENDOR_SEARCH_CARD,
  businessUnit = BUSINESS_UNIT.MARKETPLACE,
}: TrackProductCard): void {
  const payload = toSnakeCase({
    businessUnit,
    location,
    name,
    position,
    productId,
    productType,
  });
  trackEvent('Vendor Search Card Clicked', payload);
}

/* When a user clicks a card to real-weddings */
export function trackRealWeddingsSearchCardClicked({
  location,
  productId,
  name,
  position,
  productType = TrackingProductType.WEDDING_SEARCH_CARD,
  businessUnit = BUSINESS_UNIT.MARKETPLACE,
}: TrackProductCard): void {
  const payload = toSnakeCase({
    businessUnit,
    location,
    name,
    position,
    productId,
    productType,
  });
  trackEvent('Real Weddings Search Card Clicked', payload);
}

/* When a user clicks an item in a FAQ accordion */
export function trackFAQViewed({
  // Where did they view a FAQ?
  location,
  // identifier for which FAQ question they clicked
  productId,
  // Name of the FAQ
  name,
  // Position in a list
  position,
  productType = TrackingProductType.FREQUENTLY_ASKED_QUESTION,

  businessUnit = BUSINESS_UNIT.MARKETPLACE,
}: TrackProductCard): void {
  const payload = toSnakeCase({
    businessUnit,
    location,
    name,
    position,
    productId,
    productType,
  });
  trackEvent('FAQ Viewed', payload);
}

export function trackExpertAdviceClicked({
  // Where did the card get clicked
  location,
  // Article href
  productId,
  // article slug
  name,
  // Position in a list of cards
  position,
  productType = TrackingProductType.EXPERT_ADVICE,
  businessUnit = BUSINESS_UNIT.MARKETPLACE,
}: TrackProductCard): void {
  const payload = toSnakeCase({
    businessUnit,
    location,
    name,
    position,
    productId,
    productType,
  });
  trackEvent('Expert Advice Card Clicked', payload);
}

/** @deprecated - we should be using the payload structure of ProductListViewedEntry2 */
export type ProductListViewedEntry = {
  productId?: string;
  name?: string;
  uuid?: string;
  position: number;
  storefront?: string;
};

/** @deprecated - use tracking contracts */
export function trackProductListViewed(
  location: string,
  products: ProductListViewedEntry[],
  businessUnit: ValueOf<typeof BUSINESS_UNIT> = BUSINESS_UNIT.MARKETPLACE,
  storefrontUuid?: string,
  inquiryUuid?: string
): void {
  const payload = toSnakeCase({
    businessUnit,
    location,
    products,
    storefrontUuid,
    inquiryUuid,
  });
  trackEvent('Product List Viewed', payload);
}

export type CoupleProductCoupon =
  | 'FAVORITE'
  | 'INQUIRY'
  | 'UNREAD'
  | 'INVITED'
  | InquiryView.TierEnum;

export type ProductCoupon =
  | CoupleProductCoupon
  | BadgeValue
  | VendorCardBadgeViewEnum
  | 'BOOKED'
  | 'VENDOR_INQUIRY_INVITE';

const getCouponList = (badges?: VendorCardBadgeView[]) => {
  const couponList: ProductCoupon[] = [];
  if (badges?.length) {
    // we want to push only the visible badge
    const visibleBadge = getHighestRankedBadge(badges)?.type;
    if (visibleBadge) {
      couponList.push(visibleBadge as ProductCoupon);
    }
  }

  return couponList.length ? { coupon: couponList } : {};
};

export const getMappedVendor = (
  vendor: VendorCardView | BackendVendorCardView | StorefrontCardView | VendorCardVendor
): VendorCardVendor | null => {
  if ('isVendorCardVendorType' in vendor) {
    return vendor;
  }
  if ('name' in vendor) {
    return storefrontCardViewToVendorCardVendor(vendor);
  }
  if ('vendorName' in vendor) {
    return vendorCardViewToVendorCardVendor(vendor);
  }
  return null;
};

/** @deprecated use vendorCardVendorToTrackingStorefront and switch to tracking contracts */
export const mapVendorCardToProduct = (
  vendorCard: VendorCardView | BackendVendorCardView | StorefrontCardView | VendorCardVendor,
  position: number
): ProductListViewedEntry2 & { productId: string; name: string } => {
  const vendor = getMappedVendor(vendorCard);
  const { storefrontUuid, storefrontSlug, taxonomyNodeKey, badges, reviewsCount } = vendor || {};

  return {
    productId: storefrontUuid || '',
    name: storefrontSlug || '',
    category: taxonomyNodeKey ? getVendorType(taxonomyNodeKey) : null,
    position,
    url: taxonomyNodeKey && storefrontSlug ? getCanonicalUrl(taxonomyNodeKey, storefrontSlug) : '',
    ...(badges ? getCouponList(badges) : []),
    recommendationValue: reviewsCount || 0,
  };
};

export type ProductListViewedEntry2 = {
  /**
   * wedding or storefront uuid
   */
  productId: string | null;
  /**
   * wedding or storefront slug
   */
  name: string | null;
  /**
   * vendor type - VENUE, PHOTOGRAPHER, etc
   */
  category?: VendorTypeEnum | null;
  /**
   * list item position number + 1; should continue to increase when
   * user paginates; not get reset back to beginning on each new page
   */
  position: number;
  /**
   * the badge value of the product if it exists, ex 'quick_responder' for vendor.
   * coupon is not a logical slot for badges but is a side effect of using an
   * all-in-one tracking solution
   */
  coupon?: ProductCoupon[];
  /**
   * storefront url
   */
  url: string;
  /**
   * Number of recommendations/reviews a product has
   */
  recommendationValue?: number;
};

export interface ProductListViewedProperties {
  businessUnit?: ValueOf<typeof BUSINESS_UNIT>;
  component?: ValueOf<typeof COMPONENT>;
  location: TrackingProductLocation;
  trackingIdentifier?: string; // uuid used to tie together Product List Viewed & Product List Filtered on search page
  products: ProductListViewedEntry2[];
  citySlug?: string;
  isShuffled?: boolean;
  startingPrice?: number | null;
}

/** When a user views a list of weddings or vendors
 * @deprecated use tracking contracts
 */
export function trackProductListViewed2({
  businessUnit = BUSINESS_UNIT.MARKETPLACE,
  component = COMPONENT.VENDOR_MARKETPLACE,
  location,
  trackingIdentifier,
  products,
  ...rest
}: ProductListViewedProperties): void {
  const payload = toSnakeCase({
    businessUnit,
    location,
    products,
    component,
    ...rest,
    ...(trackingIdentifier ? { filteredUuid: trackingIdentifier } : null),
  });
  trackEvent('Product List Viewed', payload);
}

/* when a user filters a list by various options */
export function trackProductFiltered(
  filters: unknown,
  products: unknown,
  businessUnit: ValueOf<typeof BUSINESS_UNIT> = BUSINESS_UNIT.MARKETPLACE
): void {
  const payload = toSnakeCase({
    businessUnit,
    filters,
    products,
  });
  trackEvent('Product List Filtered', payload);
}

/**
 * Customizes the event data from zola-helpers with marketplace
 * specific fields and allows both camelCase or snake_case keys
 *
 * The version in zola-helpers doesn't quite allow the same nulls / undefines as we have
 */
export interface CtaClickedEvent /* extends CtaEventDataType */ {
  /**
   * Module identifier of the marketing module
   */
  ctaId?: string;
  /**
   * Module identifier of the marketing module
   */
  cta_id?: string;

  ctaType?: CtaType;
  cta_type?: CtaType;

  /**
   * Group identifier of the marketing module
   */
  groupId?: string;
  /**
   * Group identifier of the marketing module
   */
  group_id?: string;

  /** @deprecated - use storefront uuid */
  storefrontId?: number;
  /** @deprecated - use storefront uuid */
  storefront_id?: number;

  storefrontUuid?: string;
  storefront_uuid?: string;

  inquiryUuid?: string;
  inquiry_uuid?: string;

  weddingPhotoUuid?: string;
  wedding_photo_uuid?: string;

  vendorType?: SearchableVendorTaxonomyKey;
  vendor_type?: SearchableVendorTaxonomyKey;

  /** use taxonomy key over vendorType */
  vendorTaxonomyKey?: VendorTaxonomyKey;

  /**
   * Position of module on the page top (1) down (2,3,4...)
   */
  position?: number; // Not optional in zola-helpers
  /**
   * As defined: https://docs.google.com/spreadsheets/d/1He7KAgK_ryUca8TJgRAnowKXeFWxqq1csWZWZdyR3dc/edit#gid=290641458
   */
  section?: string;

  /**
   * As defined: https://docs.google.com/spreadsheets/d/1He7KAgK_ryUca8TJgRAnowKXeFWxqq1csWZWZdyR3dc/edit#gid=290641458
   */
  location?: TrackingProductLocation;
  /**
   * Url user is redirected to upon click (null if module does not redirect users)
   */
  cta?: string | null;

  businessUnit?: ValueOf<typeof BUSINESS_UNIT>;

  isInfiniteScroll?: boolean;
  /**
   * A check for edit listing section if the edit block has content
   */
  hasContent?: boolean;
}

export function trackCtaClicked(cta: CtaClickedEvent): void {
  const payload = toSnakeCase({
    businessUnit: BUSINESS_UNIT.MARKETPLACE,
    ...cta,
  });
  return trackEvent('CTA Clicked', payload);
}

export type TrackInquiryCtaId =
  | typeof CANCEL_CLOSE_INQUIRY_CTA_ID
  | typeof BOOK_INQUIRY_CTA_ID
  | typeof CLOSE_INQUIRY_CTA_ID
  | typeof UNBOOK_INQUIRY_CTA_ID
  | typeof VIEW_INQUIRY_CTA_ID
  | typeof SNOOZE_BOOKING_REMINDER;

export interface TrackInquiryCta
  extends SetRequired<
    Omit<CamelCasedProperties<CtaClickedEvent>, 'ctaId'>,
    'inquiryUuid' | 'storefrontUuid' | 'vendorType'
  > {
  ctaId: TrackInquiryCtaId;
}

/**
 * Helper to make sure that every time (in typescript) we provide sufficient tracking
 * data for data analysis.
 *
 * This makes the following fields required in tracking
 * - storefrontUuid
 * - inquiryUuid
 * - vendorType
 *
 * Additionally, it constraints the ctaId to a set of constants instead of the wild
 * west of string values.
 */
export const trackInquiryCtaClicked = (cta: TrackInquiryCta) => {
  trackCtaClicked(cta);
};

interface FavoritingEventPayload {
  slug: string;
  type: FavoriteType;
  uuid: string;
  location?: TrackingProductLocation | null;
  businessUnit?: ValueOf<typeof BUSINESS_UNIT>;
  tilePosition?: number | null;
  shelfCustomized?: boolean;
}
interface FavoritingEvent extends FavoritingEventPayload {
  shelfPosition?: number | null;
  eventName: string;
}

/* when a user favorites a photo, wedding, or storefront */
function trackFavoritingEvent({
  uuid,
  type,
  shelfPosition = null,
  tilePosition = null,
  shelfCustomized = false,
  eventName,
  slug,
  location = null,
  businessUnit = BUSINESS_UNIT.MARKETPLACE,
}: FavoritingEvent): void {
  const payload = toSnakeCase({
    businessUnit,
    component: COMPONENT.FAVORITE,
    uuid,
    objectFavorited: type,
    ...(shelfPosition && { shelfPosition }),
    ...(tilePosition && { tilePosition }),
    shelfCustomized,
    slug,
    ...(location && { location }),
  });
  trackEvent(eventName, payload);
}

export function trackObjectFavorited(payload: FavoritingEventPayload): void {
  trackFavoritingEvent({ eventName: 'Object Favorited', ...payload });
}

export function trackObjectUnfavorited(payload: FavoritingEventPayload): void {
  trackFavoritingEvent({ eventName: 'Object Unfavorited', ...payload });
}

interface Facet {
  parentKey?: string;
  slug: string;
}
interface Search {
  selectedColors?: Array<Facet>;
  selectedColorCategories?: Array<Facet>;
  selectedFacets?: Array<Facet>;
  selectedLocations?: Array<Facet>;
}
/* parse a search object to send as a filter payload */
export function convertSearchToFilters(search: Search = {}): Array<unknown> {
  const { selectedColors, selectedColorCategories, selectedFacets, selectedLocations } = search;
  const filters = [];
  if (selectedColors) {
    filters.push(...selectedColors.map((c) => ({ type: 'color', value: c.slug })));
  }
  if (selectedColorCategories) {
    filters.push(
      ...selectedColorCategories.map((c) => ({
        type: 'color-category',
        value: c.slug,
      }))
    );
  }
  if (selectedFacets) {
    filters.push(...selectedFacets.map((c) => ({ type: c.parentKey, value: c.slug })));
  }
  if (selectedLocations) {
    filters.push(...selectedLocations.map((c) => ({ type: 'location', value: c.slug })));
  }
  return filters;
}

interface Wedding {
  uuid: string;
  title?: string;
  address: NormalizedAddressType & { possibleCitySlug: string };
}
interface Product {
  product_id: string;
  name: string;
  category: string | undefined;
  position: number;
}
/* parse a list of weddings to send as a products payload */
export function convertWeddingsToProducts(weddings: Array<Wedding> = []): Array<Product> {
  return weddings.map(({ uuid, title = '', address = {} }, idx): Product => {
    const product = {
      product_id: uuid,
      name: title,
      category: address.possibleCitySlug,
      position: idx + 1,
    };
    return product;
  });
}

export function getComponentForVendorType(vendorType = ''): string | null {
  switch (vendorType) {
    case 'bands-djs':
      return COMPONENT.BANDS_DJS;
    case 'cakes-desserts':
      return COMPONENT.CAKES_DESSERTS;
    case 'catering':
      return COMPONENT.CATERING;
    case 'florist':
      return COMPONENT.FLORIST;
    case 'hair-makeup':
      return COMPONENT.HAIR_MAKEUP;
    case 'photographer':
      return COMPONENT.PHOTOGRAPHER;
    case 'venue':
      return COMPONENT.VENUE;
    case 'videographer':
      return COMPONENT.VIDEOGRAPHER;
    default:
      return null;
  }
}

export function trackVendorVowsCompleted(
  vendorType: string,
  stepName: string,
  stepUserResponse: string
): void {
  const payload = {
    business_unit: BUSINESS_UNIT.MARKETPLACE_VENDORS,
    component: getComponentForVendorType(vendorType),
    step_key: 'VENDOR_VOWS',
    step_name: stepName,
    step_user_response: stepUserResponse,
  };
  trackEvent('Onboarding Step Completed', payload);
}

export function trackToastDismissed(
  type: ToastTypes,
  businessUnit: ValueOf<typeof BUSINESS_UNIT>
): void {
  const payload = toSnakeCase({
    businessUnit,
    type,
  });
  trackEvent('Toast Dismissed', payload);
}
