import {
  MenuHeading,
  MenuLink,
  MenuSeparator,
  MenuType,
  StorefrontMenuLink,
  useStorefrontMenuItems,
} from '../useNavigationLinks';
import StorefrontLink from './StorefrontLink';

import styles from './mobileNav.module.less';

const useMobileStorefrontMenu = (): (
  | MenuLink
  | MenuHeading
  | MenuSeparator
  | StorefrontMenuLink
)[] => {
  const { menuItems: storefrontLinks } = useStorefrontMenuItems();

  return [
    ...storefrontLinks,
    {
      type: MenuType.SEPARATOR,
    },
    {
      type: MenuType.LINK,
      text: 'Create a new listing',
      to: '/inspire/vendors/onboard',
      trackingIdentifier: 'ADD_STOREFRONT',
      className: styles.newStorefront,
      dataTestId: 'add-listing',
    },
    {
      type: MenuType.LINK,
      text: 'Manage access',
      to: '/inspire/vendors/user-management',
      trackingIdentifier: 'MANAGE_ACCESS',
      dataTestId: 'manage-access',
    },
    {
      type: MenuType.LINK,
      text: 'Account settings',
      to: '/inspire/vendors/account',
      trackingIdentifier: 'ACCOUNT_SETTINGS',
      dataTestId: 'account-settings',
    },
    {
      type: MenuType.LINK,
      text: 'Your badges and awards',
      to: '/inspire/vendors/listing/badges',
      trackingIdentifier: 'VENDOR_BADGES',
    },
    {
      type: MenuType.LINK,
      to: '/inspire/vendors/refer-a-vendor',
      text: 'Refer vendors to join Zola',
      trackingIdentifier: 'REFER_A_VENDOR',
    },
  ];
};

/**
 * Builds an Array with one entry for each storefront, and one
 * to add a new storefront that can be used to render an menu of storefronts
 */
export const useStorefrontMenu = (): (MenuLink | MenuSeparator | MenuHeading)[] => {
  const items = useMobileStorefrontMenu();

  return items.map((item) => {
    if (item.type === MenuType.STOREFRONT) {
      return StorefrontLink(item.storefront);
    }
    return item;
  });
};
