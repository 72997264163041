import { useMemo } from 'react';

import {
  message,
  informational,
  negative,
  positive,
  ToastV2ActionOptions,
} from '@zola/zola-ui/src/actions/toastsV2Actions';
import { ToastV2Action } from '@zola/zola-ui/src/actions/types/toastV2ActionTypes';

import _reduce from 'lodash/reduce';

import { useAppDispatch } from '~/reducers';

type ToastName = `${'negative' | 'positive' | 'informational' | 'message'}Toast`;

type ToastActionCreator = (opts: ToastV2ActionOptions) => ToastV2Action;

const actions = { positive, negative, informational, message } as const;

/**
 * A generic hook to dispatch toasts to the Toasts component
 *
 * @example
 *
 * const { positiveToast, negativeToast, messageToast, informationalToast} = useToast();
 *
 * useEffect(() => {
 *   positiveToast({ headline: 'This is a positive toast' });
 *   negativeToast({ headline: 'This is a negative toast' });
 *   messageToast({ headline: 'This is a message toast' });
 *   informationalToast({ headline: 'This is an informational toast' });
 * }, [])
 *
 *
 */
export const useToast = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () =>
      _reduce<Record<string, ToastActionCreator>, Record<ToastName, ToastActionCreator>>(
        actions,
        (acc, value, key) => {
          acc[`${key}Toast` as ToastName] = (opts: ToastV2ActionOptions) => dispatch(value(opts));
          return acc;
        },
        {} as Record<string, ToastActionCreator>
      ),
    [dispatch]
  );
};
