import { useState, useEffect, Fragment } from 'react';

import { USCityView } from '@zola/svc-marketplace-ts-types';
import { Error as FormError } from '@zola/zola-ui/src/components/Form/Error';
import GeoPinIcon from '@zola/zola-ui/src/components/SvgIcons/GeoPin';

import cx from 'classnames';
import queryString from 'query-string';
import Autosuggest, {
  AutosuggestPropsBase,
  BlurEvent,
  ChangeEvent,
  GetSuggestionValue,
  OnSuggestionSelected,
  RenderInputComponentProps,
  RenderSuggestion,
  RenderSuggestionsContainerParams,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';

import useDebounce from '~/components/common/hooks/useDebounce';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';

import Label from '../Label';

import styles from './autoSuggestLocationField.module.less';

interface AutoSuggestLocationFieldProps<T extends USCityView>
  extends Partial<AutosuggestPropsBase<T>> {
  /** callback for getting value from suggestion object */
  handleGetSuggestionValue?: GetSuggestionValue<T>;

  /**
   * callback when a suggestion is selected.
   *
   * (event:React.SyntheticEvent, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => void
   * - suggestion: the selected suggestion (USCityView)
   * - suggestionValue: "City, state"
   * - suggestionIndex: the index of the selected suggestion in the suggestions array
   * - sectionIndex: null (sections aren't used)
   * - method: 'click' | 'enter'
   */
  onSuggestionSelected: OnSuggestionSelected<T>;

  /** invoked when the name changes */
  onInputBlur?: (event: React.FocusEvent<HTMLElement>, params?: BlurEvent<T>) => void;

  /** invoked when the name changes */
  onInputChange?: (event: React.FormEvent<HTMLElement>, params: ChangeEvent) => void;

  /** Custom value for label's 'for' attribute */
  labelFor?: string;

  /** Text for the input label */
  labelText?: string | React.ReactNode;

  /** The name of the input */
  name?: string;

  /** Should we put a label above the text input */
  useLabel?: boolean;
  autoFocus?: boolean;
  errorMessage?: string;
  value?: T;

  /** Allows a blank value binding.  This lets us _clear_ the auto-suggest by binding a blank value */
  allowBlank?: boolean;
  /** Adds disabled styling, prevents input */
  disabled?: boolean;
  /** placeholder text for the input */
  placeholder?: string;
  /** Render a v2 input field instead of v1 */
  inputV2?: boolean;
  /* Shows geopin icon addon in input field */
  showIcon?: boolean;
  withMarkets?: boolean;
  labelClassName?: string;
}

type InputProps<T> = AutosuggestPropsBase<T>['inputProps'];

export const formatCityName = (suggestion: { name: string | null; stateCode: string | null }) => {
  const { name, stateCode } = suggestion;
  const location = [name, stateCode];
  return location.filter((item) => item).join(', ');
};

const ErrorMessage = ({ errorMessage, inputV2 }: { errorMessage?: string; inputV2?: boolean }) => {
  if (errorMessage) {
    if (inputV2) {
      return <FormError className="error-box" message={errorMessage} iconSize={12} />;
    }

    return <div className="error-box">{errorMessage}</div>;
  }
  return null;
};

/** A wrapper for the auto-suggestions container */
const SuggestionsContainer = ({ containerProps, children }: RenderSuggestionsContainerParams) => {
  return <div {...containerProps}>{children}</div>;
};

/** Renders an item in the auto-suggest box */
const renderSuggestion: RenderSuggestion<USCityView> = (suggestion: USCityView, params) => (
  <div
    className={cx(styles.marketplaceSuggestion, styles.inline, 'px-secondary py-quaternary', {
      [styles.active]: params.isHighlighted,
    })}
  >
    <div className={styles.textMain}>{formatCityName(suggestion)}</div>
    <div className={styles.textSecondary}>
      {suggestion.population && suggestion.population < 10000 ? suggestion.county : null}
    </div>
  </div>
);

const AutoSuggestLocationField = (props: AutoSuggestLocationFieldProps<USCityView>) => {
  const {
    handleGetSuggestionValue,
    onInputBlur,
    onSuggestionSelected,
    onInputChange,
    labelFor,
    labelText = 'Type your city',
    useLabel = true,
    autoFocus = false,
    errorMessage,
    placeholder,
    name: inputName = 'locationNameSearch',
    value: val,
    allowBlank = false,
    disabled,
    inputV2 = false,
    showIcon,
    withMarkets = false,
    renderInputComponent,
    renderSuggestionsContainer,
    renderSuggestion: renderSuggestionProp,
    labelClassName,
    ...rest
  } = props;

  const [cityName, setCityName] = useState('');
  const [cityNameSuggestions, setCityNameSuggestions] = useState<USCityView[]>([]);

  useEffect(() => {
    if ((val || allowBlank) && typeof val === 'string') {
      setCityName(val);
    }
    if (val && typeof val === 'object') {
      setCityName(formatCityName(val));
    }
  }, [allowBlank, val]);

  const labelProps = {
    ...(useLabel && { useLabel }),
    ...(labelFor && { labelFor }),
    ...(labelText && { labelText }),
  };

  /** Renders the input that triggers the auto-suggest */
  const InputComponent = (renderInputProps: RenderInputComponentProps) => {
    if (inputV2) {
      const { className } = renderInputProps;
      return (
        <div className={cx('zola-ui v2-render-field', { 'has-error': !!errorMessage })}>
          <div className="input-field__container">
            <input className={cx('input-field', className)} {...renderInputProps} />
            {showIcon && (
              <div className="input-addon">
                <div className="input-addon-icon">
                  <GeoPinIcon width={20} height={20} />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="zola-ui render-field">
        <input {...renderInputProps} />
        {showIcon && (
          <div className="input-addon">
            <div className="input-addon-icon">
              <GeoPinIcon width={20} height={20} />
            </div>
          </div>
        )}
      </div>
    );
  };

  /** Invoked by the form control when the value changes */
  const onChangeHandler = (
    event: React.FormEvent<HTMLElement>,
    { newValue, method }: Autosuggest.ChangeEvent
  ) => {
    setCityName(newValue);
    if (onInputChange) {
      onInputChange(event, { newValue, method });
    }
  };

  /** Invoked by react-autosuggest to get suggestions */
  const onCitySuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestedParams) => {
    if (value.length === 0) {
      setCityNameSuggestions([]);
      return;
    }
    const options = {
      limit: 30,
      'search-string': value,
      with_markets: withMarkets || undefined,
    };

    ApiService.get<USCityView[]>(
      `/web-marketplace-api/v1/us-city/typeahead?${queryString.stringify(options)}`
    )
      .then((response) => {
        // Requiring a payload ensures the drop-down remains visible once we've started offering suggestions
        if (response && response.length > 0) {
          const suggestions = response;
          setCityNameSuggestions(suggestions);
        }
      })
      .catch(StandardLogFn);
  };

  /** Invoked by react-autosuggest to clear suggestions */
  const onCitySuggestionsClearRequested = () => {
    setCityNameSuggestions([]);
  };

  const getSuggestionValue = (suggestion: USCityView) => {
    if (handleGetSuggestionValue) {
      return handleGetSuggestionValue(suggestion);
    }
    return formatCityName(suggestion);
  };

  const inputProps: InputProps<USCityView> = {
    value: cityName,
    ...(onInputBlur && { onBlur: onInputBlur }),
    onChange: onChangeHandler,
    className: cx('search-group__element', { 'with-icon': showIcon }),
    id: 'locationNameSearch',
    name: inputName,
    autoFocus,
    disabled,
    placeholder,
    autoComplete: 'off', // prevent autofill suggestions
  };

  return (
    <Label {...labelProps} className={cx(styles.label, labelClassName)}>
      <Fragment>
        <div className={cx(styles.marketplaceAutosuggestLocationField, 'pt-quaternary')}>
          <Autosuggest
            {...rest}
            suggestions={cityNameSuggestions}
            onSuggestionsFetchRequested={useDebounce(onCitySuggestionsFetchRequested, 250)}
            onSuggestionsClearRequested={onCitySuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestionProp || renderSuggestion}
            onSuggestionSelected={onSuggestionSelected}
            inputProps={inputProps}
            renderInputComponent={renderInputComponent || InputComponent}
            renderSuggestionsContainer={renderSuggestionsContainer || SuggestionsContainer}
          />
        </div>
        <ErrorMessage errorMessage={errorMessage} inputV2={inputV2} />
      </Fragment>
    </Label>
  );
};

export default AutoSuggestLocationField;
