import { Fragment } from 'react';

import { NavigationClicked } from '@zola/tracking-contracts/src/events';

import { MenuItem, MenuType } from '../useNavigationLinks';
import { MobileNavLink } from './MobileNavLink';
import { MobileNavMenu } from './MobileNavMenu';

interface MobileNavItemProps {
  expanded: boolean;
  item: MenuItem;
  trackingProps: Omit<NavigationClicked, 'navigation_type' | 'business_unit' | 'business_category'>;
}

/** A Thin wrapper around rendering a drop down or a simple link */
export const MobileNavItem = (props: MobileNavItemProps): JSX.Element => {
  const { item } = props;

  if (item.type === MenuType.DROPDOWN) {
    return <MobileNavMenu {...props} item={item} />;
  }
  if (item.type === MenuType.LINK) {
    return <MobileNavLink {...props} item={item} />;
  }
  return <Fragment />;
};
