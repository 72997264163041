import React, { useState, useEffect, useCallback, Fragment } from 'react';

import { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomyKeyFromId } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { getVendorWordSingular } from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import { Error as FormError } from '@zola/zola-ui/src/components/Form/Error';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';
import getVendorIconV3 from '@zola/zola-ui/src/util/getVendorIconV3';

import cx from 'classnames';
import _includes from 'lodash/includes';
import _throttle from 'lodash/throttle';
import Autosuggest, {
  ChangeEvent,
  RenderInputComponent,
  RenderSuggestionsContainer,
  SuggestionSelectedEventData,
} from 'react-autosuggest';

import {
  getVendorSuggestions,
  getVendorSuggestionsForCategory,
} from '~/actions/vendorSearchActions';
import { MappedVendorSearchResult } from '~/types/mappedResponseTypes';
import { titleCase } from '~/util/textUtils';

import Label from '../Label';

import './autoSuggestVendorField.less';

interface AutoSuggestVendorFieldBaseProps {
  /** callback when a suggestion is selected */
  onSuggestionSelected: (
    event: React.FormEvent,
    data: Autosuggest.SuggestionSelectedEventData<MappedVendorSearchResult>
  ) => void;
  /** invoked when the name changes */
  onInputBlur?: React.FocusEventHandler;
  /** invoked when the name changes */
  onInputChange?: (data: string) => void;
  /** Text for the link at the bottom of the control */
  actionLinkText?: string;
  /** Custom value for label's 'for' attribute */
  labelFor?: string;
  /** Text for the input label */
  labelText?: string | React.ReactNode;
  /** Should we put a label above the text input */
  useLabel?: boolean;
  /** Is the search limit to one category of vendor */
  limitToCategory?: boolean;
  /** If limiting the search to one type of vendor, this is the category */
  categoryId?: number;
  autoFocus?: boolean;
  /**
   * These reference vendor ids will be excluded from the autosuggest.  Maybe you've already
   * added something and don't want to do it again?  This is the property for you!  Just set
   * the reference vendor ids you don't want, and MAGIC will happen.
   */
  excludedReferenceVendorIds?: number[];
  errorMessage?: string;
  value?: string | Record<string, string>;
  /** Allows a blank value binding. This lets us _clear_ the auto-suggest by binding a blank value */
  allowBlank?: boolean;
  /** Adds disabled styling, prevents input */
  disabled?: boolean;
  /** placeholder text for the input */
  placeholder?: string;
  /** Render a v2 input field instead of v1 */
  inputV2?: boolean;
  /** Render a warning asterisk after the label text */
  labelIsRequired?: boolean;
  clearOnSelected?: boolean;
  classes?: string;
}

interface AutoSuggestVendorFieldPropsWithActionLink extends AutoSuggestVendorFieldBaseProps {
  /** callback when the user clicks the action button when their vendor is not found */
  onAddNewVendorClick: React.MouseEventHandler;
  /** Should a link be shown at the bottom if the auto-suggestion doesn't find a vendor */
  includeActionLink: true;
}

interface AutoSuggestVendorFieldProps extends AutoSuggestVendorFieldBaseProps {
  /** callback when the user clicks the action button when their vendor is not found */
  onAddNewVendorClick?: React.MouseEventHandler;
  /** Should a link be shown at the bottom if the auto-suggestion doesn't find a vendor */
  includeActionLink?: false;
}

const VendorIcon = ({ suggestion }: { suggestion: MappedVendorSearchResult }) => {
  if (suggestion.storefrontUuid) {
    const Icon = getVendorIconV3(
      getVendorTaxonomyKeyFromId(suggestion.taxonomyNodeId) as VendorTaxonomyKey
    );
    return (
      <div className="marketplace__suggestion-vendor_icon">
        <Icon width={20} height={20} color={COLORS3.BAY_100} />
      </div>
    );
  }

  return <Fragment />;
};

/**
 * @deprecated Use VendorAutosuggestField or VendorAutosuggest from Zola-UI instead
 */
const AutoSuggestVendorField = (
  props: AutoSuggestVendorFieldPropsWithActionLink | AutoSuggestVendorFieldProps
) => {
  const {
    onInputBlur,
    onSuggestionSelected,
    onInputChange,
    onAddNewVendorClick = () => null,
    includeActionLink = true,
    actionLinkText = '+ Add a new vendor',
    labelFor,
    labelText = 'Vendor Name',
    labelIsRequired = false,
    useLabel = true,
    limitToCategory = false,
    categoryId = null,
    autoFocus = false,
    excludedReferenceVendorIds,
    errorMessage,
    placeholder,
    value: val,
    allowBlank = false,
    disabled,
    inputV2 = false,
    clearOnSelected = false,
    classes = '',
  } = props;

  const [vendorName, setVendorName] = useState('');
  const [vendorNameSuggestions, setVendorNameSuggestions] = useState<MappedVendorSearchResult[]>(
    []
  );

  const doGetVendorSuggestions = useCallback(
    (prefix) => {
      if (limitToCategory && categoryId) {
        return getVendorSuggestionsForCategory(prefix, categoryId);
      }
      return getVendorSuggestions(prefix);
    },
    [limitToCategory, categoryId]
  );

  useEffect(() => {
    if ((val || allowBlank) && typeof val === 'string') {
      setVendorName(val || '');
    }
    if (val && typeof val === 'object') {
      setVendorName(val.name || '');
    }
  }, [allowBlank, val]);

  const labelProps = {
    ...(useLabel && { useLabel }),
    ...(labelFor && { labelFor }),
    ...(labelText && { labelText }),
    ...(labelIsRequired && {
      labelText: (
        <>
          <span>{labelText}</span>
          <span className="text-danger danger-asterisk">*</span>
        </>
      ),
    }),
  };

  const formatLocation = (suggestion: MappedVendorSearchResult) => {
    const { taxonomyNodeId, address } = suggestion;
    const location = [
      suggestion.storefrontUuid ? address.address1 : null, // show address only for Zola storefront vendors
      address.city,
      address.stateProvince,
    ];
    if (!limitToCategory) {
      const vendorKey = getVendorTaxonomyKeyFromId(taxonomyNodeId);
      const vendorType = titleCase(getVendorWordSingular(vendorKey || undefined));
      location.splice(0, 0, vendorType);
    }
    return location.filter((item) => item).join(', ');
  };

  /** Renders an item in the auto-suggest box */
  const renderSuggestion = (suggestion: MappedVendorSearchResult) => (
    <div className="marketplace__suggestion px-secondary py-quaternary">
      <div className="marketplace__suggestion-vendor">
        <VendorIcon suggestion={suggestion} />
        <div>
          <div
            className={cx('marketplace__suggestion-vendor_name', {
              'marketplace__suggestion-vendor_name_storefront': suggestion.storefrontUuid,
            })}
          >
            {suggestion.name}
          </div>
          <div className="marketplace__suggestion-vendor_location">
            {formatLocation(suggestion)}
          </div>
        </div>
      </div>
    </div>
  );

  /** Renders the input that triggers the auto-suggest */
  const renderInputComponent: RenderInputComponent = (inputProps) => {
    if (inputV2) {
      const { className } = inputProps;
      return (
        <div className={cx('zola-ui v2-render-field', { 'has-error': !!errorMessage })}>
          <div className="input-field__container">
            <input className={cx('input-field', className)} {...inputProps} />
          </div>
        </div>
      );
    }
    return (
      <div className="zola-ui v2-render-field">
        <input {...inputProps} />
      </div>
    );
  };

  const renderError = () => {
    if (errorMessage) {
      if (inputV2) {
        return <FormError message={errorMessage} iconSize={12} />;
      }

      return <div className="error-box">{errorMessage}</div>;
    }
    return null;
  };

  /** Invoked by the form control when the value changes */
  const onChangeHandler: (event: React.FormEvent<HTMLElement>, params: ChangeEvent) => void = (
    _event,
    { newValue }
  ) => {
    setVendorName(newValue);
    if (onInputChange) {
      onInputChange(newValue);
    }
  };

  /** Invoked by the form control when the value changes */
  const onSelectedHandler: (
    event: React.FormEvent<HTMLElement>,
    params: SuggestionSelectedEventData<MappedVendorSearchResult>
  ) => void = (event, suggestionData) => {
    if (onSuggestionSelected) {
      onSuggestionSelected(event, suggestionData);
    }
    if (clearOnSelected) {
      setVendorName('');
    }
  };

  /** Invoked by react-autosuggest to get suggestions */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onVendorNameSuggestionsFetchRequested = useCallback(
    _throttle(({ value }) => {
      if (value.length === 0) {
        setVendorNameSuggestions([]);
        return;
      }
      doGetVendorSuggestions(value)
        .then((response) => {
          if (response && response.length > 0) {
            const suggestions = response.filter(
              (vendor) => !_includes(excludedReferenceVendorIds, vendor.id)
            );
            setVendorNameSuggestions(suggestions);
          }
        })
        .catch(() => null);
    }, 500),
    [categoryId]
  );

  /** Invoked by react-autosuggest to clear suggestions */
  const onVendorNameSuggestionsClearRequested = () => {
    setVendorNameSuggestions([]);
  };

  const getSuggestionValue = (suggestion: MappedVendorSearchResult) => suggestion.name;

  /** A wrapper for the auto-suggestions container */
  const renderSuggestionsContainer: RenderSuggestionsContainer = ({
    containerProps,
    children,
    query,
  }) => {
    return (
      <div {...containerProps}>
        {children}
        {query && includeActionLink && children && (
          <button
            className="marketplace__suggestion-btn"
            type="button"
            onClick={(...rest) => {
              onAddNewVendorClick(...rest);
              setVendorNameSuggestions([]);
            }}
          >
            {actionLinkText}
          </button>
        )}
      </div>
    );
  };

  return (
    <Label {...labelProps}>
      <Fragment>
        <div className="marketplace-autosuggest-vendor-field">
          <Autosuggest
            suggestions={vendorNameSuggestions}
            onSuggestionsFetchRequested={onVendorNameSuggestionsFetchRequested}
            onSuggestionsClearRequested={onVendorNameSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={onSelectedHandler}
            inputProps={{
              value: vendorName,
              ...(onInputBlur && { onBlur: onInputBlur }),
              onBlur: (e) => {
                if (onInputBlur) {
                  onInputBlur(e);
                }
                if (typeof val === 'object' && vendorName !== val.name) {
                  setVendorName(val.name || '');
                }
              },
              onChange: onChangeHandler,
              className: cx('search-group__element', classes),
              id: 'vendorName',
              name: 'vendorNameSearch', // Prevent Safari from offering autofill suggestions, because "search" is in the input name
              autoFocus,
              disabled,
              placeholder,
              autoComplete: 'off!', // Generally prevent autofill suggestions
            }}
            renderInputComponent={renderInputComponent}
            renderSuggestionsContainer={renderSuggestionsContainer}
          />
        </div>
        {renderError()}
      </Fragment>
    </Label>
  );
};

export default AutoSuggestVendorField;
