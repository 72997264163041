import { VendorTypeEnum } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { MessageStatsView } from '@zola/svc-marketplace-ts-types';

import { UnreadInquiryResponse } from '~/actions/inquiryJewelActions';
import { MappedInquiryView } from '~/types/mappedResponseTypes';
import { MappedInquiryPreferencesView } from '~/types/responseTypes';
import ApiService from '~/util/apiService';

export const getUnreadInquiries = async () => {
  return ApiService.get<UnreadInquiryResponse>('/web-marketplace-api/v1/inquiry/unread').then(
    (response) => response.unreadInquiries
  );
};

export const getMessageStats = async () => {
  return ApiService.get<MessageStatsView>('/web-marketplace-api/v1/inquiry/unread-count');
};

export const getInquiryPreferences = async (vendorType: VendorTypeEnum) => {
  return ApiService.get<MappedInquiryPreferencesView>(
    `/web-marketplace-api/v1/inquiry/preferences?vendor_type=${vendorType}`
  );
};

export const getLastInquiryToStorefront = async (storefrontUuid: string) => {
  return ApiService.get<MappedInquiryView | null>(
    `/web-marketplace-api/v1/inquiry/get-last?storefront_uuid=${storefrontUuid}`
  );
};
