import type { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';

export const getCanonicalUrl = (
  type: VendorTaxonomyKey, // TODO: Change to VendorTypeKeyType to only accept onboardable types?
  slug: string,
  isPreview = false
): string => {
  const baseUrl = `/wedding-vendors/${getVendorTaxonomyKey(type)}/${slug}`;

  if (isPreview) {
    return `${baseUrl}/preview`;
  }
  return baseUrl;
};

export const getPublicReviewUrl = (storefrontUuid: string, returnUrl?: string): string => {
  let url = `/inspire/public-recommendations/${storefrontUuid}`;

  if (returnUrl) {
    url = `${url}?returnUrl=${returnUrl}`;
  }

  return url;
};

export default getCanonicalUrl;
