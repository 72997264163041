import { useState } from 'react';

import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

const getUrlPath = () => {
  const { pathname, search, hash } = window.location;
  return `${pathname}${search}${hash}`;
};

/**
 * Returns the URL path whenever the URL changes, based on observing changes to the document body
 */
export const useUrlPathChanged = () => {
  const [path, setPath] = useState<string | null>(null);

  useEffectOnce(() => {
    setPath(getUrlPath());
    const body = document.querySelector('body') as HTMLBodyElement;
    const observer = new MutationObserver(() => {
      const currentPath = getUrlPath();
      if (path !== currentPath) {
        setPath(currentPath);
      }
    });

    observer.observe(body, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  });

  return path;
};
