/**
 * Converts a string to title case by capitalizing each word separated by spaces or specific
 * punctuation (double quotes, parentheses, slashes and hyphens)
 *
 * Any non-string will return ''
 *
 * @example titleCase('what does the cat say') => 'What Does The Cat Say'
 */
export const titleCase = (text?: string | null): string => {
  if (!text) return '';
  // Technically, this capitalizes both the first word character and the preceding space or punctuation
  return text.replace(/(^|[\s"(/-])\w/g, (char) => char.toUpperCase());
};

/**
 * Converts a string to sentence case by making everything lowercase, matching any character that
 * begins a sentence, and making it uppercase
 *
 * Any non-string will return ''
 *
 * @example sentenceCase('Couples luncheon, Rehearsal dinner, Shower, Welcome party') => 'Couples luncheon, rehearsal dinner, shower, welcome party'
 * @example sentenceCase('Baraat, Ceremony, Cocktail hour, Reception') => 'Baraat, ceremony, cocktail hour, reception';
 */
export const sentenceCase = (text?: string | null): string => {
  if (!text) return '';
  return text.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, (char) => {
    return char.toUpperCase();
  });
};

/**
 * Lowercase the first character of a string
 *
 * Any non-string will return ''
 *
 * @example lowercaseFirst('Bands and DJs') => 'bands and DJs'
 */
export const lowercaseFirst = (text?: string | null) => {
  if (!text) {
    return '';
  }
  return text[0].toLowerCase() + text.slice(1);
};

/**
 * Strip extra spaces from a string
 *
 * Any non-string will return ''
 *
 * @example stripSpaces('  What does the cat  say ?  ') => 'What does the cat say?'
 */
export const stripSpaces = (text?: string | null) => {
  if (!text) {
    return '';
  }
  return text
    .replace(/\s+/g, ' ') // Replace multiple space with one space
    .replace(/\s([.!?])/g, '$1') // Replace spaces before periods, exclamation points or question marks
    .trim();
};

/**
 * Formats a number with commas in the places expected in the US
 *
 * Any non-number will return ''
 *
 * @example formatWithCommas(1000) => '1,000'
 * @example formatWithCommas(500) => '500'
 * @example formatWithCommas(1000*1000) => '1,000,000'
 */
export const formatWithCommas = (number?: number | null): string => {
  if (typeof number !== 'number') return '';
  return number.toLocaleString('en');
};

/**
 * Formats a number as a dollar (US) price, including commas where appropriate
 *
 * Any non-number will return '', except for strings that can be parsed with Number(<value>)
 *
 * @example formatAsCurrency(1000) => '$1,000'
 * @example formatAsCurrency(1000, 2) => '$1,000.00'
 * @example formatAsCurrency(42.42) => '$42.42'
 */
export const formatAsCurrency = (
  number?: number | string | null,
  minimumFractionDigits: number | undefined = undefined
): string => {
  const asNumber = Number(number);
  if (number === null || Number.isNaN(asNumber)) {
    return '';
  }
  const hasCents = asNumber - Math.floor(asNumber) > 0;
  return asNumber.toLocaleString('en', {
    style: 'currency',
    currency: 'usd',
    minimumFractionDigits: minimumFractionDigits || (hasCents ? 2 : 0),
  });
};

/**
 * Formats a number range with a dash separating the low and high values in the range. If either
 * of the values in the range are not numbers, only the other value will be returned. If both
 * values are not-numbers, this will return an empty string
 *
 * @example formatRange(50, 1000) => '50-1000'
 * @example formatRange(undefined, 1000) => '1000'
 * @example formatRange(50, undefined) => '50'
 *
 * @see rangeUtils For a more powerful range formatter,
 */
export const formatRange = (min: number | null, max: number | null): string => {
  if (typeof min === 'number' && typeof max === 'number') {
    if (min === max) {
      return min.toString();
    }
    return `${min}-${max}`;
  }
  if (typeof min === 'number') {
    return min.toString();
  }
  if (typeof max === 'number') {
    return max.toString();
  }
  return '';
};

export const formatArrayWithOxfordComma = (values: string[], connector: 'and' | 'or') => {
  if (values.length <= 2) {
    return values.join(` ${connector} `);
  }
  const lastValue = values.pop();
  return `${values.join(', ')}, ${connector} ${lastValue}`;
};

/**
 * Pluralizes 'person' based on the provided number
 *
 * @example peopleTerm(1) => '1 person'
 * @example peopleTerm(10) => '10 people'
 * @example peopleTerm(-10) => null
 * @example peopleTerm(null) => null
 */
export const peopleTerm = (num: number | null): string | null => {
  if (num === 1) {
    return '1 person';
  }

  if ((num && num > 1) || num === 0) {
    return `${num} people`;
  }

  return null;
};

/**
 * Returns the hashtag version of the string
 *
 * @example formatHashtag('wedding') => '#wedding'
 * @example formatHashtag('RINGS') => '#rings'
 * @example formatHashtag('Place Setting') => '#placesetting'
 */
export const formatHashtag = (text: string): string =>
  `#${text.toLowerCase().replace(/[^a-z0-9]/i, '')}`;
