import { Fragment, useState, useEffect } from 'react';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

import cx from 'classnames';

import { activateSubNav, hideSubNav } from '~/actions/vendors/vendorMenuActions';
import { useAppDispatch } from '~/reducers';

import { NavLogo } from '../NavLogo';
import { NavMenuProps } from '../types';
import { useNavigationLinks } from '../useNavigationLinks';
import { LogoutButton } from './LogoutButton';
import { MobileNavItem } from './MobileNavItem';
import { StorefrontMenu } from './StorefrontMenu';

import styles from './mobileNav.module.less';

export const MobileNav = (props: NavMenuProps): JSX.Element => {
  const {
    isAuthenticated = false,
    hasStorefront = false,
    showVendorLoginModal,
    hideNavLinksAndMenu,
  } = props;

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [storefrontMenuOpen, setStorefrontMenuOpen] = useState(false);

  const links = useNavigationLinks();

  const hamburgerMenuItems = links.map((item, i) => (
    <MobileNavItem
      key={item.to}
      trackingProps={{
        navigation_level_1: 'HAMBURGER',
        navigation_level_1_position: 1,
        navigation_level_2: item.trackingIdentifier,
        navigation_level_2_position: i + 1,
      }}
      item={item}
      expanded={hamburgerOpen}
    />
  ));

  const toggleStorefrontMenu = () => {
    setStorefrontMenuOpen(!storefrontMenuOpen);
  };
  const toggleHamburgerMenu = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  // ----
  // Only one of these menus should be open at a time
  // ----
  useEffect(() => {
    if (hamburgerOpen) {
      setStorefrontMenuOpen(false);
    }
  }, [hamburgerOpen]);

  useEffect(() => {
    if (storefrontMenuOpen) {
      setHamburgerOpen(false);
    }
  }, [storefrontMenuOpen]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (storefrontMenuOpen || hamburgerOpen) {
      dispatch(activateSubNav());
    } else {
      dispatch(hideSubNav());
    }
  }, [dispatch, hamburgerOpen, storefrontMenuOpen]);

  return (
    <div className={styles.mobileNav}>
      {!hideNavLinksAndMenu && (
        <div>
          <button
            id="mobileNavMenuToggle"
            className={cx(styles.hamburgerMenu, { [styles.activeHamburger]: hamburgerOpen })}
            type="button"
            onClick={toggleHamburgerMenu}
            data-testid="nav-menu-toggle"
          >
            <span />
            <span />
            <span />
            <span />
          </button>
        </div>
      )}
      <div className={styles.vendorLogo}>
        <NavLogo
          isAuthenticated={isAuthenticated}
          trackingProps={{
            navigation_level_1: 'VENDOR_LOGO',
            navigation_level_1_position: 2,
          }}
        />
      </div>
      {!hideNavLinksAndMenu && (
        <Fragment>
          {isAuthenticated ? (
            <StorefrontMenu
              expanded={storefrontMenuOpen}
              toggleExpanded={toggleStorefrontMenu}
              trackingProps={{
                navigation_level_1: 'ACCOUNT',
                navigation_level_1_position: 3,
              }}
            />
          ) : (
            <div className={styles.storefrontMenuSpacer} />
          )}
          <div
            className={styles.dropdownMenu}
            // This sets the max height of the menu to 100% of the window height - 70px for the nav
            // 100vh does not work on mobile because the viewport height can be more than the window height
            // when the browser is adding buttons or nav space at the top and bottom of the screen
            style={{ maxHeight: hamburgerOpen ? `${window.innerHeight - 70}px` : '' }}
            onClick={toggleHamburgerMenu}
            role="presentation"
          >
            <ul className={styles.navItems}>
              {hasStorefront && hamburgerMenuItems}
              {isAuthenticated ? (
                <Fragment>
                  <LogoutButton
                    trackingProps={{
                      navigation_level_1: 'HAMBURGER',
                      navigation_level_1_position: 1,
                      navigation_level_2: 'LOGOUT',
                      navigation_level_2_position: hamburgerMenuItems.length + 1,
                    }}
                  />
                </Fragment>
              ) : (
                <li className={cx(styles.navItem, styles.login)}>
                  <ButtonV3
                    className={styles.navButton}
                    onClick={showVendorLoginModal}
                    data-testid="vendor-login"
                  >
                    Log in
                  </ButtonV3>
                </li>
              )}
            </ul>
          </div>
        </Fragment>
      )}
    </div>
  );
};
