import { CreditBalanceView } from '@zola/svc-marketplace-ts-types';

import { formatUnlimitedCreditExpiration } from '~/pages/vendors/Inquiries/components/CreditPrice/CreditPrice';

import { formatCreditCount } from './formatters';

/**
 * Determines whether the renewal plan is a free trial
 */

type PartialCreditBalanceView = Pick<
  CreditBalanceView,
  'autoRenew' | 'balance' | 'discountActiveUntil' | 'freeTrialEndsAt' | 'paidPlanStartsAt'
>;

/**
 * Gets a text description of a storefront's credit balance or renewal plan
 */
export const getCreditText = (
  isPublished: boolean,
  creditBalanceView: PartialCreditBalanceView
) => {
  const { balance, discountActiveUntil, freeTrialEndsAt, paidPlanStartsAt, autoRenew } =
    creditBalanceView;

  if (!isPublished) {
    return null;
  }

  if (!Boolean(discountActiveUntil)) {
    return formatCreditCount(balance);
  }

  if (Boolean(freeTrialEndsAt)) {
    if (paidPlanStartsAt) {
      const starts = formatUnlimitedCreditExpiration(paidPlanStartsAt);
      return `Purchased plan starts ${starts}`;
    }

    return 'Unlimited (Free trial)';
  }

  const planEndTime = formatUnlimitedCreditExpiration(discountActiveUntil);
  if (autoRenew) {
    return `Renews on ${planEndTime}`;
  }

  return `Expires on ${planEndTime}`;
};
