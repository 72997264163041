import React from 'react';

import type {
  CreateInquiryRequest,
  InquiryBudgetMatchFlowView,
  ReferenceVendorView,
} from '@zola/svc-marketplace-ts-types';

import { ValidationErrors } from 'final-form';

import { WeddingDateFormFields } from '~/components/common/form/WeddingDateField/types';
import { PriceExplorerStageEnum } from '~/types/enums';
import { FacetParentKey } from '~/types/facets';
import type {
  MappedInquiryPreferencesView,
  MappedOptionFacetView,
  VendorMarketView,
  InquiryServicesFacet,
} from '~/types/responseTypes';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import { IUserContext } from '~/types/userContext';

export const WEDDING_INFO_STEP_SLUG = 'wedding-info' as const;
export const NOTE_STEP_SLUG = 'inquiry-note' as const;

export type PhoneNumberPlacement = typeof NOTE_STEP_SLUG | typeof WEDDING_INFO_STEP_SLUG;

export type InquiryServicesOffered = {
  [key in FacetParentKey]?: InquiryServicesFacet[];
};

export type MoveStepFunction = (
  direction: { forward?: boolean; backward?: boolean },
  length?: number
) => void;

export type FormValidationResult = ValidationErrors | Promise<ValidationErrors> | undefined;

export type FormValidator<T = InquiryFormValues> = (values: T) => FormValidationResult;

// parent-key: "event-types"
export enum WeddingEventType {
  CELEBRATION = 'event-types-engagement-celebration',
  LUNCHEON = 'event-types-couples-luncheon',
  DINNER = 'event-types-rehearsal-dinner',
  BRIDAL_SHOWERS = 'event-types-bridal-showers',
  WELCOME_PARTY = 'event-types-welcome-party',
  BARAAT = 'event-types-baraat',
  CEREMONY = 'event-types-ceremony',
  COCKTAIL_HOUR = 'event-types-cocktail-hour',
  RECEPTION = 'event-types-reception',
  AFTER_HOURS = 'event-types-after-hours-events',
  FAREWELL_BRUNCH = 'event-types-farewell-brunch',
  ELOPEMENT = 'event-types-elopement',
  OTHER = 'event-types-other',
}

// This is a fake facet
export type WeddingEventTypeGroup =
  | 'ENGAGEMENT'
  | 'PRE_WEDDING'
  | 'DAY_OF'
  | 'POST_WEDDING'
  | 'ELOPEMENT'
  | 'OTHER';

// parent-key: "bakers-services"
export enum BakerServices {
  CONSULTATION = 'bakers-services-consultations',
  TASTING = 'bakers-services-tastings',
  DELIVERY = 'bakers-services-delivery',
  SETUP = 'bakers-services-setup',
}

// parent-key: "bakers-dietary-accommodations"
export enum BakerDietaryAccommodations {
  DAIRY_FREE = 'bakers-dietary-accommodations-dairy-free',
  GLUTEN_FREE = 'bakers-dietary-accommodations-gluten-free',
  HALAL = 'bakers-dietary-accommodations-halal',
  KOSHER = 'bakers-dietary-accommodations-kosher',
  NUT_FREE = 'bakers-dietary-accommodations-nut-free',
  VEGAN = 'bakers-dietary-accommodations-vegan',
  VEGETARIAN = 'bakers-dietary-accommodations-vegetarian',
  SUGAR_FREE = 'bakers-dietary-accommodations-sugar-free',
}

// parent-key: "hair-beauty-service-location"
export enum BeauticianServiceLocation {
  MY_WEDDING_VENUE = 'hair-beauty-service-location-venue',
  THEIR_STUDIO = 'hair-beauty-service-location-studio-salon',
  A_DIFFERENT_LOCATION = 'hair-beauty-service-location-other',
}

// parent-key: "florist-floral-arrangement"
export enum FloralArrangementTypes {
  BOUQUETS = 'florist-floral-arrangement-bouquets',
  BOUTONNIERES = 'florist-floral-arrangement-boutonnieres',
  CAKE_FLOWERS = 'florist-floral-arrangement-cake-flowers',
  CENTERPIECES = 'florist-floral-arrangement-centerpieces',
  CEREMONY_DECOR = 'florist-floral-arrangement-ceremony-decor',
  FLOWER_BASKETS = 'florist-floral-arrangement-flower-baskets',
  FLOWER_CROWNS = 'florist-floral-arrangement-flower-crowns-hair-pieces',
  FLOWER_JEWELRY = 'florist-floral-arrangement-floral-jewelry',
  INSTALLATIONS = 'florist-floral-arrangement-installations',
  TABLE_RUNNERS = 'florist-floral-arrangement-table-runners',
}

// parent-key: "florist-service-level"
export enum FloristServices {
  WHOLESALE = 'florist-service-level-wholesale',
  A_LA_CARTE = 'florist-service-level-a-la-carte',
  FULL_SERVICE_FLORAL_DESIGN = 'florist-service-level-full-service-floral-design',
}

// parent-key: "venue-service-level"
/** @deprecated figure out a way to use src/types/facets.ts and VENUE_SERVICE_LEVEL_** and VenueServiceLevelChildKey  */
export enum VenueServiceLevel {
  RAW_SPACE = 'venue-service-level-raw-space',
  ALL_INCLUSIVE = 'venue-service-level-all-inclusive',
  LIMITED_SERVICES = 'venue-service-level-limited-services',
}

// parent-key: "musician-performers"
export enum MusicianActType {
  SOLO_ACT = 'musician-performers-solo-act',
  DUO_OR_TRIO = 'musician-performers-duo-trio',
  QUARTET = 'musician-performers-quartet-person-band',
  MEDIUM_BAND = 'musician-performers-5-8-person-band',
  LARGE_BAND = 'musician-performers-8-plus-person-band',
}

// parent-key: "music-genre"
export enum MusicianGenre {
  ARABIC_OR_PERSIAN = 'music-genre-arabic-persian',
  AMERICANA = 'music-genre-americana',
  BLUES = 'music-genre-blues',
  BOLLYWOOD_OR_BHANGRA = 'music-genre-bollywood-bhangra',
  CLASSICAL = 'music-genre-classical',
  COUNTRY_OR_FOLK = 'music-genre-country-folk',
  DISCO_OR_FUNK = 'music-genre-funk',
  EDM_OR_HOUSE = 'music-genre-edm-house',
  GOSPEL_OR_CHRISTIAN = 'music-genre-gospel-christian',
  JAZZ = 'music-genre-jazz',
  LATIN = 'music-genre-latin',
  MOTOWN_OR_SOUL = 'music-genre-motown-soul',
  POP_OR_TOP_40S = 'music-genre-pop-top-40s',
  R_AND_B = 'music-genre-r-and-b',
  RAP_OR_HIP_HOP = 'music-genre-rap-hip-hop',
  REGGAE = 'music-genre-reggae',
  ROCK = 'music-genre-rock-and-roll',
  SCOTTISH_OR_IRISH = 'music-genre-scottish-irish',
}

// parent-key: "caterer-dietary-restrictions"
export enum DietaryNeeds {
  DAIRY_FREE = 'caterer-dietary-restrictions-dairy-free',
  GLUTEN_FREE = 'caterer-dietary-restrictions-gluten-free',
  HALAL = 'caterer-dietary-restrictions-halal',
  KOSHER = 'caterer-dietary-restrictions-kosher',
  NUT_FREE = 'caterer-dietary-restrictions-nut-free',
  VEGAN = 'caterer-dietary-restrictions-vegan',
  VEGETARIAN = 'caterer-dietary-restrictions-vegetarian',
}

export enum PlannersServiceLevel {
  FULL_SERVICE_PLANNING = 'planner-service-level-full-service-planning',
  PARTIAL_PLANNING = 'planner-service-level-partial-planning',
  DAY_OF_COORDINATION = 'planner-service-level-day-of-coordination',
  DESTINATION_WEDDING = 'planner-service-level-destination-wedding',
  ELOPEMENT_MICRO_WEDDING = 'planner-service-level-elopement',
  A_LA_CARTE = 'planner-service-level-a-la-carte',
  FULL_EVENT_DESIGN = 'planner-service-level-event-design',
}

/**
 * A lookup of facet-values to additional descriptive text
 */
export type DescriptionLookup = { [key: string]: React.ReactNode };

export type WeddingLocation = {
  city?: string | null;
  stateProvince?: string | null;
  postalCode?: string;
};

export type InquiryFormFacets = {
  HAIR_BEAUTY_SERVICE_CATEGORY?: string[];
  CATERER_DIETARY_RESTRICTIONS?: DietaryNeeds[];
  CATERER_DRINK_TYPES?: string[];
  FOOD_BEVERAGE_OFFERED?: string[];
  FLORIST_FLORAL_ARRANGEMENT?: FloralArrangementTypes[];
  FLORIST_SERVICE_LEVEL?: FloristServices[];
  VENUE_SERVICE_LEVEL?: VenueServiceLevel[];
  BAKERS_PRODUCT_TYPES?: string[];
  BAKERS_SERVICES?: BakerServices[];
  BAKERS_DIETARY_ACCOMMODATIONS?: BakerDietaryAccommodations[];
  MUSICIAN_PERFORMERS?: MusicianActType[];
  MUSIC_GENRE?: MusicianGenre[];
  MUSICIAN_TYPE?: string[];

  EVENT_TYPES?: WeddingEventType[];
  PLANNER_SERVICE_LEVEL?: PlannersServiceLevel[];
  MENU_FORMATS_OFFERED?: string[];
};

export type GroupedFormFacets = {
  EVENT_TYPES?: WeddingEventTypeGroup[];
};

export type FlexibleOnGuestCount = 'flexible-on-guest-count' | 'not-flexible-on-guest-count';

export type FlexibleOnPrice = 'flexible-on-price' | 'budget-is-not-flexible';

export type InquiryFormValues = WeddingDateFormFields & {
  primaryFirstName?: string;
  primaryLastName?: string;
  partnerFirstName?: string;
  partnerLastName?: string;

  guestCount?: number | null;
  flexibleOnGuestCount?: boolean | FlexibleOnGuestCount;
  overrideFlexibleGuestCount?: FlexibleOnGuestCount;
  beauticianServiceLocation?: BeauticianServiceLocation;
  weddingLocation?: WeddingLocation | null;

  visibleGuestCount?: number | null;
  // used for determining which guest count match screen to show
  adjustedGuestCount?: boolean;
  serviceStartPrice?: number | string | null; // not sure if these are a number or string
  serviceEndPrice?: number | string | null; // not sure if these are a number or string
  serviceEndPriceAsCurrency?: string;

  flexibleOnPrice?: boolean | FlexibleOnPrice;
  weddingEndPriceCents?: number | null;
  weddingEndPriceAsCurrency?: string;
  weddingEndPrice?: number | null;
  flexibleOnWeddingPrice?: boolean;
  notes?: string;

  stillLookingForVenue?: boolean | null;
  venueNotListed?: boolean | null;
  notListedVenueName?: string;
  venueChosenId?: number;
  venueChosenName?: string;

  priceExplorerStage?: PriceExplorerStageEnum | null;
  priceMatch?: CreateInquiryRequest.PriceMatchEnum;

  groupedFacets?: GroupedFormFacets;
  inquiryFormFacets?: InquiryFormFacets;

  // First time for category
  firstInquiryToCategory?: boolean;

  venueName?: string | null;

  weddingMarket?: VendorMarketView;

  // Beauticians
  weddingPartyCount?: number;
  stillDecidingOnWeddingParty?: boolean;
  flexibleOnWeddingPartyCount?: boolean;

  // Florists
  serviceCustomerCount?: number;
  flexibleOnServiceCustomerCount?: boolean;

  // Planner
  wantsPlannerService?: boolean;

  // Service Questions
  wantsThisService?: boolean;

  // Couples phone number: used for inquiries only.
  phone?: string;
};

export interface CardData<T> {
  text: string;
  subtext?: string;
  displayOrder?: number;
  value: T;
}

export type InquiryFlowFacets = {
  [key in FacetParentKey]?: MappedOptionFacetView[];
};

export type FlowArgs = {
  storefront: CouplesStorefrontDetails;
  matchFlowView: InquiryBudgetMatchFlowView;
  values: InquiryFormValues;
};

export type VendorTypeQuestionFactory = (
  userContext: IUserContext | null
) => (InquiryStepDefinition | undefined | null)[];

export type FacetKeysAsFields =
  | `inquiryFormFacets.${keyof InquiryFormFacets}`
  | `groupedFacets.${keyof GroupedFormFacets}`;

export type SummaryComponentProps = {
  showMisMatch: boolean;
};

export type SummaryComponent = (props: SummaryComponentProps) => JSX.Element;
export type FormInitializer = (
  args: {
    preferences: MappedInquiryPreferencesView | null | undefined;
    venueReferenceVendor: ReferenceVendorView | null;
    servicesOffered: InquiryServicesOffered;
    storefront: Pick<CouplesStorefrontDetails, 'uuid' | 'taxonomyKey'>;
    signedInMidFlow?: boolean;
  },
  values: Readonly<InquiryFormValues>
) => InquiryFormValues;

export type BuildInquiryRequest = (args: {
  values: InquiryFormValues;
  inquiry: Readonly<CreateInquiryRequest>;
}) => CreateInquiryRequest;

export type IsAnsweredArgs = {
  userContext: IUserContext | null;
  storefront: CouplesStorefrontDetails;
  matchFlowView: InquiryBudgetMatchFlowView;
  initialValues: InquiryFormValues;
  preferences?: MappedInquiryPreferencesView;
};

/**
 * Predicate function called to determine if the couple has answered all
 * questions before the flow starts.
 */
export type IsAnsweredFunction = (args: IsAnsweredArgs) => boolean;

/**
 * Inquiry Flow Question {Object}
 *
 * container for information needed by inquiry modal question forms/stepper
 *
 * TODO: Split this into individual types (SubmitQuestion / FacetQuestion / Etc)
 * TODO: This is not comprehensive for all the vendor types.
 */
export interface InquiryStepDefinition {
  /** Name of the step, used in event tracking and stepping through the flow */
  stepName: string; // todo: make this an enum

  /** The text to display on the next button */
  ctaText?: 'Close' | 'Submit' | 'Send inquiry' | 'Send' | 'Message anyway' | 'Yes, continue';

  /** The text to display when the next button is disabled */
  disabledCtaText?: string;

  /** The text to display after success animation */
  successText?: string;

  component: (props: any) => JSX.Element;

  summaryComponent?: SummaryComponent;

  /** controls navigation tab rendered at top of form */
  tabIndex: number;

  /** determines if question can offer 'skip question' link */
  isOptional?: boolean;

  /** list of fields to clear when skipping question or to use to determine if the current step is invalid */
  fieldNames?: (keyof InquiryFormValues | FacetKeysAsFields)[];

  /** If true, this is the end of the flow */
  submitAfter?: boolean;

  // Validation for the form
  validate?: FormValidator;

  /** Disable the main CTA if the form is valid.  Default: false.  If true, you must provide the form fields (fieldNames) to check for errors */
  disableCtaWhenInvalid?: boolean;

  /**
   * Should the question be conditionally skipped?  This is different than
   * omitting the question from the flow, skipped means: depending on the
   * previous answers and storefront data, we may sometimes skip this question.
   *
   * For example, the wedding-venue question in the beautician flow is
   * presented only when the inquiry has selected "I want services at my venue".
   *
   */
  isSkipped?: (args: FlowArgs) => boolean;

  /**
   * Optional list of facet keys expected
   * this is used to determine acceptable facets to pre-populate from inquiry preferences
   * if undefined or empty, the inquiry step won't be pre-populated
   */
  facets?: string[];

  slug: string | ((args: FlowArgs) => string);

  /** True when the step is the rapid repeat inquiry summary screen  */
  isSummary?: boolean;

  /**
   * Take the preferences (and other initialization data) and pre-populate the
   * form with the values needed for this question
   * */
  initializeFormValues?: FormInitializer;

  /**
   * Convert the form values for this question to the appropriate fields in the
   * request to create the inquiry.
   */
  buildInquiryRequest?: BuildInquiryRequest;

  /** Predicate to determine if this question is answered already and can be skipped */
  isAnswered?: IsAnsweredFunction;

  /**
   * IF the couple's answer is a mismatch for this question, flag it.  Called
   * with initial values (not values).
   */
  isMisMatch?: MisMatchFunction;

  /**
   * A list of facet keys to fetch from the backend before the inquiry flow starts.
   */
  requiredFacets?: FacetParentKey[];
}

export type MisMatchFunction = (
  args: IsAnsweredArgs
) => undefined | MisMatchType | MisMatchType[] | (MisMatchType | undefined)[];

export type MisMatchType =
  | 'services-offered'
  | 'service-budget'
  | 'wedding-date'
  | 'guest-count'
  | 'venue-service-level'
  | 'wedding-budget';

/**
 * The properties passed to a step in the inquiry flow
 *
 * Note: incomplete
 */
export type StepProps = {
  moveStep?: MoveStepFunction;
};
