import { Fragment, useMemo } from 'react';

import { PHOTOGRAPHERS_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { NavigationClicked } from '@zola/tracking-contracts/src/events';

import type { Location, LocationState } from 'history';
import _groupBy from 'lodash/groupBy';
import type { match as Match } from 'react-router-dom';
import { createSelector } from 'reselect';

import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import useCreditBalance from '~/hooks/vendors/useCreditBalance';
import { useShowBoostListing } from '~/hooks/vendors/useShowBoostListing';
import {
  AboutPlansUrl,
  HowPlansWorkUrl,
  ResourcesPageUrl,
} from '~/pages/vendors/Credits/utils/urlHelpers';
import { formatUnlimitedCreditExpiration } from '~/pages/vendors/Inquiries/components/CreditPrice/CreditPrice';
import { INBOX_V2_TABS, STATUS_MAP_FOR_URLS } from '~/pages/vendors/Inquiries/utils/constants';
import { getVendorInboxUrl } from '~/pages/vendors/Inquiries/utils/vendorInboxUrls';
import { useAppSelector } from '~/reducers';
import { getUnreadInquiries } from '~/selectors/inquiryJewelSelectors';
import { getStorefronts } from '~/selectors/vendorAccountSelectors';
import { MappedAccountStorefront } from '~/types/mappedResponseTypes';
import { formatCreditCount } from '~/util/formatters';

const inboxV3Paths = [
  'leads/conversations',
  'leads/connected',
  'leads/booked',
  'leads/ready',
  'leads/closed',
  'leads/find-couples',
];

export type IsActiveFunction = <Params extends { [K in keyof Params]?: string }>(
  match: Match<Params> | null,
  location: Location<LocationState>
) => boolean;

export enum MenuType {
  HEADING = 'heading',
  LINK = 'link',
  SEPARATOR = 'separator',
  DROPDOWN = 'dropdown',
  STOREFRONT = 'storefront',
}

export interface MenuLink {
  type: MenuType.LINK;
  text: string;
  to: string;

  /** Bypass react-router-dom and just use a link */
  external?: boolean;
  trackingIdentifier: string;
  isActive?: IsActiveFunction;
  tag?: string;
  jewel?: boolean;
  columnPosition?: number;
  /**
   *  to render more than just a string for the menu item label
   *  if passed will render the ReactElement and use text param for tracking only
   */
  renderText?: React.ReactElement;

  className?: string;

  /** Optional click handler invoked after tracking is completed */
  onClick?: (event: React.MouseEvent) => void;

  dataTestId?: string;

  /**
   * Optional ID attribute for the list item
   */
  id?: string;
}

export type MenuHeading = {
  type: MenuType.HEADING;
  text: string;
};

export type MenuSeparator = {
  type: MenuType.SEPARATOR;
};

export const isMenuLink = (
  item: MenuLink | MenuSeparator | MenuHeading | MenuDropdown
): item is MenuLink => {
  return item.type === MenuType.LINK;
};
export const isMenuSeparator = (
  item: MenuLink | MenuSeparator | MenuHeading | MenuDropdown
): item is MenuSeparator => {
  return item.type === MenuType.SEPARATOR;
};
export const isMenuHeading = (
  item: MenuLink | MenuSeparator | MenuHeading | MenuDropdown
): item is MenuHeading => {
  return item.type === MenuType.HEADING;
};
export const isMenuDropdown = (
  item: MenuLink | MenuSeparator | MenuHeading | MenuDropdown
): item is MenuDropdown => {
  return item.type === MenuType.DROPDOWN;
};

export interface MenuDropdown extends Omit<MenuLink, 'type'> {
  type: MenuType.DROPDOWN;
  items: (MenuLink | MenuSeparator | MenuHeading)[];
}

export type MenuItem = MenuLink | MenuDropdown;

export interface StorefrontMenuLink {
  type: MenuType.STOREFRONT;
  trackingIdentifier: 'STOREFRONT';
  storefront: MappedAccountStorefront;
}

export const addNextLevelTrackingProps = (
  baseTrackingProps: Omit<
    NavigationClicked,
    'navigation_type' | 'business_unit' | 'business_category'
  >,
  trackingIdentifer: string,
  trackingPosition: number
): Omit<NavigationClicked, 'navigation_type' | 'business_unit' | 'business_category'> => {
  const { navigation_level_1, navigation_level_2, navigation_level_3, navigation_level_4 } =
    baseTrackingProps;

  const unusedLevel =
    [navigation_level_1, navigation_level_2, navigation_level_3, navigation_level_4].findIndex(
      (value) => !value
    ) + 1;

  if (unusedLevel > 0) {
    return {
      ...baseTrackingProps,
      [`navigation_level_${unusedLevel}`]: trackingIdentifer,
      [`navigation_level_${unusedLevel}_position`]: trackingPosition,
    };
  }

  return baseTrackingProps;
};

const getStorefrontLinks = (storefronts: MappedAccountStorefront[]): StorefrontMenuLink[] => {
  const sortedStorefronts = [...storefronts].sort((a, b) =>
    a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
  );

  return sortedStorefronts.map((storefront) => {
    const link: StorefrontMenuLink = {
      type: MenuType.STOREFRONT,
      trackingIdentifier: 'STOREFRONT',
      storefront,
    };
    return link;
  });
};

type ResultType = { menuItems: (StorefrontMenuLink | MenuHeading)[]; numStorefronts: number };

export const useStorefrontMenuItems = (): ResultType => {
  const storefronts = useAppSelector(getStorefronts);
  const [publishedListings, prePublishedListings] = useMemo(() => {
    const groupedStorefronts = _groupBy(storefronts, 'published');
    return [groupedStorefronts.true || [], groupedStorefronts.false || []];
  }, [storefronts]);

  if (storefronts.length === 0) {
    return {
      menuItems: [],
      numStorefronts: 0,
    };
  }

  if (publishedListings.length === 0 || prePublishedListings.length === 0) {
    const text = storefronts.length === 1 ? 'Your listing' : 'Your listings';
    return {
      menuItems: [{ type: MenuType.HEADING, text }, ...getStorefrontLinks(storefronts)],
      numStorefronts: storefronts.length,
    };
  }

  let results: (StorefrontMenuLink | MenuHeading)[] = [];
  results = results.concat([
    { type: MenuType.HEADING, text: 'Published listings' },
    ...getStorefrontLinks(publishedListings),
  ]);

  results = results.concat([
    { type: MenuType.HEADING, text: 'Not published' },
    ...getStorefrontLinks(prePublishedListings),
  ]);

  return { menuItems: results, numStorefronts: storefronts.length };
};

const isActivePath = (location: Location<LocationState>, prefixes: string[]) =>
  prefixes.some((prefix) => location.pathname.startsWith(`/inspire/vendors/${prefix}`));

/**
 * Links in the Plans submenu
 */
const YOUR_PLANS_NAV_ITEM: MenuLink = {
  type: MenuType.LINK,
  to: '/inspire/vendors/your-plans',
  text: 'Your plans',
  trackingIdentifier: 'MANAGE_PLANS',
  dataTestId: 'manage-plans',
};

const SELECT_PLAN_NAV_ITEM: MenuLink = {
  type: MenuType.LINK,
  to: '/inspire/vendors/select-plan',
  text: 'Select a plan',
  trackingIdentifier: 'SELECT_PLAN',
  dataTestId: 'select-plan',
};

const HOW_CREDITS_WORK_NAV_ITEM: MenuLink = {
  type: MenuType.LINK,
  to: HowPlansWorkUrl,
  text: 'About our plans',
  trackingIdentifier: 'ABOUT_PLANS',
};

const CREDITS_FAQ_NAV_ITEM: MenuLink = {
  type: MenuType.LINK,
  to: AboutPlansUrl,
  text: 'FAQs',
  trackingIdentifier: 'PLANS_FAQ',
};

const getHasUnreadInquiry = createSelector(getUnreadInquiries, (unreadInquiries) => {
  const serverStatuses = INBOX_V2_TABS.flatMap(({ slug: status, showUnreadCount }) => {
    if (showUnreadCount) {
      return STATUS_MAP_FOR_URLS[status].serverStatuses;
    }
    return [];
  });

  const unreadInquiry = unreadInquiries.find(({ status: inquiryStatus }) => {
    return inquiryStatus && serverStatuses.includes(inquiryStatus);
  });

  return Boolean(unreadInquiry);
});

const isListingActive: IsActiveFunction = (_match, location) => {
  return isActivePath(location, ['listing', 'reviews', 'community']);
};

const isPlansMenuActive: IsActiveFunction = (_match, location) =>
  isActivePath(location, [
    'your-plans',
    'select-plan',
    'about-credits',
    'about-plans',
    'how-plans-work',
    'purchase-history',
    'checkout',
    'how-credits-work',
    'how-we-price',
  ]);

const LISTING_LINK: MenuLink = {
  type: MenuType.LINK,
  text: 'Listing',
  to: '/inspire/vendors/listing',
  isActive: isListingActive,
  trackingIdentifier: 'EDIT_LISTING',
  dataTestId: 'edit-listing',
  id: 'edit-listing-nav-item',
};

/**
 * Links common to both the desktop nav and the mobile nav
 */
export const useNavigationLinks = (): MenuItem[] => {
  const { balance, discountActiveUntil, autoRenew } = useCreditBalance();
  const { showBoostListing, loaded: showBootListingLoaded } = useShowBoostListing();
  const { taxonomyKey, publishedAt } = useCurrentStorefront() || {};

  const publishedPhotographer = useMemo(
    () => taxonomyKey === PHOTOGRAPHERS_TAXONOMY_KEY && publishedAt,
    [publishedAt, taxonomyKey]
  );

  const formattedCreditCount = formatCreditCount(balance);

  const hasUnreadInquiry = useAppSelector(getHasUnreadInquiry);

  let creditText = `${formattedCreditCount} remaining`;

  if (Boolean(discountActiveUntil)) {
    const planEndTime = formatUnlimitedCreditExpiration(discountActiveUntil);
    if (autoRenew) {
      creditText = `Renews on ${planEndTime}`;
    } else {
      creditText = `Expires on ${planEndTime}`;
    }
  }
  const plansLinks = useMemo(() => {
    const creditsLinks = [
      publishedAt ? YOUR_PLANS_NAV_ITEM : null,
      publishedAt ? SELECT_PLAN_NAV_ITEM : null,
      HOW_CREDITS_WORK_NAV_ITEM,
      CREDITS_FAQ_NAV_ITEM,
      publishedAt
        ? {
            type: MenuType.LINK,
            text: 'Purchase history',
            to: '/inspire/vendors/purchase-history',
            trackingIdentifier: 'PURCHASE_HISTORY',
          }
        : null,
    ].filter((link) => link) as MenuLink[];

    return creditsLinks;
  }, [publishedAt]);

  const listingMenu: MenuLink | MenuDropdown = useMemo(() => {
    const subNavItems: MenuLink[] = [
      {
        type: MenuType.LINK,
        text: 'Edit listing details',
        to: '/inspire/vendors/listing',
        isActive: isListingActive,
        trackingIdentifier: 'EDIT_DETAILS_LISTING',
        dataTestId: 'edit-listing-details',
        id: 'edit-listing-nav-item',
      },
      publishedPhotographer
        ? {
            type: MenuType.LINK,
            text: 'Submit real weddings',
            to: '/inspire/vendors/listing/real-weddings',
            trackingIdentifier: 'REAL_WEDDINGS',
            dataTestId: 'submit-real-wedding',
          }
        : null,
      {
        type: MenuType.LINK,
        text: 'Reviews',
        to: '/inspire/vendors/reviews',
        trackingIdentifier: 'REVIEWS',
      },
      {
        type: MenuType.LINK,
        text: 'Preferred vendors',
        to: '/inspire/vendors/listing/preferred-vendors',
        trackingIdentifier: 'PREFERRED_VENDORS',
      },
      {
        type: MenuType.LINK,
        text: 'Suggest alternate vendors',
        to: '/inspire/vendors/community',
        trackingIdentifier: 'ALTERNATIVE_VENDORS',
      },
    ].filter(Boolean) as MenuLink[];

    return {
      ...LISTING_LINK,
      items: subNavItems,
      type: MenuType.DROPDOWN,
    };
  }, [publishedPhotographer]);

  const navMenu: MenuItem[] = useMemo(() => {
    if (!showBootListingLoaded) {
      return [];
    }
    const finalMenu: MenuItem[] = [
      {
        type: MenuType.LINK,
        text: 'Dashboard',
        to: '/inspire/vendors/dashboard',
        isActive: (_match, location) => isActivePath(location, ['dashboard']),
        trackingIdentifier: 'DASHBOARD',
        dataTestId: 'vendor-dashboard',
        id: 'dashboard-nav-item',
      },
      {
        type: MenuType.LINK,
        text: 'Leads',
        to: getVendorInboxUrl(),
        isActive: (_match, location) => isActivePath(location, inboxV3Paths),
        jewel: hasUnreadInquiry,
        trackingIdentifier: 'INBOX',
        dataTestId: 'vendor-leads',
        id: 'leads-nav-item',
      },
      listingMenu,
      {
        type: MenuType.LINK,
        text: 'Lead preferences',
        to: '/inspire/vendors/lead-preferences',
        isActive: (_match, location) => isActivePath(location, ['lead-preferences']),
        trackingIdentifier: 'LEAD_PREFERENCES',
        dataTestId: 'lead-preferences',
      },
      {
        type: MenuType.DROPDOWN,
        text: 'Your plans',
        to: publishedAt ? '/inspire/vendors/your-plans' : '/inspire/vendors/how-plans-work',
        isActive: isPlansMenuActive,
        items: plansLinks,
        trackingIdentifier: 'PLANS',
        dataTestId: 'your-plans',
        renderText: (
          <Fragment>
            Plans
            <div className="nav-credit-balance">{creditText}</div>
          </Fragment>
        ),
      },
    ];

    if (showBoostListing) {
      finalMenu.push({
        type: MenuType.LINK,
        text: 'Boost listing',
        to: '/inspire/vendors/boost-listing',
        isActive: (_match, location) => isActivePath(location, ['boost-listing']),
        trackingIdentifier: 'FEATURED_VENDORS',
        dataTestId: 'boost-listing',
        id: 'boost-listing-nav-item',
        tag: 'New',
      });
    }

    finalMenu.push({
      type: MenuType.DROPDOWN,
      text: 'Resources',
      to: ResourcesPageUrl,
      isActive: (_match, location) => isActivePath(location, ['resources']),
      items: [
        {
          type: MenuType.LINK,
          text: 'Resources',
          to: ResourcesPageUrl,
          trackingIdentifier: 'RESOURCES_2',
        },
        {
          type: MenuType.LINK,
          text: 'FAQs',
          to: '/faq/category/360000310271-for-wedding-vendors-professionals',
          trackingIdentifier: 'FAQs',
          external: true,
        },
      ],
      trackingIdentifier: 'RESOURCES',
      dataTestId: 'resources',
      id: 'resources-nav-item',
    });

    return finalMenu;
  }, [
    creditText,
    hasUnreadInquiry,
    listingMenu,
    plansLinks,
    publishedAt,
    showBoostListing,
    showBootListingLoaded,
  ]);

  return navMenu;
};
