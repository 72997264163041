import { useMemo } from 'react';

import useVendorUserContext from '~/hooks/vendors/useVendorUserContext';
import experimentFlags from '~/util/experimentFlags';

export const useShowBoostListing = () => {
  const { vendorUserContext, loaded } = useVendorUserContext();

  const showBoostListing = useMemo(
    () =>
      loaded &&
      vendorUserContext &&
      !experimentFlags.isUnderTest('marketplace-hide-boost-listing-link', vendorUserContext, false),
    [loaded, vendorUserContext]
  );

  return {
    loaded,
    showBoostListing,
  };
};
