import { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

/**
 * The landing page for vendor search.
 *
 * This used to be feature flagged and we controlled the landing via this constant.
 */
export const VENDOR_SEARCH_LANDING_PAGE_URL = '/wedding-vendors';

/**
 * Generates a URL for the vendor category landing page
 */
export const vendorCategoryLandingUrl = (
  vendorTaxonomyKey: SearchableVendorTaxonomyKey
): string => {
  return `${VENDOR_SEARCH_LANDING_PAGE_URL}/${vendorTaxonomyKey}`;
};
