import {
  AccountPreference,
  AccountPreferencesView,
  AccountPreferenceView,
  UpdateAccountPreferencesRequest,
} from '@zola/svc-marketplace-ts-types';

import { AppDispatch, AppThunk } from '~/reducers';
import ApiService from '~/util/apiService';

import {
  ReceivedAccountPreferences,
  receivedAccountPreferences,
  setAccountPreference,
} from './types/accountPreferencesActionTypes';

const findPref = (
  prefs: AccountPreferenceView[],
  key: AccountPreferenceView.KeyEnum
): Pick<AccountPreferenceView, 'enabled' | 'rePromptEligible'> & { madeSelection: boolean } => {
  const foundPref = prefs.find((pref) => pref.key === key);

  return {
    enabled: Boolean(foundPref?.enabled),
    rePromptEligible: foundPref ? foundPref.rePromptEligible : true,
    madeSelection: Boolean(foundPref),
  };
};

export const saveAccountPreference = (
  accountPreferences: AccountPreference[],
  ctaPlacement: UpdateAccountPreferencesRequest.CtaPlacementEnum,
  source: UpdateAccountPreferencesRequest.SourceEnum
) => {
  return (dispatch: AppDispatch) => {
    const request: Omit<UpdateAccountPreferencesRequest, 'actorObjectId'> = {
      accountPreferences,
      source,
      ctaPlacement,
    };

    return ApiService.post<
      AccountPreferencesView,
      Omit<UpdateAccountPreferencesRequest, 'actorObjectId'>
    >('/web-marketplace-api/v1/account-preferences', request).finally(() => {
      accountPreferences.forEach(({ key, enabled }) => {
        if (key !== null && enabled !== null) {
          dispatch(setAccountPreference({ key, enabled }));
        }
      });
    });
  };
};

export const loadAccountPreferences = (): AppThunk<Promise<ReceivedAccountPreferences | null>> => {
  return (dispatch) => {
    return ApiService.get<AccountPreferencesView>(
      '/web-marketplace-api/v1/account-preferences'
    ).then(({ accountPreferences }) => {
      const safeAccountPreferences = accountPreferences || [];
      const accountPreferencesResult: ReceivedAccountPreferences = {
        FIRST_MOVES_SHARE_FAVORITES: findPref(
          safeAccountPreferences,
          'FIRST_MOVES_SHARE_FAVORITES'
        ),
        FIRST_MOVES_SHARE_INQUIRIES: findPref(
          safeAccountPreferences,
          'FIRST_MOVES_SHARE_INQUIRIES'
        ),
        FIRST_MOVES_SHARE_WEDDING_DETAILS: findPref(
          safeAccountPreferences,
          'FIRST_MOVES_SHARE_WEDDING_DETAILS'
        ),
      };

      dispatch(receivedAccountPreferences(accountPreferencesResult));
      return accountPreferencesResult;
    });
  };
};
