import { toSnakeCase, toCamelCase } from '@zola-helpers/client/dist/es/transformers/keyConversion';

export { toSnakeCase, toCamelCase };

const keyConversion = {
  toSnakeCase,
  toCamelCase,
};

export default keyConversion;
