import type { AppDispatch, AppThunk, RootState } from '~/reducers';
import {
  BakerDetails,
  BeauticianDetails,
  CatererDetails,
  FloristDetails,
  MusicianDetails,
  PhotographerDetails,
  VenueDetails,
  VendorDetails,
  VideographerDetails,
  WeddingPlannerDetails,
} from '~/types/responseTypes';

export type InputValueSelectorType = (state: RootState) => number | null;
type InputValueSelectorTypeWithId = (
  state: RootState,
  storefrontId: number | undefined
) => number | null;

export type InputPersistence = {
  field: string;
  object: 'Storefront' | 'Vendor';
  convertor?: (value: number | null) => number | null;
};
/** Name and label for a field presented as a price */
export interface InputMeta {
  /** The name of the field in the form.  This is the name attribute of the input field */
  name: string;
  ariaLabel: string;
  /** Optional text to appear in the input field */
  placeHolder?: string;
  /** A selector that gives the value of this field after save or on load */
  selector: InputValueSelectorType | InputValueSelectorTypeWithId;

  /** Since this deals mostly with prices, we'll put a $ prefix on each field, unless you set this property */
  noAddon?: boolean;

  /** Where does this get persisted in our storefront of vendor */
  persistance: InputPersistence | InputPersistence[];
}

export enum PriceFieldTypes {
  FIELD = 'PriceField',
  RANGE = 'PriceRange',
  GROUP = 'PriceFieldGroup',
  AT_LEAST_ONE = 'AtLeastOne',
}

interface PriceFieldMeta {
  type:
    | PriceFieldTypes.FIELD
    | PriceFieldTypes.RANGE
    | PriceFieldTypes.GROUP
    | PriceFieldTypes.AT_LEAST_ONE;
  required?: boolean;
}

export type ToolTipType = {
  id: string;
  text: string;
};

/** Meta information a single price field "What is your starting price?", "How much do you charge for a tractor?" */
export interface PriceField extends PriceFieldMeta {
  type: PriceFieldTypes.FIELD;

  label: string;

  /** Optional tool tip that appears to the right of the legend */
  toolTip?: ToolTipType;

  input: InputMeta;
}

/** Meta information about a price range (min/max) */
export interface PriceRange extends PriceFieldMeta {
  type: PriceFieldTypes.RANGE;

  /** Optional tool tip that appears to the right of the legend */
  toolTip?: ToolTipType;

  /** Name of the range, appears above the two inputs */
  legend: string;
  inputs: {
    min: InputMeta;
    max: InputMeta;
  };
}

/** Meta information about 2 or mor fields displays next two each other */
export interface PriceFieldGroup extends PriceFieldMeta {
  type: PriceFieldTypes.GROUP;

  fields: PriceField[];
}

/**
 * A list of fields where at least one must be provided.
 *
 * Providing all is allowed.
 *
 */
export interface AtLeastOne extends PriceFieldMeta {
  type: PriceFieldTypes.AT_LEAST_ONE;

  description?: string;
  fields: PriceField[];
}

export type PriceFieldType = PriceField | PriceRange | PriceFieldGroup | AtLeastOne;

type VendorDetailsType<T extends VendorDetails> = {
  selecter: (state: RootState, storefrontId: number) => T | undefined;
  updater: (
    vendor: T
  ) => ((dispatch: AppDispatch) => Promise<unknown>) | AppThunk<Promise<unknown>>;
};
export interface VendorPricingMeta {
  fields: PriceFieldType[];
  priceLabel: {
    show: string;
    hide: string;
  };
  vendorDetails?:
    | VendorDetailsType<BakerDetails>
    | VendorDetailsType<BeauticianDetails>
    | VendorDetailsType<CatererDetails>
    | VendorDetailsType<FloristDetails>
    | VendorDetailsType<MusicianDetails>
    | VendorDetailsType<PhotographerDetails>
    | VendorDetailsType<VenueDetails>
    | VendorDetailsType<VideographerDetails>
    | VendorDetailsType<WeddingPlannerDetails>;
  calculatedStartingPrice: string;
}

export interface VendorPricingFormValues {
  receptionStartPrice?: string;
  ceremonyStartPrice?: string;
  showPrice: string;
  targetCoupleBudgetMin: number | null;
  targetCoupleBudgetMax: number | null;
  targetPerPersonBudgetMin: number | null;
  targetPerPersonBudgetMax: number | null;
}
