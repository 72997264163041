import { Fragment, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
  children: JSX.Element | React.ReactNode;
  ignoreQueryParams?: boolean;
}

export const ScrollToTop = (props: ScrollToTopProps): JSX.Element => {
  const { children, ignoreQueryParams = false } = props;
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState<string>(location.pathname);
  useEffect(() => {
    if (lastLocation !== location.pathname || !ignoreQueryParams) {
      if (window && window.scrollTo) {
        window.scrollTo(0, 0);
      }
      setLastLocation(location.pathname);
    }
  }, [ignoreQueryParams, lastLocation, location]);

  return <Fragment>{children}</Fragment>;
};

export default ScrollToTop;
