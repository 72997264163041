import { useEffect, useState } from 'react';

import { receivedVendorUserContext } from '~/actions/vendorUserContextActions';
import { useAppDispatch, useAppSelector } from '~/reducers';
import { getIsAuthenticated } from '~/selectors/vendorAccountSelectors';
import { getVendorUserContextLoaded } from '~/selectors/vendorUserContextSelectors';
import type { IUserContext } from '~/types/userContext';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';

type UserVendorContextResult = {
  vendorUserContext: IUserContext | null;
  loaded: boolean;
  error: boolean;
};

/**
 * Fetches the user context associated with a vendor account.  This user context
 * is the SSOT for experiments.
 *
 * NOTE: This should only be used once per page, or we should move this state to redux.
 * If Used multiple times per page, it will make multiple requests and each instant
 * will return a different (but same data) context.
 */
const useVendorUserContext = (): UserVendorContextResult => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const [context, setContext] = useState<IUserContext | null>(null);
  const [requested, setRequested] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (isAuthenticated && !requested) {
      setRequested(true);

      ApiService.get('/web-marketplace-api/v1/vendor-user/context')
        .then((result: IUserContext) => {
          setContext(result);
        })
        .catch((e) => {
          StandardLogFn(e);
          setContext(null);
          setError(true);
        })
        .finally(() => setLoaded(true));
    }
  }, [isAuthenticated, requested]);

  return {
    vendorUserContext: context,
    loaded,
    error,
  };
};

/**
 * Fetches the user context associated with a vendor account.  This user context
 * is the SSOT for experiments.
 *
 * This is a copy of useVendorUserContext that uses redux.
 */
export const useVendorUserContextWithRedux = () => {
  const dispatch = useAppDispatch();
  const vendorUserContextLoaded = useAppSelector(getVendorUserContextLoaded);

  useEffect(() => {
    if (!vendorUserContextLoaded) {
      ApiService.get('/web-marketplace-api/v1/vendor-user/context')
        .then((result: IUserContext) => {
          dispatch(receivedVendorUserContext(result));
        })
        .catch((e) => {
          StandardLogFn(e);
        });
    }
  }, [dispatch, vendorUserContextLoaded]);
};

export default useVendorUserContext;
