import {
  SearchableVendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import {
  InquiryUrlStatus,
  CLOSED_INQUIRY_URL_STATUS,
  READY_INQUIRY_URL_STATUS,
  BOOKED_INQUIRY_URL_STATUS,
  CONNECTED_INQUIRY_URL_STATUS,
  EXPIRED_INQUIRY_URL_STATUS,
  ACTIVE_INQUIRY_STATUSES,
  INCOMING_INQUIRY_STATUSES,
  IN_CONTACT_INQUIRY_STATUSES,
} from '~/pages/vendors/Inquiries/utils/constants';
import { InquiryStatus } from '~/types/enums';
import { FormattedInquiry, InquiryStatusBooleans } from '~/types/inquiries';
import { MappedInquiryView } from '~/types/mappedResponseTypes';
import { InquiryCloseEventEnum, CancelInquiryReasons } from '~/types/responseTypes';

interface InquiryCloseEventBooleans {
  hasDeclineCloseEvent: boolean;
  hasCancelCloseEvent: boolean;
  hasExpireCloseEvent: boolean;
  hasDismissCloseEvent: boolean;
}

export const isActive = (status?: InquiryStatus): boolean => {
  return status ? ACTIVE_INQUIRY_STATUSES.includes(status) : false;
};

export const isIncoming = (status?: InquiryStatus): boolean => {
  return status ? INCOMING_INQUIRY_STATUSES.includes(status) : false;
};

export const isInContact = (status?: InquiryStatus): boolean => {
  return status ? IN_CONTACT_INQUIRY_STATUSES.includes(status) : false;
};

export const isClosed = (status?: InquiryStatus): boolean => {
  return status === 'CLOSED';
};

/**
 * Function to return a set of status boolean values.
 * Useful if a file needs to check against a few different statuses.
 *
 * isExpired, isDismissed, isDeclined & isCanceled are not included here because specific closed
 * inquiry statuses are deprecated. instead use close events
 * (hasDeclineCloseEvent, hasCancelCloseEvent etc from inquiryCloseEventBooleans)
 * in combination with the isClosed status for conditional logic.
 */
export const inquiryStatusBooleans = (inquiryStatus?: InquiryStatus): InquiryStatusBooleans => {
  return {
    isPending: inquiryStatus === 'PENDING',
    isNew: inquiryStatus === 'NEW',
    isReady: inquiryStatus === 'READY',
    isBooked: inquiryStatus === 'BOOKED',
    isConnected: inquiryStatus === 'CONNECTED',
    isActive: isActive(inquiryStatus),
    isClosed: isClosed(inquiryStatus),
    isIncoming: isIncoming(inquiryStatus),
    isInContact: isInContact(inquiryStatus),
  };
};

type NudgedInquiry<T = MappedInquiryView | FormattedInquiry> = T & { nudgedAt: number };

export const isNudged = (
  inquiry: MappedInquiryView | FormattedInquiry
): inquiry is NudgedInquiry<MappedInquiryView | FormattedInquiry> => {
  const { nudgedAt } = inquiry;
  return Boolean(nudgedAt) && isIncoming(inquiry.status);
};

/**
 * Function to return a set of close event type boolean values.
 * Useful if a file needs to check against a few different close events.
 */
export const inquiryCloseEventBooleans = (
  closeEventType?: InquiryCloseEventEnum | null
): InquiryCloseEventBooleans => {
  return {
    hasDeclineCloseEvent: closeEventType === 'DECLINE',
    hasCancelCloseEvent: closeEventType === 'CANCEL',
    hasExpireCloseEvent: closeEventType === 'EXPIRATION',
    hasDismissCloseEvent: closeEventType === 'DISMISS',
  };
};

export const getInquiryStatusUrlSegment = (
  inquiry?: FormattedInquiry | null
): InquiryUrlStatus | null => {
  switch (inquiry?.status) {
    case 'READY':
      return READY_INQUIRY_URL_STATUS;
    case 'BOOKED':
      return BOOKED_INQUIRY_URL_STATUS;
    case 'CONNECTED':
      return CONNECTED_INQUIRY_URL_STATUS;
    case 'CLOSED':
      if (inquiry?.closeEventType === 'EXPIRATION') {
        return EXPIRED_INQUIRY_URL_STATUS;
      }
      return CLOSED_INQUIRY_URL_STATUS;
    default:
      return null;
  }
};

export type isValidStatusUrlSegmentParamType = undefined | string | null;

export const isValidStatusUrlSegment = (
  status?: isValidStatusUrlSegmentParamType
): status is InquiryUrlStatus => {
  return Boolean(
    status &&
      [
        CLOSED_INQUIRY_URL_STATUS,
        READY_INQUIRY_URL_STATUS,
        BOOKED_INQUIRY_URL_STATUS,
        CONNECTED_INQUIRY_URL_STATUS,
        EXPIRED_INQUIRY_URL_STATUS,
      ].includes(status)
  );
};

export type CancelInquiryOption = {
  label: string;
  value: CancelInquiryReasons;
};

export const CANCEL_INQUIRY_REASONS_LIST: CancelInquiryOption[] = [
  {
    label: 'They do not align with our vision',
    value: CancelInquiryReasons.NEEDS_CHANGED,
  },
  {
    label: 'We booked another vendor',
    value: CancelInquiryReasons.BOOKED_ANOTHER_VENDOR,
  },
  {
    label: 'Their pricing was out of our budget',
    value: CancelInquiryReasons.PRICING_DID_NOT_MATCH_BUDGET,
  },
  {
    label: 'They do not provide the services we need',
    value: CancelInquiryReasons.MISSING_SERVICES,
  },
  {
    label: 'We were just browsing',
    value: CancelInquiryReasons.JUST_BROWSING,
  },
  {
    label: "They haven't responded to us",
    value: CancelInquiryReasons.UNRESPONSIVE,
  },
  {
    label: 'Other',
    value: CancelInquiryReasons.OTHER,
  },
];

export interface InquiryToCancel {
  storefrontUuid: string;
  uuid: string;
  taxonomyKey?: SearchableVendorTaxonomyKey;
}

export const VENDOR_CANCEL_INQUIRY_REASONS_LIST = (
  inquiry: InquiryToCancel
): CancelInquiryOption[] => {
  const { taxonomyKey } = inquiry;

  return [
    {
      label: "They haven't responded",
      value: CancelInquiryReasons.UNRESPONSIVE,
    },
    {
      label: 'Not available on the requested date',
      value: CancelInquiryReasons.REQUESTED_DATE,
    },
    {
      label: "Their budget wasn't high enough for the requested services",
      value: CancelInquiryReasons.PRICING_DID_NOT_MATCH_BUDGET,
    },
    taxonomyKey === VENUES_TAXONOMY_KEY
      ? null
      : {
          label: "We don't serve their wedding location",
          value: CancelInquiryReasons.COUPLE_LOCATION,
        },
    {
      label: "We don't provide the requested services",
      value: CancelInquiryReasons.MISSING_SERVICES,
    },
    {
      label: 'They booked another vendor',
      value: CancelInquiryReasons.BOOKED_ANOTHER_VENDOR,
    },
    {
      label: 'Other',
      value: CancelInquiryReasons.OTHER,
    },
  ].filter((option) => Boolean(option)) as CancelInquiryOption[];
};

/**
 * user settings are used to determine if an inquiry was previously viewed
 * the format for the key is marketplace:inquiry:{uuid}:booked_reminder_dismissed
 *
 * Please do not change the prefix and suffix!
 */
const BOOKING_STATUS_REMINDER_USER_SETTINGS_KEY_PREFIX = 'marketplace:inquiry:';
const BOOKING_STATUS_REMINDER_USER_SETTINGS_KEY_SUFFIX = ':booked_reminder_dismissed';
export const getInquiryBookingStatusReminderUserSettingKey = (uuid: string): string =>
  `${BOOKING_STATUS_REMINDER_USER_SETTINGS_KEY_PREFIX}${uuid}${BOOKING_STATUS_REMINDER_USER_SETTINGS_KEY_SUFFIX}`;
