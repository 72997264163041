import {
  PARTNER_FIRST_NAME_PLACEHOLDER,
  PARTNER_LAST_NAME_PLACEHOLDER,
  USER_FIRST_NAME_PLACEHOLDER,
  USER_LAST_NAME_PLACEHOLDER,
} from './constants';

export type CouplesNameFields = {
  primaryFirstName?: string | null;
  primaryLastName?: string | null;
  partnerFirstName?: string | null;
  partnerLastName?: string | null;
};

export const arePlaceholderWeddingAccountNames = ({
  primaryFirstName,
  primaryLastName,
  partnerFirstName,
  partnerLastName,
}: CouplesNameFields): boolean => {
  const allNames = [primaryFirstName, primaryLastName, partnerFirstName, partnerLastName];

  if ([null, undefined].some((value) => allNames.every((namePart) => namePart === value))) {
    return true;
  }

  if (
    primaryFirstName === USER_FIRST_NAME_PLACEHOLDER &&
    primaryLastName === USER_LAST_NAME_PLACEHOLDER &&
    partnerFirstName === PARTNER_FIRST_NAME_PLACEHOLDER &&
    partnerLastName === PARTNER_LAST_NAME_PLACEHOLDER
  ) {
    return true;
  }

  return false;
};
