import { useState, useEffect, useRef, useCallback, useMemo } from 'react';

import { getBusinessCategory } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { NavigationClicked } from '@zola/tracking-contracts/src/events';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { CaretV2Icon } from '@zola/zola-ui/src/components/SvgIcons/CaretV2';
import { TagV2, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';

import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import { activateSubNav, hideSubNav } from '~/actions/vendors/vendorMenuActions';
import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import InquiryJewel from '~/components/common/ui/InquiryJewel';
import { useAppDispatch } from '~/reducers';
import featureFlags from '~/util/featureFlags';

import { addNextLevelTrackingProps, MenuItem, MenuLink, MenuType } from '../useNavigationLinks';

import styles from './desktopNavItem.module.less';

const HOVER_DELAY_MS = 300;
interface DesktopNavItemProps {
  item: MenuItem;
  trackingPosition: number;
}

const menuItemHasChildren = (item: MenuItem): boolean => {
  if (item.type !== MenuType.DROPDOWN) return false;
  return item.items && item.items.length > 0;
};

/**
 * Either renders with ReactRouterDom (navLink) or using a standard anchor tag.
 *
 * react-router-dom provides a reloadDocument prop that would handle this, but not
 * in the version we are on.  We need this external link treatment whenever we
 * link from the vendor nav _outside_ of the react-router-dom app.
 */
const InternalOrExternalLink = (
  props: Pick<MenuLink, 'text' | 'to' | 'isActive' | 'renderText' | 'external'> & {
    className?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>;
  }
) => {
  const { text, to, isActive, renderText, external, className, onClick, onMouseEnter } = props;

  if (external) {
    return (
      <a className={className} onMouseEnter={onMouseEnter} onClick={onClick} href={to}>
        {renderText || text}
      </a>
    );
  }
  return (
    <NavLink
      className={className}
      activeClassName={styles.activeNavLink}
      isActive={isActive}
      to={to}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {renderText || text}
    </NavLink>
  );
};

/**
 * Either a link on the navigation menu, or a drop down menu of links
 *
 */
export const DesktopNavItem = ({ item, trackingPosition }: DesktopNavItemProps): JSX.Element => {
  const {
    text,
    jewel,
    to,
    tag,
    isActive,
    /**
     *  to render more than just a string for the menu item label
     *  if passed will render the ReactElement and use text param for tracking only
     */
    renderText,
    trackingIdentifier,
  } = item;

  const hasChildren = menuItemHasChildren(item);
  const [showMenu, setShowMenu] = useState(false);
  const [allowClickToOpen, setAllowClickToOpen] = useState(true);

  const storefront = useCurrentStorefront();

  // When the menu shows, show the overlay over the page
  // The overlay close is handled at the NavMenu level, so we can move the cursor
  // along the menu without having the overlay pop in an out
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (showMenu) {
      dispatch(activateSubNav());
    }
  }, [dispatch, showMenu]);

  // This is copied from NavDropdown in web-nav@2e1afba026f9be4f723da655a63e37eed8e0e8be with some modifications

  const menuDelayTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const handleMenuOpen = useCallback(() => {
    menuDelayTimeout.current = setTimeout(() => {
      setShowMenu(true);
    }, HOVER_DELAY_MS);

    // When the mouse enters, wait 1 second before a click is allowed to open the menu
    setAllowClickToOpen(false);
    setTimeout(() => setAllowClickToOpen(true), 1000);
  }, []);

  const cancelMenuOpen = useCallback(() => {
    if (menuDelayTimeout.current) {
      clearTimeout(menuDelayTimeout.current);
    }
  }, []);

  const handleMenuClose = useCallback(() => {
    cancelMenuOpen();
    setShowMenu(false);
  }, [cancelMenuOpen]);

  const trackingProps: NavigationClicked = useMemo(() => {
    return {
      navigation_type: 'CATEGORICAL',
      business_unit: 'VENDOR_EXPERIENCE',
      business_category: storefront ? getBusinessCategory(storefront.taxonomyKey) : 'UNATTRIBUTED',

      navigation_level_1: item.trackingIdentifier,
      navigation_level_1_position: trackingPosition,
    };
  }, [item.trackingIdentifier, storefront, trackingPosition]);

  // useState / useEffect so this is only computed the one time, not on every render.
  const [subMenu, setSubMenu] = useState<JSX.Element[]>([]);
  useEffect(() => {
    const children =
      item.type === MenuType.DROPDOWN &&
      item.items.map((link, i) => {
        const { to: linkTo, trackingIdentifier: subNavTrackingIdentifier } = link as MenuLink;

        return (
          <li key={linkTo}>
            <InternalOrExternalLink
              onClick={(event) => {
                if (featureFlags.get('debugVendorNavigationClicked')) {
                  event.stopPropagation();
                  event.preventDefault();
                }
                trackNavigationClicked({
                  ...addNextLevelTrackingProps(trackingProps, subNavTrackingIdentifier, i + 1),
                  ...trackingProps,
                });
              }}
              className={styles.navLink}
              {...(link as MenuLink)}
            />
          </li>
        );
      });
    if (children) {
      setSubMenu(children);
    }
  }, [hasChildren, item, storefront, trackingIdentifier, trackingProps]);

  // This click handler is primarily for touch devices where we don't have a hover
  const toggleMenu = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (allowClickToOpen) {
        if (showMenu) {
          handleMenuClose();
          dispatch(hideSubNav());
        } else {
          setShowMenu(true); // no delay
        }
      }
    },
    [allowClickToOpen, dispatch, handleMenuClose, showMenu]
  );

  if (!hasChildren) {
    return (
      <div className={styles.navItem}>
        <InternalOrExternalLink
          className={cx(styles.navLink, styles.navLinkMenu)}
          onClick={(event) => {
            if (featureFlags.get('debugVendorNavigationClicked')) {
              event.stopPropagation();
              event.preventDefault();
            }
            trackNavigationClicked(trackingProps);
          }}
          {...item}
        />
        {tag && (
          <TagV2 className={styles.tag} variant={TagV2Variant.GREEN}>
            {tag}
          </TagV2>
        )}
        {jewel && <InquiryJewel style={{ marginBottom: '6px' }} />}
      </div>
    );
  }

  return (
    <div className={styles.navDropdown} onMouseLeave={handleMenuClose}>
      <NavLink
        className={styles.navLink}
        activeClassName={styles.activeNavLink}
        to={to}
        // Note: no tracking on expanding menus, only on clicks on the links that navigate
        onClick={toggleMenu}
        onMouseEnter={handleMenuOpen}
        isActive={isActive}
      >
        {renderText || text}
        <CaretV2Icon title="Caret" className={styles.navChevron} width={12} />
      </NavLink>
      {showMenu && (
        <ul className={styles.subNav} onClick={handleMenuClose} role="presentation">
          {subMenu}
        </ul>
      )}
    </div>
  );
};
