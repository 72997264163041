import { useEffect } from 'react';

const HUBSPOT_SRC = '//js.hs-scripts.com/20623097.js';

/* adds HubSpot code snippet if not already present */
const useHubSpotTrackingSnippet = () => {
  useEffect(() => {
    if (document?.body) {
      const el = document.getElementById('hs-script-loader');
      if (!el) {
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.id = 'hs-script-loader';
        script.src = HUBSPOT_SRC;
        script.type = 'text/javascript';

        document.body.appendChild(document.createComment('Start of HubSpot Embed Code'));
        document.body.appendChild(script);
        document.body.appendChild(document.createComment('End of HubSpot Embed Code'));
      }
    }
  }, []);
};

export default useHubSpotTrackingSnippet;
