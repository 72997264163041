import { useCallback } from 'react';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { CheckboxFieldAdapter } from '@zola/zola-ui/src/components/Form/CheckboxField';
import FinalFormInput from '@zola/zola-ui/src/components/Form/inputV3/FinalFormInput';
import { formatPhoneNumberUS } from '@zola/zola-ui/src/components/Form/util/formatPhoneNumber';
import { PHONE_US } from '@zola/zola-ui/src/components/Form/util/validations';
import ModalContent from '@zola/zola-ui/src/components/Modal/ModalContent';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import { Field, Form } from 'react-final-form';

import { requestAccountSettings } from '~/actions/vendors/vendorAccountSettingsActions';
import { useModal } from '~/contexts/ModalContext';
import { useToast } from '~/hooks/useToast';
import { useAppDispatch } from '~/reducers';
import Logger, { StandardLogFn } from '~/util/logger';

import { REQUIRED } from '../../form/commonValidations';
import { Heading } from '../../zolaUI/Typography';
import { optIntoSMSNotifications } from './helpers';

import styles from './textNotificationsModal.module.less';

export const useNotificationModal = () => {
  const { showV2PredefinedModal } = useModal();

  const showNotificationModal = useCallback(() => {
    showV2PredefinedModal(
      'TEXT_NOTIFICATIONS',
      {
        showCloseButton: false,
        closeOnEscape: false,
        closeOnOverlayClick: false,
        triggerRef: null,
        dialogHeadingId: 'dialog-heading',
      },
      {}
    );
  }, [showV2PredefinedModal]);

  return { showNotificationModal };
};

interface TextNotificationsModalProps {
  hideModalFn: () => void;
}

export interface TextNotificationsFormValues {
  phoneNumber: string;
  optedIn: boolean;
}

const TextNotificationsModal = (props: TextNotificationsModalProps) => {
  const { hideModalFn } = props;
  const { positiveToast } = useToast();
  const dispatch = useAppDispatch();

  const onSubmit = (values: TextNotificationsFormValues) => {
    optIntoSMSNotifications(values.phoneNumber)
      .then(() => {
        hideModalFn();
        positiveToast({
          headline: 'You’ve opted in! You’ll receive a text each time you get a new inquiry.',
        });
        dispatch(requestAccountSettings()).catch((error: Error) => {
          Logger.error(error);
        });
      })
      .catch(StandardLogFn);
  };

  const renderButtons = (submitting: boolean, handleSubmit: () => void) => {
    return (
      <div className={styles.modalControls}>
        <div className={styles.cancelAndSave}>
          <ButtonV3
            variant="secondary"
            disabled={submitting}
            onClick={hideModalFn}
            className={styles.cancel}
            size="large"
          >
            No thanks
          </ButtonV3>
          <ButtonV3
            onClick={handleSubmit}
            type="submit"
            disabled={submitting}
            className={styles.submit}
            size="large"
          >
            Get texts
          </ButtonV3>
        </div>
      </div>
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.form}>
            <ModalContent
              className={styles.modalContent}
              controls={renderButtons(submitting, handleSubmit as () => void)}
            >
              <Heading.H3 className={styles.header}>
                Get instant alerts for new inquiries
              </Heading.H3>
              <P.BodySmall className={styles.dek}>
                Be the first to get back to couples! You’ll get alerts for all storefronts on your
                account. Turn off texts anytime.
              </P.BodySmall>
              <FinalFormInput
                alignment="vertical"
                id="phoneNumber"
                name="phoneNumber"
                label="Phone number (U.S. only)"
                placeholder="placeholder"
                parse={formatPhoneNumberUS}
                type="tel"
                required
                className={styles.numberInput}
                validate={[REQUIRED, PHONE_US]}
              />
              <Field
                className={styles.checkbox}
                component={CheckboxFieldAdapter}
                id="CheckBox-field"
                label="I consent to receiving automated texts from Zola about my inquiries. I understand that I do not have to consent to these texts to purchase Zola's products or services, and that I can reply STOP at any time to cancel. Msg & data rates may apply."
                name="optedIn"
                type="checkbox"
                validate={REQUIRED}
              />
            </ModalContent>
          </form>
        );
      }}
    />
  );
};
export default TextNotificationsModal;
