import {
  ACCESSORIES_TAXONOMY_KEY,
  ACCOMMODATIONS_TAXONOMY_KEY,
  ALTERATIONS_PRESERVATION_TAXONOMY_KEY,
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  BRIDAL_SHOPS_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  CELEBRANTS_OFFICIANTS_TAXONOMY_KEY,
  CHILDCARE_SERVICES_TAXONOMY_KEY,
  CLOTHING_RENTALS_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  GENERAL_SERVICES_TAXONOMY_KEY,
  GIFT_FAVORS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  INVITATIONS_CALLIGRAPHY_TAXONOMY_KEY,
  JEWELERS_TAXONOMY_KEY,
  LIGHTING_AV_TAXONOMY_KEY,
  PHOTOBOOTHS_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  RENTALS_DECOR_TAXONOMY_KEY,
  SERVICE_STAFF_TAXONOMY_KEY,
  TRANSPORTATION_PROVIDERS_TAXONOMY_KEY,
  VARIETY_ACTS_TAXONOMY_KEY,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
  VendorTaxonomyKey,
  SearchableVendorTaxonomyKey,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomySwitchFunc } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import Accessories from '@zola/zola-ui/src/assets/images/icons/accessories.svg';
import AgnosticVenue from '@zola/zola-ui/src/assets/images/icons/agnostic_venue.svg';
import Alterations from '@zola/zola-ui/src/assets/images/icons/alterations.svg';
import BandsDj from '@zola/zola-ui/src/assets/images/icons/bands_dj.svg';
import Bouquet from '@zola/zola-ui/src/assets/images/icons/bouquet.svg';
import Camera from '@zola/zola-ui/src/assets/images/icons/camera.svg';
import ChampagneCheers from '@zola/zola-ui/src/assets/images/icons/champagne_cheers.svg';
import ChildcareServices from '@zola/zola-ui/src/assets/images/icons/childcare_services.svg';
import DecorInspiration from '@zola/zola-ui/src/assets/images/icons/decor_inspiration.svg';
import FashionBeauty from '@zola/zola-ui/src/assets/images/icons/fashion_beauty.svg';
import GiftPile1 from '@zola/zola-ui/src/assets/images/icons/gift_pile_1.svg';
import HairMakeup from '@zola/zola-ui/src/assets/images/icons/hair_makeup.svg';
import Invitations from '@zola/zola-ui/src/assets/images/icons/invitations.svg';
import JustMarriedCar from '@zola/zola-ui/src/assets/images/icons/just_married_car.svg';
import LightBulb from '@zola/zola-ui/src/assets/images/icons/light_bulb.svg';
import LoveBirds from '@zola/zola-ui/src/assets/images/icons/love_birds.svg';
import Mixer from '@zola/zola-ui/src/assets/images/icons/mixer.svg';
import ServiceStaff from '@zola/zola-ui/src/assets/images/icons/service_staff.svg';
import Video from '@zola/zola-ui/src/assets/images/icons/video.svg';
import WeddingCake from '@zola/zola-ui/src/assets/images/icons/wedding_cake.svg';
import WeddingChecklist from '@zola/zola-ui/src/assets/images/icons/wedding_checklist.svg';
import WeddingParty from '@zola/zola-ui/src/assets/images/icons/wedding_party.svg';
import WeddingRings from '@zola/zola-ui/src/assets/images/icons/wedding_rings.svg';
import WeddingShower from '@zola/zola-ui/src/assets/images/icons/wedding_shower.svg';
import { CreateIconComponentProps } from '@zola/zola-ui/src/components/SvgIcons/CreateIconComponent';
import { getVendorIcon as getVendorIconV2 } from '@zola/zola-ui/src/util/getVendorIcon';
import { getVendorIconV3 } from '@zola/zola-ui/src/util/getVendorIconV3';

import _capitalize from 'lodash/capitalize';
import _uniq from 'lodash/uniq';

import AgnosticVenueV2 from '~/assets/images/icons/color-refresh/agnostic-venue.svg';
import BandsDjV2 from '~/assets/images/icons/color-refresh/bands-dj.svg';
import BouquetV2 from '~/assets/images/icons/color-refresh/bouquet.svg';
import CameraV2 from '~/assets/images/icons/color-refresh/camera.svg';
import ChampagneCheersV2 from '~/assets/images/icons/color-refresh/champagne-cheers.svg';
import HairMakeupV2 from '~/assets/images/icons/color-refresh/hair-makeup.svg';
import ServiceStaffV2 from '~/assets/images/icons/color-refresh/service-staff.svg';
import VideoV2 from '~/assets/images/icons/color-refresh/video-camera.svg';
import WeddingCakeV2 from '~/assets/images/icons/color-refresh/wedding-cake.svg';
import WeddingChecklistV2 from '~/assets/images/icons/color-refresh/wedding-checklist.svg';
import {
  VenueServiceLevelChildKey,
  VenueSettingChildKey,
  VENUE_SERVICE_LEVEL_ALL_INCLUSIVE,
  VENUE_SERVICE_LEVEL_LIMITED_SERVICES,
  VENUE_SERVICE_LEVEL_RAW_SPACE,
} from '~/types/facets';
import { MappedAccountStorefront } from '~/types/mappedResponseTypes';

import { isVenueServiceLevelKey, isVenueSettingKey } from './facetUtils';
import { alphabetical } from './sortUtils';

/**
 * Get the start URL for vendor management, based on the status of the current storefront
 */
export const getVendorStartUrl = (currentStorefront?: MappedAccountStorefront | null): string => {
  if (!currentStorefront) return '/inspire/vendors/onboard';

  return currentStorefront.published ? '/inspire/vendors/dashboard' : '/inspire/vendors/listing';
};

/**
 * Get the icon for a vendor category
 *
 * @param vendorTaxonomyKey The vendor taxonomy key or "all" from the legacy search drop-down
 * @param isColorRefreshEnabled Determines whether to use the V2 (enabled) or V1 version of the icon
 * @returns The icon asset image from Zola UI
 */
export const getVendorIcon = (
  vendorTaxonomyKey?: VendorTaxonomyKey | 'all',
  isColorRefreshEnabled = false
): string => {
  if (!vendorTaxonomyKey || vendorTaxonomyKey === 'all') {
    return LoveBirds;
  }

  return getVendorTaxonomySwitchFunc({
    [ACCESSORIES_TAXONOMY_KEY]: Accessories,
    [ACCOMMODATIONS_TAXONOMY_KEY]: LoveBirds,
    [ALTERATIONS_PRESERVATION_TAXONOMY_KEY]: Alterations,
    [BANDS_DJS_TAXONOMY_KEY]: isColorRefreshEnabled ? BandsDjV2 : BandsDj,
    [BAR_SERVICES_TAXONOMY_KEY]: isColorRefreshEnabled ? ChampagneCheersV2 : ChampagneCheers,
    [BRIDAL_SHOPS_TAXONOMY_KEY]: FashionBeauty,
    [CAKES_DESSERTS_TAXONOMY_KEY]: isColorRefreshEnabled ? WeddingCakeV2 : WeddingCake,
    [CATERING_TAXONOMY_KEY]: isColorRefreshEnabled ? ServiceStaffV2 : Mixer,
    [CELEBRANTS_OFFICIANTS_TAXONOMY_KEY]: WeddingChecklist,
    [CHILDCARE_SERVICES_TAXONOMY_KEY]: ChildcareServices,
    [CLOTHING_RENTALS_TAXONOMY_KEY]: WeddingParty,
    [FLORISTS_TAXONOMY_KEY]: isColorRefreshEnabled ? BouquetV2 : Bouquet,
    [GENERAL_SERVICES_TAXONOMY_KEY]: LoveBirds,
    [GIFT_FAVORS_TAXONOMY_KEY]: GiftPile1,
    [HAIR_MAKEUP_TAXONOMY_KEY]: isColorRefreshEnabled ? HairMakeupV2 : HairMakeup,
    [INVITATIONS_CALLIGRAPHY_TAXONOMY_KEY]: Invitations,
    [JEWELERS_TAXONOMY_KEY]: WeddingRings,
    [LIGHTING_AV_TAXONOMY_KEY]: LightBulb,
    [PHOTOBOOTHS_TAXONOMY_KEY]: DecorInspiration,
    [PHOTOGRAPHERS_TAXONOMY_KEY]: isColorRefreshEnabled ? CameraV2 : Camera,
    [PLANNERS_TAXONOMY_KEY]: isColorRefreshEnabled ? WeddingChecklistV2 : WeddingChecklist,
    [RENTALS_DECOR_TAXONOMY_KEY]: WeddingShower,
    [SERVICE_STAFF_TAXONOMY_KEY]: isColorRefreshEnabled ? ServiceStaffV2 : ServiceStaff,
    [TRANSPORTATION_PROVIDERS_TAXONOMY_KEY]: JustMarriedCar,
    [VARIETY_ACTS_TAXONOMY_KEY]: LoveBirds,
    [VENUES_TAXONOMY_KEY]: isColorRefreshEnabled ? AgnosticVenueV2 : AgnosticVenue,
    [VIDEOGRAPHERS_TAXONOMY_KEY]: isColorRefreshEnabled ? VideoV2 : Video,
  })(vendorTaxonomyKey);
};
export { getVendorIconV2, getVendorIconV3 };

/**
 * Determines whether the vendor category uses a guest count
 */
export const isCategoryWithGuestCount = (
  vendorTaxonomyKey?: SearchableVendorTaxonomyKey
): boolean => {
  return vendorTaxonomyKey
    ? [
        VENUES_TAXONOMY_KEY,
        CATERING_TAXONOMY_KEY,
        BAR_SERVICES_TAXONOMY_KEY,
        CAKES_DESSERTS_TAXONOMY_KEY,
        PLANNERS_TAXONOMY_KEY,
      ].includes(vendorTaxonomyKey)
    : false;
};

/**
 * Indicates if the category either doesn't allow the portfolio or if the category has an
 * optional portfolio.
 */
export const isCategoryWithOptionalPortfolioSection = (
  vendorTaxonomyKey?: SearchableVendorTaxonomyKey
) =>
  vendorTaxonomyKey &&
  [VIDEOGRAPHERS_TAXONOMY_KEY, BANDS_DJS_TAXONOMY_KEY].includes(vendorTaxonomyKey);

const formatArray = (array: string[]) => {
  const [first, ...rest] = array;
  return [_capitalize(first), ...rest].join(' & ');
};

const VENUE_SERVICE_LEVEL_TEXT_MAP = (): Record<VenueServiceLevelChildKey, string> => {
  return {
    [VENUE_SERVICE_LEVEL_ALL_INCLUSIVE]: 'all-inclusive',
    [VENUE_SERVICE_LEVEL_LIMITED_SERVICES]: 'select services',
    [VENUE_SERVICE_LEVEL_RAW_SPACE]: 'raw space',
  };
};
const VENUE_SETTING_TEXT_MAP: Record<VenueSettingChildKey, 'indoor' | 'outdoor'> = {
  'venue-setting-indoor-space': 'indoor',
  'venue-setting-outdoor-space': 'outdoor',
  'venue-setting-covered-outdoor-space': 'outdoor',
};

export const getVenueSettingsTexts = (venueSettings: string[]): ('indoor' | 'outdoor')[] => {
  return venueSettings
    ? _uniq(
        venueSettings
          .map((item) => (isVenueSettingKey(item) ? VENUE_SETTING_TEXT_MAP[item] : undefined))
          .filter((item): item is 'indoor' | 'outdoor' => Boolean(item))
          .sort(alphabetical)
      )
    : [];
};
export const formatVenueCapacity = (maxCapacity: number | null | undefined) => {
  let displayCapacityText;
  if (maxCapacity) {
    if (maxCapacity === 1) {
      displayCapacityText = `Up to ${maxCapacity} guest`;
    } else {
      displayCapacityText = `Up to ${maxCapacity} guests`;
    }
  }
  return displayCapacityText;
};

export const formatVenueServiceLevels = (serviceLevels: string[]) => {
  const displayServiceLevels = serviceLevels
    ? serviceLevels
        .map((item) =>
          isVenueServiceLevelKey(item) ? VENUE_SERVICE_LEVEL_TEXT_MAP()[item] : undefined
        )
        .filter((item): item is VenueServiceLevelChildKey => Boolean(item))
    : [];

  return formatArray(displayServiceLevels);
};

export const formatVenueSettings = (venueSettings: string[]) => {
  const displayVenueSettings: string[] = venueSettings
    ? _uniq(
        venueSettings
          .map((item) =>
            isVenueSettingKey(item) ? (VENUE_SETTING_TEXT_MAP[item] as string) : undefined
          )
          .filter((item): item is VenueSettingChildKey => Boolean(item))
      )
    : [];

  return formatArray(displayVenueSettings);
};

export const formatVenueDetails = (
  serviceLevels: string[],
  venueSettings: string[],
  maxCapacity: number | null | undefined
) => {
  const formattedVenueSettings = formatVenueSettings(venueSettings);
  const formattedServiceLevels = formatVenueServiceLevels(serviceLevels);
  const displayCapacityText = formatVenueCapacity(maxCapacity);

  return { formattedVenueSettings, formattedServiceLevels, displayCapacityText };
};

/**
 * Percentage of total wedding budget that users spend on category
 * From Column P here: https://docs.google.com/spreadsheets/d/1NDjw9bH8cjlj7osX32BiAOHfpNDoAdmlGIuZTQAfHys/edit#gid=0
 */
export const percentSpentOnCategory = getVendorTaxonomySwitchFunc<
  SearchableVendorTaxonomyKey,
  number
>({
  [VENUES_TAXONOMY_KEY]: 24,
  [PHOTOGRAPHERS_TAXONOMY_KEY]: 7,
  [VIDEOGRAPHERS_TAXONOMY_KEY]: 5,
  [FLORISTS_TAXONOMY_KEY]: 8,
  [CATERING_TAXONOMY_KEY]: 18,
  [BANDS_DJS_TAXONOMY_KEY]: 9,
  [HAIR_MAKEUP_TAXONOMY_KEY]: 2,
  [CAKES_DESSERTS_TAXONOMY_KEY]: 2,
  [PLANNERS_TAXONOMY_KEY]: 6,
  [BAR_SERVICES_TAXONOMY_KEY]: 5,
});

export type ChipOption = {
  icon: React.FC<CreateIconComponentProps>;
  hed: string;
  dek?: string;
  value: SearchableVendorTaxonomyKey;
};

export const vendorCategoryChipOptions: ChipOption[] = [
  {
    icon: getVendorIconV3(VENUES_TAXONOMY_KEY),
    hed: 'Venues',
    value: VENUES_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(PHOTOGRAPHERS_TAXONOMY_KEY),
    hed: 'Photographers',
    value: PHOTOGRAPHERS_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(VIDEOGRAPHERS_TAXONOMY_KEY),
    hed: 'Videographers',
    value: VIDEOGRAPHERS_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(FLORISTS_TAXONOMY_KEY),
    hed: 'Florists',
    value: FLORISTS_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(CATERING_TAXONOMY_KEY),
    hed: 'Caterers',
    value: CATERING_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(CAKES_DESSERTS_TAXONOMY_KEY),
    hed: 'Bakers',
    value: CAKES_DESSERTS_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(BANDS_DJS_TAXONOMY_KEY),
    hed: 'Musicians',
    value: BANDS_DJS_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(HAIR_MAKEUP_TAXONOMY_KEY),
    hed: 'Beauty',
    value: HAIR_MAKEUP_TAXONOMY_KEY,
  },
  {
    icon: getVendorIconV3(PLANNERS_TAXONOMY_KEY),
    hed: 'Planners',
    value: PLANNERS_TAXONOMY_KEY,
  },
];
