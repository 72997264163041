import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { HeartIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Heart';

import cx from 'classnames';

import { FavButtonShapeProps } from '../types';

import styles from './buttonShapes.module.less';

export const FullWidthFavButton = (props: FavButtonShapeProps) => {
  const { onMouseEnter, onMouseLeave, isFavorited, onClick, buttonClassOverrides, className } =
    props;
  return (
    <div
      className={cx(styles.container, className)}
      data-testid="FavoriteButton"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ButtonV3
        aria-label={isFavorited ? 'Unfavorite this' : 'Favorite this'}
        className={cx(styles.buttonFull, isFavorited && styles.selected, buttonClassOverrides)}
        variant="secondary"
        size="small"
        onClick={onClick}
        fullWidth
      >
        <HeartIcon
          height={20}
          width={20}
          className={cx(styles.heartIcon, isFavorited && styles.isFavorited)}
        />
        {isFavorited ? 'Favorited' : 'Favorite'}
      </ButtonV3>
    </div>
  );
};

export default FullWidthFavButton;
