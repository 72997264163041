import { InquiryView } from '@zola/svc-marketplace-ts-types';

import { createSelector } from 'reselect';

import { RootState } from '~/reducers';
import type { ActionableInquiryView } from '~/types/responseTypes';

/**
 * Gets all the unread inquiries in the state.  You likely want one of
 * the composed selectors below instead of the raw inquiries.
 *
 * @param state
 * @returns
 */
export const getUnreadInquiries = (state: RootState): ActionableInquiryView[] =>
  state.ui.inquiryJewels.unreadInquiries;

/**
 * Gets all the unresponded inquiries in the state.  You likely want one of
 * the composed selectors below instead of the raw inquiries.
 *
 * @param state
 * @returns
 */
const getUnrespondedInquiries = (state: RootState): ActionableInquiryView[] =>
  state.ui.inquiryJewels.unrespondedInquiries;

/**
 * Returns true if there is any unread inquiry (including those in a closed state)
 */
export const hasAnyUnreadInquiry = createSelector(
  getUnreadInquiries,
  (unreadInquiries) => unreadInquiries.length > 0
);

interface ActionableInquiryLookup {
  [uuid: string]: ActionableInquiryView;
}

/**
 * A memoized lookup of unread inquiries by uuid.  Can tell if you if a specific
 * inquiry is unread.
 *
 * @example
 * const lookup = getUnreadInquiryLookup(state);
 * lookup['8410f989-c500-4896-8411-5ddb0c6dbxyz'] => undefined | ActionableInquiryView
 */
export const getUnreadInquiryLookup = createSelector(
  getUnreadInquiries,
  (unreadInquiries): ActionableInquiryLookup =>
    unreadInquiries.reduce((lookupByUuid, unreadInquiry) => {
      lookupByUuid[unreadInquiry.uuid] = unreadInquiry;
      return lookupByUuid;
    }, {} as ActionableInquiryLookup)
);

/**
 * A memoized lookup of unresponded inquiries by uuid.  Can tell if you if a specific
 * inquiry needs a response.
 *
 * @example
 * const lookup = getUnrespondedInquiryLookup(state);
 * lookup['8410f989-c500-4896-8411-5ddb0c6dbxyz'] => undefined | ActionableInquiryView
 */
export const getUnrespondedInquiryLookup = createSelector(
  getUnrespondedInquiries,
  (unrespondedInquiries): ActionableInquiryLookup =>
    unrespondedInquiries.reduce((lookupByUuid, unrespondedInquiry) => {
      lookupByUuid[unrespondedInquiry.uuid] = unrespondedInquiry;
      return lookupByUuid;
    }, {} as ActionableInquiryLookup)
);

type UnreadInquiryStatusLookup = {
  [status in InquiryView.StatusEnum]?: boolean;
};

/**
 * A memoized lookup of unread inquiry statuses.
 *
 * Can tell you if there is any inquiry in the "READY" status that is unread.
 *
 * It does not tell you which inquiry in that status is unread, just that there is one.
 *
 * @example
 * const lookup = getUnreadInquiryStatusLookup(state);
 * lookup['NEW'] => undefined
 * lookup['READY'] => true
 */
export const getUnreadInquiryStatusLookup = createSelector(getUnreadInquiries, (unreadInquiries) =>
  unreadInquiries.reduce((lookupByServerStatus, unreadInquiry) => {
    lookupByServerStatus[unreadInquiry.status] = true;
    return lookupByServerStatus;
  }, {} as UnreadInquiryStatusLookup)
);

type UnreadInquiryStatusCount = {
  [status in InquiryView.StatusEnum]?: number;
};

export const getUnreadInquiryStatusCounts = createSelector(getUnreadInquiries, (unreadInquiries) =>
  unreadInquiries.reduce((lookupByServerStatus, unreadInquiry) => {
    return {
      ...lookupByServerStatus,
      [unreadInquiry.status]: (lookupByServerStatus[unreadInquiry.status] || 0) + 1,
    };
  }, {} as UnreadInquiryStatusCount)
);

const getCrossSellEligibility = (state: RootState): boolean | null =>
  state.ui.inquiryJewels.inquiryPaperCrossSellEligible;

/**
 * Returns the backend report of paper cross-sell eligibility
 */
export const isEligibleForPaperCrossSell = createSelector(getCrossSellEligibility, (status) =>
  Boolean(status)
);
