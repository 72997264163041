import { useState } from 'react';

import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

interface UseAnyModalShownProps {
  ignoreClassName?: string;
}

/**
 * Check whether any modal has been shown on this page
 */
export const useAnyModalShown = (props?: UseAnyModalShownProps) => {
  const { ignoreClassName } = props || {};
  const [anyModalShown, setAnyModalShown] = useState(false);

  useEffectOnce(() => {
    const body = document.querySelector('body') as HTMLBodyElement;
    const observer = new MutationObserver(() => {
      const modalDialog =
        document.querySelector('[class*="modal-dialog"]') ||
        document.querySelector('[class*="ModalDialog"]');
      if (modalDialog && (!ignoreClassName || !modalDialog.className.includes(ignoreClassName))) {
        setAnyModalShown(true);
      }
    });

    observer.observe(body, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  });

  return anyModalShown;
};
