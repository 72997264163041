import { InquiryStatus } from '~/types/enums';

/** Slug / URL used for NEW inquiries that needs responses from the vendor */
export const READY_INQUIRY_URL_STATUS = 'ready';

/** Slug / URL used for inquiries that the vendor connected with */
export const CONNECTED_INQUIRY_URL_STATUS = 'connected';

/** Slug / URL used for inquiries where either the couple or the vendor marked it as booked.  Yay!!  */
export const BOOKED_INQUIRY_URL_STATUS = 'booked';

/** Slug / URL used for inquiries that are either connected or booked  */
export const CONVERSATIONS_INQUIRY_URL_STATUS = 'conversations';

/** Slug / URL used for inquiries where either the vendor declined or the couple withdrew the inquiry */
export const CLOSED_INQUIRY_URL_STATUS = 'closed';

/** Slug / URL used for interested couples via MTFM */
export const FIND_COUPLES_INTERESTED_URL_STATUS = 'find-couples/browse';

/** Slug / URL used for invited couples via MTFM */
export const FIND_COUPLES_INVITED_URL_STATUS = 'find-couples/invited';

/** Slug / URL used for inquiries that expired */
export const EXPIRED_INQUIRY_URL_STATUS = 'expired';

export const ACTIVE_INQUIRY_STATUSES: InquiryStatus[] = [
  'PENDING',
  'NEW',
  'READY',
  'BOOKED',
  'CONNECTED',
];
export const INCOMING_INQUIRY_STATUSES: InquiryStatus[] = ['PENDING', 'NEW', 'READY'];
export const IN_CONTACT_INQUIRY_STATUSES: InquiryStatus[] = ['BOOKED', 'CONNECTED'];

export const CLOSED_INQUIRY_STATUSES: InquiryStatus[] = ['CLOSED'];

export const ALL_USER_FACING_STATUSES = [...ACTIVE_INQUIRY_STATUSES, ...CLOSED_INQUIRY_STATUSES];

export const INQUIRY_INBOX_STATUSES = [
  CLOSED_INQUIRY_URL_STATUS,
  READY_INQUIRY_URL_STATUS,
  BOOKED_INQUIRY_URL_STATUS,
  CONNECTED_INQUIRY_URL_STATUS,
  CONVERSATIONS_INQUIRY_URL_STATUS,
] as const;

export const INBOX_STATUSES = [
  ...INQUIRY_INBOX_STATUSES,
  FIND_COUPLES_INTERESTED_URL_STATUS,
  FIND_COUPLES_INVITED_URL_STATUS,
];

export const PROSPECT_TAB_STATUSES = [
  FIND_COUPLES_INTERESTED_URL_STATUS,
  FIND_COUPLES_INVITED_URL_STATUS,
];
export const INQUIRY_TAB_STATUSES = [READY_INQUIRY_URL_STATUS, CLOSED_INQUIRY_URL_STATUS];
export const CONVERSATION_TAB_STATUSES = [
  CONVERSATIONS_INQUIRY_URL_STATUS,
  CONNECTED_INQUIRY_URL_STATUS,
  BOOKED_INQUIRY_URL_STATUS,
];

export type InquiryOnlyStatus = typeof INQUIRY_INBOX_STATUSES[number];

export type InquiryInboxStatus = typeof INBOX_STATUSES[number];
/*
 * The different values we use in URLs for vendor inquiries
 */
export type InquiryUrlStatus = InquiryInboxStatus | typeof EXPIRED_INQUIRY_URL_STATUS;

interface StatusMapItem {
  serverStatuses: InquiryStatus[];
}

type StatusMapForUrls = Record<InquiryInboxStatus, StatusMapItem>;
type StatusMap = Record<InquiryUrlStatus, StatusMapItem>;

const STATUS_MAP_FOR_URLS: StatusMapForUrls = {
  [READY_INQUIRY_URL_STATUS]: {
    serverStatuses: ['READY'],
  },
  [CONNECTED_INQUIRY_URL_STATUS]: {
    serverStatuses: ['CONNECTED'],
  },
  [BOOKED_INQUIRY_URL_STATUS]: {
    serverStatuses: ['BOOKED'],
  },
  [CONVERSATIONS_INQUIRY_URL_STATUS]: {
    serverStatuses: ['CONNECTED', 'BOOKED'],
  },
  [CLOSED_INQUIRY_URL_STATUS]: {
    serverStatuses: CLOSED_INQUIRY_STATUSES,
  },
  [FIND_COUPLES_INVITED_URL_STATUS]: {
    serverStatuses: [],
  },
  [FIND_COUPLES_INTERESTED_URL_STATUS]: {
    serverStatuses: [],
  },
};

/** Map a URL status to inquiry fields */
const STATUS_MAP: StatusMap = {
  ...STATUS_MAP_FOR_URLS,
  [EXPIRED_INQUIRY_URL_STATUS]: {
    serverStatuses: ['CLOSED'],
  },
};

/** Map a server status to a URL param */
const SERVER_STATUS_TO_URL: { [x: string]: InquiryUrlStatus } = {
  CLOSED: CLOSED_INQUIRY_URL_STATUS,
  BOOKED: BOOKED_INQUIRY_URL_STATUS,
  CONNECTED: CONNECTED_INQUIRY_URL_STATUS,
  READY: READY_INQUIRY_URL_STATUS,
};

const PLANNING_STAGE_MAP = {
  /* deprecated */
  NOT_YET_ENGAGED: 'Not yet engaged',
  GETTING_STARTED: 'Getting started (0-1 months in)',
  RAMPING_UP: 'Ramping up (1-3 months in)',
  IN_THE_SWING_OF_THINGS: 'In the swing of things (3-6 months in)',
  ON_A_ROLL: 'On a roll (6-9 months in)',
  FINISHING_UP: 'Finishing up (9-12 months in)',

  /* active */
  STARTING_TO_BROWSE: 'Starting to browse',
  FIGURING_OUT_BUDGET_AND_COSTS: 'Figuring out budget and costs',
  DECIDING_BETWEEN_OTHER_VENDORS: 'Deciding between vendors', // this should be dynamic (vendorType)
  READY_TO_BOOK: 'Ready to book',
};

export type InquiryTab = { slug: InquiryInboxStatus; text: string; showUnreadCount: boolean };
const INBOX_V2_TABS: InquiryTab[] = [
  {
    slug: 'ready',
    text: 'Inquiries',
    showUnreadCount: true,
  },
  {
    slug: 'connected',
    text: 'Connected',
    showUnreadCount: true,
  },
  {
    slug: 'booked',
    text: 'Booked',
    showUnreadCount: false,
  },
  {
    slug: 'closed',
    text: 'Archived',
    showUnreadCount: false,
  },
];

const INBOX_V2_TABS_WITH_MTFM: InquiryTab[] = [
  {
    slug: 'ready',
    text: 'Inquiries',
    showUnreadCount: true,
  },
  { slug: 'find-couples/browse', text: 'Find couples', showUnreadCount: true },
  {
    slug: 'connected',
    text: 'Connected',
    showUnreadCount: true,
  },
  {
    slug: 'booked',
    text: 'Booked',
    showUnreadCount: true,
  },
  {
    slug: 'closed',
    text: 'Archived',
    showUnreadCount: false,
  },
];

export type StatusOptions = {
  label: string;
  value: InquiryInboxStatus;
};

export type InquiryTabV3 = {
  key: string;
  id: string;
  title: string;
  dek: string;
  defaultStatus: InquiryInboxStatus;
  statuses: StatusOptions[];
};

export const getInboxV3Tabs = (autoSendHellosEnabled?: boolean): InquiryTabV3[] => {
  return [
    {
      key: 'mtfm',
      id: 'inbox-tab-prospects',
      title: 'Prospects',
      dek: 'Say hello to leads looking for vendors like you!',
      statuses: [
        {
          label: autoSendHellosEnabled ? 'Unsent hellos' : 'For review',
          value: FIND_COUPLES_INTERESTED_URL_STATUS,
        },
        { label: 'Hellos sent', value: FIND_COUPLES_INVITED_URL_STATUS },
      ],
      defaultStatus: autoSendHellosEnabled
        ? FIND_COUPLES_INVITED_URL_STATUS
        : FIND_COUPLES_INTERESTED_URL_STATUS,
    },
    {
      key: 'unconnected',
      id: 'inbox-tab-inquiries',
      title: 'Inquiries',
      dek: 'Reply to these leads’ inquiries',
      statuses: [
        { label: 'Active', value: READY_INQUIRY_URL_STATUS },
        { label: 'Closed', value: CLOSED_INQUIRY_URL_STATUS },
      ],
      defaultStatus: READY_INQUIRY_URL_STATUS,
    },
    {
      key: 'connected',
      id: 'inbox-tab-conversations',
      title: 'Conversations',
      dek: 'Keep chatting with leads you connected with',
      statuses: [
        { label: 'All', value: CONVERSATIONS_INQUIRY_URL_STATUS },
        { label: 'Active', value: CONNECTED_INQUIRY_URL_STATUS },
        { label: 'Booked', value: BOOKED_INQUIRY_URL_STATUS },
        // { label: 'Closed', value: BOOKED_INQUIRY_URL_STATUS }, // TODO: Add a new status for "Closed conversations"
      ],
      defaultStatus: CONVERSATIONS_INQUIRY_URL_STATUS,
    },
  ];
};

export {
  STATUS_MAP,
  SERVER_STATUS_TO_URL,
  STATUS_MAP_FOR_URLS,
  PLANNING_STAGE_MAP,
  INBOX_V2_TABS,
  INBOX_V2_TABS_WITH_MTFM,
};
