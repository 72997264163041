/* eslint-disable no-bitwise */

declare global {
  interface Window {
    msCrypto: Crypto;
  }
}

const replacer = (c: string): string => {
  const i = parseInt(c, 10);
  const crypto = window.crypto || window.msCrypto;
  return (i ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (i / 4)))).toString(16);
};

const uuidv4 = () => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, replacer);
};

export default uuidv4;
