import cx from 'classnames';

import styles from './inquiryJewel.module.less';

type InquiryJewelProps = Omit<React.HTMLAttributes<HTMLElement>, 'className'> & {
  showJewel?: boolean;
  className?: string | { [className: string]: boolean };
};

const InquiryJewel = (props: InquiryJewelProps): JSX.Element => {
  const { showJewel = true, className, ...rest } = props;

  if (showJewel) {
    return <span className={cx(styles.jewel, className)} data-testid="inquiry-jewel" {...rest} />;
  }
  return <></>;
};

export default InquiryJewel;
