import { getBusinessCategory } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { NavigationClicked } from '@zola/tracking-contracts/src/events';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

import cx from 'classnames';

import { logout } from '~/actions/vendorAccountActions';
import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import { useAppDispatch } from '~/reducers';
import featureFlags from '~/util/featureFlags';

import styles from './mobileNav.module.less';

export const LogoutButton = ({
  trackingProps,
}: {
  trackingProps: Omit<NavigationClicked, 'navigation_type' | 'business_unit' | 'business_category'>;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const storefront = useCurrentStorefront();

  const trackLogout = () => {
    trackNavigationClicked({
      business_unit: 'VENDOR_EXPERIENCE',
      business_category: storefront ? getBusinessCategory(storefront.taxonomyKey) : 'UNATTRIBUTED',
      navigation_type: 'GLOBAL',
      ...trackingProps,
    });
  };
  return (
    <li className={cx(styles.navItem, styles.logout)}>
      <ButtonV3
        variant="secondary"
        className={styles.navButton}
        onClick={() => {
          trackLogout();
          if (!featureFlags.get('debugVendorNavigationClicked')) {
            dispatch(logout()).catch(() => null);
          }
        }}
        data-testid="vendor-logout"
        size="large"
      >
        Log Out
      </ButtonV3>
    </li>
  );
};
