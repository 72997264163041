import { useEffect, useMemo } from 'react';

import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';
import { ErrorBoundary } from '@zola/zola-ui/src/components/ErrorBoundary';
import { PreauthFooterV3 } from '@zola/zola-ui/src/components/PreAuthV3';
import { useForceUpdateOnWindowResize } from '@zola/zola-ui/src/hooks';

import cx from 'classnames';
import dynamic from 'next/dynamic';
import { useLocation } from 'react-router-dom';

import { hideSubNav } from '~/actions/vendors/vendorMenuActions';
import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import FeedbackWidget from '~/components/common/ui/feedbackWidget/FeedbackWidget';
import { useModal } from '~/contexts/ModalContext';
import { useAnyModalShown } from '~/hooks/useAnyModalShown';
import { useToastsFromSessionStorage } from '~/hooks/useToastsFromLocalStorage';
import useCreditBalance from '~/hooks/vendors/useCreditBalance';
import useHubSpotTrackingSnippet from '~/hooks/vendors/useHubSpotTrackingSnippet';
import useVendorAuthenticated from '~/hooks/vendors/useVendorAuthenticated';
import ClaimCreditsModal, {
  hasDismissedFreeCreditModal,
} from '~/pages/vendors/Credits/components/ClaimCreditsModal/ClaimCreditsModal';
import { useInterestedCouplesForVendorDashboard } from '~/pages/vendors/Inquiries/hooks/useInterestedCouples';
import { useAppDispatch, useAppSelector } from '~/reducers';
import { isSubNavActive } from '~/selectors/vendors/menuSelectors';
import featureFlags from '~/util/featureFlags';
import Logger from '~/util/logger';

import { useNotificationsModal } from '../../modals/TextNotificationsModal/useNotificationsModal';
import VendorGladApp from '../../VendorGladApp/VendorGladApp';
import { NavMenu } from './Navigation/NavMenu'; // nav v2
import { NavProps } from './Navigation/types';
import useUpdateUnreadInquiries from './useUpdateUnreadInquiries';

import './VendorLayout.less';

const VendorTour = dynamic(() => import('~/pages/vendors/components/VendorTour'));

/** Check to see if the vendor has free credits to claim */
const useClaimFreeCredits = (): void => {
  const { freeCreditOfferAmount } = useCreditBalance();
  const storefront = useCurrentStorefront();
  const { showCustomModal } = useModal();

  const { name: storefrontName, uuid: storefrontUuid } = storefront || {};

  // We don't want this modal to interfere with any other.
  const anyModalShown = useAnyModalShown();

  useEffect(() => {
    const dismissedModal = hasDismissedFreeCreditModal({ storefrontUuid });

    if (storefrontUuid && freeCreditOfferAmount > 0 && !dismissedModal && !anyModalShown) {
      showCustomModal(
        ClaimCreditsModal,
        {
          storefrontUuid,
          storefrontName: storefrontName as string,
          freeCreditOfferAmount,
          hideClose: true,
        },
        {
          closeOnEsc: false,
          closeOnOverlayClick: false,
          mobileHalfSheet: true,
        }
      );
    }
  }, [anyModalShown, freeCreditOfferAmount, showCustomModal, storefrontName, storefrontUuid]);
};
interface VendorLayoutProps extends NavProps {
  id?: string;
  children?: React.ReactNode | null;
  className?: string | { [className: string]: boolean } | null;
  showFooter?: boolean;
  showFeedbackWidget?: boolean;
}

/**
 * The main vendor layout on most, but not all vendor pages.  Includes the main nav for vendors.
 */
export const VendorLayout = (props: VendorLayoutProps): JSX.Element => {
  const {
    id = '',
    children = null,
    className = null,
    showFooter = true,
    showFeedbackWidget = true,
    ...navProps
  } = props;
  const activeMenu = useAppSelector(isSubNavActive);

  useToastsFromSessionStorage();

  /* add HubSpot tracking snippet */
  useHubSpotTrackingSnippet();

  useEffect(() => {
    // This makes it so that if the mobile nav menus are active, only the mobile
    // nav menus scroll, not the document body in the background.  The 1200px width
    // here matches the breakpoint from the Mobile (badly named) nav menu and the Desktop
    // nav menu.  If this is applied on the desktop, then the nav menu at the top
    // jumps around on hover because when we fix the height of the browser, the scroll bars
    // go away, changing the document with and triggering margin:auto changes.
    if (document && document.body) {
      if (activeMenu && window.outerWidth < 1200) {
        document.body.style.maxHeight = `${window.innerHeight}px`;
      } else {
        document.body.style.maxHeight = '';
      }
    }
  }, [activeMenu]);

  const classes = useMemo(() => {
    return cx('marketplace__vendors', className);
  }, [className]);

  useVendorAuthenticated();
  useUpdateUnreadInquiries();
  useClaimFreeCredits();
  useNotificationsModal();
  useInterestedCouplesForVendorDashboard();

  // On page change, hide the sub-nav
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(hideSubNav());
  }, [dispatch, location]);

  useForceUpdateOnWindowResize();

  return (
    <ErrorBoundary Logger={Logger}>
      <div id={id} className={classes}>
        <NavMenu {...navProps} />
        {children}
      </div>
      {showFooter && (
        <div className="marketplace__vendors-footer">
          <PreauthFooterV3 isGuest />
        </div>
      )}
      <VendorTour />
      {!featureFlags.get('enableVendorGladlyChat') && isDesktop() && showFeedbackWidget && (
        <FeedbackWidget />
      )}
      <VendorGladApp />
    </ErrorBoundary>
  );
};

export default VendorLayout;
