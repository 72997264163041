import { useState } from 'react';

import { ButtonV2 } from '@zola/zola-ui/src/components/Button';

import cx from 'classnames';

import { FavButtonShapeProps } from '../types';

import styles from './buttonShapes.module.less';

export const V3FavButton = (props: FavButtonShapeProps) => {
  const { onMouseEnter, onMouseLeave, isFavorited, onClick, className } = props;

  const [animate, setAnimate] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!isFavorited) {
      setAnimate(true);
    } else {
      setAnimate(false);
    }
    onClick(e);
  };

  return (
    <div
      className={cx(styles.container, className)}
      data-testid="FavoriteButton"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ButtonV2
        aria-label={isFavorited ? 'Unfavorite this' : 'Favorite this'}
        className={cx(styles.button, styles.v3)}
        variant="transparent"
        bordered={false}
        onClick={handleClick}
      >
        <div
          className={cx(styles.heart, {
            [styles.favorited]: isFavorited,
            [styles.animate]: animate,
          })}
        />
      </ButtonV2>
    </div>
  );
};

export default V3FavButton;
