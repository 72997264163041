import { CoupleSearchListView } from '@zola/svc-marketplace-ts-types';

import {
  InterestedCoupleActionTypes,
  RECEIVED_INTERESTED_COUPLES,
} from './types/interestedCoupleActionTypes';

export const receivedInterestedCouples = (
  interestedCouples: CoupleSearchListView
): InterestedCoupleActionTypes => ({
  type: RECEIVED_INTERESTED_COUPLES,
  payload: { interestedCouplesData: interestedCouples },
});
