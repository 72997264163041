// Zola UI version of the circular button

import { ButtonV2 } from '@zola/zola-ui/src/components/Button';

import cx from 'classnames';

import { FavButtonShapeProps } from '../types';

import styles from './buttonShapes.module.less';

export const CircleZuiV3FavButton = (props: FavButtonShapeProps) => {
  const { onMouseEnter, onMouseLeave, isFavorited, onClick, buttonClassOverrides, className } =
    props;

  return (
    <div
      className={cx(styles.container, className)}
      data-testid="FavoriteButton"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ButtonV2
        aria-label={isFavorited ? 'Unfavorite this' : 'Favorite this'}
        className={cx(styles.button, styles.circleZuiV3, buttonClassOverrides)}
        variant="transparent"
        bordered={false}
        onClick={onClick}
      >
        <div className={cx(styles.heart, { [styles.favorited]: isFavorited })} />
      </ButtonV2>
    </div>
  );
};

export default CircleZuiV3FavButton;
