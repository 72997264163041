import { useEffect } from 'react';

import { deleteCookie, getCookie } from '@zola-helpers/client/dist/es/util/storage';

import { IUserContext } from '~/types/userContext';
import { addMinutes, isValidDate, parseDateUtc } from '~/util/dateUtils';

import { useUrlPathChanged } from './useUrlPathChanged';

const MARKETPLACE_SESSION_COOKIE = 'MARKETPLACE_SESSION';

const MARKETPLACE_SESSION_EXPIRATION_MINUTES = 30;

export const getMarketplaceSessionStartTime = (): Date | null => {
  const currentValue = getCookie(MARKETPLACE_SESSION_COOKIE);
  if (currentValue && isValidDate(currentValue)) {
    return parseDateUtc(currentValue);
  }

  return null;
};

/**
 * Sets the marketplace session cookie.  If the cookie was already
 * set, maintain the existing value and just refresh the expiration time.
 *
 * We'll use this cookie to keep track of a users 'session'.  If this cookie is
 * not present, then, its a new session (huzzah).  If its present, then the session
 * is 'active' or 'alive';
 *
 * The value of this cookie is the time when the session started and it expires
 * in 30 minutes (no activity for 30 minutes will be considered a new session)
 */
const refreshSessionCookie = (userContext: IUserContext | null) => {
  if (userContext) {
    const currentValue = getCookie(MARKETPLACE_SESSION_COOKIE);
    if (userContext.is_guest) {
      // If they log out, the user context becomes a guest user context, so clear the session cookie
      if (currentValue) {
        deleteCookie(MARKETPLACE_SESSION_COOKIE);
      }
    } else {
      const cookieExpiration = addMinutes(
        new Date(),
        MARKETPLACE_SESSION_EXPIRATION_MINUTES
      ).toUTCString();
      document.cookie = `${MARKETPLACE_SESSION_COOKIE}=${
        currentValue || new Date().toUTCString()
      }; expires=${cookieExpiration}; path=/`;
    }
  }
};

export const useMarketplaceSessionCookie = (userContext: IUserContext | null) => {
  const path = useUrlPathChanged();

  useEffect(() => {
    refreshSessionCookie(userContext);
  }, [userContext, path]);
};
