import { useEffect, useState } from 'react';

import { NavigationClicked } from '@zola/tracking-contracts/src/events';
import { CaretV2Icon } from '@zola/zola-ui/src/components/SvgIcons/CaretV2';

import cx from 'classnames';

import { useAppSelector } from '~/reducers';
import { isSubNavActive } from '~/selectors/vendors/menuSelectors';

import { addNextLevelTrackingProps, MenuDropdown, MenuType } from '../useNavigationLinks';
import { MobileNavLink } from './MobileNavLink';

import styles from './mobileNav.module.less';

interface MobileNavPanelProps {
  menu: MenuDropdown;

  /** Is the menu expanded or collapsed */
  isOpen: boolean;

  trackingProps: Omit<
    NavigationClicked,
    'navigation_type' | 'business_unit' | 'business_category'
  > & {
    navigation_type?: NavigationClicked['navigation_type'];
  };
}

/**
 * A sub-menu in the mobile-nav
 */
export const MobileNavPanel = ({
  menu,
  isOpen,
  trackingProps,
}: MobileNavPanelProps): JSX.Element => {
  const { items } = menu;
  return (
    <ul className={cx(styles.subNav, { [styles.activeSubNav]: isOpen })}>
      {items.map((item, index) => {
        if (item.type === MenuType.SEPARATOR) {
          return (
            <li key={`separator-${index}`} role="separator" className={styles.navSeparator}>
              <hr />
            </li>
          );
        }
        if (item.type === MenuType.HEADING) {
          return (
            <li key={`heading-${index}`} className={styles.navHeading}>
              {item.text}
            </li>
          );
        }

        return (
          <MobileNavLink
            key={`item-${index}`}
            item={item}
            trackingProps={addNextLevelTrackingProps(
              trackingProps,
              item.trackingIdentifier,
              index + 1
            )}
          />
        );
      })}
    </ul>
  );
};

interface MobileNavMenuProps {
  item: MenuDropdown;

  trackingProps: Omit<NavigationClicked, 'navigation_type' | 'business_unit' | 'business_category'>;

  /** is the menu open, we'll listen for the menu becoming inactive and reset the open state */
  expanded: boolean;
}
/**
 * A menu item that expands into a sub-menu on click/touch.
 */
export const MobileNavMenu = (props: MobileNavMenuProps): JSX.Element => {
  const { item, trackingProps, expanded } = props;
  const { text, renderText, dataTestId } = item;

  const [isOpen, setIsOpen] = useState(false);

  const onClick = (event: React.MouseEvent) => {
    setIsOpen(!isOpen);
    event.preventDefault();
    event.stopPropagation();
  };

  const activeMenu = useAppSelector(isSubNavActive);
  useEffect(() => {
    // Collapse all menus back to their initial state when the all mobile menus are closed
    // or this specific menu is closed
    if (!activeMenu || !expanded) {
      setIsOpen(false);
    }
  }, [activeMenu, expanded]);

  return (
    <li
      className={cx(styles.navItem)}
      onClick={onClick}
      role="presentation"
      data-testid={dataTestId}
    >
      <div className={cx(styles.navTitle, { [styles.activeNavTitle]: isOpen })}>
        <div className={styles.navLink}>
          <div>{renderText || text}</div>
        </div>
        <CaretV2Icon title="Caret" className={cx(styles.navChevron)} width={14} />
      </div>

      <MobileNavPanel menu={item} isOpen={isOpen} trackingProps={trackingProps} />
    </li>
  );
};
