import { UserContext } from '@zola-helpers/client/dist/es/@types';
import {
  FeatureFlagsAvailableFlagSetting,
  FeatureFlagsAvailableFlagSettings,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import getEnvironment, { EnvironmentTypes } from '@zola-helpers/client/dist/es/util/environment';
import { FeatureToggle } from '@zola/zola-ui/src/components/FeatureToggle';

import cx from 'classnames';

import { useModal } from '~/contexts/ModalContext';
import useVendorUserContext from '~/hooks/vendors/useVendorUserContext';
import { useAppSelector } from '~/reducers';
import { getIsAuthenticated, getHasStorefront } from '~/selectors/vendorAccountSelectors';
import { isSubNavActive as getSubNavActive } from '~/selectors/vendors/menuSelectors';
import featureFlags, { FLAGS } from '~/util/featureFlags';

import { NAV_IDENTIFIER } from './constants';
import { DesktopNav } from './DesktopNav/DesktopNav';
import { MobileNav } from './MobileNav/MobileNav';
import { NavProps } from './types';

import styles from './navMenu.module.less';

const productFlags = Object.keys(FLAGS).reduce((result, flag) => {
  const flagSettings: FeatureFlagsAvailableFlagSetting = {
    value: featureFlags.get(flag as keyof typeof FLAGS),
    ...FLAGS[flag as keyof typeof FLAGS],
  };

  return {
    ...result,
    [flag]: flagSettings,
  };
}, {} as FeatureFlagsAvailableFlagSettings);

/** Simple wrapper around the API call and feature toggle so we don't have extra API calls in production */
const FeatureToggleWrapper = (): JSX.Element => {
  const { vendorUserContext } = useVendorUserContext();

  return (
    <FeatureToggle
      userContext={vendorUserContext as UserContext}
      product="MARKETPLACE"
      productFlags={productFlags}
    />
  );
};

/**
 * The Vendor navigation menu
 */
export const NavMenu = ({ hideNavLinksAndMenu = false }: NavProps): JSX.Element => {
  const { showAuthModal } = useModal();
  const showVendorLoginModal = () => showAuthModal('VENDOR_LOGIN');

  const props = {
    isAuthenticated: useAppSelector(getIsAuthenticated),
    hasStorefront: useAppSelector(getHasStorefront),
    showVendorLoginModal,
    hideNavLinksAndMenu,
  };

  const showFeatureToggle = getEnvironment() !== EnvironmentTypes.PRODUCTION;

  const isSubNavActive = useAppSelector(getSubNavActive);

  return (
    <nav className={styles.vendorsNav} id={NAV_IDENTIFIER}>
      <DesktopNav {...props} />
      <MobileNav {...props} />

      {showFeatureToggle && <FeatureToggleWrapper />}

      <div
        className={cx(styles.overlay, {
          [styles.active]: isSubNavActive,
        })}
      />
    </nav>
  );
};
