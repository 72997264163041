import { createContext, useContext, useEffect, useState } from 'react';

import { getSearchedLocation } from '@zola-helpers/client/dist/es/marketplace/vendorSearchUtils';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

import { LocationOrAllMarketsType } from '~/types/responseTypes';
import { setSearchedLocation as setSearchedLocationCookie } from '~/util/searchUtils';

const SearchedLocation = createContext<{
  searchedLocation: LocationOrAllMarketsType | null;
  setSearchedLocation: (searchedLocation: LocationOrAllMarketsType | null) => void;
}>({
  searchedLocation: null,
  setSearchedLocation: () => undefined,
});

export const SearchedLocationProvider = ({
  searchedLocation: providedSelectedLocation = null,
  children,
}: {
  searchedLocation?: LocationOrAllMarketsType | null;
  children: React.ReactNode;
}) => {
  const [searchedLocation, setSearchedLocation] = useState<LocationOrAllMarketsType | null>(
    providedSelectedLocation
  );

  useEffectOnce(() => {
    if (!providedSelectedLocation) {
      setSearchedLocation(getSearchedLocation());
    }
  });

  useEffect(() => {
    if (providedSelectedLocation && providedSelectedLocation !== searchedLocation) {
      setSearchedLocation(providedSelectedLocation);
      setSearchedLocationCookie(providedSelectedLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providedSelectedLocation]);

  const handleSetSearchedLocation = (location: LocationOrAllMarketsType | null) => {
    setSearchedLocation(location);
    if (location) {
      setSearchedLocationCookie(location);
      window.dispatchEvent(new CustomEvent('SEARCHED_LOCATION_CHANGE'));
    }
  };

  return (
    <SearchedLocation.Provider
      value={{ searchedLocation, setSearchedLocation: handleSetSearchedLocation }}
    >
      {children}
    </SearchedLocation.Provider>
  );
};

export const useSearchedLocation = () => useContext(SearchedLocation);
