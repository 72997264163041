import { InquiryActivityView } from '@zola/svc-marketplace-ts-types';

import { MappedInquiryView } from '~/types/mappedResponseTypes';

export const INBOX_SORTS = [
  'name',
  'recent',
  'wedding-date',
  'tier',
  'created-at',
  'updated-at',
] as const;

export type InboxSort = typeof INBOX_SORTS[number];

export const SORT_DIRECTIONS = ['asc', 'desc'] as const;

export type SortDirection = typeof SORT_DIRECTIONS[number];

export type InquiryWithActivity = MappedInquiryView & { activity: InquiryActivityView | undefined };

export type InquiryActivityLookup = {
  [key: string]: InquiryActivityView | undefined;
};

export type InboxSortWithDirection = {
  sort: InboxSort;
  direction: SortDirection;
};

export type OverlayType = 'moved' | 'declined' | 'sent' | 'dismissed' | 'booked' | 'closed';
