import { BUSINESS_UNIT } from '@zola-helpers/client/dist/es/tracking/onboard/onboardTrackingConstants';

import { TrackingProductType, trackProductViewed } from '~/util/trackingHelper';

import {
  ADD_TOAST,
  REMOVE_TOAST_BY_ID,
  Toast,
  ToastTypes,
  AddToastAction,
} from './types/toastsActionTypes';

export const addToast = ({
  toastType,
  props,
}: {
  toastType: ToastTypes;
  props: Toast;
}): AddToastAction => {
  trackProductViewed({
    productId: toastType,
    name: 'Toast',
    productType: TrackingProductType.TOAST,
    businessUnit: BUSINESS_UNIT.REAL_WEDDINGS,
  });

  return {
    type: ADD_TOAST,
    payload: {
      toastType,
      props,
    },
  };
};

export const removeToastById = (id: string) => {
  return {
    type: REMOVE_TOAST_BY_ID,
    payload: id,
  };
};
