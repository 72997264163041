import type { Mutator } from 'final-form';

/* mutator functions for React Final Form components */

/* use this to constrain your mutator to the type/interface of your form values */
export function getMutator<T, P = T>(fn: Mutator): Mutator<T, Partial<P>> {
  return <Mutator<T, Partial<P>>>(fn as unknown);
}

export const setField: Mutator = ([field, value], state, { changeValue }): void => {
  changeValue(state, field, () => value);
};

export const clearField: Mutator = ([field], state, { changeValue }): void => {
  changeValue(state, field, () => undefined);
};

export const setFieldTouched = <FormValues>(
  [name, touched]: Parameters<Mutator<FormValues>>[0],
  state: Parameters<Mutator<FormValues>>[1]
): void => {
  const field = state.fields[name];
  if (field) {
    field.touched = !!touched;
  }
};

const formMutators = {
  clearField,
  setField,
  setFieldTouched,
};

export default formMutators;
