/**
 * CTA_ID when a a vendor or a couple closes inquiry.
 */
export const CLOSE_INQUIRY_CTA_ID = 'CLOSE_INQUIRY';

/**
 * CTA_ID when a a vendor or a couple marks an inquiry as booked
 */
export const BOOK_INQUIRY_CTA_ID = 'MARK_AS_BOOKED';

/**
 * CTA_ID when a vendor or couple moves a booked inquiry to unbooked
 */
export const UNBOOK_INQUIRY_CTA_ID = 'UNBOOK_INQUIRY';

/**
 * CTA_ID when a vendor CANCELS out of a close inquiry confirmation modal
 */
export const CANCEL_CLOSE_INQUIRY_CTA_ID = 'CANCEL';

/**
 * CTA_ID when a vendor snoozes the inquiry in the inquiry resolutions
 */
export const SNOOZE_BOOKING_REMINDER = 'SNOOZE';

export const ACCEPT_FREE_CREDITS_OFFER = 'ACCEPT_FREE_CREDITS_OFFER';
export const DISMISS_FREE_CREDITS_OFFER = 'DISMISS_FREE_CREDITS_OFFER';

// Note: not consistently used in all links
export const VIEW_CREDIT_PACKAGES_CTA_ID = 'GET_MORE_CREDITS';

/**
 *
 */
export const VIEW_INQUIRY_CTA_ID = 'VIEW_INQUIRY';

/**
 * CTA_ID when a vendor clicks on the Learn More CTA on the quick responder module
 */
export const QUICK_RESPONDER_LEARN_MORE = 'QUICK_RESPONDER_LEARN_MORE';
