import { addToast } from '~/actions/toastsActions';
import { ToastTypes } from '~/actions/types/toastsActionTypes';
import { AppDispatch } from '~/reducers';
import { TrackingProductLocation } from '~/util/trackingHelper';

type ShowVendorToastProps = {
  vendorFavoriteCount: number;
  uuid: string;
  vendorPhotoUuid?: string | null;
  showCustomInitialToasts?: boolean;
  location?: TrackingProductLocation;
};

/**
 * Show a toast when a vendor is favorited.
 *
 * This originated with real-weddings/inspiration where we wanted to let a user
 * favorite a few things before prompting for authentication. These are fancy
 * toasts the pop in the top right of the browser.
 */
export const showFavoritedVendorToast = (props: ShowVendorToastProps) => {
  const { vendorFavoriteCount, uuid, vendorPhotoUuid, showCustomInitialToasts, location } = props;

  return (dispatch: AppDispatch) => {
    if (showCustomInitialToasts && vendorFavoriteCount === 0) {
      dispatch(
        addToast({
          toastType: ToastTypes.FIRST_VENDOR_FAVORITE_TOAST,
          props: {
            id: uuid,
            location,
          },
        })
      );
    } else if (showCustomInitialToasts && vendorFavoriteCount === 1) {
      dispatch(
        addToast({
          toastType: ToastTypes.SECOND_VENDOR_FAVORITE_TOAST,
          props: {
            id: uuid,
            location,
          },
        })
      );
    } else {
      dispatch(
        addToast({
          toastType: ToastTypes.VENDOR_SAVED,
          props: {
            id: uuid,
            photoUuid: vendorPhotoUuid,
            location,
          },
        })
      );
    }
  };
};
