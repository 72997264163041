import { useState, useEffect } from 'react';

import { AccountWeddingLocationView } from '@zola/svc-marketplace-ts-types';

import _isEmpty from 'lodash/isEmpty';

import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';

/**
 * Looks up and returns a pre- or post-auth user's wedding location.
 *
 * Location will first be based on user's marketplace wedding
 * preferenceslocation, then falls back to zola user context
 * location, then falls back to ip address.
 *
 * Will not return a city/state unless there is a valid slug.
 */

const useWeddingLocation = () => {
  const [weddingLocation, setWeddingLocation] = useState<AccountWeddingLocationView | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    ApiService.get<AccountWeddingLocationView | null>(
      '/web-marketplace-api/v1/account-preferences/location'
    )
      .then((response) => {
        if (response && !_isEmpty(response)) {
          setWeddingLocation(response);
        }
      })
      .catch(StandardLogFn)
      .finally(() => setLoaded(true));
  }, []);

  return {
    weddingLocation,
    loaded,
  };
};

export default useWeddingLocation;
