import { activatingSubNav, hidingSubNav } from '~/actions/vendors/types/vendorMenuActionTypes';
import { AppDispatch, AppThunk } from '~/reducers';

export const activateSubNav = (): AppThunk<void> => {
  return (dispatch: AppDispatch) => {
    dispatch(activatingSubNav());
  };
};

export const hideSubNav = (): AppThunk<void> => {
  return (dispatch: AppDispatch) => dispatch(hidingSubNav());
};
