import cx from 'classnames';
import { SetOptional } from 'type-fest/index.d';

import styles from './headings.module.less';

type HeadingElementProps = React.HTMLAttributes<HTMLHeadingElement> & {
  component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  presentation?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  strong?: boolean;
};

type HeadingProps = SetOptional<Omit<HeadingElementProps, 'component'>, 'presentation'>;

const PresentationToStyle: Record<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6', string> = {
  h1: styles.h1,
  h2: styles.h2,
  h3: styles.h3,
  h4: styles.h4,
  h5: styles.h5,
  h6: styles.h6,
};

const HeadingElement = ({
  component: Component,
  presentation,
  className,
  strong,
  ...rest
}: HeadingElementProps): JSX.Element => {
  const classes = cx(
    styles.heading,
    PresentationToStyle[presentation || Component],
    {
      [styles.strong]: strong,
    },
    className
  );
  return <Component className={classes} {...rest} />;
};

const H1 = (props: HeadingProps) => <HeadingElement component="h1" presentation="h1" {...props} />;
const H2 = (props: HeadingProps) => <HeadingElement component="h2" presentation="h2" {...props} />;
const H3 = (props: HeadingProps) => <HeadingElement component="h3" presentation="h3" {...props} />;
const H4 = (props: HeadingProps) => <HeadingElement component="h4" presentation="h4" {...props} />;
const H5 = (props: HeadingProps) => <HeadingElement component="h5" presentation="h5" {...props} />;
const H6 = (props: HeadingProps) => <HeadingElement component="h6" presentation="h6" {...props} />;

/**
 * @deprecated Replaced with Zola UI typography components
 */
const Headings = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Base: HeadingElement,
};

export default Headings;
