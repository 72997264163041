import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { fetchUnreadStorefrontInquiries } from '~/actions/inquiryJewelActions';
import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import { useAppDispatch } from '~/reducers';

/**
 * Fetches unread inquiries whenever the URL or storefront changes
 */
const useUpdateUnreadInquiries = (): void => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { uuid } = useCurrentStorefront() || {};

  useEffect(() => {
    if (uuid) {
      dispatch(fetchUnreadStorefrontInquiries(uuid)).catch(() => null);
    }
  }, [location, dispatch, uuid]);
};

export default useUpdateUnreadInquiries;
