import { StorefrontActivePromoView, CreditBalanceView } from '@zola/svc-marketplace-ts-types';

import _isEmpty from 'lodash/isEmpty';

import { useAppSelector } from '~/reducers';
import { getCurrentStorefrontUuid } from '~/selectors/vendorAccountSelectors';
import {
  getStorefrontCredits,
  getStorefrontPromoCodes,
} from '~/selectors/vendors/creditsSelectors';

export type UseCreditBalanceResult =
  | CreditBalanceView & {
      loaded: boolean;
      hasPromos: boolean;
      promos: StorefrontActivePromoView[];
    };

const useCreditBalance = (): UseCreditBalanceResult => {
  const balance = useAppSelector(getStorefrontCredits);
  const promos = useAppSelector(getStorefrontPromoCodes);
  const storefrontUuid = useAppSelector(getCurrentStorefrontUuid) as string;

  if (!balance || _isEmpty(balance) || promos === undefined) {
    // If we failed the load the balance, something has gone very wrong
    // We should always have the credit balance for the storefront
    //
    // However, strictly looking at
    // the types and the code, because a lookup by uuid is performed, there
    // is, according to the typescript compiler, a possibility that the
    // credit balance is undefined.  Well, instead of passing that down
    // stream and making everywhere that destructures an object check loaded
    // first, we just provide some defaults here.  Loaded still needs
    // to be checked, but having these defaults here stops further TS errors
    return {
      loaded: false,
      lowBalance: false,
      balance: 0,
      freeCreditOfferAmount: 0,
      discountActiveUntil: null,
      promos: [],
      hasPromos: false,
      storefrontUuid,
      autoConnect: false,
      autoRenew: false,
      freeTrialEndsAt: null,
      paidPlanStartsAt: null,
      paidPlanEndsAt: null,
      canPurchaseCredits: false,
      canPurchaseDiscounts: false,
    };
  }
  return {
    ...balance,
    promos: promos || [],
    hasPromos: Boolean(promos && promos.length > 0),
    loaded: true,
  };
};

export default useCreditBalance;
