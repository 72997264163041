export type CatererOptionFacetParentKeys =
  | 'food-beverage-offered'
  | 'caterer-pricing-structure'
  | 'target-couple-venue-preference'
  | 'caterer-dietary-restrictions'
  | 'caterer-extra-costs'
  | 'menu-formats-offered'
  | 'caterer-drink-types'
  | 'caterers-cuisine-types'
  | 'caterer-menus-food-services'
  | 'food-beverage-services'
  | 'menu-types'
  | 'event-types'
  | 'beverages-related-services'
  | 'caterer-pricing-variables'
  | 'caterer-general-services'
  | 'caterer-included-in-price';

export type PhotographyOptionFacetParentKeys =
  | 'photography-style'
  | 'photo-format'
  | 'target-couple-venue-preference'
  | 'personality'
  | 'hours-offered'
  | 'package-deliverables-offered'
  | 'working-style'
  | 'package-components-services-offered'
  | 'event-types';

export type MusicianOptionFacetParentKeys =
  | 'musician-type'
  | 'musician-performers'
  | 'musician-staff-available'
  | 'musician-services'
  | 'musician-category'
  | 'music-solo-acts'
  | 'musician-vibe'
  | 'music-band-acts'
  | 'music-genre'
  | 'music-djs'
  | 'event-types';

export type VenueOptionFacetParentKeys =
  | 'target-couple-venue-preference'
  | 'menu-options'
  | 'transportation-services'
  | 'planner-required'
  | 'venue-service-level'
  | 'rental-services'
  | 'venue-setting'
  | 'venue-guest-emotion'
  | 'pricing-structure'
  | 'services-offered'
  | 'accommodations-services'
  | 'event-types';

export type VideographyOptionFacetParentKeys =
  | 'target-couple-venue-preference'
  | 'personality'
  | 'hours-offered'
  | 'videographer-a-la-carte-options'
  | 'videographer-package-components'
  | 'videography-style'
  | 'videographer-deliverables'
  | 'event-types';

export type PlannerOptionFacetParentKeys =
  | 'planner-service-level'
  | 'planner-personality'
  | 'planner-package-components'
  | 'length-of-time'
  | 'event-types';

export type FloristOptionFacetParentKeys =
  | 'florist-services-post-wedding'
  | 'target-couple-venue-preference'
  | 'florist-ethical-areas'
  | 'florist-working-style'
  | 'florist-style'
  | 'florist-service-level'
  | 'florist-pricing-structure'
  | 'florist-floral-arrangement'
  | 'florist-arrangement-types'
  | 'florist-services-pre-wedding'
  | 'florist-services-during-wedding';

export type HairAndBeautyOptionFacetParentKeys =
  | 'beauty-services-makeup'
  | 'beauty-services-spa'
  | 'hair-beauty-service-category'
  | 'beauty-services-hair'
  | 'beauty-services-henna'
  | 'hair-beauty-type'
  | 'beauty-services-styling'
  | 'hair-beauty-service-location'
  | 'hair-beauty-style'
  | 'beauty-services-general'
  | 'beauty-services-fitness-wellness';

export type CakesAndDessertsOptionFacetParentKeys =
  | 'bakers-product-types'
  | 'target-couple-venue-preference'
  | 'bakers-services'
  | 'bakers-cake-customizations'
  | 'bakers-dietary-accommodations'
  | 'bakers-decorations';

export type FacetParentKey =
  | CatererOptionFacetParentKeys
  | PhotographyOptionFacetParentKeys
  | VenueOptionFacetParentKeys
  | VideographyOptionFacetParentKeys
  | PlannerOptionFacetParentKeys
  | FloristOptionFacetParentKeys
  | MusicianOptionFacetParentKeys
  | CakesAndDessertsOptionFacetParentKeys
  | HairAndBeautyOptionFacetParentKeys;

// WeddingVenues

export type TargetCoupleVenuePreferenceChildKey =
  | 'target-couple-venue-preference-ballrooms-banquet-halls'
  | 'target-couple-venue-preference-farms-barns-ranches'
  | 'target-couple-venue-preference-parks-gardens'
  | 'target-couple-venue-preference-historic-estates-mansions'
  | 'target-couple-venue-preference-urban-spaces'
  | 'target-couple-venue-preference-hotels-inns-resorts'
  | 'target-couple-venue-preference-industrial-buildings'
  | 'target-couple-venue-preference-restaurants-breweries'
  | 'target-couple-venue-preference-lodges-retreats'
  | 'target-couple-venue-preference-beaches-waterfronts'
  | 'target-couple-venue-preference-golf-country-clubs'
  | 'target-couple-venue-preference-museums-galleries'
  | 'target-couple-venue-preference-vineyards-wineries'
  | 'target-couple-venue-preference-zoos';

export type MenuOptionsChildKey =
  | 'menu-options-a-la-carte-items'
  | 'menu-options-byob-available'
  | 'menu-options-beer-wine-only'
  | 'menu-options-buffet-dinner'
  | 'menu-options-champagne-toast'
  | 'menu-options-cocktail-hour'
  | 'menu-options-coffee-station'
  | 'menu-options-dessert-station'
  | 'menu-options-four-course-dinner'
  | 'menu-options-plated-desserts'
  | 'menu-options-premium-shelf'
  | 'menu-options-seated-dinner'
  | 'menu-options-tableside-wines-service'
  | 'menu-options-three-course-dinner'
  | 'menu-options-wedding-cake';

export type TransportationServicesChildKey =
  | 'transportation-services-limo'
  | 'transportation-services-self-parking-free'
  | 'transportation-services-self-parking-paid'
  | 'transportation-services-shuttle'
  | 'transportation-services-valet-free'
  | 'transportation-services-valet-paid'
  | 'transportation-services-wheelchair-accessible';

export type PlannerRequiredChildKey =
  | 'planner-required-no'
  | 'planner-required-no-preference'
  | 'planner-required-yes';

export const VENUE_SERVICE_LEVEL_ALL_INCLUSIVE = 'venue-service-level-all-inclusive' as const;
export const VENUE_SERVICE_LEVEL_LIMITED_SERVICES = 'venue-service-level-limited-services' as const;
export const VENUE_SERVICE_LEVEL_RAW_SPACE = 'venue-service-level-raw-space' as const;

export const VENUE_SERVICE_LEVEL_KEYS = [
  VENUE_SERVICE_LEVEL_ALL_INCLUSIVE,
  VENUE_SERVICE_LEVEL_LIMITED_SERVICES,
  VENUE_SERVICE_LEVEL_RAW_SPACE,
] as const;

export type VenueServiceLevelChildKey = typeof VENUE_SERVICE_LEVEL_KEYS[number];

export type RentalServicesChildKey =
  | 'rental-services-barware'
  | 'rental-services-chiavari-chairs'
  | 'rental-services-china'
  | 'rental-services-flatware'
  | 'rental-services-glassware'
  | 'rental-services-lighting'
  | 'rental-services-linens'
  | 'rental-services-sound-av'
  | 'rental-services-standard-chairs';

export const VENUE_SETTING_COVERED_OUTDOOR_SPACE = 'venue-setting-covered-outdoor-space' as const;
export const VENUE_SETTING_INDOOR_SPACE = 'venue-setting-indoor-space' as const;
export const VENUE_SETTING_OUTDOOR_SPACE = 'venue-setting-outdoor-space' as const;

export const VENUE_SETTING_KEYS = [
  VENUE_SETTING_COVERED_OUTDOOR_SPACE,
  VENUE_SETTING_INDOOR_SPACE,
  VENUE_SETTING_OUTDOOR_SPACE,
] as const;

export type VenueSettingChildKey = typeof VENUE_SETTING_KEYS[number];

export type VenueGuestEmotionChildKey =
  | 'venue-guest-emotion-fun-and-festive'
  | 'venue-guest-emotion-luxurious-and-chic'
  | 'venue-guest-emotion-relaxed-and-casual'
  | 'venue-guest-emotion-warm-and-cozy';

export type PricingStructureChildKey =
  | 'pricing-structure-price-for-time'
  | 'pricing-structure-price-per-person';

export type ServicesOfferedChildKey =
  | 'services-offered-bar-services'
  | 'services-offered-catering-services'
  | 'services-offered-clean-up'
  | 'services-offered-dance-floor'
  | 'services-offered-dressing-room-bridal-suite'
  | 'services-offered-event-coordinator'
  | 'services-offered-event-rentals'
  | 'services-offered-event-staff'
  | 'services-offered-liability-insurance'
  | 'services-offered-lighting-sound'
  | 'services-offered-on-site-accomodations'
  | 'services-offered-pet-friendly'
  | 'services-offered-service-staff'
  | 'services-offered-set-up'
  | 'services-offered-transportation'
  | 'services-offered-wedding-cake-services'
  | 'services-offered-wheelchair-accessible'
  | 'services-offered-wifi';

export type AccommodationsServicesChildKey =
  | 'accommodations-services-bedroom-suites'
  | 'accommodations-services-double-bed-rooms'
  | 'accommodations-services-honeymoon-suite'
  | 'accommodations-services-luxury-suites'
  | 'accommodations-services-room-blocks-offered'
  | 'accommodations-services-single-king-rooms'
  | 'accommodations-services-single-queen-rooms'
  | 'accommodations-services-twin-bed-rooms';

export type EventTypesChildKey =
  | 'event-types-after-hours-events'
  | 'event-types-baraat'
  | 'event-types-cocktail-hour'
  | 'event-types-couples-luncheon'
  | 'event-types-elopement'
  | 'event-types-engagement-celebration'
  | 'event-types-farewell-brunch'
  | 'event-types-other'
  | 'event-types-rehearsal-dinner'
  | 'event-types-ceremony'
  | 'event-types-reception'
  | 'event-types-bridal-showers'
  | 'event-types-welcome-party';

// WeddingCatering

export type FoodBeverageOfferedChildKey =
  | 'food-beverage-offered-alcoholic-beverages'
  | 'food-beverage-offered-bar-services'
  | 'food-beverage-offered-food'
  | 'food-beverage-offered-non-alcoholic-beverages';

export type CatererPricingStructureChildKey =
  | 'caterer-pricing-structure-minimum'
  | 'caterer-pricing-structure-per-person-all-inclusive'
  | 'caterer-pricing-structure-per-person-food-only';

export type CatererDietaryRestrictionsChildKey =
  | 'caterer-dietary-restrictions-dairy-free'
  | 'caterer-dietary-restrictions-gluten-free'
  | 'caterer-dietary-restrictions-halal'
  | 'caterer-dietary-restrictions-kosher'
  | 'caterer-dietary-restrictions-nut-free'
  | 'caterer-dietary-restrictions-organic'
  | 'caterer-dietary-restrictions-vegan'
  | 'caterer-dietary-restrictions-vegetarian';

export type CatererExtraCostsChildKey =
  | 'caterer-extra-costs-administration-fee'
  | 'caterer-extra-costs-bartender-services'
  | 'caterer-extra-costs-ceremony-fee'
  | 'caterer-extra-costs-consultation-tasting'
  | 'caterer-extra-costs-corkage-fee'
  | 'caterer-extra-costs-extra-hour-of-service'
  | 'caterer-extra-costs-gratuity'
  | 'caterer-extra-costs-liquor-license'
  | 'caterer-extra-costs-rentals-beverage-ware'
  | 'caterer-extra-costs-rentals-dining-ware'
  | 'caterer-extra-costs-rentals-furniture'
  | 'caterer-extra-costs-rentals-linens'
  | 'caterer-extra-costs-service-fee'
  | 'caterer-extra-costs-wedding-cake-and-dessert-service';

export type MenuFormatsOfferedChildKey =
  | 'menu-formats-offered-breakfast-brunch'
  | 'menu-formats-offered-buffet'
  | 'menu-formats-offered-dessert-service'
  | 'menu-formats-offered-family-style-meal'
  | 'menu-formats-offered-food-stations'
  | 'menu-formats-offered-food-trucks'
  | 'menu-formats-offered-passed-appetizers'
  | 'menu-formats-offered-seated-meal'
  | 'menu-formats-offered-stationary-appetizers'
  | 'menu-formats-offered-wedding-cake-services';

export type CatererDrinkTypesChildKey =
  | 'caterer-drink-types-beer'
  | 'caterer-drink-types-wine'
  | 'caterer-drink-types-liquor'
  | 'caterer-drink-types-non-alcoholic-beverages'
  | 'caterer-drink-types-signature-cocktails'
  | 'caterer-drink-types-mobile-bar'
  | 'caterer-drink-types-coffee-service';

export type CaterersCuisineTypesChildKey =
  | 'caterers-cuisine-types-african'
  | 'caterers-cuisine-types-american'
  | 'caterers-cuisine-types-bbq'
  | 'caterers-cuisine-types-cajun'
  | 'caterers-cuisine-types-chinese'
  | 'caterers-cuisine-types-eastern-european'
  | 'caterers-cuisine-types-farm-to-table'
  | 'caterers-cuisine-types-french'
  | 'caterers-cuisine-types-fusion'
  | 'caterers-cuisine-types-greek'
  | 'caterers-cuisine-types-hawaiian'
  | 'caterers-cuisine-types-indian'
  | 'caterers-cuisine-types-italian'
  | 'caterers-cuisine-types-japanese'
  | 'caterers-cuisine-types-korean'
  | 'caterers-cuisine-types-latin-american'
  | 'caterers-cuisine-types-mexican'
  | 'caterers-cuisine-types-middle-eastern'
  | 'caterers-cuisine-types-seafood'
  | 'caterers-cuisine-types-southern'
  | 'caterers-cuisine-types-southwestern'
  | 'caterers-cuisine-types-spanish'
  | 'caterers-cuisine-types-thai';

export type CatererMenusFoodServicesChildKey =
  | 'caterer-menus-food-services-alcoholic-beverages'
  | 'caterer-menus-food-services-buffet'
  | 'caterer-menus-food-services-dessert-service'
  | 'caterer-menus-food-services-family-style-meal'
  | 'caterer-menus-food-services-station-stationary-appetizers'
  | 'caterer-menus-food-services-non-alcoholic-beverages'
  | 'caterer-menus-food-services-passed-appetizers'
  | 'caterer-menus-food-services-seated-meal'
  | 'caterer-menus-food-services-wedding-cake-service';

export type FoodBeverageServicesChildKey =
  | 'food-beverage-services-bar-only'
  | 'food-beverage-services-food-only'
  | 'food-beverage-services-food-and-bar';

export type MenuTypesChildKey = 'menu-types-custom-menus' | 'menu-types-packages';

export type BeveragesRelatedServicesChildKey =
  | 'beverages-related-services-bartender-services'
  | 'beverages-related-services-beverage-serving-ware-rentals'
  | 'beverages-related-services-mixers-non-alcoholic-beverages'
  | 'beverages-related-services-provides-liquor-license';

export type CatererPricingVariablesChildKey =
  | 'caterer-pricing-variables-alcohol-type'
  | 'caterer-pricing-variables-event-duration'
  | 'caterer-pricing-variables-guest-count'
  | 'caterer-pricing-variables-meal-type';

export type CatererGeneralServicesChildKey =
  | 'caterer-general-services-bartenders'
  | 'caterer-general-services-cleanup-and-breakdown'
  | 'caterer-general-services-consultations-tastings-complimentary'
  | 'caterer-general-services-consultations-tastings-for-a-fee'
  | 'caterer-general-services-delivery-and-setup'
  | 'caterer-general-services-event-planner'
  | 'caterer-general-services-rental-coordination-couple-invoice'
  | 'caterer-general-services-rental-coordination-no-couple-invoice'
  | 'caterer-general-services-serving-staff';

export type CatererIncludedInPriceChildKey =
  | 'caterer-included-in-price-bartender'
  | 'caterer-included-in-price-chef'
  | 'caterer-included-in-price-clean-up-trash-removal'
  | 'caterer-included-in-price-gratuity'
  | 'caterer-included-in-price-rentals'
  | 'caterer-included-in-price-service-captain'
  | 'caterer-included-in-price-service-staff'
  | 'caterer-included-in-price-setup-breakdown';

// WeddingPhotographers

export type PhotographyStyleChildKey =
  | 'photography-style-classic'
  | 'photography-style-editorial'
  | 'photography-style-fine-art'
  | 'photography-style-photojournalistic';

export type PhotoFormatChildKey =
  | 'photo-format-digital'
  | 'photo-format-film'
  | 'photo-format-hybrid';

export type PersonalityChildKey =
  | 'personality-authoritative'
  | 'personality-easygoing'
  | 'personality-lively'
  | 'personality-reserved';

export type HoursOfferedChildKey =
  | 'hours-offered-10-hours'
  | 'hours-offered-6-hours'
  | 'hours-offered-7-hours'
  | 'hours-offered-8-hours'
  | 'hours-offered-9-hours';

export type PackageDeliverablesOfferedChildKey =
  | 'package-deliverables-offered-digital-files'
  | 'package-deliverables-offered-digital-rights'
  | 'package-deliverables-offered-online-gallery'
  | 'package-deliverables-offered-photo-box'
  | 'package-deliverables-offered-printed-enlargements'
  | 'package-deliverables-offered-preview-album'
  | 'package-deliverables-offered-slideshow'
  | 'package-deliverables-offered-video'
  | 'package-deliverables-offered-wedding-album';

export type WorkingStyleChildKey =
  | 'working-style-blends-into-background'
  | 'working-style-takes-charge';

export type PackageComponentsServicesOfferedChildKey =
  | 'package-components-services-offered-portrait-shoot'
  | 'package-components-services-offered-drone'
  | 'package-components-services-offered-engagement-shoot'
  | 'package-components-services-offered-extra-hours'
  | 'package-components-services-offered-image-editing'
  | 'package-components-services-offered-online-proofing'
  | 'package-components-services-offered-printing-rights'
  | 'package-components-services-offered-same-day-edits'
  | 'package-components-services-offered-second-shooter';

// WeddingCakesDesserts

export type BakersProductTypesChildKey =
  | 'bakers-product-types-cakes'
  | 'bakers-product-types-candy-and-confections'
  | 'bakers-product-types-cookies-and-macarons'
  | 'bakers-product-types-cupcakes'
  | 'bakers-product-types-donuts'
  | 'bakers-product-types-ice-cream-and-frozen-desserts'
  | 'bakers-product-types-international-desserts'
  | 'bakers-product-types-mini-desserts'
  | 'bakers-product-types-pastries'
  | 'bakers-product-types-pies'
  | 'bakers-product-types-puddings-and-custards'
  | 'bakers-product-types-novelties-and-other';

export type BakersServicesChildKey =
  | 'bakers-services-bars-and-buffets'
  | 'bakers-services-coffee-and-tea-service'
  | 'bakers-services-consultations'
  | 'bakers-services-delivery'
  | 'bakers-services-dessert-installations'
  | 'bakers-services-service-staff'
  | 'bakers-services-setup'
  | 'bakers-services-tastings'
  | 'bakers-services-trucks-and-stands';

export type BakersCakeCustomizationsChildKey =
  | 'bakers-cake-customizations-cake-filling'
  | 'bakers-cake-customizations-cake-flavors'
  | 'bakers-cake-customizations-cake-frosting';

export type BakersDietaryAccommodationsChildKey =
  | 'bakers-dietary-accommodations-dairy-free'
  | 'bakers-dietary-accommodations-gluten-free'
  | 'bakers-dietary-accommodations-halal'
  | 'bakers-dietary-accommodations-kosher'
  | 'bakers-dietary-accommodations-nut-free'
  | 'bakers-dietary-accommodations-sugar-free'
  | 'bakers-dietary-accommodations-vegan'
  | 'bakers-dietary-accommodations-vegetarian';

export type BakersDecorationsChildKey =
  | 'bakers-decorations-3d-sugar-work'
  | 'bakers-decorations-cake-toppers'
  | 'bakers-decorations-custom-text'
  | 'bakers-decorations-fruit-topping'
  | 'bakers-decorations-live-flowers'
  | 'bakers-decorations-sugar-flowers';

// WeddingVideographers

export type VideographerALaCarteOptionsChildKey =
  | 'videographer-a-la-carte-options-price-per-album'
  | 'videographer-a-la-carte-options-price-per-hour'
  | 'videographer-a-la-carte-options-price-per-print';

export type VideographerPackageComponentsChildKey =
  | 'videographer-package-components-16mm-films'
  | 'videographer-package-components-drone-coverage'
  | 'videographer-package-components-engagement-reel'
  | 'videographer-package-components-extra-hours'
  | 'videographer-package-components-livestream-services'
  | 'videographer-package-components-next-day-editing'
  | 'videographer-package-components-same-day-editing';

export type VideographyStyleChildKey =
  | 'videography-style-cinematic'
  | 'videography-style-classic'
  | 'videography-style-documentary'
  | 'videography-style-storytelling'
  | 'videography-style-vintage';

export type VideographerDeliverablesChildKey =
  | 'videographer-deliverables-dvd-or-blue-ray'
  | 'videographer-deliverables-highlight-reel'
  | 'videographer-deliverables-long-feature'
  | 'videographer-deliverables-raw-footage'
  | 'videographer-deliverables-short-feature'
  | 'videographer-deliverables-social-media-sharing'
  | 'videographer-deliverables-trailer'
  | 'videographer-deliverables-usb';

// WeddingFlorists

export type FloristServicesPostWeddingChildKey =
  | 'florist-services-clean-up-breakdown'
  | 'florist-services-floral-preservation';

export type FloristEthicalAreasChildKey =
  | 'florist-ethical-areas-chemical-free-sourcing'
  | 'florist-ethical-areas-fair-trade-sourcing'
  | 'florist-ethical-areas-foam-free'
  | 'florist-ethical-areas-green-breakdown'
  | 'florist-ethical-areas-reusable-recyclable-materials'
  | 'florist-ethical-areas-seasonal-sourcing'
  | 'florist-ethical-areas-support-local-farms';

export type FloristWorkingStyleChildKey =
  | 'florist-working-style-couple-has-creative-control'
  | 'florist-working-style-designers-choice'
  | 'florist-working-style-in-the-middle';

export type FloristStyleChildKey =
  | 'florist-style-bohemian'
  | 'florist-style-garden-inspired'
  | 'florist-style-classic'
  | 'florist-style-modern'
  | 'florist-style-romantic'
  | 'florist-style-alternative-non-traditional';

export type FloristServiceLevelChildKey =
  | 'florist-service-level-a-la-carte'
  | 'florist-service-level-full-service-floral-design'
  | 'florist-service-level-wholesale';

export type FloristPricingStructureChildKey =
  | 'florist-pricing-structure-price-per-arrangement'
  | 'florist-pricing-structure-minimum';

export type FloristFloralArrangementChildKey =
  | 'florist-floral-arrangement-bouquets'
  | 'florist-floral-arrangement-boutonnieres'
  | 'florist-floral-arrangement-cake-flowers'
  | 'florist-floral-arrangement-centerpieces'
  | 'florist-floral-arrangement-ceremony-decor'
  | 'florist-floral-arrangement-flower-baskets'
  | 'florist-floral-arrangement-flower-crowns-hair-pieces'
  | 'florist-floral-arrangement-floral-jewelry'
  | 'florist-floral-arrangement-installations'
  | 'florist-floral-arrangement-table-runners';

export type FloristArrangementTypesChildKey =
  | 'florist-arrangement-types-non-flowering-to-be-the-star'
  | 'florist-arrangement-types-flowers-to-be-the-star'
  | 'florist-arrangement-types-non-florals-to-be-the-star';

export type FloristServicesPreWeddingChildKey =
  | 'florist-services-a-la-carte'
  | 'florist-services-consultation'
  | 'florist-services-event-design'
  | 'florist-services-mock-ups'
  | 'florist-services-venue-visits'
  | 'florist-services-wholesale-bulk-flowers';

export type FloristServicesDuringWeddingChildKey =
  | 'florist-services-container-vessel-rentals'
  | 'florist-services-day-of-coordination'
  | 'florist-services-decor-rentals'
  | 'florist-services-delivery'
  | 'florist-services-furniture-rentals'
  | 'florist-services-setup'
  | 'florist-services-structural-rentals';

// WeddingPlanners

export type PlannerServiceLevelChildKey =
  | 'planner-service-level-day-of-coordination'
  | 'planner-service-level-partial-planning'
  | 'planner-service-level-full-service'
  | 'planner-service-level-event-design'
  | 'planner-service-level-destination-wedding'
  | 'planner-service-level-elopement'
  | 'planner-service-level-a-la-carte';

export type PlannerPersonalityChildKey =
  | 'planner-personality-calm'
  | 'planner-personality-collaborative'
  | 'planner-personality-confident'
  | 'planner-personality-creative'
  | 'planner-personality-efficient'
  | 'planner-personality-empathetic'
  | 'planner-personality-flexible'
  | 'planner-personality-fun'
  | 'planner-personality-honest'
  | 'planner-personality-innovative'
  | 'planner-personality-nurturing'
  | 'planner-personality-organized'
  | 'planner-personality-positive'
  | 'planner-personality-practical'
  | 'planner-personality-reassuring'
  | 'planner-personality-resourceful'
  | 'planner-personality-supportive'
  | 'planner-personality-thoughtful'
  | 'planner-personality-whimsical';

export type PlannerPackageComponentsChildKey =
  | 'planner-package-components-accommodation-coordination'
  | 'planner-package-components-budget-planning'
  | 'planner-package-components-contract-review'
  | 'planner-package-components-event-styling'
  | 'planner-package-components-final-venue-walkthrough'
  | 'planner-package-components-invites'
  | 'planner-package-components-onsite-event-management'
  | 'planner-package-components-rsvp-tracking'
  | 'planner-package-components-rentals'
  | 'planner-package-components-timeline-management'
  | 'planner-package-components-transportation-coordination'
  | 'planner-package-components-vendor-management'
  | 'planner-package-components-vendor-selection'
  | 'planner-package-components-venue-recommendations'
  | 'planner-package-components-venue-visits'
  | 'planner-package-components-wedding-favors'
  | 'planner-package-components-wedding-website';

export type LengthOfTimeChildKey =
  | 'length-of-time-1-month'
  | 'length-of-time-12-months'
  | 'length-of-time-3-months'
  | 'length-of-time-6-months'
  | 'length-of-time-9-months'
  | 'length-of-time-day-only';

// WeddingBandsDjs

export type MusicianTypeChildKey =
  | 'musician-type-ceremony-only-musician'
  | 'musician-type-dj'
  | 'musician-type-live-band-or-ensemble'
  | 'musician-type-live-solo-performer'
  | 'musician-type-production-company';

export type MusicianPerformersChildKey =
  | 'musician-performers-5-8-person-band'
  | 'musician-performers-8-plus-person-band'
  | 'musician-performers-duo-trio'
  | 'musician-performers-quartet-person-band'
  | 'musician-performers-solo-act';

export type MusicianStaffAvailableChildKey =
  | 'musician-staff-available-djs'
  | 'musician-staff-available-emcees'
  | 'musician-staff-available-stage-manager';

export type MusicianServicesChildKey =
  | 'musician-services-band-backdrop'
  | 'musician-services-ceremony-microphones-and-sound'
  | 'musician-services-confetti'
  | 'musician-services-dance-floor'
  | 'musician-services-dance-floor-special-effects'
  | 'musician-services-lighting'
  | 'musician-services-event-planning'
  | 'musician-services-fog-snow-machines'
  | 'musician-services-hear-us-live'
  | 'musician-services-learning-new-songs'
  | 'musician-services-master-of-ceremonies'
  | 'musician-services-multimedia-displays'
  | 'musician-services-multiple-location-support'
  | 'musician-services-music-during-breaks'
  | 'musician-services-parade-baraat'
  | 'musician-services-photobooth'
  | 'musician-services-projector-equipment'
  | 'musician-services-signs'
  | 'musician-services-sound-system'
  | 'musician-services-sound-system-capability-greater-than-300'
  | 'musician-services-sparklers'
  | 'musician-services-stage-design'
  | 'musician-services-uplighting';

export type MusicianCategoryChildKey =
  | 'musician-category-brass-ensemble'
  | 'musician-category-choir'
  | 'musician-category-guitarist'
  | 'musician-category-harpist'
  | 'musician-category-pianist'
  | 'musician-category-string-ensemble'
  | 'musician-category-vocalist';

export type MusicSoloActsChildKey =
  | 'music-solo-acts-solo-instrumentalist'
  | 'music-solo-acts-solo-singer';

export type MusicianVibeChildKey =
  | 'musician-vibe-chill'
  | 'musician-vibe-energetic'
  | 'musician-vibe-go-with-the-flow';

export type MusicBandActsChildKey =
  | 'music-band-acts-mixed-chamber-ensemble'
  | 'music-band-acts-multi-piece-live-band'
  | 'music-band-acts-single-instrument-type-ensemble';

export type MusicGenreChildKey =
  | 'music-genre-americana'
  | 'music-genre-arabic-persian'
  | 'music-genre-blues'
  | 'music-genre-bollywood-bhangra'
  | 'music-genre-classical'
  | 'music-genre-country-folk'
  | 'music-genre-funk'
  | 'music-genre-edm-house'
  | 'music-genre-gospel-christian'
  | 'music-genre-jazz'
  | 'music-genre-latin'
  | 'music-genre-motown-soul'
  | 'music-genre-pop-top-40s'
  | 'music-genre-r-and-b'
  | 'music-genre-rap-hip-hop'
  | 'music-genre-reggae'
  | 'music-genre-rock-and-roll'
  | 'music-genre-scottish-irish';

export type MusicDjsChildKey = 'music-djs-group-of-djs' | 'music-djs-solo-dj';

// WeddingHairMakeup

export type BeautyServicesMakeupChildKey =
  | 'beauty-services-makeup-airbrushing'
  | 'beauty-services-makeup-body-art'
  | 'beauty-services-makeup-skin-tone-matching'
  | 'beauty-services-makeup-hypoallergenic'
  | 'beauty-services-makeup-trials'
  | 'beauty-services-makeup-natural'
  | 'beauty-services-makeup-tattoo-coverage';

export type BeautyServicesSpaChildKey =
  | 'beauty-services-spa-facials-esthetician'
  | 'beauty-services-spa-manicure-pedicure'
  | 'beauty-services-spa-massage'
  | 'beauty-services-spa-nail-art'
  | 'beauty-services-spa-tanning'
  | 'beauty-services-spa-teeth-whitening';

export type HairBeautyServiceCategoryChildKey =
  | 'hair-beauty-service-category-hair'
  | 'hair-beauty-service-category-makeup'
  | 'hair-beauty-service-category-styling'
  | 'hair-beauty-service-category-spa-treatments'
  | 'hair-beauty-service-category-henna-art'
  | 'hair-beauty-service-category-fitness-wellness';

export type BeautyServicesHairChildKey =
  | 'beauty-services-hair-blowouts'
  | 'beauty-services-hair-locs'
  | 'beauty-services-hair-natural-black-hair'
  | 'beauty-services-hair-accessories'
  | 'beauty-services-hair-extension-application'
  | 'beauty-services-hair-extension-color-matching'
  | 'beauty-services-hair-trials'
  | 'beauty-services-hair-short-hair'
  | 'beauty-services-hair-extensions'
  | 'beauty-services-hair-updos';

export type BeautyServicesHennaChildKey =
  | 'beauty-services-henna-african'
  | 'beauty-services-henna-arabic'
  | 'beauty-services-henna-colored-glam'
  | 'beauty-services-henna-engagement-nikah'
  | 'beauty-services-henna-glitter-glam'
  | 'beauty-services-henna-indian'
  | 'beauty-services-henna-indo-arabic'
  | 'beauty-services-henna-pakistani'
  | 'beauty-services-henna-private'
  | 'beauty-services-henna-sangeet-mehndi'
  | 'beauty-services-henna-wedding';

export type HairBeautyTypeChildKey =
  | 'hair-beauty-type-individual-hair-makeup-artist'
  | 'hair-beauty-type-hair-makeup-studio';

export type BeautyServicesStylingChildKey =
  | 'beauty-services-styling-day-of-coordination'
  | 'beauty-services-styling-personal-shopping'
  | 'beauty-services-styling-coordination-other-events';

export type HairBeautyServiceLocationChildKey =
  | 'hair-beauty-service-location-other'
  | 'hair-beauty-service-location-studio-salon'
  | 'hair-beauty-service-location-venue';

export type HairBeautyStyleChildKey =
  | 'hair-beauty-style-classic'
  | 'hair-beauty-style-dramatic'
  | 'hair-beauty-style-glamorous'
  | 'hair-beauty-style-natural'
  | 'hair-beauty-style-romantic'
  | 'hair-beauty-style-trendy';

export type BeautyServicesGeneralChildKey =
  | 'beauty-services-general-touchups'
  | 'beauty-services-general-children'
  | 'beauty-services-general-dupatta-sari-draping'
  | 'beauty-services-general-multiday-events'
  | 'beauty-services-general-pre-wedding-prep';

export type BeautyServicesFitnessWellnessChildKey =
  | 'beauty-services-fitness-wellness-group-classes'
  | 'beauty-services-fitness-wellness-nutrition'
  | 'beauty-services-fitness-wellness-private-training';

export type FacetChildKey =
  | TargetCoupleVenuePreferenceChildKey
  | MenuOptionsChildKey
  | TransportationServicesChildKey
  | PlannerRequiredChildKey
  | VenueServiceLevelChildKey
  | RentalServicesChildKey
  | VenueSettingChildKey
  | VenueGuestEmotionChildKey
  | PricingStructureChildKey
  | ServicesOfferedChildKey
  | AccommodationsServicesChildKey
  | EventTypesChildKey
  | FoodBeverageOfferedChildKey
  | CatererPricingStructureChildKey
  | TargetCoupleVenuePreferenceChildKey
  | CatererDietaryRestrictionsChildKey
  | CatererExtraCostsChildKey
  | MenuFormatsOfferedChildKey
  | CatererDrinkTypesChildKey
  | CaterersCuisineTypesChildKey
  | CatererMenusFoodServicesChildKey
  | FoodBeverageServicesChildKey
  | MenuTypesChildKey
  | EventTypesChildKey
  | BeveragesRelatedServicesChildKey
  | CatererPricingVariablesChildKey
  | CatererGeneralServicesChildKey
  | CatererIncludedInPriceChildKey
  | PhotographyStyleChildKey
  | PhotoFormatChildKey
  | TargetCoupleVenuePreferenceChildKey
  | PersonalityChildKey
  | HoursOfferedChildKey
  | PackageDeliverablesOfferedChildKey
  | WorkingStyleChildKey
  | PackageComponentsServicesOfferedChildKey
  | EventTypesChildKey
  | BakersProductTypesChildKey
  | TargetCoupleVenuePreferenceChildKey
  | BakersServicesChildKey
  | BakersCakeCustomizationsChildKey
  | BakersDietaryAccommodationsChildKey
  | BakersDecorationsChildKey
  | TargetCoupleVenuePreferenceChildKey
  | PersonalityChildKey
  | HoursOfferedChildKey
  | VideographerALaCarteOptionsChildKey
  | VideographerPackageComponentsChildKey
  | VideographyStyleChildKey
  | VideographerDeliverablesChildKey
  | EventTypesChildKey
  | FloristServicesPostWeddingChildKey
  | TargetCoupleVenuePreferenceChildKey
  | FloristEthicalAreasChildKey
  | FloristWorkingStyleChildKey
  | FloristStyleChildKey
  | FloristServiceLevelChildKey
  | FloristPricingStructureChildKey
  | FloristFloralArrangementChildKey
  | FloristArrangementTypesChildKey
  | FloristServicesPreWeddingChildKey
  | FloristServicesDuringWeddingChildKey
  | PlannerServiceLevelChildKey
  | PlannerPersonalityChildKey
  | PlannerPackageComponentsChildKey
  | LengthOfTimeChildKey
  | EventTypesChildKey
  | MusicianTypeChildKey
  | MusicianPerformersChildKey
  | MusicianStaffAvailableChildKey
  | MusicianServicesChildKey
  | MusicianCategoryChildKey
  | MusicSoloActsChildKey
  | MusicianVibeChildKey
  | MusicBandActsChildKey
  | MusicGenreChildKey
  | MusicDjsChildKey
  | EventTypesChildKey
  | BeautyServicesMakeupChildKey
  | BeautyServicesSpaChildKey
  | HairBeautyServiceCategoryChildKey
  | BeautyServicesHairChildKey
  | BeautyServicesHennaChildKey
  | HairBeautyTypeChildKey
  | BeautyServicesStylingChildKey
  | HairBeautyServiceLocationChildKey
  | HairBeautyStyleChildKey
  | BeautyServicesGeneralChildKey
  | BeautyServicesFitnessWellnessChildKey
  | CatererDrinkTypesChildKey;
