import _uniq from 'lodash/uniq';

import { CardData, WeddingEventType, WeddingEventTypeGroup } from '../../types';

interface EventCardData extends CardData<WeddingEventTypeGroup> {
  facetKeys: WeddingEventType[];
  value: WeddingEventTypeGroup;
}

export const EVENTS_DATA: EventCardData[] = [
  {
    text: 'Engagement',
    value: 'ENGAGEMENT',
    facetKeys: [WeddingEventType.CELEBRATION],
  },
  {
    text: 'Pre-Wedding',
    subtext: 'Couples luncheon, rehearsal dinner, shower, welcome party',
    value: 'PRE_WEDDING',
    facetKeys: [
      WeddingEventType.LUNCHEON,
      WeddingEventType.DINNER,
      WeddingEventType.BRIDAL_SHOWERS,
      WeddingEventType.WELCOME_PARTY,
    ],
  },
  {
    text: 'Day Of',
    subtext: 'Baraat, ceremony, cocktail hour, reception',
    value: 'DAY_OF',
    facetKeys: [
      WeddingEventType.BARAAT,
      WeddingEventType.CEREMONY,
      WeddingEventType.COCKTAIL_HOUR,
      WeddingEventType.RECEPTION,
    ],
  },
  {
    text: 'Post-Wedding',
    subtext: 'After party, farewell brunch',
    value: 'POST_WEDDING',
    facetKeys: [WeddingEventType.AFTER_HOURS, WeddingEventType.FAREWELL_BRUNCH],
  },
  {
    text: 'Elopement',
    value: 'ELOPEMENT',
    facetKeys: [WeddingEventType.ELOPEMENT],
  },
  {
    text: 'Other',
    value: 'OTHER',
    facetKeys: [WeddingEventType.OTHER],
  },
];
type WeddingEventTypeDescriptionLookup = {
  [key in WeddingEventTypeGroup]: string | undefined;
};

export const EVENTS_DESCRIPTIONS: WeddingEventTypeDescriptionLookup = EVENTS_DATA.reduce(
  (result, weddingEventTypeGroup) => {
    return {
      ...result,
      [weddingEventTypeGroup.value]: weddingEventTypeGroup.subtext,
    };
  },
  {} as WeddingEventTypeDescriptionLookup
);

type WeddingEventTypeTextLookup = {
  [key in WeddingEventTypeGroup]: string;
};

export const EVENTS_TEXTS: WeddingEventTypeTextLookup = EVENTS_DATA.reduce(
  (result, weddingEventTypeGroup) => {
    return {
      ...result,
      [weddingEventTypeGroup.value]: weddingEventTypeGroup.text,
    };
  },
  {} as WeddingEventTypeTextLookup
);

/* break down higher-level selections into their facet options */
export const expandEventTypeGroup = (group: WeddingEventTypeGroup): WeddingEventType[] => {
  const data = EVENTS_DATA.find((eventData) => eventData.value === group);
  if (data) {
    return data.facetKeys;
  }
  return [];
};

const compressEventType = (key: WeddingEventType): WeddingEventTypeGroup => {
  const data = EVENTS_DATA.find((eventData) => eventData.facetKeys.includes(key));
  if (data) {
    return data.value;
  }

  return 'OTHER';
};

export const compressEventTypes = (keys: WeddingEventType[] = []): WeddingEventTypeGroup[] => {
  const results = keys.map((key) => compressEventType(key));

  return _uniq(results);
};
