// title is used for redux-form, and label is for react-final
export const stateOptionList = [
  { value: 'AK', title: 'AK', label: 'AK' },
  { value: 'AL', title: 'AL', label: 'AL' },
  { value: 'AR', title: 'AR', label: 'AR' },
  { value: 'AZ', title: 'AZ', label: 'AZ' },
  { value: 'CA', title: 'CA', label: 'CA' },
  { value: 'CO', title: 'CO', label: 'CO' },
  { value: 'CT', title: 'CT', label: 'CT' },
  { value: 'DC', title: 'DC', label: 'DC' },
  { value: 'DE', title: 'DE', label: 'DE' },
  { value: 'FL', title: 'FL', label: 'FL' },
  { value: 'GA', title: 'GA', label: 'GA' },
  { value: 'HI', title: 'HI', label: 'HI' },
  { value: 'IA', title: 'IA', label: 'IA' },
  { value: 'ID', title: 'ID', label: 'ID' },
  { value: 'IL', title: 'IL', label: 'IL' },
  { value: 'IN', title: 'IN', label: 'IN' },
  { value: 'KS', title: 'KS', label: 'KS' },
  { value: 'KY', title: 'KY', label: 'KY' },
  { value: 'LA', title: 'LA', label: 'LA' },
  { value: 'MA', title: 'MA', label: 'MA' },
  { value: 'MD', title: 'MD', label: 'MD' },
  { value: 'ME', title: 'ME', label: 'ME' },
  { value: 'MI', title: 'MI', label: 'MI' },
  { value: 'MN', title: 'MN', label: 'MN' },
  { value: 'MO', title: 'MO', label: 'MO' },
  { value: 'MS', title: 'MS', label: 'MS' },
  { value: 'MT', title: 'MT', label: 'MT' },
  { value: 'NC', title: 'NC', label: 'NC' },
  { value: 'ND', title: 'ND', label: 'ND' },
  { value: 'NE', title: 'NE', label: 'NE' },
  { value: 'NH', title: 'NH', label: 'NH' },
  { value: 'NJ', title: 'NJ', label: 'NJ' },
  { value: 'NM', title: 'NM', label: 'NM' },
  { value: 'NV', title: 'NV', label: 'NV' },
  { value: 'NY', title: 'NY', label: 'NY' },
  { value: 'OH', title: 'OH', label: 'OH' },
  { value: 'OK', title: 'OK', label: 'OK' },
  { value: 'OR', title: 'OR', label: 'OR' },
  { value: 'PA', title: 'PA', label: 'PA' },
  { value: 'RI', title: 'RI', label: 'RI' },
  { value: 'SC', title: 'SC', label: 'SC' },
  { value: 'SD', title: 'SD', label: 'SD' },
  { value: 'TN', title: 'TN', label: 'TN' },
  { value: 'TX', title: 'TX', label: 'TX' },
  { value: 'UT', title: 'UT', label: 'UT' },
  { value: 'VA', title: 'VA', label: 'VA' },
  { value: 'VT', title: 'VT', label: 'VT' },
  { value: 'WA', title: 'WA', label: 'WA' },
  { value: 'WI', title: 'WI', label: 'WI' },
  { value: 'WV', title: 'WV', label: 'WV' },
  { value: 'WY', title: 'WY', label: 'WY' },
];
