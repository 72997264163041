import { PrefixSearchRequest } from '@zola/svc-marketplace-ts-types';

import { MappedVendorSearchResult } from '~/types/mappedResponseTypes';
import { VendorTaxonomyNodeId } from '~/types/types';

import ApiService from '../util/apiService';
import Logger from '../util/logger';

export const getVendorSuggestions = (prefix: string): Promise<MappedVendorSearchResult[]> => {
  return ApiService.post<MappedVendorSearchResult[], PrefixSearchRequest>(
    '/web-marketplace-api/v1/vendor-search/name-prefix-query',
    { prefix }
  )
    .then((json) => {
      return json;
    })
    .catch((error) => {
      Logger.error(error.message, error);
      throw error;
    });
};

// This doesn't seem to be defined in the types file for some reason
type ListReferenceVendorByCategoryRequest = {
  // eslint-disable-next-line camelcase
  category_id: number;
  prefix: string | null;
};

export const getVendorSuggestionsForCategory = (
  prefix: string,
  categoryId: VendorTaxonomyNodeId
): Promise<MappedVendorSearchResult[]> => {
  return ApiService.post<MappedVendorSearchResult[], ListReferenceVendorByCategoryRequest>(
    '/web-marketplace-api/v1/vendor-search/list-by-category',
    {
      prefix,

      // This is going through the node layer w/o case conversion
      category_id: categoryId,
    }
  )
    .then((json) => {
      return json;
    })
    .catch((error) => {
      Logger.error(error.message, error);
      throw error;
    });
};
