import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import { useAppSelector } from '~/reducers';
import { getCurrentStorefront } from '~/selectors/vendorStorefrontSelectors';

/**
 * Gets the current storefront for the VENDOR.  This is not for use on the
 * couples side.
 *
 * @returns The storefront in redux at `vendorStorefront.storefrontDetails`
 */
const useCurrentStorefront = () => {
  return useAppSelector(getCurrentStorefront);
};

export const useCurrentVenueStorefront = () => {
  const storefront = useCurrentStorefront();

  if (storefront?.taxonomyKey === VENUES_TAXONOMY_KEY) {
    return storefront;
  }
  return null;
};
export default useCurrentStorefront;
