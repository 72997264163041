import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import type { SecondaryNavItem } from '@zola-helpers/client/dist/es/constants/navConstants';
import type { NavItem } from '@zola-helpers/client/dist/es/nav/types';
import { isClient } from '@zola-helpers/client/dist/es/util/nextUtils';

type InitNavDataOptions = {
  userContext: UserContext | null;
  secondaryData?: SecondaryNavItem[] | NavItem[];
  tertiaryData?: SecondaryNavItem[] | NavItem[];
  quickLinks?: SecondaryNavItem[];
  activePrimaryLink?: string;
  activeSecondaryLink?: string;
  activeTertiaryLink?: string;
  disablePrimaryNavCollapse?: boolean;
  hideBanner?: boolean;
};

const initNavData = ({
  userContext,
  secondaryData,
  tertiaryData,
  quickLinks,
  activePrimaryLink,
  activeSecondaryLink,
  activeTertiaryLink,
  disablePrimaryNavCollapse = false,
  hideBanner = false,
}: InitNavDataOptions) => {
  if (isClient()) {
    window.dispatchEvent(
      new CustomEvent('INIT_NAV_DATA', {
        detail: {
          userContext,
          secondaryData,
          tertiaryData,
          quickLinks,
          activePrimaryLink,
          activeSecondaryLink,
          activeTertiaryLink,
          disablePrimaryNavCollapse,
          hideBanner,
        },
      })
    );
  }
};

export default initNavData;
