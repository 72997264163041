import { useEffect } from 'react';

import { getCurrentUser } from '~/actions/vendorAccountActions';
import { useAppDispatch, useAppSelector } from '~/reducers';
import { getIsAuthenticated, getIsIdTokenValid } from '~/selectors/vendorAccountSelectors';

/**
 * Check vendor authentication
 */
const useVendorAuthenticated = (): void => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const isIdTokenValid = useAppSelector(getIsIdTokenValid);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!isAuthenticated || !isIdTokenValid) {
      dispatch(getCurrentUser()).catch(() => null);
    }
  }, [dispatch, isAuthenticated, isIdTokenValid]);
};

export default useVendorAuthenticated;
