import type { AddressView } from '@zola/svc-marketplace-ts-types';

import _capitalize from 'lodash/capitalize';
import _isInteger from 'lodash/isInteger';
import _isString from 'lodash/isString';
import _startCase from 'lodash/startCase';

import {
  addDays,
  formatTimeAgo,
  isToday,
  DateValue,
  formatDateUtc,
  isValidDate,
  formatDate,
  isAfterDate,
} from '~/util/dateUtils';
import { formatWithCommas, titleCase } from '~/util/textUtils';

/** turns a number into a string suffixed with ' Credits'
 *
 * @example formatCreditCount(5); // '5 credits'
 * @example formatCreditCount(5,000); // '5,000 credits'
 *
 */
export const formatCreditCount = (number: number): string => {
  let count = '0';
  let term = 'credits';

  if (typeof number !== 'undefined') {
    count = formatWithCommas(number);
  }

  if (number === 1) {
    term = 'credit';
  }

  return `${count} ${term}`;
};

/** returns formatted couple name
 *
 * @example formatCoupleName('Sally'); // 'Sally'
 * @example formatCoupleName('Sally', 'Larry'); // 'Sally & Larry'
 * @example formatCoupleName('sally jane', 'larry'); // 'Sally Jane & Larry'
 */
export const formatCoupleName = (firstName?: string | null, secondName?: string | null): string => {
  // first name option is weird, but it's technically correct, there are inquiries without first names in staging
  let name = titleCase(firstName || '');
  if (secondName) name += ` & ${titleCase(secondName)}`;
  return name;
};

interface FormattedCoupleNameProps {
  primaryFirstName: string | null; // probably shouldn't allow null, but it happened on QA
  primaryLastName: string | null;
  partnerFirstName: string | null;
  partnerLastName: string | null;
  withNonBreakingSpaces?: boolean;
}

export const formatNameWithInitial = (
  firstName: string | null,
  lastName: string | null,
  withNonBreakingSpaces = false
): string | null => {
  if (firstName === null) return null;

  const lastInitial = (lastName ? lastName[0] : '').toLocaleUpperCase();
  const firstNameAndLastInitial = `${titleCase(firstName)} ${lastInitial}`.trim();

  if (withNonBreakingSpaces) {
    const nonBreakingSpace = '\u00A0';
    return firstNameAndLastInitial.replace(/\s+/g, nonBreakingSpace);
  }

  return firstNameAndLastInitial;
};

/**
 * Formats a couple name (primary & partner) as first names with last initials
 *
 * Optionally, names can be formatted with non-breaking spaces
 *
 * @example formatCoupleNameWithInitials(inquiry) => "Michelle R"
 * @example formatCoupleNameWithInitials(inquiry) => "Michelle R & Barak O"
 */
export const formatCoupleNameWithInitials = (props: FormattedCoupleNameProps): string => {
  const {
    primaryFirstName,
    primaryLastName,
    partnerFirstName,
    partnerLastName,
    withNonBreakingSpaces = false,
  } = props;

  const primaryName = formatNameWithInitial(
    primaryFirstName,
    primaryLastName,
    withNonBreakingSpaces
  );
  const partnerName = formatNameWithInitial(
    partnerFirstName,
    partnerLastName,
    withNonBreakingSpaces
  );

  if (partnerName) {
    return `${primaryName} & ${partnerName}`;
  }

  return primaryName || '';
};

/**
 * Formats a couples first names as initials
 *
 * @example formatCouplesFirstInitials({primaryFirstName: 'Michelle'}) => "M"
 * @example formatCouplesFirstInitials({primaryFirstName: 'Michelle', partnerFirstName: 'Barak'}) => "M & B"
 */
export const formatCoupleFirstInitials = (props: {
  primaryFirstName?: string | null; // null seems unlikely, but it did happen on QA
  partnerFirstName?: string | null;
}): string => {
  const { primaryFirstName, partnerFirstName } = props;

  const parts: string[] = [(primaryFirstName || '').trim()[0], (partnerFirstName || '').trim()[0]];
  return parts.filter((x: string) => Boolean(x)).join(' & ');
};

type DateFormatter = (date?: DateValue | null) => string | null;

/** Returns the date as MMM D, YYYY using the UTC timezone
 *
 * @example
 * formatEventDate(new Date('2020-01-10T00:00:00.000Z')) // Jan 10, 2020
 * formatEventDate('2020-01-10T00:00:00.000Z') // Jan 10, 2020
 * formatEventDate(date) // Feb 8, 2021
 *
 */
export const formatEventDate: DateFormatter = (date) => {
  return date && isValidDate(date) ? formatDateUtc(date, 'MMM D, YYYY') : null;
};

/** Returns the date as M/D/YY (ddd) using the UTC timezone
 *
 * @example formatEventDateShort(new Date('2020-01-10T00:00:00.000Z')) // 1/10/20 (Fri)
 *
 */
export const formatEventDateShort: DateFormatter = (date) => {
  return date && isValidDate(date) ? formatDateUtc(date, 'M/D/YY (ddd)') : null;
};

/** Returns the date as M/D/YY using the UTC timezone
 *
 * @example formatEventDateVeryShort(new Date('2020-01-10T00:00:00.000Z')) // 1/10/20
 *
 */
export const formatEventDateVeryShort: DateFormatter = (date) => {
  return date && isValidDate(date) ? formatDateUtc(date, 'M/D/YY') : null;
};

/** Returns the date as MMM/YYYY (Jan/2020) using the UTC timezone
 *
 * @example formatEventMonthYear(new Date('2020-01-10T00:00:00.000Z')) // Jan/2020
 *
 */
export const formatEventMonthYear: DateFormatter = (date) => {
  return date && isValidDate(date) ? formatDateUtc(date, 'MMM/YYYY') : null;
};

/**
 * Returns the date as MMMM YYYY (January 2020) using the UTC timezone
 *
 * @example
 * formatEventMonthYearLong(new Date('2020-01-10T00:00:00.000Z')) // 'January 2020'
 * formatEventMonthYearLong(null) // null
 * formatEventMonthYearLong(undefined) // null
 */
export const formatEventMonthYearLong: DateFormatter = (date) => {
  return date && isValidDate(date) ? formatDateUtc(date, 'MMMM YYYY') : null;
};

/**
 * Returns the date as MMMM DD, YYYY (ddd) (January 10, 2020 (Fri)) using the UTC timezone
 *
 * @example
 * formatEventMonthYearLong(new Date('2020-01-10T00:00:00.000Z')) // January 10, 2020 (Fri)
 * formatEventMonthYearLong(null) // null
 * formatEventMonthYearLong(undefined) // null
 */
export const formatEventDateLong: DateFormatter = (date) => {
  return date && isValidDate(date) ? formatDateUtc(date, 'MMMM D, YYYY (ddd)') : null;
};

/**
 * Formats a past time, relative to today.
 * - If it is today, return the time (7:00 pm)
 * - If it is within 14 days (the default) return the month and day (Feb 14)
 * - If it is outside the 14 day range, return an event date (Feb 14, 2021)
 *
 * The time cutoff for switching to a formatter date instead of a relative time
 * can be configured as can the default formatter when the date falls outside the
 * cutoff range.
 *
 * @example
 * formatRelativeTime(...) // 7:00 pm
 * formatRelativeTime(...) // Feb 14
 * formatRelativeTime(...) // Mar 29, 2021
 */
export const formatRelativeTime = (
  time: DateValue,
  rangeCutOff = -14,
  defaultFormatter: DateFormatter = formatEventDate
): string => {
  let datePart = formatTimeAgo(time);
  if (isToday(time)) {
    datePart = formatDate(time, 'h:mm a');
  } else if (isAfterDate(time, addDays(new Date(), rangeCutOff))) {
    datePart = formatDate(time, 'MMM D');
  } else {
    datePart = defaultFormatter(time) as string;
  }
  return datePart;
};

/** returns a budget range, min, or max
 *
 * @example formatBudget(100, 5000) // '$100-$5,000'
 * @example formatBudget(1000) // '$1,000+'
 * @example formatBudget(null, 50000) // 'Up to $50,000'
 *
 */
export const formatBudget = (startPrice?: number | null, endPrice?: number | null): string => {
  let budget = '';
  const [start, end] = [startPrice, endPrice].map((priceInt) => {
    return priceInt ? formatWithCommas(priceInt) : null;
  });

  if (start && end) {
    budget = `$${start}\u2013$${end}`; // n-dash with no spaces around
  } else if (start && !end) {
    budget = `$${start}+`;
  } else if (end && !start) {
    budget = `Up to $${end}`;
  } else if (end || start) {
    budget = `$${end || start}`;
  }

  return budget;
};

/** takes an address object or string and returns a correctly-cased `city, state` string
 *
 * @example formatLocation({ city: 'Germantown', stateProvince: 'NY', ... }); // 'Germantown, NY'
 * @example formatLocation('germantown, ny'); // 'Germantown, NY'
 *
 */
export const formatLocation = (location?: Partial<AddressView> | string | null): string => {
  if (!location) return '';
  let city;
  let stateProvince;
  if (typeof location === 'string') {
    const matches = location.match(/(.+),\s*(.+)/);
    if (matches) {
      [, city, stateProvince] = matches;
    } else if (location.length === 2) {
      stateProvince = location; // The string is probably a state code
    } else {
      city = location; // The string is probably a city
    }
  } else {
    ({ city, stateProvince } = location);
  }
  city = titleCase(city).trim();
  city = city.replace(/Mc([a-z])/i, (_, captured) => `Mc${_capitalize(captured)}`);
  city = city.replace(/'([a-z]\S)/i, (_, captured) => `'${_capitalize(captured)}`);
  city = city.replace(/\s([dl])'/i, (_, captured) => ` ${captured.toLowerCase()}'`);
  stateProvince = stateProvince?.toUpperCase().trim();
  return [city, stateProvince].filter((e) => e).join(', ');
};

/**
 * takes in a string and converts it to a number if possible, removing commas if present.
 *
 * If the value cannot be converted to a number, returns undefined.
 *
 * @example formatStringToNumber("1,000"); // 1000
 * @example formatStringToNumber("cat"); // undefined
 * @example formatStringToNumber(""); // undefined
 *
 */
export const formatStringToNumber = (value?: unknown): number | undefined => {
  if (_isString(value)) {
    const strippedVal = value.replace(/,/g, '');
    const parsedVal = parseInt(strippedVal, 10);
    return _isInteger(parsedVal) ? parsedVal : undefined;
  }
  return _isInteger(value) ? (value as number) : undefined;
};

/** takes in a string and capitalizes first letters while respecting a hyphen.
 *
 * @example capitalizeFirstLetters('all-inclusive'); // All-Inclusive
 *
 */
export const capitalizeFirstLetters = (str: string): string => str.replace(/\w+/g, _startCase);

/**
 * Formats a phone number as a string of numbers for a tel link
 *
 * @example formatPhoneNumberLink('(555) 555-5555'); // 5555555555
 */
export const formatPhoneNumberLink = (phoneNumber: string): string => {
  return phoneNumber.replace(/[^0-9+]/g, '');
};
